import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';
import globalMessages from 'translations/messages/global-messages';

const PeriodicReport = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="16"
    height="16"
    {...props}
  >
    <title>
      {props.title || props.intl.formatMessage(globalMessages.export)}
    </title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5267 1.19418C1.9174 0.803478 2.4473 0.583984 2.99984 0.583984H8.33317C8.53208 0.583984 8.72285 0.663002 8.8635 0.803654L12.8635 4.80365C13.0042 4.94431 13.0832 5.13507 13.0832 5.33398V9.33398C13.0832 9.7482 12.7474 10.084 12.3332 10.084C11.919 10.084 11.5832 9.7482 11.5832 9.33398V5.64464L8.02251 2.08398H2.99984C2.84513 2.08398 2.69675 2.14544 2.58736 2.25484C2.47796 2.36423 2.4165 2.51261 2.4165 2.66732V13.334C2.4165 13.4887 2.47796 13.6371 2.58736 13.7465C2.69675 13.8559 2.84513 13.9173 2.99984 13.9173H6.99984C7.41405 13.9173 7.74984 14.2531 7.74984 14.6673C7.74984 15.0815 7.41405 15.4173 6.99984 15.4173H2.99984C2.4473 15.4173 1.9174 15.1978 1.5267 14.8071C1.136 14.4164 0.916504 13.8865 0.916504 13.334V2.66732C0.916504 2.11478 1.136 1.58488 1.5267 1.19418Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33317 0.583984C8.74738 0.583984 9.08317 0.919771 9.08317 1.33398V4.58398H12.3332C12.7474 4.58398 13.0832 4.91977 13.0832 5.33398C13.0832 5.7482 12.7474 6.08398 12.3332 6.08398H8.33317C7.91896 6.08398 7.58317 5.7482 7.58317 5.33398V1.33398C7.58317 0.919771 7.91896 0.583984 8.33317 0.583984Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0302 14.5309C9.73727 14.8238 9.2624 14.8238 8.96951 14.5309L6.96951 12.5309C6.67661 12.238 6.67661 11.7632 6.96951 11.4703C7.2624 11.1774 7.73727 11.1774 8.03017 11.4703L9.49984 12.9399L10.9695 11.4703C11.2624 11.1774 11.7373 11.1774 12.0302 11.4703C12.3231 11.7632 12.3231 12.238 12.0302 12.5309L10.0302 14.5309Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.49984 14.7506C9.08562 14.7506 8.74984 14.4148 8.74984 14.0006V10.5006C8.74984 10.0864 9.08562 9.75061 9.49984 9.75061C9.91405 9.75061 10.2498 10.0864 10.2498 10.5006V14.0006C10.2498 14.4148 9.91405 14.7506 9.49984 14.7506Z"
    />
  </svg>
);

PeriodicReport.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};
export default compose(injectIntl)(PeriodicReport);
