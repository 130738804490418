import { fromJS } from 'immutable';

import { SET_ERROR, SET_INFO } from './constants';

export const initialState = fromJS({
  error: null,
  info: null,
});

function errorReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ERROR:
      return state.set('error', action.error);
    case SET_INFO:
      return state.set('info', action.info);
    default:
      return state;
  }
}

export default errorReducer;
