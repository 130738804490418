import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectContractsPageState = state =>
  state.contractsPage || initialState;

const selectLoadingFlag = () =>
  createSelector(selectContractsPageState, collaboratorsState =>
    collaboratorsState.get('loading'),
  );

const selectCurrentPlan = () =>
  createSelector(selectContractsPageState, collaboratorsState =>
    collaboratorsState.get('plan').toJS(),
  );

const selectAvailableOffers = () =>
  createSelector(selectContractsPageState, collaboratorsState =>
    collaboratorsState.get('offers').toJS(),
  );

const selectMembers = () =>
  createSelector(selectContractsPageState, collaboratorsState =>
    collaboratorsState.get('members'),
  );

const selectUsers = () =>
  createSelector(selectContractsPageState, collaboratorsState =>
    collaboratorsState.get('users'),
  );

const selectMonthly = () =>
  createSelector(selectContractsPageState, collaboratorsState =>
    collaboratorsState.get('monthly'),
  );

export {
  selectLoadingFlag,
  selectCurrentPlan,
  selectAvailableOffers,
  selectMembers,
  selectUsers,
  selectMonthly,
};
