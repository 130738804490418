import { push } from 'connected-react-router';
import { takeLatest, put } from 'redux-saga/effects';

import { REDIRECT } from './constants';

function* redirect({ url }) {
  yield put(push(url));
}

export default function* saga() {
  yield takeLatest(REDIRECT, redirect);
}
