import { withStyles } from '@mui/styles';
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Placeholder from './Placeholder';

const styles = () => ({
  wrapperAuthor: {
    display: 'flex',
    alignItems: 'center',
  },
  alignBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    height: '2em',
  },
  leftMargin: {
    marginLeft: '0.625rem',
  },
  border: {
    borderRadius: '5px',
  },
});

const MemberCellSkeleton = props => {
  const { classes } = props;
  return (
    <div className={classNames(classes.wrapperAuthor)}>
      <Placeholder
        className={classes.border}
        shape="circle"
        height="32px"
        width="32px"
      />
      <div className={classNames(classes.alignBetween, classes.leftMargin)}>
        <Placeholder height="10px" width="60%" />
        <Placeholder height="8px" width="50%" />
      </div>
    </div>
  );
};

MemberCellSkeleton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MemberCellSkeleton);
