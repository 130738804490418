export default {
  showLoader: () => {
    const initialLoader = document.getElementById('initialLoader');
    if (initialLoader) {
      initialLoader.style.visibility = 'visible';
    }
  },
  hideLoader: () => {
    const initialLoader = document.getElementById('initialLoader');
    if (initialLoader) {
      initialLoader.style.visibility = 'hidden';
    }
  },
};
