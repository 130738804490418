import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';
import { reduxForm, Field, getFormValues } from 'redux-form/immutable';
import isEmpty from 'lodash/isEmpty';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import Dialog from 'components/dialogs/Dialog';
import DialogSubmit from 'components/dialogs/DialogSubmit';
import classNames from 'classnames';
import { createStructuredSelector } from 'reselect';

import CleanableTextField from 'components/CleanableTextField';
import LoadingIndicator from 'components/LoadingIndicator';

import globalMessages from 'translations/messages/global-messages';
import { requiredByKey } from 'utils/validators';
import { addBusinessOrganization } from './utils/config';

const validateAddBusinessOrganisation = values => requiredByKey(values, 'name');

export const styles = theme => ({
  contentTitle: {
    display: 'flex',
    fontSize: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
    padding: theme.spacing(2),
  },
  textName: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '120%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.dark,
    marginBottom: theme.spacing(5),
  },
  textField: {
    fontWeight: 'initial',
  },
  container: {
    padding: '3.5em 4.5em',
  },
  contentForm: {
    width: '100%',
  },
  loading: {
    opacity: 0,
  },
});

export class AddBusinessOrganisation extends PureComponent {
  onSubmit = (values, f, currentProps) => {
    const valuesString = JSON.stringify(values, null, 2);
    const objectForm = JSON.parse(valuesString);
    if (currentProps.dirty) {
      this.props.onAddOrganisation(objectForm.name);
    }
  };
  render() {
    const { classes, handleSubmit, intl, loading, formValues } = this.props;

    const formValidation = validateAddBusinessOrganisation(formValues);

    return (
      <Dialog
        id="dialogPlans"
        open
        fullWidth
        maxWidth="sm"
        onCloseDialog={this.props.onCloseDialog}
        loading={loading}
        dialogContentClasses={{ root: classes.contentTitle }}
        dialogTitleNode={intl.formatMessage(globalMessages.create_organization)}
      >
        {loading && <LoadingIndicator size="40" />}
        <form
          onSubmit={handleSubmit(this.onSubmit)}
          className={classNames(classes.contentForm, {
            [classes.loading]: loading,
          })}
        >
          <div className={classes.container}>
            <Typography noWrap className={classes.textName}>
              {intl.formatMessage(globalMessages.what_is_organization_name)}
            </Typography>
            <Field
              name="name"
              placeholder={intl.formatMessage(
                globalMessages.enter_organization_name,
              )}
              required
              customClassName={classes.textField}
              component={CleanableTextField}
              autoFocus
            />
          </div>
          <div className={classes.footer}>
            <DialogSubmit
              onClose={this.props.onCloseDialog}
              disabled={!isEmpty(formValidation)}
            />
          </div>
        </form>
      </Dialog>
    );
  }
}
AddBusinessOrganisation.propTypes = {
  intl: intlShape.isRequired,
  loading: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  formValues: PropTypes.object,
  onCloseDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
  onAddOrganisation: PropTypes.func,
};

AddBusinessOrganisation.defaultProps = {
  formValues: fromJS({}),
  loading: false,
};

const mapStateToProps = createStructuredSelector({
  formValues: getFormValues(addBusinessOrganization),
});

const withConnect = connect(
  mapStateToProps,
  null,
);

export default compose(
  withConnect,
  withStyles(styles),
  injectIntl,
  reduxForm({
    destroyOnUnmount: true,
    enableReinitialize: false,
    validate: validateAddBusinessOrganisation,
  }),
)(AddBusinessOrganisation);
