import React from 'react';

import Slider from 'react-slick';
import { compose } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';
import imgSlide1 from './assets/img-slide-1.png';
import imgSlide12X from './assets/img-slide-1-2x.png';
import imgSlide2 from './assets/img-slide-2.png';
import imgSlide22X from './assets/img-slide-2-2x.png';
import imgSlide3 from './assets/img-slide-3.png';
import imgSlide32X from './assets/img-slide-3-2x.png';
import imgSlide4 from './assets/img-slide-4.png';
import imgSlide42X from './assets/img-slide-4-2x.png';
import imgSlide5 from './assets/img-slide-5.png';
import imgSlide52X from './assets/img-slide-5-2x.png';
import { CarouselItem } from './carousel-item';
import ChevronLeft from '../../../components/SvgComponents/icons/ChevronLeft';
import ChevronRight from '../../../components/SvgComponents/icons/ChevronRight';

const LoginCarouselComp = ({ intl }) => {
  const items = [
    {
      image: imgSlide1,
      image2x: imgSlide12X,
      title: intl.formatMessage(globalMessages.onBoarding_title1),
      description: intl.formatMessage(globalMessages.onBoarding_description1),
    },
    {
      image: imgSlide2,
      image2x: imgSlide22X,
      title: intl.formatMessage(globalMessages.onBoarding_title2),
      description: intl.formatMessage(globalMessages.onBoarding_description2),
    },
    {
      image: imgSlide3,
      image2x: imgSlide32X,
      title: intl.formatMessage(globalMessages.onBoarding_title3),
      description: intl.formatMessage(globalMessages.onBoarding_description3),
    },
    {
      image: imgSlide4,
      image2x: imgSlide42X,
      title: intl.formatMessage(globalMessages.onBoarding_title4),
      description: intl.formatMessage(globalMessages.onBoarding_description4),
    },
    {
      image: imgSlide5,
      image2x: imgSlide52X,
      title: intl.formatMessage(globalMessages.onBoarding_title5),
      description: intl.formatMessage(globalMessages.onBoarding_description5),
    },
  ];

  const settings = {
    centerMode: true,
    dots: false,
    adaptiveHeight: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: 0,
    autoplay: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    prevArrow: <ChevronLeft />,
    nextArrow: <ChevronRight />,
  };

  return (
    <div id="loginCarousel">
      <Slider {...settings}>
        {items.map(item => <CarouselItem key={item.title} {...item} />)}
      </Slider>
    </div>
  );
};

LoginCarouselComp.propTypes = {
  intl: intlShape.isRequired,
};

export const LoginCarousel = compose(injectIntl)(LoginCarouselComp);
