import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

function Edit(props) {
  const { intl, title, ...otherProps } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <title>{title || intl.formatMessage(globalMessages.edit)}</title>
      <path
        fill={props.color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.66943C11.8239 2.66943 11.6549 2.73941 11.5303 2.86398L3.34391 11.0504L3.03079 12.3029L4.28325 11.9898L12.4697 3.80332C12.5314 3.74165 12.5803 3.66843 12.6137 3.58783C12.6471 3.50721 12.6642 3.42083 12.6642 3.33365C12.6642 3.24646 12.6471 3.16009 12.6137 3.07946C12.5803 2.99887 12.5314 2.92565 12.4697 2.86398C12.408 2.80232 12.3348 2.75338 12.2542 2.71999C12.1736 2.68661 12.0872 2.66943 12 2.66943ZM10.4697 1.80332C10.8756 1.39745 11.426 1.16943 12 1.16943C12.2842 1.16943 12.5656 1.22542 12.8282 1.33417C13.0908 1.44293 13.3294 1.60233 13.5303 1.80332C13.7313 2.00429 13.8907 2.24287 13.9995 2.50544C14.1082 2.76797 14.1642 3.0494 14.1642 3.33365C14.1642 3.6179 14.1082 3.89932 13.9995 4.16186C13.8907 4.42442 13.7313 4.66301 13.5303 4.86398L5.19701 13.1973C5.10089 13.2934 4.98046 13.3616 4.84858 13.3946L2.18192 14.0613C1.92634 14.1252 1.65597 14.0503 1.46969 13.864C1.2834 13.6777 1.20851 13.4073 1.27241 13.1517L1.93908 10.4851C1.97204 10.3532 2.04023 10.2328 2.13635 10.1367L10.4697 1.80332ZM7.25002 13.3337C7.25002 12.9194 7.5858 12.5837 8.00002 12.5837H14C14.4142 12.5837 14.75 12.9194 14.75 13.3337C14.75 13.7479 14.4142 14.0837 14 14.0837H8.00002C7.5858 14.0837 7.25002 13.7479 7.25002 13.3337Z"
      />
    </svg>
  );
}
Edit.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
  color: PropTypes.string,
};

Edit.defaultProps = {
  title: '',
  color: '#848F9D',
};

export default injectIntl(Edit);
