/* eslint-disable no-console */
import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { getRemoteConfig, getValue } from 'firebase/remote-config';
import isEmpty from 'lodash/isEmpty';

import localStorageUser from 'utils/localStorageUser';

import firebaseApp from '../firebase';

const analytics = getAnalytics(firebaseApp);
const remoteConfig = getRemoteConfig(firebaseApp);

/**
 * Obfuscation of our analytics libraries, should be more
 * convenient if we change libraries or needs to automate some statistics
 */
class Analytics {
  debug = false;

  static isOnHomeLocation(pathname) {
    return pathname?.includes('/projects/');
  }

  activeDebugMode = () => {
    this.debug = true;
  };

  getPersistentParameters = () => {
    const parameters = {};
    const organization = localStorageUser.getBusinessOrganizationLocalStorage();
    const oktaToken = localStorageUser.getOktaTokenStorage();
    const userEmail = oktaToken?.idToken?.claims?.email || oktaToken?.accessToken?.claims?.sub;

    const projects = localStorageUser.getRecentlyViewedProjects(organization.id);

    if (!isEmpty(organization)) {
      parameters.organization_id = organization?.public_id;
      parameters.offer = organization?.offerDetails?.name;
    }
    if (!isEmpty(organization?.offerDetails)) {
      parameters.offer = organization?.offerDetails?.name;
    }
    if (userEmail) {
      parameters.mail_domain = userEmail?.split('@')[1];
    }
    const userId = oktaToken?.accessToken?.claims?.fcUserId;
    if (userId) {
      parameters.user_id = userId;
    }

    if (this.constructor.isOnHomeLocation(window?.location?.pathname) && projects.length > 0) {
      parameters.project_id = projects[0].project_id;
    }

    return parameters;
  };

  setUserID = id => {
    setUserId(analytics, id);
  };

  setUserProperties = params => {
    setUserProperties(analytics, params);
  };

  track = (key, params = {}) => {
    const activate = getValue(remoteConfig, 'web_analytics_console')?.asBoolean();

    if (activate || this.debug) {
      console.log('track', key, params);
    }

    logEvent(analytics, key, {
      ...params,
      ...this.getPersistentParameters(),
    });
  };

  trackError = (key, params = {}) => {
    console.log('track', key, params);

    logEvent(key, {
      ...params,
      ...this.getPersistentParameters(),
    });
  };
}

export default new Analytics();

// Analytics constants
export const SOURCE = {
  lists: 'lists',
  massEdit: 'mass_edit',
};
