import React from 'react';
import PropTypes from 'prop-types';

function Two(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{props.title || '2'}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM10.6406 11.6813H5.51875V10.6406C7.68125 8.24063 8.88125 7.35938 8.88125 6.4C8.88125 6.08125 8.71875 5.35938 7.91875 5.35938C6.88125 5.35938 6.88125 6.4 6.88125 6.64062H5.35938C5.35938 5.91875 5.91875 4.24062 7.91875 4.24062C9.91875 4.24062 10.3188 5.44063 10.3188 6.31875C10.3188 7.51875 9.44062 8.48125 7.35938 10.5594H10.5594V11.6813H10.6406Z"
      />
    </svg>
  );
}
Two.propTypes = {
  title: PropTypes.string,
};

Two.defaultProps = {
  title: '',
};

export default Two;
