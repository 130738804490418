import React from 'react';
import PropTypes from 'prop-types';

export const TypePin = props => (
  <svg
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.11 19.2893L12.5187 20.8372C12.2955 21.0543 11.9337 21.0543 11.7106 20.8372L10.1962 19.3642C6.64664 18.5686 4 15.4756 4 11.7814C4 7.48386 7.58172 4 12 4C16.4183 4 20 7.48386 20 11.7814C20 15.3686 17.5045 18.3888 14.11 19.2893ZM22 11.7814C22 16.1396 19.1001 19.7841 15.1511 21.0667L13.9132 22.2709C12.9137 23.243 11.3155 23.243 10.3161 22.2709L9.18305 21.1688C5.06318 19.9865 2 16.2615 2 11.7814C2 6.32742 6.52974 2 12 2C17.4703 2 22 6.32742 22 11.7814Z"
      fill="current"
    />
  </svg>
);

TypePin.propTypes = {
  size: PropTypes.number,
}
