import { fromJS } from 'immutable';

import localStorageUser from 'utils/localStorageUser';

import {
  SET_RENAME_ITEM,
  SET_LANGUAGE_DEFAULT,
  RESET_LANGUAGE_DEFAULT,
  SET_CHECKED_PROJECTS,
  SET_ALL_CHECKED_PROJECTS,
  CLEAR_CHECKED_PROJECTS,
  SET_MODIFY,
} from './constants';

export const initialState = fromJS({
  itemId: '',
  language: localStorageUser.getLanguageTradeLocalStorage(),
  isModify: false,
  checkedProjects: [],
  allChecked: false,
});

function tradePageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_RENAME_ITEM:
      return state.set('itemId', fromJS(action.id));
    case SET_LANGUAGE_DEFAULT:
      return state.set('language', fromJS(action.language));
    case RESET_LANGUAGE_DEFAULT:
      return state.set('language', fromJS(localStorageUser.getLanguageTradeLocalStorage()));
    case SET_CHECKED_PROJECTS:
      return state.set('checkedProjects', fromJS(action.projects));
    case SET_ALL_CHECKED_PROJECTS:
      return state.set('allChecked', action.allChecked);
    case CLEAR_CHECKED_PROJECTS:
      return state.set('checkedProjects', fromJS([])).set('allChecked', false);
    case SET_MODIFY:
      return state.set('isModify', action.isModify);
    default:
      return state;
  }
}
export default tradePageReducer;
