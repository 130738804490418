const scope = 'superAdmin/commonObservation/';

export const SET_RENAME_ITEM = `${scope}SET_RENAME_ITEM`;
export const RENAME_ITEM = `${scope}RENAME_ITEM`;
export const DELETE_ITEM = `${scope}DELETE_ITEM`;
export const GET_TRADE = `${scope}GET_TRADE`;
export const SET_TRADE = `${scope}SET_TRADE`;
export const SET_LANGUAGE_DEFAULT = `${scope}SET_LANGUAGE_DEFAULT`;
export const ADD_OBSERVATION = `${scope}ADD_OBSERVATION`;
export const RESET_LANGUAGE_DEFAULT = `${scope}RESET_LANGUAGE_DEFAULT`;
