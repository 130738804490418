import React from 'react';

import { Divider, ListItem, ListItemIcon, ListItemText, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import AvatarRectangle from 'components/AvatarV3/RectangleAvatar';
import { TypographyEllipsis } from 'components/Templates/TypographyEllipsis';
import Tooltip from 'components/Tooltip';
import globalMessages from 'translations/messages/global-messages';

export const ListItem_EntityContainer = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: `${theme.spacing(2)}`,

  '& .MuiListItemText-root': {
    margin: 0,
  },
}));

export const TypographyEllipsis_EntityName = styled(TypographyEllipsis)(({ theme }) => ({
  lineHeight: '1.3em',
  display: 'block',
  alignItems: 'center',
  marginLeft: theme.spacing(2),
}));

export const Div_EntityInfosContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  marginLeft: theme.spacing(2),
}));

export const TypographyEllipsis_EntityInfos = styled(TypographyEllipsis)(() => ({
  lineHeight: '120%',
  display: 'block',
  alignItems: 'center',
}));

const ListItemText_Container = styled(ListItemText, {
  shouldForwardProp: prop => prop !== 'hideTextInSmallScreen',
})(({ theme, hideTextInSmallScreen }) => ({
  ...(hideTextInSmallScreen && {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }),
}));

const BreadcrumbRow = ({
  entity,
  showDivider,
  onClick,
  showMember,
  publicId,
  intl,
  sx,
  sxIcon,
  className,
  hideTextInSmallScreen,
  hideAvatar,
  disableTooltips = false,
  isButton = true,
}) => {
  const entityNameRef = React.useRef(null);
  const entityIdRef = React.useRef(null);

  return (
    <>
      <ListItem_EntityContainer
        onClick={() => onClick(entity)}
        button={isButton}
        sx={sx}
        className={className}
      >
        {!hideAvatar && (
          <ListItemIcon sx={{ minWidth: 0, ml: 2, ...sxIcon }}>
            <AvatarRectangle
              src={entity?.media_resource?.url || entity?.logo?.url || entity?.logo?.pre_signed_url}
              name={entity?.name || ''}
              alt={entity?.name}
            />
          </ListItemIcon>
        )}
        <ListItemText_Container hideTextInSmallScreen={hideTextInSmallScreen}>
          <Tooltip
            title={entity?.name}
            forwardedRef={entityNameRef}
            key={`tooltip-name-${entity?.name}`}
            placement="bottom"
            disableHoverListener={disableTooltips}
          >
            <TypographyEllipsis_EntityName className="entityName" variant="body" ref={entityNameRef}>
              {entity?.name || ''}
            </TypographyEllipsis_EntityName>
          </Tooltip>
          <Div_EntityInfosContainer>
            <Tooltip
              title={publicId || entity?.public_id}
              forwardedRef={entityIdRef}
              key={`tooltip-name-${publicId || entity?.public_id}`}
              placement="bottom"
              disableHoverListener={disableTooltips}
            >
              <TypographyEllipsis_EntityInfos sx={{ display: 'block' }} ref={entityIdRef} variant="subtitleLight">
                {publicId || entity?.public_id}
              </TypographyEllipsis_EntityInfos>
            </Tooltip>
            {showMember && (
              <TypographyEllipsis_EntityInfos sx={{ overflow: 'visible' }} variant="subtitleLight">
                &nbsp;|&nbsp;{intl.formatMessage(globalMessages.groups_list_member, {
                  count: entity?.members_count,
                })}
              </TypographyEllipsis_EntityInfos>
            )}
          </Div_EntityInfosContainer>
        </ListItemText_Container>
      </ListItem_EntityContainer>
      {showDivider && <Divider variant="inset" component="li" sx={{ ml: 0 }} />}
    </>
  );
};

BreadcrumbRow.propTypes = {
  entity: PropTypes.object,
  showDivider: PropTypes.bool,
  onClick: PropTypes.func,
  showMember: PropTypes.bool,
  publicId: PropTypes.string,
  intl: intlShape.isRequired,
  sx: PropTypes.object,
  sxIcon: PropTypes.object,
  className: PropTypes.string,
  hideTextInSmallScreen: PropTypes.bool,
  hideAvatar: PropTypes.bool,
  disableTooltips: PropTypes.bool,
  isButton: PropTypes.bool,
};

export default BreadcrumbRow;
