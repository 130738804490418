import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@mui/styles';

const styles = theme => ({
  loadingWrap: {
    width: '100%',
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1000,
    bottom: 0,
    right: 0,
    textAlign: 'center',
  },
  loadingBackground: {
    backgroundColor: `${theme.palette.background.dark}1A`,
  },
  breath: {
    animation: 'grow-and-shrink 4000ms infinite ease-in-out',
  },
});

function LoadingIndicator(props) {
  const { classes, className, withBackground } = props;
  return (
    <div
      className={classNames({
        [classes.loadingWrap]: !className,
        [classes.loadingBackground]: !!withBackground,
        [className]: className,
      })}
    >
      <svg
        className={classes.breath}
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.8763 21.0427V0H29.8699V14.0532H43.9231V21.0468L22.8763 21.0427ZM0.846191 22.0301V29.0237H14.8994V43.0769H21.893V22.0301H0.846191Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="10.2136"
            y1="42.6079"
            x2="34.5228"
            y2="0.497785"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.01" stopColor="#FC9917" />
            <stop offset="0.2" stopColor="#FF8029" />
            <stop offset="0.64" stopColor="#FE6102" />
            <stop offset="0.93" stopColor="#F93C1F" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
LoadingIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
  withBackground: PropTypes.bool,
  className: PropTypes.string,
};

LoadingIndicator.defaultProps = {
  withBackground: true,
};

export default withStyles(styles)(LoadingIndicator);
