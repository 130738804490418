import React from 'react';

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import LoadingIndicator from 'components/LoadingIndicator';
import { updateModuleSettings } from 'containers/GlobalWrapper/modules/actions';
import { selectCurrentModuleMap } from 'containers/GlobalWrapper/modules/selectors';
import { selectLoadingFlag } from 'containers/GlobalWrapper/selectors';
import globalMessages from 'translations/messages/global-messages';

import ActivateLibraryForm from '../../components/ActivateLibraryForm';
import { MODULE_SETTINGS } from '../../constants';

export const styles = theme => ({
  activateLibrary: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: `${theme.palette.info.main}1A`,
    borderRadius: 16,
    border: `1px solid ${theme.palette.info.main}`,
    padding: `${theme.spacing(4)} 0 ${theme.spacing(4)} ${theme.spacing(4)}`,
    maxWidth: 380,
    marginBottom: theme.spacing(3),
  },
  activateLibraryFlex: {
    display: 'flex',
  },
  activeLibraryWrap: {
    flex: 1,
    paddingRight: theme.spacing(3),
  },
});

/**
 * ActivateLibrary
 */
function ActiveLibrary({
  intl,
  classes,
  children,
  loading,
  className,
  moduleId,
  handleActiveLibraryChange,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const moduleMap = useSelector(selectCurrentModuleMap());

  const onSubmit = (values, f, currentProps) => {
    if (currentProps.dirty) {
      const libraryStatus = values.get('libraryStatus');
      const settings = {
        is_observation_library_mandatory: libraryStatus,
        is_description_mandatory: !libraryStatus,
      };

      handleActiveLibraryChange();
      dispatch(
        updateModuleSettings({
          projectId: params.idData,
          moduleId: moduleId || params.itemId,
          settings,
        }),
      );
    }
  };

  return (
    <div className={classNames(classes.activateLibrary, className)}>
      {loading && <LoadingIndicator size="40" />}
      <div className={classes.activateLibraryFlex}>
        <div className={classes.activeLibraryWrap}>
          <Typography variant="h6" gutterBottom>
            {intl.formatMessage(globalMessages.observations_library)}
          </Typography>
          <Typography variant="body">
            {intl.formatMessage(globalMessages.activate_library_observations_description)}
          </Typography>
        </div>
        <div>
          <ActivateLibraryForm
            onSubmit={onSubmit}
            initialValues={{
              libraryStatus: moduleMap?.getIn(['settings', MODULE_SETTINGS.libraryStatus]),
            }}
            form="activate-library"
          />
        </div>
      </div>
      {children}
    </div>
  );
}

ActiveLibrary.propTypes = {
  loading: PropTypes.bool,
  intl: intlShape.isRequired,
  handleActiveLibraryChange: PropTypes.func,
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  moduleId: PropTypes.string,
};

ActiveLibrary.defaultProps = {
  classes: {},
};

const mapStateToProps = createStructuredSelector({
  loading: selectLoadingFlag(),
});

const withConnect = connect(mapStateToProps);

export default compose(
  withConnect,
  injectIntl,
  withStyles(styles),
)(ActiveLibrary);
