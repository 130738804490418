import React, { useRef } from 'react';

import { InputAdornment, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import uuid from 'uuid';

import Clear from 'components/SvgComponents/icons/Clear';
import Search from 'components/SvgComponents/icons/Search';
import searchTextFieldTheme from 'theme/search-text-field-theme';
import globalMessages from 'translations/messages/global-messages';
import { keyboard } from 'utils/constants';

export function SearchTextField(props) {
  const textInput = useRef(null);
  const {
    autoFocus,
    className,
    classes,
    disabled,
    intl,
    placeHolder,
    searchInputProps,
    searchText,
    size,
    style,
    ref,
  } = props;

  const handleClear = () => {
    props.onChange('');
    setTimeout(() => {
      if (textInput?.current) textInput.current.focus();
    }, 500);
  };

  const handleSearchTextField = ev => {
    if (ev.target) {
      props.onChange(ev.target.value);
    }
  };

  const handleKeyPress = ev => {
    if (ev.charCode === keyboard.enter) {
      handleSearchTextField(ev);
    }
  };

  const endAdornment = searchText ? (
    <InputAdornment position="end" onClick={handleClear}>
      <Clear className={classes.clearIcon} />
    </InputAdornment>
  ) : null;

  return (
    <div className={classNames(classes.searchTextField, className)} style={style}>
      <TextField
        ref={ref}
        autoFocus={autoFocus}
        value={searchText}
        variant="outlined"
        id={`search-${uuid.v4()}`} // avoid autocomplete
        disabled={disabled}
        fullWidth
        size={size}
        placeholder={placeHolder || `${intl.formatMessage(globalMessages.search_dot)}`}
        onChange={handleSearchTextField}
        onKeyPress={handleKeyPress}
        InputProps={{
          classes: {
            root: classes.cssInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
            adornedStart: classes.adornedStart,
            adornedEnd: classes.adornedEnd,
          },
          startAdornment: (
            <InputAdornment position="start" disablePointerEvents>
              <Search className={classes.searchIcon} />
            </InputAdornment>
          ),
          endAdornment,
          ...searchInputProps,
        }}
      />
    </div>
  );
}

SearchTextField.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  intl: intlShape.isRequired,
  onChange: PropTypes.func.isRequired,
  placeHolder: PropTypes.string,
  searchInputProps: PropTypes.object,
  searchText: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object,
  ref: PropTypes.object,
};

SearchTextField.defaultProps = {
  disabled: false,
  searchText: '',
  searchInputProps: {},
  style: {},
  ref: null,
};

export default injectIntl(withStyles(searchTextFieldTheme)(SearchTextField));
