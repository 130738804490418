import {
  ADD_DOCUMENTS,
  ADD_FOLDER,
  CREATE_AS_PLAN,
  DELETE_DOCUMENTS,
  GET_FOLDERS_CONTENT,
  GET_PLAN_FOLDERS_CONTENT,
  GET_SEARCH_IN_ALL_DOCUMENTS,
  MOVE_ITEMS_TO,
  SET_DOCUMENT_TO_RENAME,
  SET_LOADING_FLAG,
  SET_SEARCH_IN_ALL_DOCUMENTS,
  UPDATE_DOCUMENT,
  RENAME_DOCUMENT,
  UPLOAD_DOCUMENT,
  UPLOAD_DOCUMENT_FAILED,
  UPLOAD_DOCUMENT_SUCCESS,
} from './constants';

export function setLoadingFlag(loading) {
  return {
    type: SET_LOADING_FLAG,
    loading,
  };
}

export function addDocuments(documents, folderId, listRequestOption, callback) {
  return {
    type: ADD_DOCUMENTS,
    documents,
    folderId,
    listRequestOption,
    callback,
  };
}

export function addFolder(folder, listRequestOption, callback) {
  return {
    type: ADD_FOLDER,
    folder,
    listRequestOption,
    callback,
  };
}

export function deleteDocuments(ids, listRequestOption, callback) {
  return {
    type: DELETE_DOCUMENTS,
    ids,
    listRequestOption,
    callback,
  };
}

export function getFoldersContent(params, id, callback) {
  return {
    type: GET_FOLDERS_CONTENT,
    params,
    id,
    callback,
  };
}

export function getPlanFoldersContent(params, id, callback) {
  return {
    type: GET_PLAN_FOLDERS_CONTENT,
    id,
    callback,
    params,
  };
}

export function moveItems({ parentId, items, callback }) {
  return {
    type: MOVE_ITEMS_TO,
    parentId,
    items,
    callback,
  };
}

export function createAsPlan(document, folder_id, onRedirectClick) {
  return {
    type: CREATE_AS_PLAN,
    document,
    folder_id,
    onRedirectClick,
  };
}

export function getSearchInAllDocuments(params, search_term) {
  return {
    type: GET_SEARCH_IN_ALL_DOCUMENTS,
    search_term,
    params,
  };
}

export function setSearchInAllDocuments(results) {
  return {
    type: SET_SEARCH_IN_ALL_DOCUMENTS,
    results,
  };
}

export function setDocumentToRename(id) {
  return {
    type: SET_DOCUMENT_TO_RENAME,
    id,
  };
}

export function updateDocument(document, listRequestOption, callback) {
  return {
    type: UPDATE_DOCUMENT,
    document,
    listRequestOption,
    callback,
  };
}

export function renameDocument(document, listRequestOption) {
  return {
    type: RENAME_DOCUMENT,
    document,
    listRequestOption,
  };
}

export function uploadDocument(item, callbackSuccess, callbackError) {
  return {
    type: UPLOAD_DOCUMENT,
    item,
    callbackSuccess,
    callbackError,
  };
}

export function uploadDocumentSuccess(item) {
  return {
    type: UPLOAD_DOCUMENT_SUCCESS,
    item,
  };
}

export function uploadDocumentFailed(item) {
  return {
    type: UPLOAD_DOCUMENT_FAILED,
    item,
  };
}
