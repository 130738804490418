export const getUserFullName = user => {
  if (!user?.first_name && !user?.last_name && user?.email_local_part)
    return user?.email_local_part;

  if (!user?.first_name && !user?.last_name && user?.email) return user?.email;

  let name = '';
  if (user?.first_name) {
    name = user?.first_name;
  }
  if (user?.last_name) {
    name += ` ${user?.last_name}`;
  }

  return name;
};

export default getUserFullName;
