import React from 'react';

import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const styles = theme => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    height: 18,
    color: theme.palette.common.white,
    background: theme.palette.background.tertiary,
    borderRadius: 4,
  },
  colorPrimary: {
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.common.white,
  },
  colorSecondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  colorWarning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.main,
  },
  colorError: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main,
  },
  colorSuccess: {
    color: `${theme.palette.success.main} !important`,
    background: 'rgba(73, 203, 12, 0.1) !important',
  },
  outlined: {
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.background.tertiary}`,
  },
  outlinedPrimary: {
    color: theme.palette.text.dark,
    border: `1px solid ${theme.palette.background.dark}`,
  },
  outlinedSecondary: {
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  label: {
    padding: '2px 8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.75rem',
    display: 'flex',
    alignItems: 'center',
  },
  textBold: {
    fontFamily: 'Effra Medium, Roboto !important',
  },
});

export function Tile(props) {
  const { classes, className, color = 'default', label, variant = 'default', textBold } = props;

  if (label) {
    return (
      <div
        className={classNames(
          classes.root,
          {
            [classes.colorPrimary]: color === 'primary',
            [classes.colorSecondary]: color === 'secondry',
            [classes.colorWarning]: color === 'warning',
            [classes.colorError]: color === 'error',
            [classes.colorSuccess]: color === 'success',
            [classes.outlined]: variant === 'outlined',
            [classes.outlinedPrimary]: variant === 'outlined' && color === 'primary',
            [classes.outlinedSecondary]: variant === 'outlined' && color === 'secondary',
            [classes.textBold]: !!textBold,
          },
          className,
        )}
      >
        <span className={classes.label}>{label}</span>
      </div>
    );
  }
  return null;
}

Tile.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'warning', 'error', 'success']),
  label: PropTypes.node,
  variant: PropTypes.oneOf(['default', 'outlined']),
  textBold: PropTypes.bool,
};

export default withStyles(styles)(Tile);
