import React, { useContext, useEffect } from 'react';

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { getWorkspace } from 'containers/GlobalWrapper/actions';
import { OrganizationContext } from 'containers/GlobalWrapper/BusinessOrganizationDataValidation';
import { selectGlobalWorkspace } from 'containers/GlobalWrapper/selectors';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { splitCenterEllipse } from 'utils/splitName';

import { getUserWorkspaces } from './actions';
import reducer from './reducer';
import saga from './saga';
import SwitcherSelect from './SwitcherSelect';

export const styles = theme => ({
  workspaceSwitcher: {
    display: 'flex',
    whiteSpace: 'initial',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
  },
  organizationName: {
    color: theme.palette.text.primary,
    fontSize: '0.75rem',
    marginBottom: theme.spacing(2),
  },
  switcherLabel: {
    wordWrap: 'break-word',
    width: 'calc(100% - 32px)',
  },
  clientReference: {
    marginLeft: theme.spacing(2),
  },
});

/**
 * WorkspaceSwitcher
 */
function WorkspaceSwitcher(props) {
  const {
    classes,
    match,
    dispatchGetWorkspace,
    globalWorkspace,
    placement,
    dispatchGetUserWorkspaces,
  } = props;
  const { workspaceId, organizationId } = match.params || {};
  const organization = useContext(OrganizationContext);

  useEffect(
    () => {
      if (organization?.id) dispatchGetUserWorkspaces(organization.id);
    },
    [organization?.id, dispatchGetUserWorkspaces],
  );

  useEffect(
    () => {
      if (workspaceId && organizationId) {
        dispatchGetWorkspace({ params: match.params });
      }
    },
    [workspaceId, dispatchGetWorkspace, match.params],
  );

  if (workspaceId) {
    return (
      <>
        <div className={classes.workspaceSwitcher}>
          <Typography flex={1} className={classes.switcherLabel} component="h1" variant="header">
            {globalWorkspace?.name && splitCenterEllipse(globalWorkspace?.name, 24)}{' '}
          </Typography>
          <div>
            <SwitcherSelect placement={placement} />
          </div>
        </div>
        <Typography variant="subTitleLight" component="p" className={classes.clientReference}>
          {globalWorkspace?.client_reference}
        </Typography>
      </>
    );
  }

  return (
    <div className={classes.workspaceSwitcher}>
      <Typography flex={1} className={classes.switcherLabel} component="h1" variant="header">
        {splitCenterEllipse(organization?.name, 24)}
      </Typography>
      <div>
        <SwitcherSelect placement={placement} />
      </div>
    </div>
  );
}

WorkspaceSwitcher.propTypes = {
  classes: PropTypes.object,
  globalWorkspace: PropTypes.object,
  match: PropTypes.object,
  dispatchGetWorkspace: PropTypes.func,
  placement: PropTypes.string,
  dispatchGetUserWorkspaces: PropTypes.func,
};

WorkspaceSwitcher.defaultProps = {
  classes: {},
};

const mapDispatchToProps = {
  dispatchGetWorkspace: getWorkspace,
  dispatchGetUserWorkspaces: getUserWorkspaces,
};
const mapStateToProps = createStructuredSelector({
  globalWorkspace: selectGlobalWorkspace(),
});

const withSaga = injectSaga({ key: 'workspaceSwitcher', saga });
const withReducer = injectReducer({ key: 'workspaceSwitcher', reducer });

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withSaga,
  withReducer,
  withConnect,
  withRouter,
  withStyles(styles),
)(WorkspaceSwitcher);
