import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

function Organization(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>
        {props.title || props.intl.formatMessage(globalMessages.organization)}
      </title>
      <path d="M2.66665 2C1.93131 2 1.33331 2.598 1.33331 3.33333V14H10.6666H14.6666V6C14.6666 5.27129 14.062 4.66667 13.3333 4.66667H10.6666V3.33333C10.6666 2.598 10.0686 2 9.33331 2H2.66665ZM3.99998 4.66667H5.33331V6H3.99998V4.66667ZM6.66665 4.66667H7.99998V6H6.66665V4.66667ZM10.6666 6H13.3333V12.6667H10.6666V11.3333H12V10H10.6666V8.66667H12V7.33333H10.6666V6ZM3.99998 7.33333H5.33331V8.66667H3.99998V7.33333ZM6.66665 7.33333H7.99998V8.66667H6.66665V7.33333ZM3.99998 10H5.33331V11.3333H3.99998V10ZM6.66665 10H7.99998V11.3333H6.66665V10Z" />
    </svg>
  );
}
Organization.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

Organization.defaultProps = {
  title: '',
};

export default injectIntl(Organization);
