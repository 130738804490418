export function isFeatureAccessible(organizationId, offerId, featureId, featureFlags) {
  const shouldUserOffer = featureFlags.is_offer_differentiation_activated;

  if (!shouldUserOffer) {
    return {
      isFeatureAccessible: true,
    };
  }

  const featureFlagData = featureFlags.offers_feature_activation;
  const data = featureFlagData ? JSON.parse(featureFlagData) : [];
  // Check if the feature is part of offer plan
  const feature = data.features.find(item => item.id === featureId);
  const isPartOfOfferPlan = !!feature;
  const isAccessible = isPartOfOfferPlan && feature.offer_ids.includes(offerId);
  const isInWhiteList = feature?.white_list?.includes(organizationId);
  const isInBlackList = feature?.black_list?.includes(organizationId);

  return {
    isFeatureAccessible: (isAccessible || !!isInWhiteList) && !isInBlackList,
    namePhraseKey: feature?.display?.name_phrase_key,
    nameFallback: feature?.display?.name_fallback,
  };
}
