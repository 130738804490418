import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import { moduleIcons } from './utils';

export const IconPicker = ({ icons = moduleIcons, input, disabled }) => {
  const onSelect = iconName => {
    if (disabled) {
      return;
    }
    input.onChange(iconName);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1rem',
      }}
    >
      {icons.map(icon => {
        const CustomComponent = icon.icon;
        return (
          <Box
            key={`component-${icon.name}`}
            sx={{
              display: 'flex',
              width: 42,
              height: 42,
              borderRadius: 26,
              border: icon.name === input.value ? 2 : 0,
              borderColor: 'text.primary',
              backgroundColor: 'background.primary',
              color: 'text.primary',
              button: {
                width: '100%',
                height: '100%',
              },
              '.icon': {
                height: 21,
                width: 21,
                margin: 'auto',
              },
            }}
          >
            <button type="button" onClick={() => onSelect(icon.name)}>
              <CustomComponent className="icon" {...CustomComponent.params} />
            </button>
          </Box>
        );
      })}
    </Box>
  );
};

IconPicker.propTypes = {
  classes: PropTypes.object,
  input: PropTypes.object.isRequired,
  icons: PropTypes.array,
  disabled: PropTypes.bool,
};
