const scope = 'components/TableDragDropSelfStanding/';
export const SET_LOADING_FLAG = `${scope}SET_LOADING_FLAG`;
export const GET_ITEMS = `${scope}GET_ITEMS`;
export const UPDATE_ITEMS = `${scope}UPDATE_ITEMS`;
export const SET_ITEMS = `${scope}SET_ITEMS`;
export const CLEAR_ITEMS = `${scope}CLEAR_ITEMS`;
export const SET_CHECKED_ITEMS = `${scope}SET_CHECKED_ITEMS`;
export const CLEAR_CHECKED_ITEMS = `${scope}CLEAR_CHECKED_ITEMS`;
export const RESET_TABLE = `${scope}RESET_TABLE`;
export const CLEAR_SEARCH_TEXT = `${scope}CLEAR_SEARCH_TEXT`;
export const SET_SEARCH_TEXT = `${scope}SET_SEARCH_TEXT`;
