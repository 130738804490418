import React from 'react';
import PropTypes from 'prop-types';

export const Flag = props => (
  <svg
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1C6.38728 1 5.25949 1.26917 4.50361 1.57152C4.12656 1.72234 3.8464 1.87972 3.64842 2.0117C3.54962 2.07757 3.47183 2.13675 3.41312 2.1851C3.38378 2.20926 3.35926 2.23068 3.33929 2.24883C3.32931 2.2579 3.32047 2.26616 3.31275 2.27352L3.302 2.2839L3.29724 2.28857L3.29502 2.29078L3.29394 2.29185C3.29342 2.29237 3.29289 2.29289 4 3L3.29289 2.29289C3.10536 2.48043 3 2.73478 3 3V14.9999V15V21.9999C3 22.5522 3.44772 22.9999 4 22.9999C4.55229 22.9999 5 22.5522 5 21.9999V15.538C5.06941 15.5036 5.15123 15.4665 5.24639 15.4285C5.74051 15.2308 6.61272 15 8 15C9.28069 15 10.3605 15.4212 11.6286 15.9285L11.6843 15.9508C12.9033 16.4385 14.3065 17 16 17C17.6127 17 18.7405 16.7308 19.4964 16.4285C19.8734 16.2777 20.1536 16.1203 20.3516 15.9883C20.4504 15.9224 20.5282 15.8632 20.5869 15.8149C20.6162 15.7907 20.6407 15.7693 20.6607 15.7512C20.6707 15.7421 20.6795 15.7338 20.6873 15.7265L20.698 15.7161L20.7028 15.7114L20.705 15.7092L20.7061 15.7082C20.7066 15.7076 20.7071 15.7071 20 15L20.7071 15.7071C20.8946 15.5196 21 15.2652 21 15V3C21 2.59554 20.7564 2.2309 20.3827 2.07612C20.0186 1.92533 19.601 2.00265 19.3153 2.27119C19.3074 2.27767 19.2837 2.29654 19.2422 2.3242C19.1589 2.37972 19.0016 2.47234 18.7536 2.57152C18.2595 2.76917 17.3873 3 16 3C14.7193 3 13.6395 2.57876 12.3714 2.07152L12.3157 2.04923C11.0967 1.56148 9.69345 1 8 1ZM5 13.3968V3.53805C5.06941 3.50364 5.15123 3.46654 5.24639 3.42848C5.74051 3.23083 6.61272 3 8 3C9.28069 3 10.3605 3.42124 11.6286 3.92848L11.6843 3.95077C12.9033 4.43852 14.3066 5 16 5C17.2918 5 18.2724 4.82731 19 4.6032V14.4619C18.9306 14.4964 18.8488 14.5335 18.7536 14.5715C18.2595 14.7692 17.3873 15 16 15C14.7193 15 13.6395 14.5788 12.3714 14.0715L12.3157 14.0492C11.0967 13.5615 9.69345 13 8 13C6.70825 13 5.7276 13.1727 5 13.3968Z"
      fill="current"
    />
  </svg>
);

Flag.propTypes = {
  size: PropTypes.number,
}
