import React from 'react';

import { IconButton, styled } from '@mui/material';
import { useTheme } from '@mui/styles';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import Moon from 'components/SvgComponents/icons/Moon';
import Sun from 'components/SvgComponents/icons/Sun';
import { useColorMode } from 'contexts/ColorModeProvider';
import { useFeatureFlags } from 'contexts/FeatureFlagsProvider';
import globalMessages from 'translations/messages/global-messages';
import { colorTheme } from 'utils/constants';

const IconButton_DarkModeStyle = styled(IconButton)(() => ({
  '& .darkModeSvgFill': {
    opacity: 0,
    position: 'absolute',
  },
  '&:hover .darkModeSvg': {
    opacity: 0,
  },
  '&:hover .darkModeSvgFill': {
    opacity: 1,
  },
}));

function SwitchThemeColorButton({ intl }) {
  const { mode, toggleColorMode } = useColorMode();
  const featureFlags = useFeatureFlags();
  const theme = useTheme();
  const isDarkMode = mode === colorTheme.dark;

  const colorModeIcon = !isDarkMode ? (
    <>
      <Moon
        height={24}
        width={24}
        title={intl.formatMessage(globalMessages.dark_mode)}
        color={theme.palette.text.primary}
        className={isDarkMode ? 'darkModeSvgFill' : 'darkModeSvg'}
        fill={!isDarkMode ? theme.palette.text.white : theme.palette.text.primary}
      />
      <Moon
        height={24}
        width={24}
        title={intl.formatMessage(globalMessages.dark_mode)}
        color={theme.palette.text.primary}
        className={isDarkMode ? 'darkModeSvg' : 'darkModeSvgFill'}
        fill={isDarkMode ? theme.palette.text.white : theme.palette.text.primary}
      />
    </>
  ) : (
    <>
      <Sun
        height={24}
        width={24}
        title={intl.formatMessage(globalMessages.dark_mode)}
        className={isDarkMode ? 'darkModeSvgFill' : 'darkModeSvg'}
        color={!isDarkMode ? theme.palette.background.white : theme.palette.text.primary}
        fill={theme.palette.background.primary}
      />
      <Sun
        height={24}
        width={24}
        title={intl.formatMessage(globalMessages.dark_mode)}
        className={isDarkMode ? 'darkModeSvg' : 'darkModeSvgFill'}
        color={!isDarkMode ? theme.palette.background.white : theme.palette.text.primary}
        fill={theme.palette.text.white}
      />
    </>
  );

  return featureFlags?.web_dark_mode ? (
    <IconButton_DarkModeStyle onClick={toggleColorMode}>{colorModeIcon}</IconButton_DarkModeStyle>
  ) : null;
}

SwitchThemeColorButton.propTypes = {
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(SwitchThemeColorButton);
