const scope = 'forms/';
export const SET_LOADING_FLAG = 'SET_LOADING_FLAG';
export const FORM_INSTANCES_IDS = 'form_instance_ids';
export const GET_STATUS = `${scope}GET_STATUS`;
export const SET_STATUS = `${scope}SET_STATUS`;
export const GET_PRIORITIES = `${scope}GET_PRIORITIES`;
export const GET_TEMPLATES = `${scope}GET_TEMPLATES`;
export const SET_TEMPLATES = `${scope}SET_TEMPLATES`;
export const CLEAR_TEMPLATES = `${scope}CLEAR_TEMPLATES`;
export const SET_PRIORITIES = `${scope}SET_PRIORITIES`;
export const SET_OBSERVATION_STATUS = `${scope}SET_OBSERVATION_STATUS`;
export const SET_LAST_CONFIGS = `${scope}SET_LAST_CONFIGS`;
export const SET_BULK_OBSERVATION_STATUS = `${scope}SET_BULK_OBSERVATION_STATUS`;
export const ADD_TEMPLATE_TO_PROJECT = `${scope}ADD_TEMPLATE_TO_PROJECT`;
export const REMOVE_TEMPLATE_TO_PROJECT = `${scope}REMOVE_TEMPLATE_TO_PROJECT`;
export const GET_AVAILABLE_FORMS = `${scope}GET_AVAILABLE_FORMS`;
export const ADD_FORM_INSTANCE = `${scope}ADD_FORM_INSTANCE`;
export const DELETE_FORM_INSTANCE = `${scope}DELETE_FORM_INSTANCE`;
export const GET_PHASES = `${scope}GET_PHASES`;
export const SET_PHASES = `${scope}SET_PHASES`;
export const NO_PHASE = `${scope}NO_PHASE`;
export const SET_BULK_PHASE = `${scope}SET_BULK_PHASE`;
export const IMPORT_FORMS = `${scope}IMPORT_FORMS`;
export const DOWNLOAD_IMPORT_TEMPLATE = `${scope}DOWNLOAD_IMPORT_TEMPLATE`;
export const GET_INFORMATIONS_ABOUT_AVAILABLE_FORMS = `${scope}GET_INFORMATIONS_ABOUT_AVAILABLE_FORMS`;
