import React, { useState, useEffect } from 'react';

import { CircularProgress, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import CardMedia from 'components/CardMedia';
import PdfViewer from 'components/PdfViewer';
import AlertCircle from 'components/SvgComponents/icons/AlertCircle';
import Download from 'components/SvgComponents/icons/Download';
import Trash from 'components/SvgComponents/icons/Trash';
import Upload from 'components/SvgComponents/icons/Upload';
import combineStyles from 'theme/combineStyles';
import dropZoneStyle from 'theme/dropzone-theme';
import globalMessages from 'translations/messages/global-messages';
import { generateChunks } from 'utils/chunkUploader';
import { usePrevious } from 'utils/hooks';

import FilePreviewDwg from './FileErrorPreviewDwg';

export const AcceptImages = {
  'image/jpeg': ['.jpeg', '.jpg', '.JPG', '.jfif'],
  'image/png': ['.png', '.PNG'],
  'image/gif': ['.gif'],
  'image/tiff': ['.tif', '.tiff'],
  'image/bmp': ['.bmp'],
};

export const AcceptPdf = {
  'application/pdf': ['.pdf', '.PDF'],
};

export const AcceptXLS = {
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
};

export const AcceptDWG = {
  'image/x-dwg': ['.dwg'],
  'application/acad': ['.dwg'],
};

export const AcceptIFC = {
  'application/x-step': ['.ifc'],
  'application/octet-stream': ['.ifc'],
};

export const AcceptDesktop = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/msword': ['.doc'],
  'application/vnd.ms-powerpoint': ['.ppt', '.pps', '.ppt'],
  'application/rtf': ['.rtf'],
  'text/plain': ['.txt'],
  'application/vnd.ms-works': ['.wps'],
  'application/vnd.sun.xml.writer': ['.sxw'],
  'application/vnd.oasis.opendocument.text': ['.odt'],
  'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
  'application/vnd.oasis.opendocument.presentation': ['.odp'],
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow': ['.ppsx'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
  'application/vnd.oasis.opendocument.presentation-template': ['.otp'],
};

export const AcceptDeveloper = {
  'application/json': ['.json'],
};

export const AcceptAudio = {
  'audio/mpeg': ['.mp3'],
  'audio/wav': ['wav'],
  'application/ogg': ['.ogg'],
  'audio/x-ms-wma': ['wma'],
  'audio/x-midi': ['mid'],
};

export const AcceptVideo = {
  'video/quicktime': ['.mov'],
  'video/avi': ['.avi'],
  'video/mp4': ['.mp4'],
  'video/x-flv': ['.flv'],
  'video/x-ms-wmv': ['.wmv'],
};

export const AcceptCSV = {
  'text/csv': ['.csv'],
};

export const AcceptCarbonFile = {
  'application/xml': ['.xml'],
  'text/html': ['.html'],
  'application/vnd.oasis.opendocument.text': ['.odt'],
  'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
  'application/vnd.oasis.opendocument.presentation': ['.odp'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
  'application/vnd.oasis.opendocument.graphics': ['.odg'],
  'application/vnd.adobe.indesign-idml-package': ['.idml'],
};

export const styles = theme => ({
  floatIcon: {
    backgroundColor: theme.palette.text.primary,
    position: 'absolute',
    top: 12,
    right: 12,
    padding: 4,
    width: 20,
    height: 20,
    borderRadius: '100%',
    zIndex: 2,
  },
});

const DropZone = ({
  accept,
  acceptAll,
  classes,
  className,
  disabled,
  input,
  intl,
  labelRerenderer,
  rejectRerenderer,
  supportedFormatMessage,
  uploadedErrorMessage,
  onUploading,
  onDragNDrop,
  onChange,
  multiple,
  dropFileMessage,
}) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reject, setReject] = useState(false);
  const prevFiles = usePrevious(files);

  useEffect(
    () => {
      if (!isEqual(files, prevFiles)) {
        if (onChange && !!files.length) {
          onChange(files);
        } else {
          input.onChange(files);
        }
      }
    },
    [files, input, prevFiles],
  );

  const handleOnDrop = filesToUpload => {
    setLoading(true);
    setReject(false);
    setFiles([]);
    const filesUpload = [];

    filesToUpload.forEach(file => {
      generateChunks({
        file,
        filesUpload,
        filesToUpload,
        setFiles,
        setReject,
        onDragNDrop,
        clearUpload,
        onUploading,
        setLoading,
        handleOnDropRejected,
      });
    });
  };

  const handleOnDropRejected = () => {
    setFiles([]);
    setLoading(false);
    setReject(true);
  };

  const clearUpload = () => {};

  const handleClearUpload = e => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    setLoading(false);
    setReject(false);
    setFiles([]);
    input.onChange(null);
  };

  const renderPreview = () => {
    if (files[0].type === 'application/pdf') {
      return <PdfViewer url={files[0].preview} addZoomButton={false} isFirstPageOnly={false} />;
    }

    if (files[0].name.includes('.dwg')) {
      return <FilePreviewDwg />;
    }

    return <CardMedia component="img" src={files[0].preview} alt={files[0].name} />;
  };

  const renderContent = () => {
    if (loading) {
      return <CircularProgress size={64} thickness={2} className={classes.circularProgress} />;
    }

    if (files?.length) {
      return (
        <>
          <IconButton className={classes.floatIcon} onClick={handleClearUpload}>
            <Trash width="12" height="12" color="white" />
          </IconButton>
          {renderPreview()}
        </>
      );
    }

    if (rejectRerenderer && reject) {
      return rejectRerenderer();
    }

    if (reject) {
      return (
        <>
          <IconButton className={classes.floatIcon}>
            <Upload width="12" height="12" color="white" viewBox="0 0 12 12" />
          </IconButton>
          <AlertCircle className={classes.icon} />
          <p className={classes.instruction}>
            {intl.formatMessage(globalMessages.file_incompatible)}
          </p>
          {uploadedErrorMessage && <p className={classes.supported}>{uploadedErrorMessage}</p>}
        </>
      );
    }

    if (labelRerenderer) {
      return labelRerenderer();
    }

    return (
      <div>
        {!disabled ? (
          <>
            <Download className={classes.icon} width="48" height="48" />
            <p className={classes.instruction}>
              {dropFileMessage || intl.formatMessage(globalMessages.drop_file)}
            </p>
            {supportedFormatMessage && (
              <p className={classes.supported}>{supportedFormatMessage}</p>
            )}
          </>
        ) : null}
      </div>
    );
  };

  return (
    <Dropzone
      accept={acceptAll ? undefined : accept}
      multiple={multiple}
      disabled={disabled}
      onDrop={handleOnDrop}
      onDropRejected={handleOnDropRejected}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          {...getRootProps()}
          className={classnames(classes.container, className, {
            [classes.containerActive]: isDragActive,
          })}
        >
          <input {...getInputProps()} />
          {renderContent()}
        </div>
      )}
    </Dropzone>
  );
};

DropZone.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  input: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  acceptAll: PropTypes.bool,
  multiple: PropTypes.bool,
  accept: PropTypes.object,
  supportedFormatMessage: PropTypes.string,
  uploadedErrorMessage: PropTypes.string,
  rejectRerenderer: PropTypes.func,
  labelRerenderer: PropTypes.func,
  handleUpdateImages: PropTypes.func,
  onUploading: PropTypes.func,
  onChange: PropTypes.func,
  onDragNDrop: PropTypes.func,
  dropFileMessage: PropTypes.string,
};

DropZone.defaultProps = {
  accept: {
    ...AcceptImages,
    ...AcceptPdf,
    ...AcceptDWG,
  },
  acceptAll: false,
  multiple: true,
  onUploading: () => null,
  input: {
    onChange: () => null,
  },
};

export default compose(
  injectIntl,
  withStyles(combineStyles(styles, dropZoneStyle)),
)(DropZone);
