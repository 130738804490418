import React, { useContext, useEffect, useRef, useState } from 'react';

import { Box, IconButton, styled, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import FCList from 'components/FCList';
import ChevronDown from 'components/SvgComponents/icons/ChevronDown';
import ChevronRight from 'components/SvgComponents/icons/ChevronRight';
import ChevronUp from 'components/SvgComponents/icons/ChevronUp';
import ThreeDots from 'components/SvgComponents/icons/ThreeDots';
import { TypographyEllipsis } from 'components/Templates/TypographyEllipsis';
import BreadcrumbRow, {
  ListItem_EntityContainer,
} from 'containers/BusinessOrganisation/components/BreadcrumbRow';
import { getWorkspace } from 'containers/GlobalWrapper/actions';
import { OrganizationContext } from 'containers/GlobalWrapper/BusinessOrganizationDataValidation';
import { ProjectContext } from 'containers/GlobalWrapper/ProjectDataValidation';
import { Routing } from 'routing/routing';
import { appPaths } from 'utils/constants';

const Div_BreadcrumbContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const BreadcrumbRow_Container = styled(BreadcrumbRow)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  height: '41px',
  '& .MuiListItemIcon-root': {
    marginLeft: 0,
  },
  '& .MuiAvatar-root': {
    height: '32px',
    width: '32px',
  },
  '& .MuiListItemText-root': {
    maxWidth: '200px',
  },
}));

export const Breadcrumb = ({
  history,
  match,
  intl,
  dispatchGetWorkspace,
  openListOrganisation,
}) => {
  const location = useLocation();
  const currentOrganization = useContext(OrganizationContext);
  const currentProject = useContext(ProjectContext);
  const [workspaceTree, setWorkspaceTree] = useState([]);
  const [loadingWorkspace, setLoadingWorkspace] = useState(false);
  const [workspaceListIsOpen, setWorkspaceListIsOpen] = useState(false);
  const hasToLoadWorkspace = currentProject?.workspace_id;
  const theme = useTheme();
  const openWorkspaceClickButton = useRef(null);
  const isSuperAdminRoute = location.pathname.includes('/super-admin');
  const isOrganizationProjectList = match?.path?.startsWith(appPaths.organizationProjectList);
  const workspaceRef = React.useRef(null);

  const fetchWorkspaceRecursively = (workspaceId, fetchWorkspace, callback) => {
    setLoadingWorkspace(true);
    fetchWorkspace({ params: { organizationId: currentOrganization.id, workspaceId } }, data => {
      if (data) {
        callback(data);

        if (data?.parent_id) {
          fetchWorkspaceRecursively(data?.parent_id, fetchWorkspace, callback);
        } else {
          setLoadingWorkspace(false);
        }
      }
    });
  };

  useEffect(
    () => () => {
      setWorkspaceTree([]);
    },
    [],
  );

  useEffect(
    () => {
      if (hasToLoadWorkspace) {
        setWorkspaceTree([]);
        fetchWorkspaceRecursively(
          currentProject?.workspace_id,
          dispatchGetWorkspace,
          parentWorkspace => {
            setWorkspaceTree(prevWorkspaceTree => [parentWorkspace, ...prevWorkspaceTree]);
          },
        );
      }
    },
    [hasToLoadWorkspace],
  );

  const redirectToHome = () => {
    history.push(Routing.home.home(currentOrganization?.id, currentProject?.project_id));
  };

  const redirectToProjecthome = () => {
    history.push(Routing.organizations.projectList(currentOrganization?.id));
  };

  return (
    <Div_BreadcrumbContainer>
      <IconButton
        variant="text"
        sx={{ borderRadius: 1, padding: '4px 8px', height: '32px' }}
        onClick={openListOrganisation}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ChevronUp height={16} width={16} color={theme.palette.background.dark} />
          <ChevronDown
            height={16}
            width={16}
            color={theme.palette.background.dark}
            style={{ marginTop: '-5px' }}
          />
        </Box>
      </IconButton>
      {isSuperAdminRoute && (
        <Typography noWrap variant="h4">
          Quartier Général des Super-Héros
        </Typography>
      )}
      {currentOrganization && (
        <BreadcrumbRow_Container
          entity={currentOrganization}
          intl={intl}
          onClick={redirectToProjecthome}
          showMember
          sx={{ borderRadius: 1 }}
          hideTextInSmallScreen={!isOrganizationProjectList}
        />
      )}
      {currentProject?.workspace_id && (
        <>
          <ChevronRight
            height={24}
            width={24}
            color={theme.palette.background.dark}
            style={{ height: 'auto', margin: '0 8px' }}
          />
          {loadingWorkspace && (
            <IconButton variant="text" sx={{ borderRadius: 2, p: 2, height: '24px' }}>
              <ThreeDots height={16} width={16} color={theme.palette.background.dark} />
            </IconButton>
          )}
          {workspaceTree.length === 1 &&
            !loadingWorkspace && (
              <ListItem_EntityContainer sx={{ p: 0 }}>
                <BreadcrumbRow_Container
                  entity={workspaceTree[0]}
                  intl={intl}
                  sx={{ borderRadius: 1 }}
                  publicId={workspaceTree[0]?.client_reference}
                  hideAvatar
                  isButton={false}
                >
                  {workspaceTree[0]?.name || ''}
                </BreadcrumbRow_Container>
              </ListItem_EntityContainer>
            )}
          {workspaceTree.length > 1 &&
            !loadingWorkspace && (
              <IconButton
                variant="text"
                sx={{ borderRadius: 2, p: 2, height: '24px' }}
                onClick={() => setWorkspaceListIsOpen(prevIsOpen => !prevIsOpen)}
                ref={openWorkspaceClickButton}
              >
                <ThreeDots height={16} width={16} color={theme.palette.background.dark} />
              </IconButton>
            )}
          <FCList
            anchor={openWorkspaceClickButton?.current}
            placement="bottom-start"
            isOpen={workspaceListIsOpen}
            onClose={() => setWorkspaceListIsOpen(false)}
            noDivider
          >
            {workspaceTree.map(workspace => (
              <FCList.ListItem key={workspace?.id}>
                <TypographyEllipsis
                  noWrap
                  component="p"
                  sx={{ maxWidth: '200px' }}
                  ref={workspaceRef}
                  variant="body"
                >
                  {workspace?.name}
                </TypographyEllipsis>
              </FCList.ListItem>
            ))}
          </FCList>
        </>
      )}
      {currentProject && (
        <>
          <ChevronRight
            height={24}
            width={24}
            color={theme.palette.background.dark}
            style={{ height: 'auto', margin: '0 8px' }}
          />
          <BreadcrumbRow_Container
            entity={currentProject}
            intl={intl}
            sx={{ borderRadius: 1 }}
            publicId={`#${currentProject?.legacy_id}`}
            onClick={redirectToHome}
          />
        </>
      )}
    </Div_BreadcrumbContainer>
  );
};

Breadcrumb.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object,
  intl: intlShape.isRequired,
  dispatchGetWorkspace: PropTypes.func,
  openListOrganisation: PropTypes.func,
};
Breadcrumb.defaultProps = {};
const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {
  dispatchGetWorkspace: getWorkspace,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withRouter,
  injectIntl,
)(Breadcrumb);
