const scope = 'OneHomePage/ContractsPage/';
export const SET_LOADING_FLAG = `${scope}SET_LOADING_FLAG`;
export const GET_CURRENT_PLAN = `${scope}GET_CURRENT_PLAN`;
export const SET_CURRENT_PLAN = `${scope}SET_CURRENT_PLAN`;
export const GET_AVAILABLE_OFFERS = `${scope}GET_AVAILABLE_OFFERS`;
export const SET_AVAILABLE_OFFERS = `${scope}SET_AVAILABLE_OFFERS`;
export const GET_ALL_MEMBERS = `${scope}GET_ALL_MEMBERS`;
export const SET_ALL_MEMBERS = `${scope}SET_ALL_MEMBERS`;
export const SET_MONTHLY_PRICE = `${scope}SET_MONTHLY_PRICE`;
export const SET_NUMBER_USERS = `${scope}SET_NUMBER_USERS`;
export const CANCEL_PLAN = `${scope}CANCEL_PLAN`;
export const RESET_CONTRACT_PAGE_STATE = `${scope}RESET_CONTRACT_PAGE_STATE`;
export const MANAGE_PAYMENT_METHOD = `${scope}MANAGE_PAYMENT_METHOD`;
