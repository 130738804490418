import React, { useEffect, useRef, useState } from 'react';

import { withStyles } from '@mui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { submit } from 'redux-form';
import { change, clearFields } from 'redux-form/immutable';
import { isPristine } from 'redux-form/lib/immutable';
import { createStructuredSelector } from 'reselect';
import uuid from 'uuid';

import Button from 'components/Button';
import Dialog from 'components/dialogs/Dialog';
import LoadingIndicator from 'components/LoadingIndicator';
import { selectLoggedUserData } from 'containers/GlobalWrapper/selectors';
import { changeLocale } from 'containers/LanguageProvider/actions';
import { setGlobalMessage } from 'containers/MessageHandler/actions';
import globalMessages from 'translations/messages/global-messages';
import Analytics from 'utils/Analytics';
import { tempTemplateLanguages } from 'utils/constants';
import { usePrevious } from 'utils/hooks';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import {
  setSaveUser,
  uploadImage,
  resetPassword,
  addCommunication,
  deleteAccount,
} from './actions';
import UserForm from './components/UserForm';
import UserHeader from './components/UserHeader';
import reducer from './reducer';
import saga from './saga';
import { selectLoadingFlag } from './selectors';

export const styles = theme => ({
  contentWrap: {
    backgroundColor: theme.palette.background.white,
    overflow: 'auto',
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    padding: theme.spacing(10),
  },
  textDisplayTitle: {
    padding: '2.75rem 0 3rem',
  },
  logOutWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginRight: '16px',
    marginTop: '16px',
  },
});
function AccountSettings(props) {
  const dispatch = useDispatch();
  const [imageToSave, setImageToSave] = useState();
  const { classes, intl, loading, userInfo } = props;
  const communications = userInfo?.communications || [];
  const formReference = useRef();
  const phoneNumber = communications.find(com => com.communication_type === 'Phone');
  const findLanguage = tempTemplateLanguages.find(item => item.code === userInfo?.locale);
  const isUserFormPristine = useSelector(isPristine('userForm'));
  const prevLoading = usePrevious(loading);

  useEffect(() => {
    Analytics.track('user_settings_views');
  }, []);

  useEffect(
    () => {
      if (prevLoading && !loading) {
        props.onCloseDialog();
      }
    },
    [loading],
  );

  const saveUserDetail = values => {
    const { dispatchAddCommunication } = props;

    const optionValueStr = JSON.stringify(values, null, 2);
    const optionValue = JSON.parse(optionValueStr);
    if (phoneNumber && phoneNumber.value !== optionValue?.phoneNumber.value) {
      dispatchAddCommunication({
        communication_id: phoneNumber.communication_id,
        communication_type: phoneNumber.communication_type,
        phone_type: phoneNumber.phone_type,
        email_type: phoneNumber.email_type,
        label: phoneNumber.label,
        value: optionValue?.phoneNumber.value,
        method: 'PUT',
      });
    }
    if (!phoneNumber && optionValue?.phoneNumber?.value) {
      dispatchAddCommunication({
        communication_id: uuid.v4(),
        communication_type: 'Phone',
        phone_type: 'Custom',
        email_type: null,
        label: 'Phone',
        value: optionValue?.phoneNumber?.value,
        method: 'POST',
      });
    }

    if (imageToSave) {
      const uploadImageProps = {
        ...imageToSave,
        userId: optionValue.user_id,
      };
      if (optionValue?.media_resource?.url?.indexOf(optionValue?.media_resource?.id) > -1) {
        uploadImageProps.previous_media_id = optionValue?.media_resource?.id;
      }
      props.dispatchUploadImage(uploadImageProps, () => {
        props.dispatchSetSaveUser(optionValue);
      });
    } else {
      props.dispatchSetSaveUser(optionValue);
    }
    moment.locale(optionValue.locale);
    props.dispatchChangeLocale(optionValue.locale);
  };

  const handleUpdateImages = images => {
    setImageToSave(images);
    if (images?.file || userInfo?.media_resource) {
      dispatch(change('userForm', 'mediaResources.url', images));
    } else {
      // Clear to avoid dirty form and disable save button
      dispatch(clearFields('userForm', false, false, 'mediaResources.url'));
    }
  };

  const handleCallbackResetPassword = () => {
    props.onSetGlobalMessage({
      type: 'success',
      intlMessage: {
        id: 'password_text',
      },
      active: true,
      verticalPosition: 'bottom',
    });
  };

  const handleResetPassword = email => {
    props.dispatchResetPassword(email, handleCallbackResetPassword);
  };

  const handleDeleteAccount = callbacks => {
    props.dispatchDeleteAccount(userInfo.user_id, callbacks.deleteAccountSuccessCallback);
  };

  return (
    <Dialog
      id="AccountSettingsDialog"
      open
      fullWidth
      maxWidth="sm"
      onCloseDialog={props.onCloseDialog}
      dialogTitleNode={intl.formatMessage(globalMessages.your_profile)}
      dialogActionNode={[
        <Button onClick={props.onCloseDialog} variant="plain" key="cancel">
          {intl.formatMessage(globalMessages.cancel)}
        </Button>,
        <Button
          onClick={() => {
            dispatch(submit('userForm'));
          }}
          disabled={isUserFormPristine}
          key="save"
        >
          {intl.formatMessage(globalMessages.save)}
        </Button>,
      ]}
    >
      <div className={classes.contentWrap}>
        {loading && <LoadingIndicator size="40" />}
        {userInfo && (
          <UserForm
            form="userForm"
            userInfo={userInfo}
            ref={formReference}
            initialValues={{
              ...userInfo,
              phoneNumber,
              locale: findLanguage?.code || 'en',
            }}
            onSubmit={saveUserDetail}
            onResetPassword={handleResetPassword}
            onDeleteAccount={handleDeleteAccount}
            userAvatarComp={
              <UserHeader
                mediaResources={userInfo?.media_resource}
                onUpdateImages={handleUpdateImages}
              />
            }
          />
        )}
      </div>
    </Dialog>
  );
}

AccountSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  loading: PropTypes.bool,
  userInfo: PropTypes.object,
  dispatchSetSaveUser: PropTypes.func,
  dispatchUploadImage: PropTypes.func,
  dispatchResetPassword: PropTypes.func,
  dispatchDeleteAccount: PropTypes.func,
  dispatchChangeLocale: PropTypes.func,
  onSetGlobalMessage: PropTypes.func,
  dispatchAddCommunication: PropTypes.func,
  onCloseDialog: PropTypes.func,
};
AccountSettings.defaultProps = {
  loading: false,
};

const mapStateToProps = createStructuredSelector({
  userInfo: selectLoggedUserData(),
  loading: selectLoadingFlag(),
});

const mapDispatchToProps = {
  dispatchSetSaveUser: setSaveUser,
  dispatchUploadImage: uploadImage,
  dispatchResetPassword: resetPassword,
  dispatchChangeLocale: changeLocale,
  onSetGlobalMessage: setGlobalMessage,
  dispatchAddCommunication: addCommunication,
  dispatchDeleteAccount: deleteAccount,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withSaga = injectSaga({ key: 'accountSettingsPage', saga });
const withReducer = injectReducer({ key: 'accountSettingsPage', reducer });

export default compose(
  withSaga,
  injectIntl,
  withStyles(styles),
  withReducer,
  withConnect,
)(AccountSettings);
