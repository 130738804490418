const scope = 'addMessage/';
export const ADD_ATTACHMENT = `${scope}ADD_ATTACHMENT`;
export const SEND_MESSAGE = `${scope}SEND_MESSAGE`;
export const SEND_MESSAGE_CLUSTER = `${scope}SEND_MESSAGE_CLUSTER`;
export const SEND_MESSAGE_CLUSTER_BATCH = `${scope}SEND_MESSAGE_CLUSTER_BATCH`;
export const SET_LOADING_FLAG = `${scope}SET_LOADING_FLAG`;
export const MESSAGE_TYPES = {
  ATTACHMENT: 'ATTACHMENT',
  TEXT: 'TEXT',
  TEXT_WITH_MENTION: 'TEXT_WITH_MENTION',
  OBSERVATION: 'OBSERVATION',
  FORM: 'FORM',
};
