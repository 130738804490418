import React from 'react';
import PropTypes from 'prop-types';

import typeFieldList from 'utils/typeFieldList';

function TypeFieldIcon(props) {
  const findIconObject = typeFieldList.find(item => item.name === props.type);
  return <>{findIconObject && findIconObject.icon}</>;
}

TypeFieldIcon.propTypes = {
  type: PropTypes.string,
};

export default TypeFieldIcon;
