import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectTradePage = state => state.tradePage || initialState;

const selectTradeId = () =>
  createSelector(selectTradePage, dataState => dataState.get('itemId'));

const selectLanguage = () =>
  createSelector(selectTradePage, dataState => dataState.get('language'));

const selectCheckedProjects = () =>
  createSelector(selectTradePage, dataState =>
    dataState.get('checkedProjects').toJS(),
  );

const selectModify = () =>
  createSelector(selectTradePage, dataState => dataState.get('isModify'));

const selectAllCheckedProjects = () =>
  createSelector(selectTradePage, dataState => dataState.get('allChecked'));

export {
  selectTradeId,
  selectLanguage,
  selectAllCheckedProjects,
  selectCheckedProjects,
  selectModify,
};
