const scope = 'OneHomePage/DocumentsPage/';
export const SET_LOADING_FLAG = `${scope}SET_LOADING_FLAG`;
export const ADD_DOCUMENTS = `${scope}ADD_DOCUMENTS`;
export const ADD_FOLDER = `${scope}ADD_FOLDER`;
export const DELETE_DOCUMENTS = `${scope}DELETE_DOCUMENTS`;
export const MOVE_ITEMS_TO = `${scope}MOVE_ITEMS_TO`;
export const GET_FOLDERS_CONTENT = `${scope}GET_FOLDERS_CONTENT`;
export const GET_PLAN_FOLDERS_CONTENT = `${scope}GET_PLAN_FOLDERS_CONTENT`;
export const CREATE_AS_PLAN = `${scope}CREATE_AS_PLAN`;
export const GET_SEARCH_IN_ALL_DOCUMENTS = `${scope}GET_SEARCH_IN_ALL_DOCUMENTS`;
export const SET_SEARCH_IN_ALL_DOCUMENTS = `${scope}SET_SEARCH_IN_ALL_DOCUMENTS`;
export const SET_DOCUMENT_TO_RENAME = `${scope}SET_DOCUMENT_TO_RENAME`;
export const UPDATE_DOCUMENT = `${scope}UPDATE_DOCUMENT`;
export const RENAME_DOCUMENT = `${scope}RENAME_DOCUMENT`;
export const UPLOAD_DOCUMENT = `${scope}UPLOAD_DOCUMENT`;
export const UPLOAD_DOCUMENT_SUCCESS = `${scope}UPLOAD_DOCUMENT_SUCCESS`;
export const UPLOAD_DOCUMENT_FAILED = `${scope}UPLOAD_DOCUMENT_FAILED`;
