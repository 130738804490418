import { useEffect, useState } from 'react';

import config from 'config/config';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { stripeStatus } from 'utils/constants';
import initialLoader from 'utils/initialLoader';
import localStorageUser from 'utils/localStorageUser';

export default function useStripeCheckout({ sessionId, businessOrganization }) {
  const oktaToken = localStorageUser.getOktaTokenStorage();

  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerId, setCustomerId] = useState('');

  const getCheckoutSessionRequestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/checkout/session/${sessionId}`;

  useEffect(() => {
    initialLoader.hideLoader();

    fetch(getCheckoutSessionRequestURL, {
      method: 'GET',
      headers: {
        'Cache-Control': 'No-Store',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken?.accessToken?.accessToken}`,
      },
    })
      .then(res => res.json())
      .then(data => {
        setStatus(data?.status);
        setCustomerEmail(data?.email);
        setCustomerId(data?.customer_id);
      });
  }, []);

  const updateCustomerRequestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/checkout/customer/${customerId}`;

  useEffect(
    () => {
      if (status === stripeStatus.complete && customerId) {
        initialLoader.hideLoader();
        fetch(updateCustomerRequestURL, {
          method: 'PUT',
          headers: {
            'Cache-Control': 'No-Store',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${oktaToken?.accessToken?.accessToken}`,
          },
          body: JSON.stringify({
            organization_id: businessOrganization?.id,
            organization_name: businessOrganization?.name,
            organization_public_id: businessOrganization?.public_id,
          }),
        });
      }
    },
    [status, customerId],
  );

  return {
    status,
    customerEmail,
  };
}
