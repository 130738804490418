import React from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';

export const ListLoader = ({ rows }) => (
  <Box
    sx={{
      height: '100%',
      paddingTop: 4,
      px: 4,
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
    }}
  >
    {[...Array(rows).keys()].map(key => (
      <Skeleton
        key={key}
        height={18}
        variant="rectangular"
        sx={{ borderRadius: 1 }}
      />
    ))}
  </Box>
);

ListLoader.propTypes = {
  rows: PropTypes.number.isRequired,
};
