import React from 'react';
import PropTypes from 'prop-types';

export const ScrollContext = React.createContext();

export const useScrollWidth = () => React.useContext(ScrollContext);
export const ScrollConsumer = ScrollContext.Consumer;

const ScrollProvider = props => {
  const { children } = props;
  const [scrollWidth, setScrollWidth] = React.useState(0);

  const addAndMeasureScrollbar = () => {
    const scrollDiv = document.createElement('div');
    scrollDiv.className = 'scrollbarWidthCalculator';
    document.body.appendChild(scrollDiv);
    const width = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    setScrollWidth(width);
    document.body.removeChild(scrollDiv);
  };

  React.useEffect(() => {
    window.onzoom = addAndMeasureScrollbar;

    // detect resize
    const detectResize = () => {
      const oldresize = window.onresize;
      window.onresize = e => {
        const event = window.event || e;
        if (typeof oldresize === 'function' && !oldresize.call(window, event)) {
          return () => null;
        }
        if (typeof window.onzoom === 'function') {
          return window.onzoom.call(window, event);
        }
        return () => null;
      };
    };
    detectResize();
    addAndMeasureScrollbar();
  }, []);
  return (
    <ScrollContext.Provider value={scrollWidth}>
      {children}
    </ScrollContext.Provider>
  );
};

ScrollProvider.propTypes = {
  children: PropTypes.node,
};

export default ScrollProvider;
