const oktaLoginTheme = theme => ({
  containerLogin: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    backgroundColor: theme.palette.background.white,
  },
  centerLoginContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'auto',
  },
  wrapper: {
    minWidth: '30%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: theme.palette.background.white,
  },
  createAccountWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  subtitle: {
    padding: `0 0 20px 0`,
    maxWidth: 260,
  },
  wrapTitle: {
    display: 'flex',
    paddingBlock: 30,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 60,
    },
  },
  wrapTitleCenter: {
    width: '100%',
    maxWidth: 260,
    justifyContent: 'center',
  },
  absoluteBtn: {
    position: 'absolute',
    top: 20,
    left: '6%',
    [theme.breakpoints.up('md')]: {
      top: 60,
    },
  },
  absoluteBtnRight: {
    position: 'absolute',
    top: 20,
    right: '6%',
    [theme.breakpoints.up('md')]: {
      top: 60,
    },
  },
  avatarWrapper: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(10),
  },
  oktaForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 320,
    width: '100%',
    backgroundColor: theme.palette.background.white,
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    width: '100%',
    fontSize: 16,
    textAlign: 'left',
    fontWeight: 'bold',
    paddingBottom: theme.spacing(2),
  },
  checkboxField: {
    width: '100%',
    marginBottom: theme.spacing(4),
    textAlign: 'left',
  },
  checkboxCheck: {
    color: theme.palette.text.dark,
  },
  contentWrap: {
    flex: 'auto',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    color: theme.palette.text.primary,
  },
  contentDialogTitle: {
    fontWeight: 'bold',
    color: theme.palette.text.dark,
  },
  passwordContainer: {
    height: 100,
    width: '100%',
  },
  submitDisabled: {
    backgroundColor: `${theme.palette.secondary.main}54 !important`,
    color: 'white !important',
  },
  submit: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 16,
    textTransform: 'capitalize',
    boxShadow: 'none',
    height: '2.286em',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: theme.shadows[2],
    },
  },
});

export default oktaLoginTheme;
