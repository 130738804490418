import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button as MuiButton, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { injectIntl, intlShape } from 'react-intl';

import ChevronDrown from 'components/SvgComponents/icons/ChevronDown';
import { getTranslation } from 'utils/localization';
import { isDeleted } from 'utils/delete-utils';
import globalMessages from 'translations/messages/global-messages';

import { setDropdownData } from './actions';

export const styles = theme => ({
  container: {
    display: 'flex',
    maxWidth: '100%',
  },
  button: {
    fontFamily: 'Effra Medium, Roboto',
    background: theme.palette.background.primary,
    height: '22px',
    borderRadius: theme.spacing(),
    textTransform: 'capitalize',
    padding: '0.125rem 0.5rem',
    minWidth: '0',
    width: 'fit-content',
  },
  textName: {
    letterSpacing: '0px',
    textAlign: 'center',
  },
  icon: {
    marginLeft: '0.375rem',
  },
});

export const InlineDropdownButton = ({
  classes,
  selected,
  itemId,
  dispatchSetDropdownData,
  disabled,
  intl,
}) => {
  const ref = useRef(null);

  let buttonStyles = {};
  let textStyles = {};
  if (selected?.color) {
    buttonStyles = {
      backgroundColor: `${selected?.color}1A`,
    };

    textStyles = {
      color: `${selected?.color}`,
    };
  }
  if (!selected) {
    return <div>-</div>;
  }
  return (
    <div className={classes.container} ref={ref}>
      <MuiButton
        disabled={disabled}
        className={classes.button}
        size="small"
        style={buttonStyles}
        onClick={e => {
          e.stopPropagation();
          dispatchSetDropdownData({
            visibility: true,
            reference: ref,
            id: itemId,
            selected: selected?.id,
          });
        }}
      >
        <Typography
          title={getTranslation(selected?.names?.translations, selected?.name)}
          noWrap
          style={textStyles}
          className={classes.textName}
          variant="subtitle"
        >
          {getTranslation(selected?.names?.translations, selected?.name)}
        </Typography>
        {isDeleted(selected) && (
          <Typography noWrap component="p" variant="bodyLight">
            {intl.formatMessage(globalMessages.deleted)}
          </Typography>
        )}
        {!disabled && (
          <ChevronDrown
            className={classes.icon}
            width={12}
            height={10}
            color={selected?.color}
          />
        )}
      </MuiButton>
    </div>
  );
};

InlineDropdownButton.propTypes = {
  classes: PropTypes.object.isRequired,
  selected: PropTypes.object,
  itemId: PropTypes.string.isRequired,
  dispatchSetDropdownData: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  intl: intlShape.isRequired,
};

const mapDispatchToProps = {
  dispatchSetDropdownData: setDropdownData,
};

const withConnect = connect(
  null,
  mapDispatchToProps,
);

export default compose(
  withStyles(styles),
  withConnect,
  injectIntl,
)(InlineDropdownButton);
