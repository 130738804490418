export const bannerStyle = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 16,
    gap: '0.5em',
    fontSize: '1rem',
    boxShadow: 'none',
  },
  rootPadding: {
    padding: '1em 1.125em',
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
  },
  info: {
    backgroundColor: theme.palette.info.main,
  },
  infoLight: {
    backgroundColor: theme.palette.info.mainLight,
  },
  rootContent: {
    padding: `0 !important`,
    width: '100%',
  },
  rootTypography: {
    fontSize: '1rem',
  },
  warningTypography: {
    color: theme.palette.text.primary,
  },
  infoTypography: {
    color: theme.palette.text.primary,
  },
  rootButton: {
    marginInlineStart: 'auto',
  },
});

export default bannerStyle;
