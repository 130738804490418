import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';
import globalMessages from 'translations/messages/global-messages';
const Attachment = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <title>
      {props.title || props.intl.formatMessage(globalMessages.attachment)}
    </title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5202 1.59188C9.98937 1.59188 9.48028 1.80275 9.10493 2.1781L2.97826 8.30477C2.35273 8.9303 2.0013 9.77872 2.0013 10.6634C2.0013 11.548 2.35273 12.3964 2.97826 13.022C3.6038 13.6475 4.45221 13.9989 5.33686 13.9989C6.2215 13.9989 7.06992 13.6475 7.69545 13.022L13.8221 6.89529C14.0825 6.63494 14.5046 6.63494 14.7649 6.89529C15.0253 7.15564 15.0253 7.57775 14.7649 7.8381L8.63826 13.9648C7.76268 14.8404 6.57513 15.3323 5.33686 15.3323C4.09859 15.3323 2.91104 14.8404 2.03545 13.9648C1.15987 13.0892 0.667969 11.9016 0.667969 10.6634C0.667969 9.42509 1.15987 8.23754 2.03545 7.36196L8.16212 1.23529C8.78752 0.609891 9.63574 0.258545 10.5202 0.258545C11.4046 0.258545 12.2529 0.60989 12.8783 1.23529C13.5037 1.86069 13.855 2.70891 13.855 3.59336C13.855 4.47781 13.5037 5.32603 12.8783 5.95143L6.74493 12.0781C6.36972 12.4533 5.86082 12.6641 5.33019 12.6641C4.79956 12.6641 4.29067 12.4533 3.91546 12.0781C3.54024 11.7029 3.32945 11.194 3.32945 10.6634C3.32945 10.1327 3.54024 9.62384 3.91546 9.24862L9.57573 3.59501C9.83623 3.33482 10.2583 3.33506 10.5185 3.59557C10.7787 3.85607 10.7785 4.27818 10.518 4.53838L4.85826 10.1914C4.73327 10.3166 4.66278 10.4865 4.66278 10.6634C4.66278 10.8404 4.7331 11.0101 4.85826 11.1353C4.98343 11.2605 5.15319 11.3308 5.33019 11.3308C5.5072 11.3308 5.67696 11.2605 5.80212 11.1353L11.9355 5.00862C12.3106 4.6333 12.5217 4.12407 12.5217 3.59336C12.5217 3.06253 12.3108 2.55345 11.9355 2.1781C11.5601 1.80275 11.051 1.59188 10.5202 1.59188Z"
    />
  </svg>
);

Attachment.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};
export default compose(injectIntl)(Attachment);
