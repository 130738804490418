import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

function Dwg(props) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{props.title || 'pdf'}</title>

      <path
        fill="#CED2D8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.37868 2.21199C4.94129 1.64938 5.70435 1.33331 6.5 1.33331H13.5C13.7652 1.33331 14.0196 1.43867 14.2071 1.62621L21.2071 8.62621C21.3946 8.81374 21.5 9.0681 21.5 9.33331V20.3333C21.5 21.129 21.1839 21.892 20.6213 22.4546C20.0587 23.0172 19.2956 23.3333 18.5 23.3333H6.5C5.70435 23.3333 4.94129 23.0172 4.37868 22.4546C3.81607 21.892 3.5 21.129 3.5 20.3333V4.33331C3.5 3.53767 3.81607 2.7746 4.37868 2.21199ZM6.5 3.33331C6.23478 3.33331 5.98043 3.43867 5.79289 3.62621C5.60536 3.81374 5.5 4.06809 5.5 4.33331V20.3333C5.5 20.5985 5.60536 20.8529 5.79289 21.0404C5.98043 21.228 6.23478 21.3333 6.5 21.3333H18.5C18.7652 21.3333 19.0196 21.228 19.2071 21.0404C19.3946 20.8529 19.5 20.5985 19.5 20.3333V10.3333H13.5C12.9477 10.3333 12.5 9.8856 12.5 9.33331V3.33331H6.5ZM14.5 4.74753L18.0858 8.33331H14.5V4.74753Z"
      />
    </svg>
  );
}
Dwg.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

Dwg.defaultProps = {
  title: '',
};

export default injectIntl(Dwg);
