import { call, put, select, takeEvery } from 'redux-saga/effects';

import Config from 'config/config';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { checkParams } from 'utils/errors';
import request from 'utils/request';

import { selectRouteParams } from '../../selectors';
import { setPlan, setPlanFolder } from './actions';
import { GET_PLAN, FETCH_PLAN, GET_PLAN_FOLDER } from './constants';
import { selectPlan, selectPlanFolder } from './selectors';

const pendingRequests = new Map();

export function* fetchPlan({ id, callback }) {
  const route = yield select(selectRouteParams());
  const requestURL = `${checkOrSetSlash(Config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/plans/${id}`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURL, options, {
    errorRedirect: false,
    errorCustom: true,
  });

  if (callback) {
    yield call(callback, data);
  }
}

export function* getPlan({ id, callback, params }) {
  checkParams({ params, keys: ['idData'] });
  const planData = yield select(selectPlan(id));

  if (planData) {
    if (callback) yield call(callback, planData);
    return;
  }

  if (pendingRequests.has(id)) {
    pendingRequests.get(id).push(callback);
    return;
  }

  pendingRequests.set(id, [callback]);

  const requestURL = `${checkOrSetSlash(Config.apiHostGateway, 'apiHostGateway')}api/projects/${
    params.idData
  }/plans/${id}`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURL, options, {
    errorRedirect: false,
    errorCustom: true,
  });

  const callbacks = pendingRequests.get(id) || [];
  pendingRequests.delete(id);

  if (data && !data.message) {
    yield put(setPlan(id, data));

    // eslint-disable-next-line no-restricted-syntax
    for (const cb of callbacks) {
      if (cb) yield call(cb, data);
    }
  }
}

export function* getPlanFolder({ id, callback, params }) {
  const folderData = yield select(selectPlanFolder(id));

  if (folderData) {
    if (callback) yield call(callback, folderData);
  } else {
    checkParams({ params, keys: ['idData'] });

    const requestURL = `${checkOrSetSlash(Config.apiHostGateway, 'apiHostGateway')}api/projects/${
      params.idData
    }/folders/${id}`;

    const options = {
      method: 'GET',
      headers: {
        'Cache-Control': 'No-Store',
        'Content-Type': 'application/json',
      },
    };

    const data = yield call(request, requestURL, options, {
      errorRedirect: false,
    });
    if (data && !data.message) {
      yield put(setPlanFolder(id, data));
      if (callback) yield call(callback, data);
    }
  }
}

export default function* initPlansSaga() {
  yield takeEvery(GET_PLAN, getPlan);
  yield takeEvery(FETCH_PLAN, fetchPlan);
  yield takeEvery(GET_PLAN_FOLDER, getPlanFolder);
}
