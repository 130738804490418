import React from 'react';

export const cache = (hooks, values, callback) => event => {
  if (event) {
    event.stopPropagation();
  }

  hooks.forEach((hook, i) => {
    hook(values[i]);
  });

  if (callback) {
    callback();
  }
};

export const usePrevious = data => {
  const ref = React.useRef();
  React.useEffect(
    () => {
      ref.current = data;
    },
    [data],
  );
  return ref.current;
};
