import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';

import { useScrollWidth } from 'contexts/ScrollProvider';

const styles = theme => ({
  removeScrollbar: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    overflow: 'hidden',
    // not sure if needed, should be removed it position: 'relative',
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
    },
  },
  removeScrollWrap: {
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    // fix for safari should be remove on issue found
    flex: '1 1 auto',
    minHeight: 0,
  },
  paddingBottom: {
    paddingBottom: theme.spacing(3),
  },
  overflowFix: {
    overflow: 'initial',
  },
});

function RemoveScrollbar(props) {
  const scrollWidth = useScrollWidth();
  const {
    classes,
    children,
    paddingBottom,
    className,
    scrollContainerClass,
    style,
    onRef,
    onScroll,
  } = props;
  const widthStyle = {
    width: `calc( 100% + ${scrollWidth}px)`,
  };

  let scrollClasses = classNames({
    [classes.removeScrollWrap]: true,
    [classes.paddingBottom]: paddingBottom,
  });

  if (scrollContainerClass) {
    scrollClasses = classNames({
      [classes.removeScrollWrap]: true,
      [classes.paddingBottom]: paddingBottom,
      [scrollContainerClass]: true,
    });
  }

  return (
    <div
      className={classNames(classes.removeScrollbar, className)}
      style={style}
    >
      <div
        ref={onRef}
        style={widthStyle}
        className={scrollClasses}
        onScroll={onScroll}
      >
        {children}
      </div>
    </div>
  );
}

RemoveScrollbar.propTypes = {
  classes: PropTypes.object.isRequired,
  paddingBottom: PropTypes.bool,
  scrollContainerClass: PropTypes.string,
  className: PropTypes.string,
  onRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  style: PropTypes.object,
  onScroll: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

RemoveScrollbar.defaultProps = {
  onScroll: () => null,
};

export default withStyles(styles)(RemoveScrollbar);
