import React, { useRef, useState, useEffect } from 'react';

import { IconButton, Typography } from '@mui/material';
import { useTheme, withStyles } from '@mui/styles';
import { useOktaAuth } from '@okta/okta-react';
import Gleap from 'gleap';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import ApiKeyGenerator from 'components/ApiKeyGenerator';
import DialogApiKeyGenerator from 'components/ApiKeyGenerator/DialogApiKeyGenerator';
import Avatar from 'components/AvatarV3';
import FCList from 'components/FCList';
import LogOut from 'components/LogOut';
import DialogLogOut from 'components/LogOut/DialogLogOut';
import { People } from 'components/SvgComponents/icons/module';
import AccountSettings from 'containers/AccountSettings';
import contractsReducer from 'containers/ContractPage/reducer';
import contractsSaga from 'containers/ContractPage/saga';
import {
  checkIfCurrentUserIsAdminAndHasEnterprisePlanInProjectsList,
  getAllOrganizations,
} from 'containers/GlobalWrapper/actions';
import {
  selectAllOrganizations,
  selecthasEnterprisePlanProjectsListOfCurrentUser,
  selectisAdminInProjectsListOfCurrentUser,
} from 'containers/GlobalWrapper/selectors';
import { useFeatureFlags } from 'contexts/FeatureFlagsProvider';
import globalMessages from 'translations/messages/global-messages';
import Analytics from 'utils/Analytics';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import localStorageUser from 'utils/localStorageUser';
import sessionStorageUser from 'utils/sessionStorageUser';

import { AVATAR_HEIGHT, AVATAR_WIDTH } from '../../constants';

const styles = theme => ({
  avatar: {
    width: AVATAR_WIDTH,
    height: AVATAR_HEIGHT,
    marginRight: theme.spacing(2),
  },
  accountSettingsWrapper: {
    display: 'none',
    width: AVATAR_WIDTH + 8,
    height: AVATAR_HEIGHT + 8,
    position: 'absolute',
    borderRadius: '50%',
  },
  avatarRoot: {
    width: AVATAR_WIDTH,
    height: AVATAR_HEIGHT,
  },
  avatarWrapper: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
    '&:hover $avatarRoot': {
      boxShadow: `0px 0px 0px 2px ${theme.palette.text.primary}`,
    },
    '&:hover $accountSettingsWrapper': {
      display: 'block',
    },
  },
});

function AccountSettingsButton(props) {
  const theme = useTheme();
  const {
    classes,
    intl,
    userOrganizations,
    dispatchGetAllOrganizations,
    dispatchcheckIfCurrentUserIsAdminAndHasEnterprisePlanInProjectsList,
    dataUser = {},
  } = props;
  const [openModalApiKey, setOpenModalApiKey] = useState(false);
  const [openModalLogout, setOpenModalLogout] = useState(false);
  const showAcccountSettingsButton = useRef(null);
  const featureFlags = useFeatureFlags();
  const [openAccountSettingModal, setOpenAccountSettingModal] = useState(false);
  const isAdminInProjectsListOfCurrentUser = useSelector(
    selectisAdminInProjectsListOfCurrentUser(),
  );
  const hasEnterprisePlanProjectsListOfCurrentUser = useSelector(
    selecthasEnterprisePlanProjectsListOfCurrentUser(),
  );
  const [didInit, setDidInit] = useState(false);

  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (!userOrganizations || userOrganizations.length === 0) {
      dispatchGetAllOrganizations();
    }
  }, []);

  useEffect(
    () => {
      if (!didInit && userOrganizations?.length > 0) {
        // dispatchcheckIfCurrentUserIsAdminAndHasEnterprisePlanInProjectsList(userOrganizations);
        setDidInit(true);
      }
    },
    [didInit, userOrganizations],
  );

  const handleSignOut = () => {
    Analytics.track('users_sign_out');
    sessionStorageUser.setFinalcadTempRegistrationInfo(null);
    sessionStorageUser.setIsSignUp(null);
    localStorageUser.cleanExpiredProjectFilter();
    oktaAuth.signOut();

    Gleap.clearIdentity();
    Gleap.clearCustomData();
  };

  return (
    <>
      <IconButton
        className={classes.avatarWrapper}
        aria-label="Account"
        aria-describedby="account-settings"
        ref={showAcccountSettingsButton}
      >
        <Avatar
          materialUIClasses={{ root: classes.avatarRoot }}
          alt={dataUser?.media_resource?.display_name}
          src={dataUser?.media_resource?.url}
          user={dataUser}
        />
        <div className={classes.accountSettingsWrapper} />
      </IconButton>
      <FCList
        anchor={showAcccountSettingsButton?.current}
        placement="bottom-start"
        attacheEventToAnchor
      >
        <FCList.ListItemButton onClick={() => setOpenAccountSettingModal(true)}>
          <People
            height={16}
            width={16}
            color={theme.palette.text.primary}
            style={{ marginRight: '8px' }}
          />
          <Typography variant="body">
            {intl.formatMessage(globalMessages.account_settings)}
          </Typography>
        </FCList.ListItemButton>
        {featureFlags?.api_key_generation &&
          hasEnterprisePlanProjectsListOfCurrentUser &&
          isAdminInProjectsListOfCurrentUser && (
            <ApiKeyGenerator onClickGenerate={() => setOpenModalApiKey(!openModalApiKey)} />
          )}
        <LogOut onClickLogout={() => setOpenModalLogout(!openModalLogout)} />
      </FCList>
      {openModalLogout && (
        <DialogLogOut
          onCloseDialog={() => setOpenModalLogout(!openModalLogout)}
          onSubmitDialog={handleSignOut}
        />
      )}
      {openModalApiKey && (
        <DialogApiKeyGenerator
          onCloseDialog={() => setOpenModalApiKey(!openModalApiKey)}
          onSubmitDialog={() => {}}
        />
      )}
      {openAccountSettingModal && (
        <AccountSettings
          onCloseDialog={() => setOpenAccountSettingModal(!openAccountSettingModal)}
        />
      )}
    </>
  );
}

AccountSettingsButton.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  dataUser: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  dispatchGetAllOrganizations: PropTypes.func.isRequired,
  dispatchcheckIfCurrentUserIsAdminAndHasEnterprisePlanInProjectsList: PropTypes.func.isRequired,
  userOrganizations: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
  userOrganizations: selectAllOrganizations(),
});

const mapDispatchToProps = {
  dispatchGetAllOrganizations: getAllOrganizations,
  dispatchcheckIfCurrentUserIsAdminAndHasEnterprisePlanInProjectsList: checkIfCurrentUserIsAdminAndHasEnterprisePlanInProjectsList,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withContractsSaga = injectSaga({ key: 'contractsPage', saga: contractsSaga });
const withContractsReducer = injectReducer({ key: 'contractsPage', reducer: contractsReducer });

export default compose(
  withRouter,
  injectIntl,
  withConnect,
  withStyles(styles),
  withContractsSaga,
  withContractsReducer,
)(AccountSettingsButton);
