import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectDataNavigation = state => state.verticalNavBar || initialState;

const selectDataUser = () =>
  createSelector(selectDataNavigation, dataState => dataState.get('dataUser'));
const selectLoadingFlag = () =>
  createSelector(selectDataNavigation, dataState => dataState.get('loading'));

export { selectDataNavigation, selectDataUser, selectLoadingFlag };
