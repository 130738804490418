import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormHelperText, Input, Select, FormControl } from '@mui/material';
import { withStyles } from '@mui/styles';
import ChevronDown from 'components/SvgComponents/icons/ChevronDown';
import combineStyles from 'theme/combineStyles';
import formEngineStyle from 'theme/formEngine-theme';

export const styles = theme => ({
  formControl: {
    width: '100%',
  },
  root: {
    border: '1px  solid transparent',
    borderRadius: 200,
    '&:focus:not(:disabled)': {
      border: `1px solid ${theme.palette.text.primary}`,
    },
  },
  selectMenu: {
    backgroundColor: theme.palette.background.primary,
    border: '1px  solid transparent',
    borderRadius: 12,
    padding: '0.625em 2em 0.625em 1em',
    '&:hover:not(:disabled)': {
      border: `1px solid ${theme.palette.text.primary}`,
      backgroundColor: theme.palette.background.primary,
      borderRadius: 12,
    },
    '&:focus:not(:disabled)': {
      border: `1px solid ${theme.palette.text.primary}`,
      backgroundColor: theme.palette.background.primary,
      borderRadius: 12,
    },
  },
  selectMenuWithIcon: {
    paddingLeft: '2.5em',
  },
  icon: {
    right: 16,
    top: 'auto',
  },
  customIcon: {
    color: theme.palette.text.primary,
    position: 'absolute',
    zIndex: 1,
    left: '1em',
  },
  menu: {
    borderRadius: theme.spacing(),
    '& ul': {
      padding: 0,
    },
  },
});
export class SelectFieldOne extends React.Component {
  render() {
    const {
      input,
      defaultValue,
      children,
      error,
      meta,
      classes,
      placeholder,
      className,
      icon,
      renderValue,
      selectClasses,
      ...custom
    } = this.props;

    const onChange = event => {
      if (event.target.value !== input.value) {
        return input.onChange(event);
      }
      return null;
    };

    const metaError = meta.touched && !!meta.error;
    return (
      <FormControl className={classes.formControl}>
        <Select
          {...custom}
          renderValue={renderValue || null}
          onChange={onChange}
          value={input.value || defaultValue || placeholder}
          error={error || metaError}
          input={
            <Input
              startAdornment={
                icon && {
                  ...icon,
                  props: {
                    width: 16,
                    height: 16,
                    className: classes.customIcon,
                  },
                }
              }
            />
          }
          MenuProps={{
            classes: {
              paper: classes.menu,
            },
          }}
          disableUnderline
          IconComponent={props => <ChevronDown {...props} />}
          classes={{
            root: classes.root,
            icon: classes.icon,
            ...selectClasses,
            select: classNames(classes.selectMenu, {
              [classes.selectMenuWithIcon]: icon,
              [selectClasses?.selectMenu]: selectClasses?.selectMenu,
            }),
          }}
        >
          {children}
        </Select>
        {(error || (meta.touched && meta.error)) && (
          <FormHelperText error>{error || meta.error}</FormHelperText>
        )}
      </FormControl>
    );
  }
}

SelectFieldOne.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  selectClasses: PropTypes.object,
  className: PropTypes.string,
  meta: PropTypes.object.isRequired,
  error: PropTypes.object,
  renderValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  defaultValue: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  icon: PropTypes.node,
};

export default withStyles(combineStyles(formEngineStyle, styles))(
  SelectFieldOne,
);
