import React from 'react';

import PropTypes from 'prop-types';

import ChevronRight from 'components/SvgComponents/icons/ChevronRight';
import Folder from 'components/SvgComponents/icons/Folder';
import { TypographyEllipsis } from 'components/Templates/TypographyEllipsis';

import ItemRowContainer from './ItemRowContainer';

function ItemFolder({ folder, onClick }) {
  return (
    <ItemRowContainer onClick={() => onClick(folder)}>
      <Folder size={20} />
      <TypographyEllipsis variant="body" component="p" sx={{ flex: 1 }}>
        {folder.name}
      </TypographyEllipsis>
      <ChevronRight size={20} />
    </ItemRowContainer>
  );
}

ItemFolder.propTypes = {
  folder: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ItemFolder;
