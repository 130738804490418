import {
  SET_LOADING_FLAG,
  GET_CURRENT_PLAN,
  SET_CURRENT_PLAN,
  GET_AVAILABLE_OFFERS,
  SET_AVAILABLE_OFFERS,
  GET_ALL_MEMBERS,
  SET_ALL_MEMBERS,
  SET_MONTHLY_PRICE,
  SET_NUMBER_USERS,
  CANCEL_PLAN,
  RESET_CONTRACT_PAGE_STATE,
  MANAGE_PAYMENT_METHOD,
} from './constants';

export function setLoadingFlag(loading) {
  return {
    type: SET_LOADING_FLAG,
    loading,
  };
}
export function getCurrentPlan() {
  return {
    type: GET_CURRENT_PLAN,
  };
}
export function setCurrentPlan(plan) {
  return {
    type: SET_CURRENT_PLAN,
    plan,
  };
}
export function getAvailableOffers() {
  return {
    type: GET_AVAILABLE_OFFERS,
  };
}
export function setAvailableOffers(offers) {
  return {
    type: SET_AVAILABLE_OFFERS,
    offers,
  };
}
export function getAllMembers() {
  return {
    type: GET_ALL_MEMBERS,
  };
}
export function setAllMembers(members) {
  return {
    type: SET_ALL_MEMBERS,
    members,
  };
}
export function setNumberUsers(users) {
  return {
    type: SET_NUMBER_USERS,
    users,
  };
}
export function setMonthlyPrice(monthly) {
  return {
    type: SET_MONTHLY_PRICE,
    monthly,
  };
}

export function cancelPlan(callback) {
  return {
    type: CANCEL_PLAN,
    callback,
  };
}
export function resetPageState() {
  return {
    type: RESET_CONTRACT_PAGE_STATE,
  };
}

export function managePaymentMethod() {
  return {
    type: MANAGE_PAYMENT_METHOD,
  };
}
