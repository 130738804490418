import React, { useState } from 'react';

import { Card, DialogActions, IconButton, List, ListItem, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import Button from 'components/Button';
import Dialog from 'components/dialogs/Dialog';
import Member from 'components/Member';
import RemoveScrollbar from 'components/RemoveScrollbar';
import TextCellSkeleton from 'components/SkeletonScreen/components/TextCellSkeleton';
import MoreHorizontal from 'components/SvgComponents/icons/MoreHorizontal';
import UserAvatar from 'components/SvgComponents/icons/UserAvatar';
import { selectAllMembers } from 'containers/GlobalWrapper/selectors';
import AutoFetchingUser from 'containers/OneProjectPage/GroupsPage/components/AutoFetchingUser';
import globalMessages from 'translations/messages/global-messages';
import { handleToggleDialog } from 'utils/dialog';

export const styles = theme => ({
  membersList: {
    paddingLeft: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:first-child': {
      margin: 0,
    },
  },
  avatar: {
    width: '24px',
    height: '24px',
    marginLeft: '-5px',
    borderRadius: '50%',
  },
  moreItems: {
    backgroundColor: 'white',
    boxShadow: `inset 0 0 0 1px ${theme.palette.background.secondary}`,
    ZIndex: 1,
    color: theme.palette.text.dark,
    '&:hover': {
      backgroundColor: theme.palette.background.white,
    },
  },
  tooltip: {
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.text.secondary,
  },
  moreIcon: {
    color: theme.palette.text.dark,
    transform: 'scale(0.75)',
  },
  actionsRoot: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: '0 1em',
    margin: '0',
    height: '3.5em',
  },
  container: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    margin: `${theme.spacing(10)}`,
  },
  items: {
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: 'inset 0px -0.5px 0px rgba(132, 143, 157, 0.2)',
  },
});

export function MembersAvatarsCell(props) {
  const { loading, users = [], classes, intl, usersData, dialogTitle } = props;
  const [activeDialog, setActiveDialog] = useState(false);

  if (loading) {
    return <TextCellSkeleton />;
  }

  if (!loading && isEmpty(users)) {
    return <div>-</div>;
  }

  const toggle = e => {
    e.stopPropagation();
    handleToggleDialog(setActiveDialog, activeDialog)();
  };

  const getUser = userId => usersData.find(userData => userData?.user_id === userId);

  const sortedUsers = users.sort((a, b) => {
    const aName = a.first_name && a.last_name ? `${a.first_name} ${a.last_name}` : a.email;
    const bName = b.first_name && b.last_name ? `${b.first_name} ${b.last_name}` : b.email;

    if (aName && bName) {
      return aName.localeCompare(bName);
    }
    return 0;
  });

  return (
    <>
      <span className={classes.membersList}>
        {sortedUsers?.slice(0, 4).map((user, index) => {
          const itemKey = `${user.user_id}-${index}`;
          return (
            <AutoFetchingUser
              key={itemKey}
              className={classes.avatar}
              userId={user.user_id}
              showTooltip
              tooltipClassName={classes.tooltip}
              member={getUser(user.user_id)}
              customAvatar={() => <UserAvatar className={classes.avatar} width={24} height={24} />}
            />
          );
        })}
        <Tooltip
          title={intl.formatMessage(globalMessages.see_all)}
          placement="bottom-end"
          classes={{
            tooltip: classes.tooltip,
          }}
        >
          <IconButton
            onClick={toggle}
            classes={{
              root: classnames(classes.avatar, classes.moreItems),
            }}
          >
            <MoreHorizontal width={16} height={16} className={classes.moreIcon} />
          </IconButton>
        </Tooltip>
      </span>
      <Dialog
        id="members-dialog"
        maxWidth="sm"
        open={activeDialog}
        dialogTitleNode={dialogTitle || intl.formatMessage(globalMessages.users)}
        showTitle
        fullWidth
        onCloseDialog={toggle}
      >
        <Card elevation={0} className={classes.container}>
          <RemoveScrollbar>
            <List>
              {sortedUsers.map(user => {
                const userObj = getUser(user.user_id);
                return (
                  userObj && (
                    <ListItem key={`${userObj.user_id}-${userObj.email}`} className={classes.items}>
                      <Member member={userObj} isFirstColumnInTable />
                    </ListItem>
                  )
                );
              })}
            </List>
          </RemoveScrollbar>
        </Card>
        <DialogActions
          classes={{
            root: classes.actionsRoot,
          }}
        >
          <Button onClick={toggle} type="submit">
            {intl.formatMessage(globalMessages.ok)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
MembersAvatarsCell.propTypes = {
  users: PropTypes.array,
  usersData: PropTypes.object,
  loading: PropTypes.bool,
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  dialogTitle: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  usersData: selectAllMembers(),
});

const withConnect = connect(mapStateToProps);

export default compose(
  injectIntl,
  withConnect,
  withStyles(styles),
)(MembersAvatarsCell);
