import localStorageUser from 'utils/localStorageUser';
import { getArrayOrDefault } from 'utils/mappers';

export function getLocalization(data, value) {
  const locale = localStorageUser.getLanguageUserFromLocalStorage();
  let result = data
    .find(d => d.id === value)
    ?.name.find(e => e.localization === locale)?.translation;

  if (!result) {
    result = data
      .find(d => d.id === value)
      ?.name.find(e => e.localization === 'en')?.translation;
  }
  return result;
}

export function getLocalizationList(data) {
  const locale = localStorageUser.getLanguageUserFromLocalStorage();
  if (!data?.length) {
    return '';
  }

  let result = data.find(d => d.localization === locale)?.translation;

  if (!result) {
    result = data.find(d => d.localization === 'en')?.translation;
  }

  return result;
}

export function getTranslation(
  data,
  name,
  code = localStorageUser.getLanguageUserFromLocalStorage(),
) {
  const locale = code;
  let result = getArrayOrDefault(data).find(e => e.localization === locale)
    ?.translation;

  if (!result) {
    result = name;
  }

  if (!result && (data?.length && data[0])) {
    result = data[0].translation;
  }
  return result;
}

export default { getLocalization };
