import React from 'react';

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import DateTimeFormat from 'components/DateTimeFormat';
import DateCellSkeleton from 'components/SkeletonScreen/components/DateCellSkeleton';

export const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

export const DetailedDateCell = props => {
  const { classes, cellData, loading } = props;

  if (loading && !cellData) {
    return <DateCellSkeleton />;
  }

  if (!loading && !cellData) {
    return <div>-</div>;
  }

  return (
    <div className={classNames(classes.wrapper)}>
      <Typography component="p" noWrap variant="subtitle">
        <DateTimeFormat day="2-digit" month="short" year="numeric" date={cellData} />
      </Typography>
      <Typography component="p" noWrap variant="hint">
        <DateTimeFormat hour="numeric" minute="numeric" date={cellData} />
      </Typography>
    </div>
  );
};
DetailedDateCell.propTypes = {
  classes: PropTypes.object.isRequired,
  cellData: PropTypes.string,
  loading: PropTypes.bool,
};

export default withStyles(styles)(DetailedDateCell);
