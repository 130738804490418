const scope = 'PlansPage:';
export const ADD_FOLDER = `${scope}ADD_FOLDER`;
export const UPLOAD_PLAN = `${scope}UPLOAD_PLAN`;
export const UPDATE_PLAN = `${scope}UPDATE_PLAN`;
export const UPLOAD_PLAN_FAILED = `${scope}UPLOAD_PLAN_FAILED`;
export const UPLOAD_PLAN_SUCCESS = `${scope}UPLOAD_PLAN_SUCCESS`;
export const UPLOAD_FOLDER = `${scope}UPLOAD_FOLDER`;
export const GET_FOLDERS_CONTENT = `${scope}GET_FOLDERS_CONTENT`;
export const DELETE_ITEMS = `${scope}DELETE_ITEMS`;
export const MOVE_ITEMS_TO = `${scope}MOVE_ITEMS_TO`;
export const MOVE_FOLDERS_TO = `${scope}MOVE_FOLDERS_TO`;
export const MOVE_PLANS_TO = `${scope}MOVE_ITEM_TO`;
export const SET_LOADING_FLAG = `${scope}SET_LOADING_FLAG`;
export const SET_ITEM_TO_RENAME = `${scope}SET_ITEM_TO_RENAME`;
export const RENAME_ITEM = `${scope}RENAME_ITEM`;
export const SET_PLANS_TO_UPLOAD = `${scope}SET_PLANS_TO_UPLOAD`;
export const SET_FOLDERS_BREADCRUMBS = `${scope}SET_FOLDERS_BREADCRUMBS`;
export const SAVE_ITEM = `${scope}SAVE_ITEM`;
export const FETCH_ZONE_DATA = `${scope}FETCH_ZONE_DATA`;
export const SET_ZONE_DATA = `${scope}SET_ZONE_DATA`;
export const RESET_BREADCRUMB = `${scope}RESET_BREADCRUMB`;
export const FETCH_DATA_ITEM = `${scope}FETCH_DATA_ITEM`;
export const SET_DATA_ITEM = `${scope}SET_DATA_ITEM`;
export const DELETE_IMAGE = `${scope}DELETE_IMAGE`;
export const SAVE_ORDERED_ITEM = `${scope}SAVE_ORDERED_ITEM`;
export const GET_SMART_ZONING_TO_VALIDATE_COUNT = `${scope}GET_SMART_ZONING_TO_VALIDATE_COUNT`;
export const SET_SMART_ZONING_TO_VALIDATE_COUNT = `${scope}SET_SMART_ZONING_TO_VALIDATE_COUNT`;
export const CREATE_SMART_ZONING_FOLDER = `${scope}CREATE_SMART_ZONING_FOLDER`;
export const GET_PLAN = `${scope}GET_PLAN`;
export const GET_ALL_PLANS = `${scope}GET_ALL_PLANS`;
export const GET_ALL_FOLDERS = `${scope}GET_ALL_FOLDERS`;
