import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

function MoreHorizontal(props) {
  const { intl, title, ...otherProps } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <title>{title || intl.formatMessage(globalMessages.more)}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 12C17 10.8954 17.8954 10 19 10C20.1046 10 21 10.8954 21 12C21 13.1046 20.1046 14 19 14C17.8954 14 17 13.1046 17 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 10.8954 3.89543 10 5 10C6.10457 10 7 10.8954 7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12Z"
      />
    </svg>
  );
}
MoreHorizontal.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

MoreHorizontal.defaultProps = {
  title: '',
};

export default injectIntl(MoreHorizontal);
