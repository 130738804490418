import { Box, styled } from '@mui/material';

const ItemRowContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.spacing(1)};
  align-items: center;
  height: 48px;
  cursor: pointer;
  border-radius: 4px;
  padding: 0 ${props => props.theme.spacing(1)};
  &:hover {
    background-color: ${props => props.theme.palette.custom.grey_hover.background};
  }
`;

export default ItemRowContainer;
