import sortBy from 'lodash/sortBy';
import { put, call } from 'redux-saga/effects';

import config from 'config/config';
import { setGlobalError } from 'containers/ErrorHandler/actions';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { MODULE_TYPES } from 'utils/constants';
import request from 'utils/request';

import { setLoadingFlag } from '../actions';
import {
  setModulesObservationsAndMeeting,
  setModulesForms,
  setNewModule,
  setUpdatedModule,
  setCurrentModule,
} from './actions';

const MODULE_VERSION_API = 'v2.2';

export function* fetchModulesObservationsAndMeeting({ project_id, featureFlags, callback }) {
  yield put(setLoadingFlag(true));
  let requestURL = '';
  requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/${MODULE_VERSION_API}/projects/${project_id}/modules`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
    },
  };

  const data = yield call(request, requestURL, options);

  if (data && !data.message) {
    if (featureFlags?.templates_module_management) {
      if (data.modules) {
        yield put(
          setModulesObservationsAndMeeting(
            sortBy(data.modules.filter(module => module.type !== MODULE_TYPES.forms), 'order') ||
              [],
          ),
        );
      }
      yield call(callback);
    } else {
      yield put(setModulesObservationsAndMeeting(sortBy(data.modules, 'order') || []));
    }
  }
  yield put(setLoadingFlag(false));
}

export function* fetchModulesForms({ project_id, append }) {
  yield put(setLoadingFlag(true));

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.1/projects/${project_id}/forms-modules/filters?limit=1000`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      search_term: '',
      visibility: null,
      creator_ids: null,
    }),
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    yield put(setModulesForms(sortBy(data.modules, 'order') || [], append));
  }
  yield put(setLoadingFlag(false));
}

export function* fetchCurrentModule({
  projectId,
  moduleId,
  templatesModuleManagement,
  moduleType,
}) {
  yield put(setLoadingFlag(true));

  try {
    const options = {
      method: 'GET',
      headers: {
        'Cache-Control': 'No-Store',
      },
    };

    const requestURL =
      moduleType === MODULE_TYPES.forms && templatesModuleManagement
        ? `${checkOrSetSlash(
            config.apiHostGateway,
            'apiHostGateway',
          )}api/v1.0/projects/${projectId}/forms-modules/${moduleId}`
        : `${checkOrSetSlash(
            config.apiHostGateway,
            'apiHostGateway',
          )}api/${MODULE_VERSION_API}/projects/${projectId}/modules/${moduleId}`;

    const data = yield call(request, requestURL, options);
    if (!data) {
      throw new Error('Error updating module description setting');
    }
    if (data.message || data.status >= 400) {
      yield put(setGlobalError(data));
    }

    yield put(setCurrentModule(data));
  } catch (error) {
    yield put(setGlobalError(error));
  } finally {
    yield put(setLoadingFlag(false));
  }
}

export function* updateModuleSettings({ projectId, moduleId, settings }) {
  try {
    yield put(setLoadingFlag(true));
    const requestURL = `${checkOrSetSlash(
      config.apiHostGateway,
      'apiHostGateway',
    )}api/${MODULE_VERSION_API}/projects/${projectId}/modules/${moduleId}/settings`;

    const options = {
      method: 'POST',
      headers: {
        'Cache-Control': 'No-Store',
        'Content-Type': 'application/json',
      },

      body: JSON.stringify(settings),
    };

    const data = yield call(request, requestURL, options);

    if (!data) {
      throw new Error('Error updating module description setting');
    }

    if (data.message || data.status >= 400) {
      yield put(setGlobalError(data));
    }

    yield put(setCurrentModule(data));
  } catch (error) {
    yield put(setGlobalError(error));
  } finally {
    yield put(setLoadingFlag(false));
  }
}

// Add existing module
export function* addModule({ projectId, selectedModule }) {
  const requestURL =
    selectedModule.type === MODULE_TYPES.forms
      ? `${checkOrSetSlash(
          config.apiHostGateway,
          'apiHostGateway',
        )}api/v1.0/projects/${projectId}/forms-modules`
      : `${checkOrSetSlash(
          config.apiHostGateway,
          'apiHostGateway',
        )}api/${MODULE_VERSION_API}/projects/${projectId}/modules`;

  const body = {
    from_module_id: selectedModule.id,
  };

  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  const data = yield call(request, requestURL, options);

  if (data && data?.message) {
    yield put(setGlobalError(data));
  } else if (data) {
    yield put(setNewModule(data));
  }
}

// Create a new module
export function* createModule({ payload }) {
  yield put(setLoadingFlag(true));
  const { name, icon, color, projectId, workspace_id, organization_id } = payload;
  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/${MODULE_VERSION_API}/projects/${projectId}/modules`;

  const body = {
    name,
    icon,
    color,
    organization_id,
    order: 0,
    type: MODULE_TYPES.observations,
    from_module_id: null,
    workspace_id,
  };

  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  const data = yield call(request, requestURL, options);

  if (data && data?.message) {
    console.error(data.message);
  } else if (data) {
    yield put(setNewModule(data));
  }
  yield put(setLoadingFlag(false));
}

// Edit a module
export function* editModule({ payload, templatesModuleManagement }) {
  yield put(setLoadingFlag(true));
  const { id, name, order, icon, color, enabled, projectId, type } = payload;
  const requestURL =
    type === MODULE_TYPES.forms && templatesModuleManagement
      ? `${checkOrSetSlash(
          config.apiHostGateway,
          'apiHostGateway',
        )}api/v1.0/projects/${projectId}/forms-modules/${id}`
      : `${checkOrSetSlash(
          config.apiHostGateway,
          'apiHostGateway',
        )}api/${MODULE_VERSION_API}/projects/${projectId}/modules/${id}`;

  const body = {
    name,
    icon,
    color,
    ...(type === MODULE_TYPES.forms ? { visibility: true, enabled } : { order, enabled }),
  };

  const options = {
    method: 'PUT',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  const data = yield call(request, requestURL, options);
  if (data && data?.message) {
    console.error(data.message);
  } else if (data) {
    yield put(setUpdatedModule(data));
  }
  yield put(setLoadingFlag(false));
}
