import React, { useEffect, useState } from 'react';

import { IconButton, styled, Typography } from '@mui/material';
import { useTheme, withStyles } from '@mui/styles';
import Gleap from 'gleap';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import FCList from 'components/FCList';
import HelpCircle from 'components/SvgComponents/icons/HelpCircle';
import HelpCircleFill from 'components/SvgComponents/icons/HelpCircleFill';
import Megaphone from 'components/SvgComponents/icons/Megaphone';
import Message from 'components/SvgComponents/icons/Message';
import ThumbsUp from 'components/SvgComponents/icons/ThumbsUp';
import { LOGO_TOOLBAR_HEIGHT, LOGO_TOOLBAR_WIDTH } from 'containers/NavigationBar/constants';
import { useColorMode } from 'contexts/ColorModeProvider';
import { useFeatureFlags } from 'contexts/FeatureFlagsProvider';
import globalMessages from 'translations/messages/global-messages';
import Analytics from 'utils/Analytics';
import { colorTheme } from 'utils/constants';

const styles = theme => ({
  popper: {
    marginLeft: '8px',
    marginBottom: '15px',
    zIndex: theme.zIndex.drawer,
  },
  paper: {
    padding: 0,
    borderRadius: theme.spacing(3),
    boxShadow: '0px 6px 15px 0px rgba(23, 33, 52, 0.15)',
    overflow: 'hidden',
  },
  helpContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginInlineEnd: '0.5rem',
  },
});

const IconButton_HelpCenter = styled(IconButton)(() => ({
  '& .helpCenterSvgFill': {
    opacity: 0,
    position: 'absolute',
  },
  '&:hover .helpCenterSvg': {
    opacity: 0,
  },
  '&:hover .helpCenterSvgFill': {
    opacity: 1,
  },
}));

const HelpCenter = ({ classes, intl, dataUser }) => {
  const featureFlags = useFeatureFlags();
  const [displayVoteOption, setDisplayVoteOption] = useState(false);
  const btnOpenHelp = React.useRef(null);
  const theme = useTheme();
  const { mode } = useColorMode();
  const isDarkMode = mode === colorTheme.dark;

  useEffect(
    () => {
      const languageForVote = 'fr';
      if (dataUser) {
        const { locale } = dataUser;

        const { language } = navigator;
        const localeLanguage = language.split('-')[0];
        setDisplayVoteOption(locale === languageForVote || localeLanguage === languageForVote);
      }

      return () => {
        setDisplayVoteOption(false);
      };
    },
    [dataUser],
  );

  const handleClickWhatNew = () => {
    Analytics.track('onboarding_release_note_opened');
  };
  const handleClickContactUs = () => {
    Gleap.open();
  };
  const handleClickVote = () => {
    Analytics.track('onboarding_vote_feature_opened');
  };
  const handleClickHelpCenter = () => {
    Analytics.track('onboarding_help_center_opened');
  };

  return (
    <>
      <IconButton_HelpCenter className={classes.iconButton} ref={btnOpenHelp}>
        <HelpCircle
          width={LOGO_TOOLBAR_WIDTH}
          height={LOGO_TOOLBAR_HEIGHT}
          className={isDarkMode ? 'helpCenterSvgFill' : 'helpCenterSvg'}
          fill={isDarkMode ? theme.palette.text.white : theme.palette.text.primary}
        />
        <HelpCircleFill
          width={LOGO_TOOLBAR_WIDTH}
          height={LOGO_TOOLBAR_HEIGHT}
          className={isDarkMode ? 'helpCenterSvg' : 'helpCenterSvgFill'}
          color={isDarkMode ? theme.palette.background.white : theme.palette.text.primary}
          fill={theme.palette.background.white}
        />
      </IconButton_HelpCenter>
      <FCList anchor={btnOpenHelp?.current} placement="bottom-end" attacheEventToAnchor>
        <FCList.ListItemButton
          onClick={handleClickHelpCenter}
          href={featureFlags?.web_help_center_link || ''}
          target="_blank"
        >
          <HelpCircle
            width={16}
            height={16}
            fill={theme.palette.text.primary}
            className={classes.icon}
          />
          <Typography noWrap component="p" variant="body">
            {intl.formatMessage(globalMessages.help_center)}
          </Typography>
        </FCList.ListItemButton>
        <FCList.ListItemButton onClick={handleClickContactUs}>
          <Message
            width={16}
            height={16}
            fill={theme.palette.text.primary}
            className={classes.icon}
          />
          <Typography noWrap component="p" variant="body">
            {intl.formatMessage(globalMessages.contact_us)}
          </Typography>
        </FCList.ListItemButton>
        <FCList.ListItemButton
          onClick={handleClickWhatNew}
          href={featureFlags?.product_board_whats_new || ''}
          target="_blank"
        >
          <Megaphone
            width={16}
            height={16}
            fill={theme.palette.text.primary}
            className={classes.icon}
          />
          <Typography noWrap component="p" variant="body">
            {intl.formatMessage(globalMessages.what_new)}
          </Typography>
        </FCList.ListItemButton>
        {displayVoteOption && (
          <FCList.ListItemButton
            onClick={handleClickVote}
            href={featureFlags?.product_board_vote || ''}
            target="_blank"
          >
            <ThumbsUp
              width={16}
              height={16}
              stroke={theme.palette.text.primary}
              className={classes.icon}
            />
            <Typography noWrap component="p" variant="body">
              {intl.formatMessage(globalMessages.vote)}
            </Typography>
          </FCList.ListItemButton>
        )}
      </FCList>
    </>
  );
};

HelpCenter.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  dataUser: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
export default compose(
  injectIntl,
  withStyles(styles),
)(HelpCenter);
