import { fromJS } from 'immutable';

import { scheduleType } from 'utils/constants';

import {
  SET_RECIPIENTS,
  SET_LOADING_FLAG,
  SET_ACTIVE_FILTERS,
  CLEAR_ACTIVE_FILTERS,
  SET_FORMS_PRIORITIES,
  SET_OBSERVATIONS_PRIORITIES,
  SET_PERIODIC_PREFERENCES,
  CLEAR_PERIODIC_PREFERENCES,
  SET_RECIPIENTS_PREFERENCES,
  SET_CUSTOMIZATION_CONFIGS,
  CLEAR_CUSTOMIZATION_CONFIGS,
} from './constants';

const initialValues = {
  loading: false,
  activeFilters: {},
  periodicReportPreferences: { periodicity: scheduleType.daily },
  formsPriorities: [],
  observationsPriorities: [],
  count: 0,
  recipients: [],
  recipientIds: [],
  customizationConfigs: {},
};
export const initialState = fromJS(initialValues);

function formsPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_RECIPIENTS:
      return state.set('recipients', fromJS(action.recipients));
    case SET_RECIPIENTS_PREFERENCES:
      return state.set('recipientIds', fromJS(action.recipient_ids));
    case SET_ACTIVE_FILTERS:
      return state.set('loading', true).set('activeFilters', fromJS(action.filters));
    case SET_PERIODIC_PREFERENCES:
      return state
        .set('loading', true)
        .set('periodicReportPreferences', fromJS(action.periodicReportPreferences));
    case CLEAR_PERIODIC_PREFERENCES:
      return state
        .set('loading', true)
        .set('periodicReportPreferences', fromJS(initialValues.periodicReportPreferences));
    case CLEAR_ACTIVE_FILTERS:
      return state.set('loading', true).set(
        'activeFilters',
        fromJS({
          split_by: state.get('activeFilters').get('split_by'),
        }),
      );
    case SET_FORMS_PRIORITIES:
      return state.set('formsPriorities', fromJS(action.priorities));
    case SET_OBSERVATIONS_PRIORITIES:
      return state.set('observationsPriorities', fromJS(action.priorities));
    case SET_LOADING_FLAG:
      return state.set('loading', fromJS(action.loading));
    case SET_CUSTOMIZATION_CONFIGS:
      return state.set('customizationConfigs', fromJS(action.configs));
    case CLEAR_CUSTOMIZATION_CONFIGS:
      return state.set('customizationConfigs', fromJS({}));
    default:
      return state;
  }
}
export default formsPageReducer;
