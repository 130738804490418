import React from 'react';
import PropTypes from 'prop-types';

import { MenuItem } from '@mui/material';
import { withStyles } from '@mui/styles';

import Select from 'components/reduxFormComponentsV3/Select';
import {
  getTempTemplateLanguagesDisplay,
  tempTemplateLanguages,
} from 'utils/constants';

export const styles = theme => ({
  formLanguageSelect: {
    marginTop: theme.spacing(4),
  },
  roleMenuItem: {
    minWidth: 100,
    fontSize: '0.75rem',
    padding: `3px ${theme.spacing(2)}`,
    '&:hover': {
      backgroundColor: theme.palette.background.primary,
    },
    '&:focus': {
      backgroundColor: theme.palette.background.primary,
    },
  },
  selectedMenu: {
    backgroundColor: `${theme.palette.background.primary} !important`,
  },
});

function ChangeLanguage(props) {
  const { formLanguages, classes, language } = props;
  const handleChange = ({ target }) => props.onChange(target.value);
  const languages = formLanguages.reduce((acc, lang) => {
    const findOption = tempTemplateLanguages.find(
      option => lang === option.code,
    );
    if (findOption) {
      acc.push(findOption);
    }
    return acc;
  }, []);

    const languageExistInLanguages = languages.find(
    option => language === option.code,
    );

  return (
    <div className={classes.formLanguageSelect}>
      <Select
        value={languageExistInLanguages ? language : languages[0]?.code}
        onChange={handleChange}
        fullWidth={false}
        size="small"
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {languages.map(option => (
          <MenuItem
            key={option.code}
            value={option.code}
            data-guid={option.code}
            classes={{
              root: classes.roleMenuItem,
              selected: classes.selectedMenu,
            }}
          >
            {getTempTemplateLanguagesDisplay(option.code)}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

ChangeLanguage.propTypes = {
  classes: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  formLanguages: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

ChangeLanguage.defaultProps = {
  formLanguages: [],
};

export default withStyles(styles)(ChangeLanguage);
