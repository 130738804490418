import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { takeLatest, put, call, select } from 'redux-saga/effects';

import { sendMessage } from 'components/AddMessage/actions';
import { setDropdownData } from 'components/Dropdown/actions';
import { clearItems } from 'components/FCTable/actions';
import { setLoadingFlag as setLoadingFlagTable } from 'components/InfiniteTableSelfStandingV3/actions';
import config from 'config/config';
import { setGlobalError } from 'containers/ErrorHandler/actions';
import { removeObservation } from 'containers/GlobalWrapper/globalStore/observations/actions';
import { selectObservation } from 'containers/GlobalWrapper/globalStore/observations/selectors';
import { selectRouteParams } from 'containers/GlobalWrapper/selectors';
import { getLimitOffer } from 'containers/LimitationMessageOffer/actions';
import { setGlobalMessage } from 'containers/MessageHandler/actions';
import globalMessages from 'translations/messages/global-messages';
import Analytics from 'utils/Analytics';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { uploadMedia } from 'utils/chunkUploader';
import { checkParams } from 'utils/errors/check-params';
import { downloadBlob } from 'utils/file';
import { request, requestFile, getNameFromContentDisposition } from 'utils/request';

import { customerIoTrackerWithParams } from '../../../utils/CustomerIo';
import {
  setStatusList,
  setToStatusList,
  setPriorities,
  setLoadingFlag,
  setPhases,
} from './actions';
import {
  GET_ALL_STATUSES,
  SET_OBSERVATION_STATUS,
  SET_BULK_OBSERVATION_STATUS,
  SET_BULK_DUE_DATE,
  GET_PRIORITIES,
  EXPORT_OBSERVATIONS,
  EXPORT_OBSERVATIONS_LOGS,
  DELETE_OBSERVATIONS,
  SAVE_OBSERVATION,
  GET_TO_STATUS_LIST,
  ADD_TRADE,
  ADD_OBSERVATION,
  SET_BULK_COMPANY,
  SET_BULK_PRIORITY,
  SET_BULK_ASSIGNEE,
  SET_BULK_PHASE,
  GET_PHASES,
  NO_PHASE,
  NO_ASSIGNEE,
  NO_COMPANY,
  IMPORT_OBSERVATIONS,
  DOWNLOAD_IMPORT_TEMPLATE,
  SET_BULK_MODULE,
} from './constants';

export function* fetchAllStatuses({ params }) {
  checkParams({ params, keys: ['idData'] });

  const { with_deleted = true, without_disabled = false } = params;

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    params.idData
  }/statuses?with_deleted=${with_deleted}&without_disabled=${without_disabled}`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
    },
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    yield put(setStatusList(sortBy(data.statuses, 'position') || []));
  }
}

export function* fetchToStatusList({ statusId, params }) {
  checkParams({ params, keys: ['idData'] });

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    params.idData
  }/statuses/${statusId}/next-statuses`;
  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
    },
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    if (!data.statuses?.length) {
      yield put(
        setDropdownData({
          visibility: false,
          id: null,
          reference: {},
          selected: null,
        }),
      );
    } else {
      yield put(setToStatusList({ [statusId]: sortBy(data.statuses, 'position') }));
    }
  }
}

export function* setObservationStatus({ observationId, statusId }) {
  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/observations/${observationId}/status/${statusId}`;

  const options = {
    method: 'POST',
    body: JSON.stringify({ client_updated_at: moment() }),
  };

  yield call(request, requestURL, options);

  Analytics.track('observations_status_changed');
  yield put(clearItems());
}

export function* setBulkObservationStatus({ observationList, status }) {
  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/observations/bulk-status-update`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      new_status_id: status.id,
      ids: observationList.map(observation => observation.id),
    }),
  };

  const httpCall = yield call(request, requestURL, options, {
    errorCustom: true,
  });
  const rejectErrors = httpCall.errors || httpCall.response?.errors;
  if (rejectErrors?.length) {
    const mappedMessages = rejectErrors.map(err => {
      const cObs = observationList.filter(ob => ob.id === err.observation_id)[0];
      return {
        id: 'observation_status_not_changed',
        values: {
          observation: cObs.name,
          status: cObs.status.name,
          newStatus: status.name,
        },
      };
    });
    yield put(
      setGlobalError({
        customErrorMessageIntl: mappedMessages,
      }),
    );
  }
  Analytics.track('observations_engagement');
  Analytics.track('observation_bulk_status_changed');
  yield put(clearItems());
}

export function* setBulkDueDate({ observationIds, dueDate }) {
  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/observations/bulk-due-date-update`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      new_due_date: dueDate,
      ids: observationIds.map(observation => observation.id),
    }),
  };

  yield call(request, requestURL, options);
  Analytics.track('observations_engagement');
  Analytics.track('observations_bulk_due_date_changed');
  yield put(clearItems());
}

export function* setBulkCompany({ observationIds, companyId }) {
  const route = yield select(selectRouteParams());
  // Send id = null to backend for "no company"
  const newCompanyId = companyId === NO_COMPANY ? null : companyId;

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/observations/bulk-company-update`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      new_company_id: newCompanyId,
      ids: observationIds.map(observation => observation.id),
    }),
  };
  yield call(request, requestURL, options);
  Analytics.track('observations_engagement');
  Analytics.track('observation_bulk_company_assign_changed');
  yield put(clearItems());
}

export function* setBulkPriority({ observationIds, priorityId }) {
  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/observations/bulk-priority-update`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      new_priority_id: priorityId,
      ids: observationIds.map(observation => observation.id),
    }),
  };

  yield call(request, requestURL, options);
  Analytics.track('observations_engagement');
  Analytics.track('observation_bulk_priority_changed');
  yield put(clearItems());
}

export function* setBulkAssignee({ observationIds, assigneeId }) {
  const route = yield select(selectRouteParams());

  // Send id = null to backend for "no assignee"
  const newAssigneeId = assigneeId === NO_ASSIGNEE ? null : assigneeId;

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/observations/bulk-assignee-update`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      new_assignee_id: newAssigneeId,
      ids: observationIds.map(observation => observation.id),
    }),
  };
  yield call(request, requestURL, options);
  Analytics.track('observations_engagement');
  Analytics.track('observation_bulk_assignee_changed');
  yield put(clearItems());
}

export function* setBulkPhase({ observationIds, phaseId }) {
  const route = yield select(selectRouteParams());

  // Send id = null to backend for "no phase"
  const newPhaseId = phaseId === NO_PHASE ? null : phaseId;

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/observations/bulk-phase-update`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      new_phase_id: newPhaseId,
      ids: observationIds.map(observation => observation.id),
    }),
  };

  yield call(request, requestURL, options);
  Analytics.track('observations_engagement');
  Analytics.track('observation_bulk_phase_changed');
  yield put(clearItems());
}

export function* deleteObservation({ id }) {
  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/observations/${id}`;

  const options = {
    method: 'DELETE',
  };

  const data = yield call(requestFile, requestURL, options);
  if (data && !data.message) {
    Analytics.track('observations_deleted');
    yield put(clearItems());

    const observationData = yield select(selectObservation(id));
    if (observationData) {
      yield put(removeObservation(id));
    }
  }
  yield put(getLimitOffer({ params: route }));
}

export function* getPriorities({ params }) {
  checkParams({ params, keys: ['idData'] });

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    params.idData
  }/observations-priorities`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
    },
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    yield put(setPriorities(data.priorities || []));
  }
}

export function* getPhases({ params }) {
  checkParams({ params, keys: ['idData'] });

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    params.idData
  }/phases?with_deleted=false`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
    },
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    yield put(setPhases(data.phases || []));
  }
}

export function* fetchObservationsToExport({ activeFilters, params }) {
  checkParams({ params, keys: ['idData', 'itemId'] });

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    params.idData
  }/export/observations`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...activeFilters,
      module_id: params.itemId,
    }),
  };

  yield put(
    setGlobalMessage({
      type: 'success',
      intlMessage: {
        id: 'export_in_progress',
      },
      active: true,
    }),
  );

  const data = yield call(requestFile, requestURL, options);

  if (data && !data.message) {
    const name = getNameFromContentDisposition(data.headers);
    const reader = data.body?.getReader();
    const result = [];
    if (!reader) return;
    reader.read().then(function processText({ done, value }) {
      if (done) {
        Analytics.track('reports_observations_xls_succeedeed');
        downloadBlob(result, name);
        return;
      }
      result.push(value);
      reader.read().then(processText);
    });
  } else {
    Analytics.track('reports_observations_xls_failed');
  }
}

export function* fetchObservationsLogsToExport({ project, activeFilters, params }) {
  Analytics.track('observations_get_log_file');
  checkParams({ params, keys: ['idData', 'itemId'] });

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    params.idData
  }/history/observations`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...activeFilters,
      module_id: params.itemId,
    }),
  };

  const data = yield call(requestFile, requestURL, options, {
    errorRedirect: false,
    errorCustom: true,
  });

  if (data?.response?.code === 'ENTITY_NOT_FOUND') {
    yield put(
      setGlobalError({
        data,
        customErrorMessageIntl: globalMessages.observation_log_error,
      }),
    );
  } else if (data && !data.message) {
    yield put(
      setGlobalMessage({
        type: 'success',
        intlMessage: {
          id: 'export_in_progress',
        },
        active: true,
      }),
    );

    const name = getNameFromContentDisposition(data.headers);
    const reader = data.body?.getReader();
    const result = [];

    if (!reader) return;
    reader.read().then(function processText({ done, value }) {
      if (done) {
        downloadBlob(
          result,
          name || `${project?.name}_module_log_${moment().format('DD-MM-YYYY-HH-MM')}.csv`,
        );
        return;
      }
      result.push(value);
      reader.read().then(processText);
    });
  }
}

export function* saveObservation({
  projectId,
  trade,
  observation,
  group,
  description,
  callback,
  position,
  moduleId = null,
  source = 'observations',
  dueDate,
}) {
  const route = yield select(selectRouteParams());
  yield put(setLoadingFlag(true));
  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/observations`;

  const params = {};

  if (trade || observation) {
    params.trade_id = trade.id;
    params.common_observation_id = observation.id;
  }

  if (position?.x && position?.y && position?.planId) {
    params.position_x = position.x;
    params.position_y = position.y;
    params.plan_id = position.planId;
  }

  if (group?.group_id) {
    params.group_id = group.group_id;
  }

  if (dueDate) {
    params.due_date = dueDate;
  }

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      description,
      ...params,
      module_id: moduleId || route.itemId,
      client_created_at: moment(),
    }),
  };

  const data = yield call(request, requestURL, options);
  yield put(setLoadingFlag(false));
  if (!data?.message && callback) {
    if (group?.group_id) {
      const sendMessageRequestOption = {
        method: 'POST',
        url: `${checkOrSetSlash(
          config.apiHostGateway,
          'apiHostGateway',
        )}api/v1.3/projects/${projectId}/groups/${group.group_id}/messages`,
        dataKey: 'sendObservationMessage',
        nameSpace: 'sendObservationMessage',
        from: 'Observation',
      };

      yield put(
        sendMessage(data.id, 'Observation', sendMessageRequestOption, [], () => {
          callback(data);
        }),
      );
    } else {
      yield call(callback, data);
    }
    Analytics.track('observations_creation_succeeded', {
      source,
      with_image: 'no_image',
    });
    yield customerIoTrackerWithParams('observations_creation_succeeded', data.id, projectId);
  }
  yield put(getLimitOffer({ params: route }));
}

export function* addTrade({ trade, baseApiRoute, callback, listsRequestOption }) {
  const { namespace } = listsRequestOption;
  yield put(setLoadingFlagTable(true, namespace));
  yield put(setLoadingFlag(true, namespace));

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}${baseApiRoute}`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(trade),
  };

  const data = yield call(request, requestURL, options);

  if (!data || (data && !data.message)) {
    yield call(callback, data);
  }
  yield put(setLoadingFlag(false, namespace));
}

export function* addObservation({ observation, baseApiRoute, listsRequestOption, callback }) {
  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}${baseApiRoute}`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(observation),
  };
  const { namespace } = listsRequestOption;
  yield put(setLoadingFlagTable(true, namespace));
  const data = yield call(request, requestURL, options);
  if (!data || (data && !data.message)) {
    yield call(callback, data);
  }
  yield put(setLoadingFlagTable(false, namespace));
}

export function* importObservations({ item, params, callback }) {
  yield put(setLoadingFlagTable(true));
  const requestUploadMediaUrl = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.2/projects/${params.idData}/`;
  const dataUpload = yield call(uploadMedia, item, {
    baseUrl: requestUploadMediaUrl,
    parallel: false,
    requestHeaders: {
      'Content-Type': 'application/json',
    },
  });

  if (dataUpload && !dataUpload.message) {
    const payload = {
      media_id: dataUpload.id,
    };

    if (params?.itemId) {
      payload.parent_id = params.itemId;
    }

    const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
      params.idData
    }/import/observations/module/${params.itemId}`;

    const optionsUpload = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };
    const data = yield call(request, requestURL, optionsUpload, {
      errorRedirect: false,
      errorCustom: true,
    });

    if (data && (data.message || data.status === 500)) {
      yield put(setLoadingFlagTable(false));
      if (data.status === 500) {
        yield put(
          setGlobalError({
            data,
            customErrorMessageIntl: globalMessages.masse_import_wrong_template,
          }),
        );
      } else if (data?.response?.code === 'INPUT_VALUE_NOT_FOUND') {
        yield put(
          setGlobalError({
            data,
            customErrorMessageIntl: globalMessages.masse_import_obs_error_filled,
          }),
        );
      } else if (data?.response?.code === 'WRONG_TEMPLATE') {
        yield put(
          setGlobalError({
            data,
            customErrorMessageIntl: globalMessages.masse_import_wrong_template,
          }),
        );
      } else if (data?.response?.code === 'INCORRECTLY_FILLED_TEMPLATE') {
        yield put(
          setGlobalError({
            data,
            customErrorMessageIntl: globalMessages.masse_import_obs_error_filled,
          }),
        );
      } else if (data?.response?.code === 'WRONG_PROJECT_TEMPLATE') {
        yield put(
          setGlobalError({
            data,
            customErrorMessageIntl: globalMessages.masse_import_wrong_project_template,
          }),
        );
      } else if (data?.response?.code === 'WRONG_MODULE_TEMPLATE') {
        yield put(
          setGlobalError({
            data,
            customErrorMessageIntl: globalMessages.masse_import_wrong_module_template,
          }),
        );
      } else if (data?.response?.code === 'EMPTY_TEMPLATE') {
        yield put(
          setGlobalError({
            data,
            customErrorMessageIntl: globalMessages.imported_template_is_empty,
          }),
        );
      }
    } else {
      yield put(
        setGlobalMessage({
          type: 'success',
          intlMessage: {
            id: 'masse_import_observation_started_description',
          },
          active: true,
        }),
      );
    }

    if (callback) {
      yield call(callback);
    }
  }
}

export function* downloadImportTemplate({ params }) {
  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    params.idData
  }/import/template/observations/module/${params.itemId}`;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  yield put(
    setGlobalMessage({
      type: 'success',
      intlMessage: {
        id: 'export_in_progress',
      },
      active: true,
    }),
  );

  const data = yield call(requestFile, requestURL, options);
  if (data && !data.message) {
    const name = getNameFromContentDisposition(data.headers);

    const reader = data.body?.getReader();
    const result = [];
    if (reader) {
      reader.read().then(function processText({ done, value }) {
        if (done) {
          downloadBlob(result, name || `template.xlsx`);
          return;
        }
        result.push(value);
        reader.read().then(processText);
      });
    }
  }
}

export function* setBulkModule({ observations, module, callback }) {
  yield put(setLoadingFlagTable(true));
  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.3/projects/${route.idData}/observations/bulk-move-module-update`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      new_module_id: module?.id,
      ids: observations.map(item => item.id),
    }),
  };

  const data = yield call(request, requestURL, options);

  if (!data || (data && !data.message)) {
    yield put(
      setGlobalMessage({
        type: 'success',
        intlMessage: {
          id: 'item_moved_to_module',
          values: { module: module?.label, count: observations.length },
        },
        active: true,
      }),
    );

    if (callback) {
      yield put(clearItems());
      yield call(callback);
    }
  }

  yield put(setLoadingFlagTable(false));
}

export function buildItemFetchPayLoadByOffset(projectId, moduleId, search, filters = {}) {
  return ({ pageSize, offset }) => ({
    method: 'POST',
    path: `api/projects/${projectId}/observations/filter`,
    rootKey: 'observations',
    body: {
      ...filters,
      assignee_ids: [
        ...(filters?.assignee_ids ? filters?.assignee_ids : []),
        ...(filters?.assigned_to_me ? filters?.assigned_to_me : []),
      ],
      module_ids: [moduleId],
      ...(search ? { search_term: search } : {}),
    },
    params: {
      limit: pageSize,
      offset,
    },
    totalCountKey: 'count',
  });
}

export function buildItemFetchPayLoad(projectId, moduleId, search, filters = {}) {
  return ({ pageSize, pageIndex }) =>
    buildItemFetchPayLoadByOffset(projectId, moduleId, search, filters)({
      pageSize,
      offset: pageSize * pageIndex,
    });
}

export default function* initObservationsPageSaga() {
  yield takeLatest(GET_ALL_STATUSES, fetchAllStatuses);
  yield takeLatest(GET_TO_STATUS_LIST, fetchToStatusList);
  yield takeLatest(SET_OBSERVATION_STATUS, setObservationStatus);
  yield takeLatest(SET_BULK_OBSERVATION_STATUS, setBulkObservationStatus);
  yield takeLatest(SET_BULK_DUE_DATE, setBulkDueDate);
  yield takeLatest(SET_BULK_COMPANY, setBulkCompany);
  yield takeLatest(SET_BULK_PRIORITY, setBulkPriority);
  yield takeLatest(SET_BULK_ASSIGNEE, setBulkAssignee);
  yield takeLatest(SET_BULK_PHASE, setBulkPhase);
  yield takeLatest(SET_BULK_MODULE, setBulkModule);
  yield takeLatest(GET_PRIORITIES, getPriorities);
  yield takeLatest(EXPORT_OBSERVATIONS, fetchObservationsToExport);
  yield takeLatest(EXPORT_OBSERVATIONS_LOGS, fetchObservationsLogsToExport);
  yield takeLatest(DELETE_OBSERVATIONS, deleteObservation);
  yield takeLatest(SAVE_OBSERVATION, saveObservation);
  yield takeLatest(ADD_TRADE, addTrade);
  yield takeLatest(ADD_OBSERVATION, addObservation);
  yield takeLatest(GET_PHASES, getPhases);
  yield takeLatest(IMPORT_OBSERVATIONS, importObservations);
  yield takeLatest(DOWNLOAD_IMPORT_TEMPLATE, downloadImportTemplate);
}
