import React, { useEffect } from 'react';

import { withOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ErrorsPage from 'components/Errors';
import LoadingIndicator from 'components/LoadingIndicator';

import { fetchSpecialRights } from '../actions';
import { selectRouteParams } from '../selectors';
import { ResponsiveLayout } from './responsive-layout';
import { useLayout } from './use-layout';

function LayoutComp({ authState, children }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useSelector(selectRouteParams());

  useEffect(
    () => {
      dispatch(fetchSpecialRights());
    },
    [dispatch],
  );

  const { error } = useLayout({ authState });
  const loaded = location?.pathname === params?.location;

  return (
    <ResponsiveLayout>
      {!loaded && <LoadingIndicator size="40" />}
      {!error && loaded && children}
      {error && <ErrorsPage title={error.toString()} />}
    </ResponsiveLayout>
  );
}

LayoutComp.propTypes = {
  children: PropTypes.node.isRequired,
  authState: PropTypes.object,
};

export const Layout = withOktaAuth(LayoutComp);
