import { fromJS } from 'immutable';
import localStorageUser from 'utils/localStorageUser';

import {
  SET_FORM,
  CLEAR_FORM,
  SET_FORM_TREE,
  SET_LOADING_FLAG,
  SET_SELECTED_TREE_NODE,
  SET_FORM_LANGUAGE,
} from './constants';

const locale = localStorageUser.getLanguageUserFromLocalStorage();

export const initialState = fromJS({
  form: null,
  language: locale,
  form_tree: {},
  selected_tree_node: {},
  loading: false,
});

function formPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FORM_TREE:
      return state.set('form_tree', fromJS(action.form_tree));
    case SET_FORM:
      return state.set('form', action.form);
    case SET_FORM_LANGUAGE:
      return state.set('language', action.lang);
    case SET_SELECTED_TREE_NODE:
      return state.set('selected_tree_node', fromJS(action.node));
    case CLEAR_FORM:
      return initialState;
    case SET_LOADING_FLAG:
      return state.set('loading', action.loading);
    default:
      return state;
  }
}
export default formPageReducer;
