import React from 'react';

const ArrowDown = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 8.80509L3.92897 4.82355C3.48788 4.39215 2.77191 4.39215 2.33082 4.82355C1.88973 5.25494 1.88973 5.95517 2.33082 6.38657L7.24674 11.1944C7.66332 11.6019 8.33774 11.6019 8.75326 11.1944L13.6692 6.38657C14.1103 5.95517 14.1103 5.25494 13.6692 4.82355C13.2281 4.39215 12.5121 4.39215 12.071 4.82355L8 8.80509Z"
    />
  </svg>
);

export default ArrowDown;
