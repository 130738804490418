import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import Tile from 'components/Tile/Tile';
import globalMessages from 'translations/messages/global-messages';

const getUserEmailStatusTranslation = (intl, user) => {
  if (user?.email_validated) {
    return intl.formatMessage(globalMessages.verified);
  }

  return intl.formatMessage(globalMessages.to_verify);
};

const styles = theme => ({
  userEmail: {
    marginLeft: theme.spacing(1),
  },
});

export function UserEmailStatusTile(props) {
  const { classes, intl, user } = props;

  const titleLabel = getUserEmailStatusTranslation(intl, user);

  if (!titleLabel) {
    return null;
  }

  return (
    <Tile
      className={classes.userEmail}
      label={titleLabel}
      color={user?.email_validated ? 'success' : 'warning'}
      textBold
    />
  );
}

UserEmailStatusTile.propTypes = {
  intl: intlShape.isRequired,
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default compose(
  injectIntl,
  withStyles(styles),
)(UserEmailStatusTile);
