import React from 'react';

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import DetailCellSkeleton from 'components/SkeletonScreen/components/DetailCellSkeleton';
import globalMessages from 'translations/messages/global-messages';
import { projectStatus } from 'utils/constants';

export const styles = theme => ({
  bullet: {
    margin: '0 5px',
  },
  status: {
    color: theme.palette.secondary.main,
  },
});

export const DetailCell = props => {
  const { data, loading, classes, intl } = props;
  const { name, description, legacy_id, status } = data;

  if (loading && isEmpty(data)) {
    return <DetailCellSkeleton />;
  }

  if (!loading && isEmpty(data)) {
    return <div>-</div>;
  }

  return (
    <>
      <Typography noWrap variant="subtitle" component="p">
        {name}
      </Typography>
      <Typography component="div" noWrap variant="subtitleLight">
        #{legacy_id}
        {legacy_id &&
          description &&
          status !== projectStatus.done && (
            <>
              <span className={classes.bullet}>&#8226;</span>
              {description}
            </>
          )}
        {legacy_id &&
          status === projectStatus.done && (
            <>
              <span className={classes.bullet}>&#8226;</span>
              <span className={classes.status}>{intl.formatMessage(globalMessages.done)}</span>
            </>
          )}
      </Typography>
    </>
  );
};

DetailCell.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  classes: PropTypes.object,
  intl: intlShape.isRequired,
};

export default compose(
  injectIntl,
  withStyles(styles),
)(DetailCell);
