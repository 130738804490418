const limitationTheme = theme => ({
  containerColorLimits: {
    background: theme.palette.secondary.light,
  },
  containerColorLimitsReachPlan: {
    background: theme.palette.error.light,
  },
  nameTitleColorReach: {
    color: theme.palette.error.main,
  },
  nameTitleColor: {
    color: theme.palette.secondary.main,
  },
  nameTitle: {
    fontFamily: 'Effra Medium, Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '1rem',
    lineHeight: '120%',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.text.dark,
  },
  nameTitleHeader: {
    fontFamily: 'Effra Medium, Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '1rem',
    lineHeight: '120%',
    alignItems: 'center',
    textAlign: 'center',
  },
  submit: {
    borderRadius: 16,
    padding: '6px 10px',
    fontSize: '0.75rem',
    fontWeight: '500',
    textTransform: 'capitalize',
    boxShadow: 'none',
    height: '2.286em',
    flexShrink: 0,
  },
  submitColor: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: theme.shadows[1],
    },
  },
  submitColorReach: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      boxShadow: theme.shadows[1],
    },
  },
  arrow: {
    marginRight: theme.spacing(),
  },
});

export default limitationTheme;
