import React from 'react';

import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { findFilledIcon } from 'components/icon-picker';
import { selectModules } from 'containers/GlobalWrapper/selectors';
import { Routing } from 'routing/routing';
import { MODULE_TYPES } from 'utils/constants';

import { Pin } from './pin';

const PIN_WIDTH = 60;
const PIN_HEIGHT = 72;

export function PdfPlanPin({ pinData, color, zoomValue, entityType }) {
  const { organizationId, idData } = useParams();
  const modules = useSelector(selectModules());
  const module = modules.find(m => m.id === pinData.module_id);
  const IconComponent = findFilledIcon({ name: module?.icon });

  if (!pinData) return null;

  const handleClick = () => {
    window.open(
      entityType === MODULE_TYPES.observations
        ? Routing.observations.detail(organizationId, idData, pinData.module_id, pinData.id)
        : Routing.forms.detail(organizationId, idData, pinData.module_id, pinData.id),
      '_blank',
    );
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: `calc(${100 * pinData?.position_y}% + ${6 * zoomValue}px)`,
        left: `calc(${100 * pinData?.position_x}% - ${PIN_WIDTH / 2}px)`,
        zIndex: 2,
      }}
    >
      <Box
        onClick={handleClick}
        sx={{
          position: 'absolute',
          bottom: '-6px', // Get the tip of pin at the bottom of parent div
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'end',
          cursor: 'pointer',
        }}
      >
        <Pin color={color} width={PIN_WIDTH} height={PIN_HEIGHT} />

        <Box
          as={IconComponent}
          width={60}
          height={30}
          sx={{
            position: 'absolute',
            top: 15,
            color: 'white',
          }}
        />

        <Typography
          variant="h6"
          sx={{
            width: '100%',
            position: 'absolute',
            bottom: '-14px',
            textAlign: 'center',
            textShadow:
              '2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff',
          }}
        >
          #{pinData.legacy_id}
        </Typography>
      </Box>
    </Box>
  );
}

PdfPlanPin.propTypes = {
  entityType: PropTypes.string.isRequired,
  pinData: PropTypes.object,
  color: PropTypes.string,
  zoomValue: PropTypes.number,
};

export default PdfPlanPin;
