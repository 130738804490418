import React, { useEffect, useState } from 'react';

import { Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import TransparentBorder from 'components/Avatar/TransparentBorder';
import Avatar from 'components/AvatarV3';
import MemberCellSkeleton from 'components/SkeletonScreen/components/MemberCellSkeleton';
import { getUserData } from 'containers/GlobalWrapper/actions';
import { selectUsers } from 'containers/GlobalWrapper/selectors';
import { getUserFullName } from 'utils/users';

const AutoFetchingUser = ({
  avatarAndName,
  className,
  connectedUserLabel,
  dispatchGetUserData,
  fullInfoClassName,
  hideAvatar,
  isConnectedUser,
  loading,
  users,
  userId,
  customAvatar,
  showTooltip,
  tooltipClassName,
  member,
}) => {
  const [data, setData] = useState({});
  const foundUser = users ? users[userId] : null;

  useEffect(
    () => {
      // EVITER 15 calls useless
      let doesComponentHasBeenUnmounted = false;
      if (userId && !foundUser) {
        dispatchGetUserData(userId, newData => {
          if (!doesComponentHasBeenUnmounted) {
            setData(newData);
          }
        });
      } else {
        setData(foundUser);
      }

      return () => {
        doesComponentHasBeenUnmounted = true;
      };
    },
    [userId, dispatchGetUserData, foundUser],
  );
  if ((loading || (foundUser && foundUser.loading)) && !hideAvatar) {
    return <MemberCellSkeleton />;
  }
  let name = getUserFullName(data);
  if (!name) {
    name = data?.email;
  }
  if (hideAvatar && !isConnectedUser) {
    return <Typography noWrap>{name}</Typography>;
  }
  if (hideAvatar && isConnectedUser) {
    return <Typography noWrap>{connectedUserLabel}</Typography>;
  }

  const renderNoUsers = () => (
    <Tooltip
      title={showTooltip ? name || '' : ''}
      classes={{ tooltip: tooltipClassName }}
      placement="bottom"
    >
      <Avatar
        classes={{ root: className }}
        src={data?.media_resource?.url || data?.picture?.url}
        alt={`${data?.first_name} ${data?.last_name}`}
        customAvatar={customAvatar}
        showTooltip={showTooltip}
        user={member || data}
      />
    </Tooltip>
  );

  if (!users) {
    return renderNoUsers();
  }

  const renderAvatarAndName = () => (
    <div className={fullInfoClassName}>
      <Tooltip
        title={showTooltip ? name || '' : ''}
        classes={{ tooltip: tooltipClassName }}
        placement="bottom"
      >
        <TransparentBorder borderClassName={className}>
          <Avatar
            materialUIClasses={{ root: className }}
            src={foundUser?.media_resource?.url || foundUser?.picture?.url}
            alt={name}
            customAvatar={customAvatar}
            showTooltip={showTooltip}
            user={member || data}
          />
        </TransparentBorder>
      </Tooltip>
      <Typography variant="subtitleBold"> {name} </Typography>
    </div>
  );

  if (avatarAndName) {
    return renderAvatarAndName();
  }

  return (
    <Tooltip
      title={showTooltip ? name || '' : ''}
      classes={{ tooltip: tooltipClassName }}
      placement="bottom"
    >
      <TransparentBorder borderClassName={className}>
        <Avatar
          materialUIClasses={{ root: className }}
          src={foundUser?.media_resource?.url || foundUser?.picture?.url}
          alt={`${foundUser?.first_name} ${foundUser?.last_name}`}
          customAvatar={customAvatar}
          user={member || data}
        />
      </TransparentBorder>
    </Tooltip>
  );
};
AutoFetchingUser.propTypes = {
  dispatchGetUserData: PropTypes.func.isRequired,
  users: PropTypes.object,
  className: PropTypes.string,
  tooltipClassName: PropTypes.string,
  fullInfoClassName: PropTypes.string,
  connectedUserLabel: PropTypes.string,
  userId: PropTypes.string,
  loading: PropTypes.bool,
  isConnectedUser: PropTypes.bool,
  hideAvatar: PropTypes.bool,
  avatarAndName: PropTypes.bool,
  customAvatar: PropTypes.func,
  showTooltip: PropTypes.bool,
  member: PropTypes.object,
};
AutoFetchingUser.defaultProps = {
  hideAvatar: false,
  isConnectedUser: false,
  avatarAndName: false,
  fullInfoClassName: '',
  connectedUserLabel: '',
};

const mapStateToProps = createStructuredSelector({
  users: selectUsers(),
});

const mapDispatchToProps = {
  dispatchGetUserData: getUserData,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(AutoFetchingUser);
