export const styles = theme => ({
  listItem: {
    border: `1px solid transparent`,
    borderRadius: 8,
    padding: '0.5em 1em',
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.background.primary,
      color: theme.palette.text.dark,
    },
    '&:focus-visible': {
      background: 'none',
      border: `1px solid ${theme.palette.info.main}`,
    },
    display: 'grid',
    gridTemplateColumns: '0.1fr 1fr 0.1fr',
    gap: '0.5rem',
  },
  projectListItem: {
    alignItems: 'center',
    border: `1px solid transparent`,
    borderRadius: 8,
    padding: '0.3em 1em',
    '&:hover': {
      backgroundColor: theme.palette.background.primary,
      color: theme.palette.text.dark,
    },
    '&:focus-visible': {
      background: 'none',
      border: `1px solid ${theme.palette.info.main}`,
    },
    display: 'flex',
    gap: '0.5rem',
  },
  listSubItem: {
    border: `1px solid transparent`,
    borderRadius: 8,
    padding: '0.3em 1em',
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.background.primary,
      color: theme.palette.text.dark,
    },
    '&:focus-visible': {
      background: 'none',
      border: `1px solid ${theme.palette.info.main}`,
    },
    display: 'flex',
    gap: '0.5rem',
    fontSize: '1.125rem',
    alignItems: 'center',
  },
  collapseListItem: {
    border: `1px solid transparent`,
    borderRadius: 8,
    padding: '0.5em 1em',
    '&:hover': {
      backgroundColor: theme.palette.background.primary,
    },
    '&:focus-visible': {
      background: 'none',
      border: `1px solid ${theme.palette.info.main}`,
    },
    display: 'flex',
    justifyContent: 'space-between',
    gap: '0.5rem',
    alignItems: 'center',
  },
  label: {
    fontFamily: 'Effra Medium, Roboto',
    fontWeight: 500,
    lineHeight: '120%',
  },
  collapseLabel: {
    fontSize: '0.875rem',
    fontFamily: 'Effra Medium, Roboto',
  },
  subItemIcon: {
    color: theme.palette.info.main,
  },
  labelMultiline: {
    whiteSpace: 'normal',
  },
  active: {
    backgroundColor: theme.palette.background.primary,
    '&:focus-visible': {
      backgroundColor: theme.palette.background.primary,
    },
    '&:focus': {
      backgroundColor: theme.palette.background.primary,
    },
  },
  backButton: {
    border: `1px solid transparent`,
    width: 'fit-content',
    height: '32px',
    borderRadius: '200px',
    color: theme.palette.info.main,
    background: `${theme.palette.info.main}1A`,
    '&:hover': {
      background: `${theme.palette.info.main}33`,
    },
    '&:focus-visible': {
      border: `1px solid ${theme.palette.info.main}`,
      background: `${theme.palette.info.main}33`,
    },
  },
  backBtnLabel: {
    marginRight: '0.250em',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    lineHeight: '120%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
});
