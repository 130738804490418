import { useCallback, useContext, useEffect, useState } from 'react';

import Gleap from 'gleap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import {
  getUserData,
  setLoggedUserData,
  setRouteParams,
  getCurrentOffer,
  getWorkspace,
} from 'containers/GlobalWrapper/actions';
import { OrganizationContext } from 'containers/GlobalWrapper/BusinessOrganizationDataValidation';
import { ProjectContext } from 'containers/GlobalWrapper/ProjectDataValidation';
import { selectAllOrganizations } from 'containers/GlobalWrapper/selectors';
import Analytics from 'utils/Analytics';
import getDomainFromEmail from 'utils/getDomainFromEmail';
import initialLoader from 'utils/initialLoader';

export const useLayout = ({ authState }) => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentOrganization = useContext(OrganizationContext);
  const project = useContext(ProjectContext);
  const safeStringify = obj => JSON.stringify(obj) || '';
  const organizations = useSelector(selectAllOrganizations());
  const [error, setError] = useState(null);

  let currentOffer = {};
  let workspace = {};

  const createCustomerIoScript = (userInfo, userId) => {
    const baseData = {
      id: userInfo?.user_id,
      email: userInfo?.email,
      created_at: Math.floor(Date.parse(userInfo?.created_at) / 1000),
      mail_domain: userInfo?.email?.split('@')[1],
      first_name: userInfo?.first_name,
      last_name: userInfo?.last_name,
      job_mission: userInfo?.mission,
      locale: userInfo?.locale,
      mission: userInfo?.mission,
    };

    const organizationData = currentOrganization
      ? {
          organizations_count: organizations?.length,
          current_organization: {
            id: currentOrganization.id,
            name: currentOrganization.name,
            public_id: currentOrganization.public_id,
            offer_name: currentOffer?.name,
          },
        }
      : {};

    const projectData = project
      ? {
          current_project: {
            id: project.project_id,
            name: project.name,
            public_id: project.legacy_id,
          },
        }
      : {};

    const workspaceData = workspace
      ? {
          current_top_parent_workspace: {
            id: workspace.parent_id,
            name: workspace.parent_name,
          },
          current_workspace: {
            id: project?.workspace_id,
            name: workspace.name,
          },
        }
      : {};

    const contextCustomerIo = {
      ...baseData,
      ...organizationData,
      ...projectData,
      ...workspaceData,
    };

    const customerIoScript = document.createElement('script');
    customerIoScript.innerHTML = `_cio.identify(${safeStringify(contextCustomerIo)});`;
    document.body.appendChild(customerIoScript);
    Gleap.identify(userId, {
      name: `${userInfo?.first_name} ${userInfo?.last_name}`,
      email: userInfo?.email,
    });
  };

  const handleCallBackForWorkspace = (data, userInfo) => {
    workspace = data;
    createCustomerIoScript(userInfo, userInfo.userId);
  };

  const handleCallBackForCurrentOffer = (data, userInfo) => {
    currentOffer = data;
    if (currentOrganization?.id && project?.workspace_id) {
      const offerParams = {
        organizationId: currentOrganization?.id,
        workspaceId: project?.workspace_id,
      };
      dispatch(
        getWorkspace({ params: offerParams }, workspaceObj =>
          handleCallBackForWorkspace(workspaceObj, userInfo),
        ),
      );
    } else {
      createCustomerIoScript(userInfo, userInfo?.userId);
    }
  };

  const setAnalytics = useCallback(
    () => {
      try {
        const userId = authState?.accessToken?.claims?.fcUserId;

        Analytics.setUserID(userId);
        Analytics.setUserProperties({ organizations_id: currentOrganization?.id });
        Analytics.setUserProperties({
          offer: currentOrganization?.offerDetails?.name,
        });

        if (userId) {
          dispatch(
            getUserData(userId, userInfo => {
              const { email } = userInfo;

              dispatch(setLoggedUserData(userInfo));

              if (currentOrganization?.id != null && currentOffer?.id != null) {
                dispatch(
                  getCurrentOffer(currentOrganization?.id, offer =>
                    handleCallBackForCurrentOffer(offer, userInfo),
                  ),
                );
              } else {
                createCustomerIoScript(userInfo, userId);
              }

              Analytics.setUserProperties({
                mail_domain: getDomainFromEmail(email),
              });
            }),
          );
        }
      } catch (err) {
        setError(err);
      }
    },
    [
      dispatch,
      authState?.accessToken?.claims?.fcUserId,
      currentOrganization?.id,
      currentOrganization?.offerDetails?.name,
      project?.project_id,
      project?.workspace_id,
      project?.business_organization_id,
    ],
  );

  useEffect(() => {
    try {
      initialLoader.hideLoader();
    } catch (err) {
      setError(err);
    }
  }, []);

  useEffect(
    () => {
      try {
        dispatch(
          setRouteParams({
            ...params,
            location: location?.pathname,
          }),
        );
      } catch (err) {
        setError(err);
      }
    },
    [dispatch, params, location?.pathname],
  );

  useEffect(
    () => {
      setAnalytics();
    },
    [setAnalytics],
  );

  return { error };
};
