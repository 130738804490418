import {
  GET_COMMON_OBSERVATION,
  SET_COMMON_OBSERVATION,
  GET_OBSERVATION,
  GET_OBSERVATION_PHASE,
  GET_OBSERVATION_PRIORITY,
  GET_OBSERVATION_STATUS,
  SET_OBSERVATION,
  SET_OBSERVATION_PHASE,
  SET_OBSERVATION_PRIORITY,
  SET_OBSERVATION_STATUS,
  REMOVE_OBSERVATION,
} from './constants';

export function getCommonObservation(params, id, callback) {
  return {
    type: GET_COMMON_OBSERVATION,
    id,
    callback,
    params,
  };
}

export function setCommonObservation(id, commonObservation) {
  return {
    type: SET_COMMON_OBSERVATION,
    id,
    commonObservation,
  };
}

export function getObservationPriority(params, id, callback) {
  return {
    type: GET_OBSERVATION_PRIORITY,
    id,
    callback,
    params,
  };
}

export function setObservationPriority(id, priority) {
  return {
    type: SET_OBSERVATION_PRIORITY,
    id,
    priority,
  };
}

export function getObservationPhase(params, id, callback) {
  return {
    type: GET_OBSERVATION_PHASE,
    id,
    callback,
    params,
  };
}

export function setObservationPhase(id, phase) {
  return {
    type: SET_OBSERVATION_PHASE,
    id,
    phase,
  };
}

export function getObservationStatus(params, id, callback) {
  return {
    type: GET_OBSERVATION_STATUS,
    id,
    callback,
    params,
  };
}

export function setObservationStatus(id, status) {
  return {
    type: SET_OBSERVATION_STATUS,
    id,
    status,
  };
}

export function getObservation(params, id, callback) {
  return {
    type: GET_OBSERVATION,
    id,
    callback,
    params,
  };
}

export function removeObservation(id) {
  return {
    type: REMOVE_OBSERVATION,
    id,
  };
}

export function setObservation(id, observation) {
  return {
    type: SET_OBSERVATION,
    id,
    observation,
  };
}
