import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectLimitationOffer = state =>
  state.limitationOffer || initialState;

const selectLoadingFlag = () =>
  createSelector(selectLimitationOffer, dataState => dataState.get('loading'));

const selectLimitsOfferObservation = () =>
  createSelector(selectLimitationOffer, dataState =>
    dataState.get('limitsObservation').toJS(),
  );
const selectLimitsOfferForms = () =>
  createSelector(selectLimitationOffer, dataState =>
    dataState.get('limitsForms').toJS(),
  );

export {
  selectLoadingFlag,
  selectLimitsOfferObservation,
  selectLimitsOfferForms,
};
