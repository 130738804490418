import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from '@mui/material';

import ArrowRight from 'components/SvgComponents/icons/ArrowRight';

function SuccessMessage(props) {
  const { onClick, data } = props;

  return (
    <IconButton
      key="Redirect"
      aria-label="Redirect"
      color="inherit"
      onClick={() => onClick(data)}
    >
      <ArrowRight />
    </IconButton>
  );
}

SuccessMessage.propTypes = {
  onClick: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default SuccessMessage;
