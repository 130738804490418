import { call, put, takeLatest } from 'redux-saga/effects';
import config from 'config/config';
import request from 'utils/request';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { setGlobalError } from 'containers/ErrorHandler/actions';
import globalMessages from 'translations/messages/global-messages';
import Analytics from 'utils/Analytics';
import { setLoadingFlag } from './actions';
import { SEND_VALIDATION_EMAIL, VALIDATE_CODE } from './constants';

export function* validateCode({ email, code, callback }) {
  yield put(setLoadingFlag(true));

  const requestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/v1.1/registration/validate-email?email=${encodeURIComponent(
    email,
  )}&code=${code}`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURL, options, {
    errorRedirect: false,
    errorCustom: true,
  });
  if (data?.response?.code === 'INCORRECT_VALIDATION_CODE') {
    yield put(
      setGlobalError({
        data,
        customErrorMessageIntl: globalMessages.invalid_code_try_again,
      }),
    );
    Analytics.track('users_validate_email_failed', {
      source: 'user_settings',
      failure_reason: 'invalid_code',
    });
  }
  if (data?.response?.code === 'VALIDATION_CODE_EXPIRED') {
    yield put(
      setGlobalError({
        data,
        customErrorMessageIntl: globalMessages.expired_code,
      }),
    );
    Analytics.track('users_validate_email_failed', {
      source: 'user_settings',
      failure_reason: 'expired_code',
    });
  }
  if (data && data?.message) {
    Analytics.track('users_validate_email_failed', {
      source: 'user_settings',
      failure_reason: 'server_error',
    });
  }
  if (!data?.message && callback) {
    Analytics.track('users_validate_email_succeeded');
    yield call(callback);
  }
  yield put(setLoadingFlag(false));
}
export function* sendValidationEmail({ email, callback }) {
  const requestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/v1.1/registration/send-validation-email`;
  const body = {
    email,
  };

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  const data = yield call(request, requestURL, options);
  if (data === null && callback) {
    yield call(callback);
  } else if (data && data?.message) {
    yield put(
      setGlobalError({
        data,
        customErrorMessageIntl: globalMessages.something_went_wrong,
      }),
    );
  }
}
export default function* initAccountSettingsPageSaga() {
  yield takeLatest(VALIDATE_CODE, validateCode);
  yield takeLatest(SEND_VALIDATION_EMAIL, sendValidationEmail);
}
