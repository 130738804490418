import React, { useState, useEffect } from 'react';

import { withStyles } from '@mui/styles';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import config from 'config/config';
import { selectLoggedUserData } from 'containers/GlobalWrapper/selectors';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import initialLoader from 'utils/initialLoader';
import localStorageUser from 'utils/localStorageUser';

const styles = () => ({
  containerCheckout: {
    height: '100%',
    overflow: 'auto',
    padding: '40px',
  },
});

function PaymentPage(props) {
  const { classes, history, userInfo } = props;
  const stripePromise = loadStripe(`${config.stripeCheckoutKey}`);
  const historyData = history?.location?.state;
  const [clientSecret, setClientSecret] = useState('');
  const [customerId, setCustomerId] = useState('');
  const email = userInfo?.email || userInfo?.toJS()?.email;
  const oktaToken = localStorageUser.getOktaTokenStorage();

  const getStripeCustomerRequestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/checkout/customer/${email}`;

  const createCheckoutSessionRequestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/checkout/session`;

  useEffect(() => {
    initialLoader.hideLoader();
    fetch(getStripeCustomerRequestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken?.accessToken?.accessToken}`,
      },
    })
      .then(res => res.json())
      .then(data => setCustomerId(data?.customer_id));
  }, []);

  useEffect(
    () => {
      // Create a Checkout Session
      if (historyData?.checkoutType === 0) {
        initialLoader.hideLoader();
        fetch(createCheckoutSessionRequestURL, {
          method: 'POST',
          headers: {
            'Cache-Control': 'No-Store',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${oktaToken?.accessToken?.accessToken}`,
          },
          body: JSON.stringify({
            price_id: historyData?.priceId,
            offer_id: historyData?.offerId,
            organization_id: historyData?.organizationId,
          }),
        })
          .then(res => res.json())
          .then(data => setClientSecret(data.client_secret));
      }
      // Update a Checkout Session
      if (customerId && historyData?.checkoutType === 1) {
        initialLoader.hideLoader();
        fetch(createCheckoutSessionRequestURL, {
          method: 'POST',
          headers: {
            'Cache-Control': 'No-Store',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${oktaToken?.accessToken?.accessToken}`,
          },
          body: JSON.stringify({
            customer_id: customerId,
            price_id: historyData?.priceId,
            offer_id: historyData?.offerId,
            organization_id: historyData?.organizationId,
          }),
        })
          .then(res => res.json())
          .then(data => setClientSecret(data.client_secret));
      }
      // Create a Checkout Session for user with new email
      if (customerId === null && historyData?.checkoutType === 1) {
        initialLoader.hideLoader();
        fetch(createCheckoutSessionRequestURL, {
          method: 'POST',
          headers: {
            'Cache-Control': 'No-Store',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${oktaToken?.accessToken?.accessToken}`,
          },
          body: JSON.stringify({
            price_id: historyData?.priceId,
            offer_id: historyData?.offerId,
            organization_id: historyData?.organizationId,
          }),
        })
          .then(res => res.json())
          .then(data => setClientSecret(data.client_secret));
      }
    },
    [customerId],
  );

  return (
    <div className={classes.containerCheckout}>
      <div id="checkout">
        {clientSecret && (
          <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
    </div>
  );
}

PaymentPage.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  userInfo: selectLoggedUserData(),
});

const withConnect = connect(
  mapStateToProps,
  null,
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect,
)(PaymentPage);
