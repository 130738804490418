import { fromJS } from 'immutable';

import {
  SET_LOADING_FLAG,
  CLEAR_ITEMS,
  SET_ITEMS,
  SET_CHECKED_ITEMS,
  CLEAR_CHECKED_ITEMS,
  RESET_TABLE,
  CLEAR_SEARCH_TEXT,
  SET_SEARCH_TEXT,
} from './constants';

export const initialState = fromJS({
  checkedItems: [],
  searchText: '',
  loading: false,
  list: [],
});

const dragDropTableReducer = (namespace = 'tableDragDrop1') => (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case `${namespace}/${SET_SEARCH_TEXT}`:
      return state.set('loading', true).set('searchText', action.text);
    case `${namespace}/${CLEAR_SEARCH_TEXT}`:
      return state.set('loading', true).set('searchText', '');
    case `${namespace}/${SET_LOADING_FLAG}`:
      return state.set('loading', action.loading);
    case `${namespace}/${CLEAR_ITEMS}`:
      return state.set('list', fromJS([]));
    case `${namespace}/${SET_ITEMS}`:
      return state.set('list', fromJS(action.items));
    case `${namespace}/${SET_CHECKED_ITEMS}`:
      return state.set('checkedItems', fromJS(action.items));
    case `${namespace}/${CLEAR_CHECKED_ITEMS}`:
      return state.set('checkedItems', fromJS([]));
    case `${namespace}/${RESET_TABLE}`:
      return initialState;
    default:
      return state;
  }
};

export default dragDropTableReducer;
