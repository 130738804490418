import React from 'react';

import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Placeholder from './Placeholder';

const styles = () => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
});

const CompanyCellSkeleton = props => {
  const { classes } = props;
  return (
    <div className={classNames(classes.wrapper)}>
      <Placeholder height="14px" width="80%" />
    </div>
  );
};

CompanyCellSkeleton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CompanyCellSkeleton);
