import React from 'react';
import PropTypes from 'prop-types';

export const Hammer = props => (
  <svg
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4844 1.01665C11.771 0.963696 12.0664 1.03837 12.2933 1.22115L12.9145 1.72133C13.1339 1.89802 13.2686 2.15905 13.2854 2.44025C13.3023 2.72145 13.1998 2.99673 13.0031 3.1984L10.3075 5.96223L22.2591 17.2491C22.7608 17.6613 23.2426 18.5709 22.8662 19.7367C22.817 19.8889 22.7321 20.0271 22.6185 20.1397L21.308 21.4387C20.8582 21.9031 19.8734 22.3029 18.782 21.6847C18.7035 21.6403 18.6315 21.5855 18.5677 21.5217L16.1181 19.0721C16.1163 19.0704 16.1146 19.0686 16.1128 19.0668L15.7929 18.7469C15.7504 18.7044 15.7126 18.6591 15.6793 18.6115L6.91476 9.39845L5.2329 11.0923C5.04521 11.2813 4.78985 11.3877 4.52347 11.3877C4.25709 11.3877 4.00169 11.2815 3.81393 11.0926L1.29065 8.55316C1.10309 8.3644 0.998491 8.10867 1.00002 7.84257C1.00154 7.57648 1.10906 7.32196 1.29877 7.13537L6.38417 2.13353C6.52647 1.99356 6.7074 1.89938 6.90368 1.86311L11.4844 1.01665ZM17.5381 17.6637L19.8624 19.988C19.8797 19.9937 19.8925 19.9965 19.901 19.998C19.9082 19.9992 19.914 19.9997 19.9185 19.9999L20.9998 18.9281C21 18.9226 21 18.9173 21 18.9123C20.9996 18.8664 20.992 18.8339 20.9846 18.8125C20.9793 18.7973 20.9737 18.786 20.9694 18.7788C20.9516 18.7641 20.9342 18.7488 20.9174 18.7329L8.5727 7.07479C8.5541 7.05722 8.53618 7.03894 8.51898 7.02L8.1696 6.63525C7.81415 6.2438 7.82486 5.6433 8.19403 5.26478L10.105 3.3054L7.56827 3.77416L3.41781 7.85643L4.52303 8.9687L6.2205 7.25914C6.41085 7.06743 6.67067 6.9609 6.94081 6.96379C7.21095 6.96668 7.46843 7.07875 7.65463 7.27448L17.5381 17.6637Z"
      fill="current"
    />
  </svg>
);

Hammer.propTypes = {
  size: PropTypes.number,
};
