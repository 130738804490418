// TODO: Get 2 args instead of one object: easier to use, first arg obj of params, second arg array of key. and handle errors when wrong type

export const checkParams = ({ params, keys }) => {
  try {
    if (!params) {
      throw new Error('params is undefined !');
    }
    keys.forEach(key => {
      if (!params[key]) {
        throw new Error(`${key} is missing in params`);
      } else if (typeof params[key] !== 'string') {
        throw new Error(`${key} is not a string`);
      } else if (params[key].length === 0) {
        throw new Error(`${key} is empty string`);
      } else if (params[key] === 'undefined') {
        throw new Error(`${key} is undefined`);
      }
    });
  } catch (error) {
    console.error(error);
  }
};
