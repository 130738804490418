import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';

const DateTimeFormat = props =>
  props.date ? (
    <FormattedDate
      weekday={props.weekday}
      value={props.date}
      year={props.year}
      month={props.month}
      day={props.day}
      hour={props.hour}
      minute={props.minute}
    />
  ) : (
    <div style={{ color: 'inherit' }}>{props.placeholder || ' '}</div>
  );

DateTimeFormat.propTypes = {
  date: PropTypes.string,
  weekday: PropTypes.string,
  year: PropTypes.string,
  month: PropTypes.string,
  day: PropTypes.string,
  hour: PropTypes.string,
  minute: PropTypes.string,
  placeholder: PropTypes.string,
};

export default DateTimeFormat;
