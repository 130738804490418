import sortBy from 'lodash/sortBy';
import { useSelector } from 'react-redux';
import { takeEvery } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import uuid from 'uuid';

import { sendMessage } from 'components/AddMessage/actions';
import { clearItems } from 'components/FCTable/actions';
import config from 'config/config';
import { setGlobalError } from 'containers/ErrorHandler/actions';
import { removeForm } from 'containers/GlobalWrapper/globalStore/forms/actions';
import { selectForm } from 'containers/GlobalWrapper/globalStore/forms/selectors';
import { selectRouteParams } from 'containers/GlobalWrapper/selectors';
import { getLimitOffer } from 'containers/LimitationMessageOffer/actions';
import { setGlobalMessage } from 'containers/MessageHandler/actions';
import globalMessages from 'translations/messages/global-messages';
import Analytics, { SOURCE } from 'utils/Analytics';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { uploadMedia } from 'utils/chunkUploader';
import { getFormsApiVersion, getFormsLibraryApiVersion, apiFetchItemsCount } from 'utils/constants';
import { checkParams } from 'utils/errors/check-params';
import { downloadBlob } from 'utils/file';
import { request, requestFile, getNameFromContentDisposition } from 'utils/request';

import { customerIoTrackerWithParams } from '../../../utils/CustomerIo';
import { setPriorities, setStatus, setTemplates, setLoadingFlag, setPhases } from './actions';
import {
  ADD_FORM_INSTANCE,
  ADD_TEMPLATE_TO_PROJECT,
  DELETE_FORM_INSTANCE,
  GET_AVAILABLE_FORMS,
  GET_PHASES,
  GET_PRIORITIES,
  GET_STATUS,
  GET_TEMPLATES,
  REMOVE_TEMPLATE_TO_PROJECT,
  SET_BULK_PHASE,
  NO_PHASE,
  IMPORT_FORMS,
  DOWNLOAD_IMPORT_TEMPLATE,
  GET_INFORMATIONS_ABOUT_AVAILABLE_FORMS,
} from './constants';

export function* fetchStatus({ params }) {
  checkParams({ params, keys: ['idData'] });

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    params.idData
  }/statuses`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
    },
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    yield put(setStatus(sortBy(data.statuses, 'position') || []));
  }
}
export function* fetchTemplates({ params }) {
  checkParams({ params, keys: ['idData'] });
  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/${getFormsLibraryApiVersion()}/businessOrganizations/${params.organizationId}/projects/${
    params.idData
  }/available-forms`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
    },
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    yield put(setTemplates(data.available_forms || []));
  }
}
export function* getPriorities() {
  const params = yield select(selectRouteParams());
  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/${getFormsApiVersion()}/projects/${params.idData}/forms-priorities`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
    },
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    yield put(setPriorities(data.priorities || []));
  }
}

export function* getPhases({ params }) {
  checkParams({ params, keys: ['idData'] });

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    params.idData
  }/phases?with_deleted=false`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
    },
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    yield put(setPhases(data.phases || []));
  }
}

export function* addTemplateProject({ template }) {
  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/${getFormsApiVersion()}/projects/${route.idData}/attach-forms`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      form_ids: template,
    }),
  };

  yield call(request, requestURL, options);
  Analytics.track('template_add_project_template');
}
export function* removeTemplateProject({ template }) {
  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/${getFormsApiVersion()}/projects/${route.idData}/remove-forms`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      form_ids: template,
    }),
  };

  yield call(request, requestURL, options);
}

export function* fetchAvailableForms({ moduleId, callback, hierarchyActivated }) {
  yield put(setLoadingFlag(true));
  const params = yield select(selectRouteParams());

  const version = hierarchyActivated ? 'v1.1' : 'v1.0';
  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/${version}/projects/${params.idData}/forms-modules/${moduleId}/forms`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
    },
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    yield call(callback, data);
  }
  yield put(setLoadingFlag(false));
}

export function* getInformationsAboutAvailableForms({
  formsIds,
  callback,
  searchTerm,
  width_disabled,
}) {
  yield put(setLoadingFlag(true));
  const params = yield select(selectRouteParams());
  const dataUrl = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/${getFormsApiVersion()}/projects/${
    params.idData
  }/reduced-forms?with_disabled=${width_disabled}`;

  const requestURL = searchTerm ? `${dataUrl}?search_term=${searchTerm}` : dataUrl;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ form_ids: formsIds }),
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    yield call(callback, data);
  }
  yield put(setLoadingFlag(false));
}

export function* addFormInstance({ formInstance, category_id, moduleId, featureFlag, callback }) {
  const { form, position, source = 'home' } = formInstance;
  yield put(setLoadingFlag(true));
  const route = yield select(selectRouteParams());
  let dataForm = {
    id: uuid.v4(),
    name: form.nameForm,
    form_id: form.id,
    category_id,
    client_created_at: new Date(),
    client_updated_at: new Date(),
  };
  if (featureFlag?.templates_module_management) {
    dataForm = {
      ...dataForm,
      module_id: moduleId,
    };
  }

  if (position?.x && position?.y && position?.planId) {
    dataForm.position_x = position.x;
    dataForm.position_y = position.y;
    dataForm.plan_id = position.planId;
  }

  if (form?.group?.group_id) {
    dataForm.group_id = form?.group?.group_id;
  }

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/${getFormsApiVersion()}/projects/${route.idData}/form-instances?with_default_answers=true`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataForm),
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    if (form?.group?.group_id) {
      const sendMessageRequestOption = {
        method: 'POST',
        url: `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/v1.3/projects/${
          route.idData
        }/groups/${form?.group?.group_id}/messages`,
        dataKey: 'sendFormMessage',
        nameSpace: 'sendFormMessage',
        from: 'Form',
      };
      yield put(
        sendMessage(data.id, 'Form', sendMessageRequestOption, [], () => {
          callback(data);
        }),
      );
    } else {
      yield call(callback, data);
    }
    Analytics.track('forms_creation_succeeded', {
      source,
    });
    yield customerIoTrackerWithParams(
      'forms_creation_succeeded',
      route.organizationId,
      route.idData,
    );
    yield put(getLimitOffer({ params: route }));
  }
  yield put(setLoadingFlag(false));
}

export function* deleteFormInstance({ formInstanceId }) {
  yield put(setLoadingFlag(true));
  const route = yield select(selectRouteParams());
  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/${getFormsApiVersion()}/projects/${route.idData}/form-instances/${formInstanceId}`;

  const options = {
    method: 'DELETE',
  };
  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    Analytics.track('forms_deleted');
    yield put(clearItems());

    const formData = yield select(selectForm(formInstanceId));
    if (formData) {
      yield put(removeForm(formInstanceId));
    }

    yield put(getLimitOffer({ params: route }));
  }
}

export function* setBulkPhase({ formInstanceIds, phaseId }) {
  const route = yield select(selectRouteParams());

  // Send id = null to backend for "no phase"
  const newPhaseId = phaseId === NO_PHASE ? null : phaseId;

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/${getFormsApiVersion()}/projects/${route.idData}/form-instances/bulk-phase-update`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      new_phase_id: newPhaseId,
      ids: formInstanceIds,
    }),
  };

  yield call(request, requestURL, options);
  Analytics.track('forms_engagement');
  Analytics.track('forms_phase_changed', { source: SOURCE.massEdit });
  yield put(clearItems());
}

export function* importForms({ item, params, callback }) {
  const requestUploadMediaUrl = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.2/projects/${params.idData}/`;
  const dataUpload = yield call(uploadMedia, item, {
    baseUrl: requestUploadMediaUrl,
    parallel: false,
    requestHeaders: {
      'Content-Type': 'application/json',
    },
  });

  if (dataUpload && !dataUpload.message) {
    const payload = {
      media_id: dataUpload.id,
    };

    const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
      params.idData
    }/import/forms/module/${params.itemId}`;

    const optionsUpload = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };
    const data = yield call(request, requestURL, optionsUpload, {
      errorRedirect: false,
      errorCustom: true,
    });
    if (data && (data.message || data.status === 500)) {
      if (data.status === 500) {
        yield put(
          setGlobalError({
            data,
            customErrorMessageIntl: globalMessages.masse_import_wrong_template,
          }),
        );
      } else if (data?.response?.code === 'INCORRECTLY_FILLED_TEMPLATE') {
        yield put(
          setGlobalError({
            data,
            customErrorMessageIntl: globalMessages.masse_import_error_filled,
          }),
        );
      } else if (data?.response?.code === 'WRONG_PROJECT_TEMPLATE') {
        yield put(
          setGlobalError({
            data,
            customErrorMessageIntl: globalMessages.masse_import_wrong_project_template,
          }),
        );
      } else if (data?.response?.code === 'WRONG_MODULE_TEMPLATE') {
        yield put(
          setGlobalError({
            data,
            customErrorMessageIntl: globalMessages.masse_import_wrong_module_template,
          }),
        );
      } else if (data?.response?.code === 'EMPTY_TEMPLATE') {
        yield put(
          setGlobalError({
            data,
            customErrorMessageIntl: globalMessages.imported_template_is_empty,
          }),
        );
      }
    } else {
      yield put(
        setGlobalMessage({
          type: 'success',
          intlMessage: {
            id: 'masse_import_form_started_description',
          },
          active: true,
        }),
      );
    }

    if (callback) {
      yield call(callback);
    }
  }
}

export function* downloadImportTemplate({ params }) {
  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    params.idData
  }/import/template/forms/module/${params.itemId}`;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  yield put(
    setGlobalMessage({
      type: 'success',
      intlMessage: {
        id: 'download_in_progress',
      },
      active: true,
    }),
  );

  const data = yield call(requestFile, requestURL, options);
  if (data && !data.message) {
    const name = getNameFromContentDisposition(data.headers);
    const reader = data.body?.getReader();
    const result = [];
    if (!reader) return;
    reader.read().then(function processText({ done, value }) {
      if (done) {
        downloadBlob(result, name || `template.xlsx`);
        return;
      }
      result.push(value);
      reader.read().then(processText);
    });
  }
}

export function buildItemFetchPayLoadByOffset(projectId, moduleId, search, filters = {}) {
  return ({ pageSize, offset }) => ({
    method: 'POST',
    path: `api/${getFormsApiVersion()}/projects/${projectId}/form-instances/filter`,
    rootKey: 'form_instances',
    body: {
      ...filters,
      assignee_ids: [
        ...(filters?.assignee_ids ? filters?.assignee_ids : []),
        ...(filters?.assigned_to_me ? filters?.assigned_to_me : []),
      ],
      module_ids: [moduleId],
      ...(search ? { search_term: search } : {}),
    },
    params: {
      limit: pageSize,
      offset,
    },
    totalCountKey: 'count',
  });
}

export function buildItemFetchPayLoad(projectId, moduleId, search, filters = {}) {
  return ({ pageIndex, pageSize }) =>
    buildItemFetchPayLoadByOffset(projectId, moduleId, search, filters)({
      pageSize,
      offset: pageIndex * pageSize,
    });
}

export default function* initFormsPageSaga() {
  yield takeLatest(GET_STATUS, fetchStatus);
  yield takeLatest(GET_PRIORITIES, getPriorities);
  yield takeLatest(GET_TEMPLATES, fetchTemplates);
  yield takeLatest(ADD_TEMPLATE_TO_PROJECT, addTemplateProject);
  yield takeLatest(REMOVE_TEMPLATE_TO_PROJECT, removeTemplateProject);
  yield takeLatest(GET_AVAILABLE_FORMS, fetchAvailableForms);
  yield takeEvery(GET_INFORMATIONS_ABOUT_AVAILABLE_FORMS, getInformationsAboutAvailableForms);
  yield takeLatest(ADD_FORM_INSTANCE, addFormInstance);
  yield takeLatest(DELETE_FORM_INSTANCE, deleteFormInstance);
  yield takeLatest(GET_PHASES, getPhases);
  yield takeLatest(SET_BULK_PHASE, setBulkPhase);
  yield takeLatest(IMPORT_FORMS, importForms);
  yield takeLatest(DOWNLOAD_IMPORT_TEMPLATE, downloadImportTemplate);
}
