import {
  ADD_FOLDER,
  UPLOAD_FOLDER,
  SET_PLANS_TO_UPLOAD,
  UPLOAD_PLAN,
  UPLOAD_PLAN_FAILED,
  UPLOAD_PLAN_SUCCESS,
  SET_LOADING_FLAG,
  MOVE_ITEMS_TO,
  MOVE_PLANS_TO,
  MOVE_FOLDERS_TO,
  SET_ITEM_TO_RENAME,
  RENAME_ITEM,
  SET_FOLDERS_BREADCRUMBS,
  SAVE_ITEM,
  FETCH_DATA_ITEM,
  SET_DATA_ITEM,
  SAVE_ORDERED_ITEM,
  DELETE_IMAGE,
  GET_FOLDERS_CONTENT,
  DELETE_ITEMS,
  FETCH_ZONE_DATA,
  SET_ZONE_DATA,
  GET_SMART_ZONING_TO_VALIDATE_COUNT,
  SET_SMART_ZONING_TO_VALIDATE_COUNT,
  CREATE_SMART_ZONING_FOLDER,
  UPDATE_PLAN,
  GET_PLAN,
  GET_ALL_PLANS,
  GET_ALL_FOLDERS,
} from './constants';

export function addFolder(values, namespace, callback) {
  return {
    type: ADD_FOLDER,
    values,
    namespace,
    callback,
  };
}

export function uploadFolder(folder, callback) {
  return {
    type: UPLOAD_FOLDER,
    folder,
    callback,
  };
}

export function addPlans(resources, namespace, callback) {
  return {
    type: SET_PLANS_TO_UPLOAD,
    namespace,
    resources,
    callback,
  };
}

export function uploadPlan(item, callbackSuccess, callbackError) {
  return {
    type: UPLOAD_PLAN,
    item,
    callbackSuccess,
    callbackError,
  };
}

export function updatePlan(item, callbackSuccess, callbackError) {
  return {
    type: UPDATE_PLAN,
    item,
    callbackSuccess,
    callbackError,
  };
}

export function uploadPlanSuccess(plans) {
  return {
    type: UPLOAD_PLAN_SUCCESS,
    plans,
  };
}

export function uploadPlanFailed(plans) {
  return {
    type: UPLOAD_PLAN_FAILED,
    plans,
  };
}

export function setItemToRename(id) {
  return {
    type: SET_ITEM_TO_RENAME,
    id,
  };
}

export function renameItem(item, itemType, callback) {
  return {
    type: RENAME_ITEM,
    item,
    itemType,
    callback,
  };
}

export function setLoadingFlag(loading) {
  return {
    type: SET_LOADING_FLAG,
    loading,
  };
}

export function deleteItems(items, callback) {
  return {
    type: DELETE_ITEMS,
    items,
    callback,
  };
}

export function moveFolders(parentId, itemIds, callback) {
  return {
    type: MOVE_FOLDERS_TO,
    parentId,
    itemIds,
    callback,
  };
}

export function movePlans(parentId, itemIds, callback) {
  return {
    type: MOVE_PLANS_TO,
    parentId,
    itemIds,
    callback,
  };
}

export function moveItems({ parentId, items, callback }) {
  return {
    type: MOVE_ITEMS_TO,
    parentId,
    items,
    callback,
  };
}

export function getFoldersContent(params, id, callback) {
  return {
    type: GET_FOLDERS_CONTENT,
    id,
    callback,
    params,
  };
}

export function setBreadcrumbs(data) {
  return {
    type: SET_FOLDERS_BREADCRUMBS,
    data,
  };
}

export function saveEditItem(item, callback) {
  return {
    type: SAVE_ITEM,
    item,
    callback,
  };
}

export function saveOrderedItem(item, callback) {
  return {
    type: SAVE_ORDERED_ITEM,
    item,
    callback,
  };
}

export function fetchDataItem({ params }) {
  return {
    type: FETCH_DATA_ITEM,
    params,
  };
}

export function fetchZoningData(zoneObj) {
  return {
    type: FETCH_ZONE_DATA,
    zoneObj,
  };
}

export function setZoningData(zonesData) {
  return {
    type: SET_ZONE_DATA,
    zonesData,
  };
}

export function setDataItem(dataItem) {
  return {
    type: SET_DATA_ITEM,
    dataItem,
  };
}

export function deleteImage(idImage) {
  return {
    type: DELETE_IMAGE,
    idImage,
  };
}

export function getSmartZoningToValidateCount() {
  return {
    type: GET_SMART_ZONING_TO_VALIDATE_COUNT,
  };
}

export function setSmartZoningToValidateCount(count) {
  return {
    type: SET_SMART_ZONING_TO_VALIDATE_COUNT,
    count,
  };
}

export function createSmartZoningFolder(item, callback) {
  return {
    type: CREATE_SMART_ZONING_FOLDER,
    item,
    callback,
  };
}

export function getPlan(id, callbackSuccess, callbackError) {
  return {
    type: GET_PLAN,
    id,
    callbackSuccess,
    callbackError,
  };
}

export function getAllPlans(callback) {
  return {
    type: GET_ALL_PLANS,
    callback,
  };
}

export function getAllFolders(callback) {
  return {
    type: GET_ALL_FOLDERS,
    callback,
  };
}
