import React, { useState } from 'react';

import { Drawer, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import DownloadAppButton from 'components/DownloadMobileApp';
import ErrorBoundaries from 'components/ErrorBoundaries';
import RemoveScrollbar from 'components/RemoveScrollbar';
import ArrowDown from 'components/SvgComponents/icons/ArrowDown';
import LimitationMessageOffer from 'containers/LimitationMessageOffer';
import reducer from 'containers/OneProjectPage/HomePage/reducer';
import saga from 'containers/OneProjectPage/HomePage/saga';
import { oneProjectPages } from 'utils/constants';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

export const styles = theme => ({
  drawer: {
    display: 'flex',
    zIndex: theme.zIndex.appBar,
    flexDirection: 'column',
  },
  drawerPaper: {
    height: '100%',
    position: 'relative',
    width: 240,
    [theme.breakpoints.down('sm')]: {
      width: '85px',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  button: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      alignSelf: 'flex-end',
    },
  },
  icon: {
    willChange: 'transform',
    transition: 'transform 250ms',
  },
  iconOpen: {
    transform: 'rotate(180deg)',
  },
  '@media (prefers-reduced-motion: reduce)': {
    icon: {
      transition: 'transform 0ms',
    },
  },
  drawerPaperWrap: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: '2em 1em 1rem 1em',
    borderLeft: `1px solid ${theme.palette.divider}`,
    gap: '1rem',
    [theme.breakpoints.down('xs')]: {
      padding: '1em',
      display: 'none',
    },
  },
  drawerPaperWrapOpen: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
  downloadButton: {
    marginInline: 'auto',
  },
  settingsItem: {
    padding: '0.5rem 1em',
    color: theme.palette.text.primary,
    gap: '0.5rem',
  },
  settingsLabel: {
    fontSize: '0.875rem',
  },
  activeLabel: {
    color: theme.palette.info.main,
  },
  wrapper: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '2.5rem',
  },
});

const LeftNavigation = ({ classes, children, menuOptions, match }) => {
  const [expanded, setExpanded] = useState(false);
  const { url } = match;
  const { menuList, component, name } = menuOptions;
  const onToggleMenuClick = () => {
    setExpanded(prevState => !prevState);
  };

  if (!component) {
    return null;
  }

  const COMPONENT = component;

  const renderButton = () => {
    if (
      url.includes(oneProjectPages.organizations) &&
      url.includes(oneProjectPages.forms) &&
      !match.params?.idData
    ) {
      return null;
    }
    return <DownloadAppButton className={classes.downloadButton} />;
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      classes={{
        root: classes.drawer,
        paper: classes.drawerPaper,
      }}
    >
      <ErrorBoundaries>
        <IconButton aria-expanded={expanded} onClick={onToggleMenuClick} className={classes.button}>
          <ArrowDown
            className={classNames(classes.icon, {
              [classes.iconOpen]: expanded,
            })}
            height={16}
            width={16}
          />
        </IconButton>
        <div
          className={classNames(classes.drawerPaperWrap, {
            [classes.drawerPaperWrapOpen]: expanded,
          })}
        >
          {children}
          <RemoveScrollbar>
            <div className={classes.wrapper}>
              <div>
                <COMPONENT list={menuList || []} name={name} />
              </div>
              <LimitationMessageOffer />
            </div>
          </RemoveScrollbar>

          {renderButton()}
        </div>
      </ErrorBoundaries>
    </Drawer>
  );
};

LeftNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  menuOptions: PropTypes.shape({
    menuList: PropTypes.arrayOf(PropTypes.object),
    component: PropTypes.oneOfType([PropTypes.node, () => null]),
    name: PropTypes.string,
  }),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

const withSaga = injectSaga({ key: 'projectHomePage', saga });
const withReducer = injectReducer({ key: 'projectHomePage', reducer });

export default compose(
  withRouter,
  withStyles(styles),
  withSaga,
  withReducer,
)(LeftNavigation);
