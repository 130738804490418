import React from 'react';

import { NavLink } from 'react-router-dom';

import { Routing } from 'routing/routing';
import sessionStorageUser from 'utils/sessionStorageUser';

export const redirectAfterLogin = (history, optionalUrl) => {
  sessionStorageUser.setFinalcadTempRegistrationInfo(null);
  history.replace(optionalUrl || Routing.general.welcome);
};

export const CustomNavLink = React.forwardRef((props, ref) => (
  <NavLink innerRef={ref} {...props} />
));

export function findBestRedirect(organizationId, projectId) {
  let path = Routing.general.welcome;
  if (organizationId && projectId) {
    path = Routing.home.home(organizationId, projectId);
  } else if (organizationId) {
    path = Routing.organizations.projectList(organizationId);
  }
  return path;
}
