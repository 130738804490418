import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectErrorInfoState = state => state.errorState || initialState;

const selectGlobalError = () =>
  createSelector(selectErrorInfoState, eiState => eiState.get('error'));

const selectGlobalInfo = () =>
  createSelector(selectErrorInfoState, eiState => eiState.get('info'));

export { selectGlobalError, selectGlobalInfo };
