import { Avatar } from '@mui/material';
import { withStyles } from '@mui/styles';

import SecuredImage, { quality } from 'components/SecuredImage';
import Finalcad from 'components/SvgComponents/icons/Finalcad';
import { OrganizationContext } from 'containers/GlobalWrapper/BusinessOrganizationDataValidation';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import splitName from 'utils/splitName';

export const styles = theme => ({
  avatar: {
    borderRadius: '4px',
    backgroundColor: theme.palette.background.tertiary,
    width: 28,
    height: 28,
    fontSize: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.common.white,
    border: `1px solid ${theme.palette.background.secondary}`,
    '& img': {
      borderRadius: '4px',
    },
  },
  activeComponent: {
    backgroundColor: theme.palette.background.tertiary,
    width: 28,
    height: 28,
    fontSize: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    border: `2px solid white`,
    borderRadius: '8px',
    color: theme.palette.common.white,
  },
  avatarComponent: {
    borderRadius: 6,
    backgroundColor: theme.palette.background.dark,
    opacity: '0.5',
    width: 28,
    height: 28,
    fontSize: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    '&:hover': {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '8px',
    },
  },
});

const Error = props => {
  const { className } = props;
  return (
    <div className={className}>
      <Finalcad />
    </div>
  );
};

Error.propTypes = {
  className: PropTypes.string,
};

class RectangleAvatar extends Component {
  static contextType = OrganizationContext;

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.src !== nextProps.src && nextState.error) {
      this.setState({
        error: false,
      });
    }
    return true;
  }

  state = {
    error: false,
  };

  render() {
    const { src, alt, classes, name, component, id } = this.props;
    const { error } = this.state;

    if (!src || error) {
      return (
        <>
          {name ? (
            <Avatar
              alt={alt}
              classes={{
                root: this.context?.id === id ? classes.activeComponent : classes.avatar,
              }}
            >
              {name && splitName(name)}
            </Avatar>
          ) : (
            <Avatar
              classes={{
                root: this.context?.id === id ? classes.activeComponent : classes.avatarComponent,
              }}
              alt={alt}
            >
              {component}
            </Avatar>
          )}
        </>
      );
    }

    return (
      <SecuredImage imgQuality={quality.thumbnail} showFallback>
        <Avatar
          classes={{
            root: this.context?.id === id ? classes.activeComponent : classes.avatar,
          }}
          src={src}
          onError={this.handleError}
          alt={alt}
        />
      </SecuredImage>
    );
  }

  handleError = error => {
    const { onError } = this.props;
    this.setState({
      error: true,
    });
    if (onError) {
      onError(error);
    }
  };
}

RectangleAvatar.propTypes = {
  classes: PropTypes.object,
  component: PropTypes.node,
  src: PropTypes.string,
  alt: PropTypes.string,
  id: PropTypes.string,
  onError: PropTypes.func,
  name: PropTypes.string,
};

export default compose(withStyles(styles))(RectangleAvatar);
