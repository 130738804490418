const scope = 'observationsPage/';

export const GET_ALL_STATUSES = `${scope}GET_ALL_STATUSES`;
export const SET_STATUS_LIST = `${scope}SET_STATUS_LIST`;
export const SET_TO_STATUS_LIST = `${scope}SET_TO_STATUS_LIST`;
export const GET_TO_STATUS_LIST = `${scope}GET_TO_STATUS_LIST`;
export const GET_PRIORITIES = `${scope}GET_PRIORITIES`;
export const SET_PRIORITIES = `${scope}SET_PRIORITIES`;
export const SET_OBSERVATION_STATUS = `${scope}SET_OBSERVATION_STATUS`;
export const SET_BULK_OBSERVATION_STATUS = `${scope}SET_BULK_OBSERVATION_STATUS`;
export const EXPORT_OBSERVATIONS = `${scope}EXPORT_OBSERVATIONS`;
export const EXPORT_OBSERVATIONS_LOGS = `${scope}EXPORT_OBSERVATIONS_LOGS`;
export const DELETE_OBSERVATIONS = `${scope}DELETE_OBSERVATIONS`;
export const SAVE_OBSERVATION = `${scope}SAVE_OBSERVATION`;
export const SET_LOADING_FLAG = `${scope}SET_LOADING_FLAG`;
export const ADD_TRADE = `${scope}ADD_TRADE`;
export const ADD_OBSERVATION = `${scope}ADD_OBSERVATION`;
export const SET_BULK_DUE_DATE = `${scope}SET_BULK_DUE_DATE`;
export const SET_BULK_COMPANY = `${scope}SET_BULK_COMPANY`;
export const SET_BULK_PRIORITY = `${scope}SET_BULK_PRIORITY`;
export const SET_BULK_ASSIGNEE = `${scope}SET_BULK_ASSIGNEE`;
export const SET_BULK_PHASE = `${scope}SET_BULK_PHASE`;
export const SET_BULK_MODULE = `${scope}SET_BULK_MODULE`;
export const SET_PHASES = `${scope}SET_PHASES`;
export const GET_PHASES = `${scope}GET_PHASES`;

export const NO_ASSIGNEE = `${scope}NO_ASSIGNEE`;
export const NO_COMPANY = `${scope}NO_COMPANY`;
export const NO_PHASE = `${scope}NO_PHASE`;

export const BULK_ASSIGNEE_FORM = `${scope}BULK_ASSIGNEE_FORM`;
export const BULK_COMPANY_FORM = `${scope}BULK_COMPANY_FORM`;
export const BULK_PHASE_FORM = `${scope}BULK_STATUS_FORM`;
export const BULK_PRIORITY_FORM = `${scope}BULK_PRIORITY_FORM`;
export const BULK_STATUS_FORM = `${scope}BULK_STATUS_FORM`;
export const BULK_MODULE_FORM = `${scope}BULK_MODULE_FORM`;

export const IMPORT_OBSERVATIONS = `${scope}IMPORT_OBSERVATIONS`;
export const DOWNLOAD_IMPORT_TEMPLATE = `${scope}DOWNLOAD_IMPORT_TEMPLATE`;
export const SET_CONFIG = `${scope}SET_CONFIG`;
