import React from 'react';
import PropTypes from 'prop-types';

import {
  defaultCellDataGetter,
  defaultCellRenderer,
  defaultHeaderRenderer,
} from './utils';

/**
 * Column
 */

function Column(props) {
  const {
    cellRenderer,
    headerCellRenderer,
    cellDataGetter,
    columnData,
    dataKey,
    rowData,
    style,
    className,
    listRequestOption,
    index,
  } = props;

  const parsedRowData = rowData.toJS()

  const a11yProps = {
    'aria-colindex': index,
    role: 'gridcell',
    className,
  };

  if (parsedRowData.internalTableHeader && headerCellRenderer) {
    return (
      <div style={style} {...a11yProps}>
        {headerCellRenderer()}
      </div>
    );
  }

  if (!cellRenderer) {
    return null;
  }

  return (
    <div style={style} {...a11yProps}>
      {cellRenderer({
        rowData,
        cellData: cellDataGetter({rowData, dataKey}),
        listRequestOption,
        dataKey,
        columnData,
      })}
    </div>
  );
}

Column.propTypes = {
  classes: PropTypes.object,
  rowData: PropTypes.object,
  columnData: PropTypes.object,
  style: PropTypes.object,
  listRequestOption: PropTypes.object,
  flexGrow: PropTypes.number,
  flexShrink: PropTypes.number,
  dataKey: PropTypes.string,
  index: PropTypes.string,
  className: PropTypes.string,
  cellDataGetter: PropTypes.func,
  headerRenderer: PropTypes.func,
  cellRenderer: PropTypes.func,
  headerCellRenderer: PropTypes.func,
};

Column.defaultProps = {
  cellDataGetter: defaultCellDataGetter,
  cellRenderer: defaultCellRenderer,
  flexGrow: 0,
  flexShrink: 1,
  headerRenderer: defaultHeaderRenderer,
  style: {},
};

export default Column;
