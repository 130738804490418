import React from 'react';
import { compose } from 'redux';

import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import saga from './saga';
import reducer from './reducer';

function GlobalExport() {
  return <div />;
}

const withReducer = injectReducer({ key: 'globalExport', reducer });
const withSaga = injectSaga({ key: 'globalExport', saga });

export default compose(
  withReducer,
  withSaga,
)(GlobalExport);
