import React from 'react';

import { Box, styled } from '@mui/material';
import PropTypes from 'prop-types';

import TextCellSkeleton from 'components/SkeletonScreen/components/TextCellSkeleton';
import CheckSimple from 'components/SvgComponents/icons/CheckSimple';
import Clipboard from 'components/SvgComponents/icons/Clipboard';
import { TypographyEllipsis } from 'components/Templates/TypographyEllipsis';
import Tooltip from 'components/Tooltip';
import { ORANGE } from 'theme/colors';

import ItemRowContainer from './ItemRowContainer';

const VerticalContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

function ItemForm({ form, onClick, isSelected }) {
  return (
    <ItemRowContainer onClick={() => onClick(form)}>
      <Clipboard size={20} />
      {form?.loading ? (
        <VerticalContainer>
          <TextCellSkeleton />
          <Box sx={{ height: '8px' }} />
          <TextCellSkeleton />
        </VerticalContainer>
      ) : (
        <VerticalContainer>
          <Tooltip
            title={form.name}
            placement="top-start"
            leaveDelay={100}
            enterDelay={750}
            key={`tooltip-name-${form.id}`}
          >
            <TypographyEllipsis variant="body" component="p">
              {form.name}
            </TypographyEllipsis>
          </Tooltip>
          <TypographyEllipsis variant="subtitleLight" component="p">
            {form.version}
          </TypographyEllipsis>
        </VerticalContainer>
      )}
      {isSelected && <CheckSimple color={ORANGE} />}
      {!isSelected && <Box sx={{ width: '24px' }} />}
    </ItemRowContainer>
  );
}

ItemForm.propTypes = {
  form: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

export default ItemForm;
