import { withStyles } from '@mui/styles';
import React from 'react';
import PropTypes from 'prop-types';
import Placeholder from './Placeholder';

const styles = () => ({
  centerItems: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
});

const TextCellSkeleton = props => {
  const { classes } = props;
  return (
    <div className={classes.centerItems}>
      <Placeholder height="10px" width="80%" />
    </div>
  );
};

TextCellSkeleton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TextCellSkeleton);
