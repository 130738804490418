export const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'space-between',
    background: theme.palette.background.primary,
    borderRadius: '0.75rem',
    textTransform: 'none',
    border: `1px solid transparent`,
    padding: `0.25em 1em`,
    fontWeight: 500,
    fontSize: '0.75rem',
    color: theme.palette.text.primary,
    '& svg': {
      marginLeft: '0.5rem',
    },
    '&:hover:not(:disabled)': {
      border: `1px solid ${theme.palette.text.primary}`,
      backgroundColor: theme.palette.background.primary,
    },
    '&:focus:not(:disabled)': {
      border: `1px solid ${theme.palette.text.primary}`,
      backgroundColor: theme.palette.background.primary,
    },
  },
  menuItemText: {
    flex: 1,
  },
  menuItem: {
    borderRadius: '0.25rem',
    padding: '0.5rem 0.7rem 0.5rem 1rem',
    display: 'flex',
    lineHeight: '120%',
    alignItems: 'center',
    '& svg': {
      width: 16,
      height: 16,
    },
    '&:hover': {
      background: theme.palette.background.primary,
    },
  },
  menuHeader: {
    justifyContent: 'start',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  menuIconLeft: {
    marginRight: '0.5rem',
    color: theme.palette.text.primary,
  },
  menuIconAuto: {
    marginLeft: 'auto',
    color: theme.palette.text.primary,
  },
  menuIconRight: {
    marginLeft: '0.5rem',
    color: theme.palette.text.primary,
  },
  menuListRoot: {
    padding: 0,
  },
  selectRoot: {
    padding: `${theme.spacing()}  0 ${theme.spacing()} ${theme.spacing(4)}`,
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'space-between',
    background: theme.palette.background.primary,
    borderRadius: '0.75rem',
    lineHeight: '22px',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '0.75rem',
    color: theme.palette.text.primary,
    '&:hover': {
      background: theme.palette.background.primary,
    },
    '& svg': {
      width: 16,
      height: 16,
      top: 7,
      marginRight: '0.5rem',
      color: theme.palette.text.primary,
    },
  },
  menuPaper: {
    minWidth: 250,
    marginTop: theme.spacing(),
    borderRadius: 12,
  },
  dropdown: {
    width: 160,
    borderRadius: '0.75rem',
    textTransform: 'capitalize',
    position: 'fixed',
    zIndex: theme.zIndex.drawer,
    marginTop: '2rem',
  },
  control: {
    marginLeft: '2.25rem',
  },
  confirmContainer: {
    padding: '0 6.5em',
  },
  title: {
    fontWeight: 'normal',
    fontSize: '1.250rem',
  },
  subtitle: {
    marginTop: theme.spacing(6),
    fontSize: '1rem',
  },
});

export default styles;
