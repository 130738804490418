import React from 'react';

import { styled } from '@mui/material';
import PropTypes from 'prop-types';

import DateTimeFormat from 'components/DateTimeFormat';
import DateCellSkeleton from 'components/SkeletonScreen/components/DateCellSkeleton';
import Calendar from 'components/SvgComponents/icons/Calendar';
import { TypographyEllipsis } from 'components/Templates/TypographyEllipsis';

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    marginRight: theme.spacing(),
  },
}));

const Imperative = styled(Wrapper)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const DateCell = ({ imperative, cellData, loading, showIcon }) => {
  if (loading && !cellData) {
    return <DateCellSkeleton />;
  }

  if (!loading && !cellData) {
    return <div>-</div>;
  }

  const WrapperComponent = imperative ? Imperative : Wrapper;

  return (
    <WrapperComponent>
      {showIcon && cellData && <Calendar height={14} width={14} />}
      <TypographyEllipsis component="div" noWrap variant="subtitleLight">
        <DateTimeFormat day="2-digit" month="short" year="numeric" date={cellData} />
      </TypographyEllipsis>
    </WrapperComponent>
  );
};

DateCell.propTypes = {
  imperative: PropTypes.bool,
  cellData: PropTypes.string,
  loading: PropTypes.bool,
  showIcon: PropTypes.bool,
};

export default DateCell;
