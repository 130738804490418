import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { reduxForm, submit } from 'redux-form/immutable';
import { connect } from 'react-redux';

import SwitchFieldForm from 'components/reduxFormComponentsV3/SwitchFieldForm';

function ActivateLibraryForm(props) {
  const { handleSubmit, form, dispatchOnRemoteFormSubmit } = props;

  const handleSelectChange = () => {
    dispatchOnRemoteFormSubmit(form);
  };

  return (
    <form onSubmit={handleSubmit}>
      <SwitchFieldForm
        form={form}
        name="libraryStatus"
        onFieldChange={handleSelectChange}
      />
    </form>
  );
}

ActivateLibraryForm.propTypes = {
  form: PropTypes.string,
  handleSubmit: PropTypes.func,
  dispatchOnRemoteFormSubmit: PropTypes.func,
};

const mapDispatchToProps = {
  dispatchOnRemoteFormSubmit: submit,
};

const withConnect = connect(
  null,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  reduxForm({
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
)(ActivateLibraryForm);
