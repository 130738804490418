import React, { useEffect } from 'react';

import { styled, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import CompanyCellSkeleton from 'components/SkeletonScreen/components/CompanyCellSkeleton';
import Tool from 'components/SvgComponents/icons/Tool';
import { TypographyEllipsis } from 'components/Templates/TypographyEllipsis';
import { getCompany } from 'containers/GlobalWrapper/globalStore/company/actions';
import { selectCompany } from 'containers/GlobalWrapper/globalStore/company/selectors';
import globalMessages from 'translations/messages/global-messages';
import { isDeleted } from 'utils/delete-utils';

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const Name = styled(TypographyEllipsis)({
  margin: '0 8px',
});

const DisabledStatus = styled(TypographyEllipsis)({
  marginLeft: '8px',
});

const CompanyCell = ({ intl, companyId }) => {
  const company = useSelector(selectCompany(companyId));
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(
    () => {
      if (companyId) {
        dispatch(getCompany(params, companyId));
      }
    },
    [companyId, params, dispatch],
  );

  if (companyId && !company) {
    return <CompanyCellSkeleton />;
  }

  if (companyId === null) {
    return <div>-</div>;
  }

  return (
    <Container>
      <Tool width={16} height={16} />
      <Tooltip
        title={company?.name || ''}
        placement="top-start"
        leaveDelay={10}
        key={`tooltip-name-${company?.name}`}
      >
        <Name variant="subtitleLight" component="p">
          {company?.name}
        </Name>
      </Tooltip>
      {isDeleted(company) && (
        <DisabledStatus variant="subtitleLight" component="span">
          {intl.formatMessage(globalMessages.deleted)}
        </DisabledStatus>
      )}
    </Container>
  );
};

CompanyCell.propTypes = {
  intl: intlShape.isRequired,
  companyId: PropTypes.string,
};

export default injectIntl(CompanyCell);
