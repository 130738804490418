import { GET_COMPANY, SET_COMPANY } from './constants';

export function getCompany(params, id, callback) {
  return {
    type: GET_COMPANY,
    id,
    callback,
    params,
  };
}

export function setCompany(id, company) {
  return {
    type: SET_COMPANY,
    id,
    company,
  };
}
