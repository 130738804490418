import React from 'react';

import { ListItem, ListItemText, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { compose } from 'redux';

export const styles = theme => ({
  listItem: {
    padding: 0,
    marginTop: 0,
    marginBottom: theme.spacing(4),
  },
  listItemInline: {
    paddingLeft: 0,
  },
  label: {
    margin: 0,
  },
});

const DetailsItem = ({ detail, classes }) => (
  <ListItemText
    classes={{ root: classes.detail }}
    primary={
      <Typography component="span" variant="bodyLight">
        {detail}
      </Typography>
    }
  />
);

export function UserDetailListItem(props) {
  const { classes, label, detail, inline } = props;
  return (
    <ListItem
      classes={{
        root: classes.listItem,
      }}
    >
      <ListItemText
        classes={{
          root: classes.label,
        }}
        primary={
          <Typography component="p" variant="body">
            {label}
          </Typography>
        }
        secondary={!inline && <DetailsItem detail={detail} classes={classes} />}
      />
      {inline && <DetailsItem detail={detail} classes={classes} />}
    </ListItem>
  );
}

UserDetailListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  inline: PropTypes.bool,
  label: PropTypes.string,
  detail: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.string.isRequired]),
};
UserDetailListItem.defaultProps = {
  label: '',
  inline: false,
};
export default compose(withStyles(styles))(UserDetailListItem);
