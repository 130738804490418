import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

function Triangle(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>
        {props.title || props.intl.formatMessage(globalMessages.warning)}
      </title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8165 13.0174L9.10065 1.63467C8.86628 1.23722 8.4548 1 7.9998 1C7.5448 1 7.13332 1.23722 6.89971 1.63389L0.183136 13.0174C-0.0550516 13.4211 -0.0611589 13.9259 0.16634 14.3358C0.393076 14.7457 0.821356 15 1.28399 15H14.7164C15.1782 15 15.6073 14.7457 15.834 14.3358C16.0615 13.9259 16.0547 13.4211 15.8165 13.0174ZM8.38151 12.6667H7.61809C7.40739 12.6667 7.23638 12.4924 7.23638 12.2778V11.5C7.23638 11.2853 7.40739 11.1111 7.61809 11.1111H8.38151C8.59222 11.1111 8.76322 11.2853 8.76322 11.5V12.2778C8.76322 12.4924 8.59222 12.6667 8.38151 12.6667ZM7.9998 9.55556C7.57839 9.55556 7.23638 9.20711 7.23638 8.77778V6.44444C7.23638 6.01511 7.57839 5.66667 7.9998 5.66667C8.42121 5.66667 8.76322 6.01511 8.76322 6.44444V8.77778C8.76322 9.20711 8.42121 9.55556 7.9998 9.55556Z"
      />
    </svg>
  );
}
Triangle.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

Triangle.defaultProps = {
  title: '',
};

export default injectIntl(Triangle);
