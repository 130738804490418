import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LoadingIndicator from 'components/LoadingIndicator';
import { getAllOrganizations, getProjectInfo } from 'containers/GlobalWrapper/actions';
import { Routing } from 'routing/routing';
import localStorageUser from 'utils/localStorageUser';

/*
 * This component is used to redirect the user to the correct page.
 * Due to URLs reworks, we added /organizations/:organizationId at start.
 * To keep the old URLs working, without 404 error page we need to redirect the user to the correct page.
 */
function RedirectProjectLegacyRoute({ history, match }) {
  const dispatch = useDispatch();
  const lastOrganization = localStorageUser.getBusinessOrganizationLocalStorage();
  const projectId = match?.params?.idData;

  useEffect(
    () => {
      if (match?.path === '/projects') {
        if (lastOrganization?.id) {
          history.replace(Routing.organizations.projectList(lastOrganization.id));
        } else {
          dispatch(
            getAllOrganizations(data => {
              const organization = data?.organizations[0];
              if (organization?.id) {
                history.replace(Routing.organizations.projectList(organization.id));
              }
            }),
          );
        }
      } else if (match?.path?.startsWith('/projects/:idData') && projectId) {
        const getProjectCallback = data => {
          if (data?.business_organization_id) {
            history.replace(Routing.home.home(data.business_organization_id, projectId));
          } else {
            history.replace(Routing.general.welcome);
          }
        };

        dispatch(getProjectInfo(projectId, getProjectCallback, false));
      } else {
        history.replace(Routing.general.welcome);
      }
    },
    [dispatch, lastOrganization],
  );

  return <LoadingIndicator />;
}

RedirectProjectLegacyRoute.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(RedirectProjectLegacyRoute);
