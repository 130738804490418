import {
  SET_LOADING_FLAG,
  SET_LIMIT_OFFER_OBSERVATION,
  SET_LIMIT_OFFER_FORMS,
  GET_LIMIT_OFFER,
} from './constants';

export function setLoadingFlag(loading) {
  return {
    type: SET_LOADING_FLAG,
    loading,
  };
}
export function getLimitOffer({ params }) {
  return {
    type: GET_LIMIT_OFFER,
    params,
  };
}
export function setLimitOfferObservation(limitsObservation) {
  return {
    type: SET_LIMIT_OFFER_OBSERVATION,
    limitsObservation,
  };
}
export function setLimitOfferForms(limitsForms) {
  return {
    type: SET_LIMIT_OFFER_FORMS,
    limitsForms,
  };
}
