import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectFormPage = state => state.formPage || initialState;

const selectFormData = () =>
  createSelector(selectFormPage, dataState => dataState.get('form'));

const selectFormLanguage = () =>
  createSelector(selectFormPage, dataState => dataState.get('language'));

const selectFormTree = () =>
  createSelector(selectFormPage, eventsDataState =>
    eventsDataState.get('form_tree').toJS(),
  );

const selectSelectedTreeNode = () =>
  createSelector(selectFormPage, eventsDataState =>
    eventsDataState.get('selected_tree_node').toJS(),
  );

const selectLoadingFlag = () =>
  createSelector(selectFormPage, dataState => dataState.get('loading'));

export {
  selectFormTree,
  selectSelectedTreeNode,
  selectLoadingFlag,
  selectFormData,
  selectFormLanguage,
};
