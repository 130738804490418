import {
  SET_LOADING_FLAG,
  GET_USER_WORKSPACES,
  SET_USER_WORKSPACES,
  SET_SELECTED_WORKSPACES,
  SET_IS_SEARCH,
} from './constants';

export function setLoadingFlag(loading) {
  return {
    type: SET_LOADING_FLAG,
    loading,
  };
}

export function getUserWorkspaces(organizationId, parentId, searchTerm) {
  return {
    type: GET_USER_WORKSPACES,
    organizationId,
    parentId,
    searchTerm,
  };
}
export function setUserWorkSpaces(items) {
  return {
    type: SET_USER_WORKSPACES,
    items,
  };
}

export function setSelectedWorkSpaces(workspace) {
  return {
    type: SET_SELECTED_WORKSPACES,
    workspace,
  };
}

export const setIsSearch = ({ isSearch }) => ({
  type: SET_IS_SEARCH,
  isSearch,
});
