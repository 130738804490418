/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import imageMore from 'assets/images/baseline-arrow_drop_down-24px.svg';
import imageMoreWhite from 'assets/images/baseline-arrow_drop_down-24px-white.svg';
import imageLess from 'assets/images/baseline-arrow_drop_up-24px.svg';
import imageLessWhite from 'assets/images/baseline-arrow_drop_up-24px-white.svg';
import { colorTheme } from 'utils/constants';

export const Div_TreeWrapper = styled.div`
  ${props => `width: 100%;

  .rc-tree {
    margin-top: 0;
    padding: ${props.theme.spacing(1)};
    position: relative;
    padding-right: ${props.theme.spacing(3)};
  }

  .tree-node-standard {
    font-weight: normal;
  }

  .tree-node-bold {
    font-weight: bold;
  }

  .tree-node-search-highighted>.rc-tree-node-content-wrapper>.rc-tree-title{
    color: ${props.theme.palette.secondary.main};
  }

  .rc-tree li {
    margin: 0;
    list-style: none;
    white-space: nowrap;
    outline: 0;
  }

  .rc-tree .tree-icon {
    width: 16px;
    height: 16px;
  }

  .rc-tree li .draggable {
    user-select: none;
    /* Required to make elements draggable in old WebKit */
    -khtml-user-drag: element;
    -webkit-user-drag: element;
  }
  .rc-tree li.drag-over:not(.Field) > .draggable {
    background-color: ${props.theme.palette.background.dark};
    color: white;
    border: 1px ${props.theme.palette.background.dark} solid;
    opacity: 0.5;
  }
  .rc-tree li.drag-over-gap-top > .draggable {
    border-top: 2px ${props.theme.palette.background.dark} solid;
  }
  .rc-tree li.drag-over-gap-bottom > .draggable {
    border-bottom: 2px ${props.theme.palette.background.dark} solid;
  }
  .rc-tree-node-content-wrapper,
  .rc-tree-node-content-wrapper-normal {
    padding-top: ${props.theme.spacing() - 2}px;
    padding-bottom: ${props.theme.spacing() - 2}px;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
  .rc-tree-node-selected .rc-tree-node-content-wrapper {
    display: flex;
  }
  .rc-tree li.filter-node .rc-tree-node-content-wrapper {
    color: #a60000 !important;
    font-weight: bold !important;
    display: inline-block;
    padding: 1px 3px 0 0;
    margin: 0;
    cursor: pointer;
    text-decoration: none;
    vertical-align: top;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .rc-tree li ul {
    margin: 0;
    padding: 0 0 0 18px;
    cursor: pointer;
  }
  .rc-tree li span.rc-tree-switcher,
  .rc-tree li span.rc-tree-checkbox,
  .rc-tree li span.rc-tree-iconEle {
    line-height: 16px;
    margin-right: 3px;
    margin-bottom: 3px;
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    border: 0 none;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-image: url("${props.theme.palette.mode === colorTheme.light ? imageLess : imageLessWhite}");
  }
  .rc-tree li span.rc-tree-switcher {
    position: absolute;
  }

  .rc-tree li span.rc-tree-switcher.rc-tree-icon__customize,
  .rc-tree li span.rc-tree-checkbox.rc-tree-icon__customize,
  .rc-tree li span.rc-tree-iconEle.rc-tree-icon__customize {
    background-image: none;
  }
  .rc-tree li span.rc-tree-icon_loading {
    margin-right: 2px;
    vertical-align: top;
    background: url('data:image/gif;base64,R0lGODlhEAAQAKIGAMLY8YSx5HOm4Mjc88/g9Ofw+v///wAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQFCgAGACwAAAAAEAAQAAADMGi6RbUwGjKIXCAA016PgRBElAVlG/RdLOO0X9nK61W39qvqiwz5Ls/rRqrggsdkAgAh+QQFCgAGACwCAAAABwAFAAADD2hqELAmiFBIYY4MAutdCQAh+QQFCgAGACwGAAAABwAFAAADD1hU1kaDOKMYCGAGEeYFCQAh+QQFCgAGACwKAAIABQAHAAADEFhUZjSkKdZqBQG0IELDQAIAIfkEBQoABgAsCgAGAAUABwAAAxBoVlRKgyjmlAIBqCDCzUoCACH5BAUKAAYALAYACgAHAAUAAAMPaGpFtYYMAgJgLogA610JACH5BAUKAAYALAIACgAHAAUAAAMPCAHWFiI4o1ghZZJB5i0JACH5BAUKAAYALAAABgAFAAcAAAMQCAFmIaEp1motpDQySMNFAgA7')
      no-repeat scroll 0 0 transparent;
  }
  .rc-tree li span.rc-tree-switcher.rc-tree-switcher-noop {
    cursor: auto;
  }
  .rc-tree li span.rc-tree-switcher.rc-tree-switcher_open {
    background-image: url("${props.theme.palette.mode === colorTheme.light ? imageLess : imageLessWhite}");
    margin-top: 10px;
    content: "Collapse";
  }
  .rc-tree li span.rc-tree-switcher.rc-tree-switcher_close {
    background-image: url("${props.theme.palette.mode === colorTheme.light ? imageMore : imageMoreWhite}");
    margin-top: 10px;
    content: "Collapse";
  }
  .rc-tree li span.rc-tree-checkbox {
    width: 13px;
    height: 13px;
    margin: 0 3px;
    background-position: 0 0;
  }
  .rc-tree li span.rc-tree-checkbox-checked {
    background-position: -14px 0;
  }
  .rc-tree li span.rc-tree-checkbox-indeterminate {
    background-position: -14px -28px;
  }
  .rc-tree li span.rc-tree-checkbox-disabled {
    background-position: 0 -56px;
  }
  .rc-tree
    li
    span.rc-tree-checkbox.rc-tree-checkbox-checked.rc-tree-checkbox-disabled {
    background-position: -14px -56px;
  }
  .rc-tree
    li
    span.rc-tree-checkbox.rc-tree-checkbox-indeterminate.rc-tree-checkbox-disabled {
    position: relative;
    background: #ccc;
    border-radius: 3px;
  }
  .rc-tree
    li
    span.rc-tree-checkbox.rc-tree-checkbox-indeterminate.rc-tree-checkbox-disabled::after {
    content: ' ';
    transform: scale(1);
    position: absolute;
    left: 3px;
    top: 5px;
    width: 5px;
    height: 0;
    border: 2px solid ${props.theme.palette.common.white};
    border-top: 0;
    border-left: 0;
  }
  .rc-tree:not(.rc-tree-show-line) .rc-tree-switcher-noop {
    background: none;
  }
  .rc-tree.rc-tree-show-line li:not(:last-child) > ul {
    background: url('data:image/gif;base64,R0lGODlhCQACAIAAAMzMzP///yH5BAEAAAEALAAAAAAJAAIAAAIEjI9pUAA7')
      0 0 repeat-y;
  }
  .rc-tree.rc-tree-show-line li:not(:last-child) > .rc-tree-switcher-noop {
    background-position: -56px -18px;
  }
  .rc-tree.rc-tree-show-line li:last-child > .rc-tree-switcher-noop {
    background-position: -56px -36px;
  }
  .rc-tree-child-tree {
    display: none;
  }
  .rc-tree-child-tree-open {
    display: block;
  }
  .rc-tree-treenode-disabled > span:not(.rc-tree-switcher) {
    color: #767676;
    cursor: not-allowed;
  }
  .rc-tree-treenode-selected,
  .rc-tree-node-selected {
    cursor: default;
  }
  .rc-tree-node-selected {
    background: ${props.theme.palette.secondary.main}1A;
  }

  .rc-tree .rc-tree-node-selected .rc-tree-title,
  .rc-tree-node-content-wrapper:hover .rc-tree-title {
    color: ${props.theme.palette.secondary.main};
    fill: ${props.theme.palette.secondary.main};
    text-overflow: ellipsis;
    width: inherit;
    overflow: hidden;
  }
  .rc-tree-node-content-wrapper .rc-tree-title {
    text-overflow: ellipsis;
    width: inherit;
    display: block;
    overflow: hidden;
    color: ${props.theme.palette.text.dark};
  }
  .rc-tree .rc-tree-node-selected .tree-icon,
  .rc-tree-node-content-wrapper:hover .tree-icon {
    color: ${props.theme.palette.secondary.main};
    fill: ${props.theme.palette.secondary.main};
  }
  .rc-tree-icon__open {
    margin-right: 2px;
    background-position: -110px -16px;
    vertical-align: top;
  }
  .rc-tree-icon__close {
    margin-right: 2px;
    background-position: -110px 0;
    vertical-align: top;
  }
  .rc-tree-icon__docu {
    margin-right: 2px;
    background-position: -110px -32px;
    vertical-align: top;
  }
  .rc-tree-icon__customize {
    margin-right: 2px;
    vertical-align: top;
  }
`};
`;
