import { createSelector } from 'reselect';

import { initialState } from '../../reducer';

const globalState = state => state.global || initialState;

const selectFormStatus = id =>
  createSelector(globalState, dataState => dataState.get('formStatuses').get(id));

const selectForm = id => createSelector(globalState, dataState => dataState.get('forms').get(id));

const selectFormPhase = id =>
  createSelector(globalState, dataState => dataState.get('formPhases').get(id));

const selectFormPriority = id =>
  createSelector(globalState, dataState => dataState.get('formPriorities').get(id));

export { selectForm, selectFormStatus, selectFormPhase, selectFormPriority };
