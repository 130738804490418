import {
  SET_STATUS,
  GET_STATUS,
  SET_PRIORITIES,
  GET_PRIORITIES,
  GET_TEMPLATES,
  SET_TEMPLATES,
  ADD_TEMPLATE_TO_PROJECT,
  REMOVE_TEMPLATE_TO_PROJECT,
  CLEAR_TEMPLATES,
  GET_AVAILABLE_FORMS,
  ADD_FORM_INSTANCE,
  DELETE_FORM_INSTANCE,
  SET_LOADING_FLAG,
  GET_PHASES,
  SET_PHASES,
  SET_BULK_PHASE,
  IMPORT_FORMS,
  DOWNLOAD_IMPORT_TEMPLATE,
  GET_INFORMATIONS_ABOUT_AVAILABLE_FORMS,
  SET_LAST_CONFIGS,
} from './constants';

export function setLoadingFlag(loading) {
  return {
    type: SET_LOADING_FLAG,
    loading,
  };
}

export function getStatus({ params }) {
  return {
    type: GET_STATUS,
    params,
  };
}
export function getTemplates({ params }) {
  return {
    type: GET_TEMPLATES,
    params,
  };
}

export function setTemplates(templates) {
  return {
    type: SET_TEMPLATES,
    templates,
  };
}
export function setStatus(status) {
  return {
    type: SET_STATUS,
    status,
  };
}

export function getPriorities() {
  return {
    type: GET_PRIORITIES,
  };
}

export function setPriorities(priorities) {
  return {
    type: SET_PRIORITIES,
    priorities,
  };
}

export function getPhases({ params }) {
  return {
    type: GET_PHASES,
    params,
  };
}

export function setPhases(phases) {
  return {
    type: SET_PHASES,
    phases,
  };
}

export function addTemplateToProject(template) {
  return {
    type: ADD_TEMPLATE_TO_PROJECT,
    template,
  };
}
export function removeTemplateToProject(template) {
  return {
    type: REMOVE_TEMPLATE_TO_PROJECT,
    template,
  };
}
export function clearTemplates() {
  return {
    type: CLEAR_TEMPLATES,
  };
}

export function getAvailableForms({ params, moduleId, callback }) {
  return {
    type: GET_AVAILABLE_FORMS,
    moduleId,
    callback,
  };
}

export function getInformationsAboutAvailableForms({ params, formsIds, callback, searchTerm, width_disabled = false }) {
  return {
    type: GET_INFORMATIONS_ABOUT_AVAILABLE_FORMS,
    formsIds,
    callback,
    searchTerm,
    width_disabled,
  };
}

export function addFormInstance(formInstance, moduleId, featureFlag, category_id, callback) {
  return {
    type: ADD_FORM_INSTANCE,
    formInstance,
    moduleId,
    featureFlag,
    category_id,
    callback,
  };
}

export function deleteFormInstance(formInstanceId, listRequestOption) {
  return {
    type: DELETE_FORM_INSTANCE,
    formInstanceId,
    listRequestOption,
  };
}

export function changeBulkPhase(formInstanceIds, phaseId, listsRequestOption) {
  return {
    type: SET_BULK_PHASE,
    formInstanceIds,
    phaseId,
    listsRequestOption,
  };
}

export function excelImportForms(item, params, listRequestOption, callback) {
  return {
    type: IMPORT_FORMS,
    item,
    params,
    listRequestOption,
    callback,
  };
}

export function downloadImportTemplate(params) {
  return {
    type: DOWNLOAD_IMPORT_TEMPLATE,
    params,
  };
}

export function setLastConfigs(configs) {
  return {
    type: SET_LAST_CONFIGS,
    configs,
  };
}
