import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getPerformance } from 'firebase/performance';

import config from 'config/config';
import localStorageUser from 'utils/localStorageUser';

const firebaseApp = initializeApp(config.firebaseConfig);
const analytics = getAnalytics(firebaseApp);

getPerformance(firebaseApp);

/**
 * Obfuscation of our analytics libraries, should be more
 * convenient if we change libraries or needs to automate some statistics
 */
export class Analytics {
  setUserProperties = params => {
    analytics.setUserProperties(params);
  };

  logEvent = (key, params = {}) => {
    const organization = localStorageUser.getBusinessOrganizationLocalStorage();
    const projects = localStorageUser.getRecentlyViewedProjects(organization);
    analytics.logEvent(key, { ...params, project_id: projects[0] });
  };
}

export default firebaseApp;
