import React, { useState } from 'react';

import { List, MenuItem, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import { useOktaAuth } from '@okta/okta-react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form/immutable';

import Button from 'components/Button';
import CleanableTextField from 'components/CleanableTextField';
import DeleteAccount from 'components/DeleteAccount';
import SelectFieldOne from 'components/reduxFormComponentsV3/SelectFieldOne';
import Control from 'components/SvgComponents/icons/Control';
import Edit from 'components/SvgComponents/icons/Edit';
import UserEmailStatusTile from 'components/Tile/Status/UserEmailStatusTile';
import config from 'config/config';
import { useFeatureFlags } from 'contexts/FeatureFlagsProvider';
import globalMessages from 'translations/messages/global-messages';
import { tempTemplateLanguages, getTempTemplateLanguagesDisplay } from 'utils/constants';
import { normalizePhone } from 'utils/validators';

import UserDetailListItem from './UserDetailListItem';
import VerifyEmailButton from './VerifyEmailButton';

export const styles = theme => ({
  contentPassword: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  checkIcon: {
    color: theme.palette.secondary.main,
  },
  emailTitle: {
    display: 'flex',
    alignItems: 'center',
  },
});

export function UserForm(props) {
  const {
    classes,
    userInfo,
    handleSubmit,
    intl,
    language,
    userAvatarComp,
    onDeleteAccount,
  } = props;
  const featureFlags = useFeatureFlags();
  const { authState } = useOktaAuth();
  const [openDeleteModal, setOpenDeleteModal] = useState();
  const theme = useTheme();

  const isIdp = !isEmpty(config.okta.idps.filter(({ id }) => id === authState.idToken.claims.idp));

  const handleSetOpenDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.wrapper}>
        <Grid container spacing={10}>
          <Grid item xs="auto">
            {userAvatarComp}
          </Grid>
          <Grid item xs>
            <List sx={{ p: 0 }}>
              <UserDetailListItem
                label={intl.formatMessage(globalMessages.first_name)}
                detail={
                  <Field
                    required
                    name="first_name"
                    component={CleanableTextField}
                    placeholder={intl.formatMessage(globalMessages.first_name)}
                  />
                }
              />
              <UserDetailListItem
                label={intl.formatMessage(globalMessages.last_name)}
                detail={
                  <Field
                    required
                    name="last_name"
                    component={CleanableTextField}
                    placeholder={intl.formatMessage(globalMessages.last_name)}
                  />
                }
              />
            </List>
          </Grid>
        </Grid>
        <List sx={{ p: 0 }}>
          <UserDetailListItem
            label={intl.formatMessage(globalMessages.phone_number)}
            detail={
              <Field
                normalize={normalizePhone}
                name="phoneNumber.value"
                component={CleanableTextField}
                clearable
                clearableOnlyOnFocus
                placeholder={intl.formatMessage(globalMessages.phone_number)}
              />
            }
          />
          <UserDetailListItem
            edit
            label={
              <Typography variant="body" className={classes.emailTitle}>
                {intl.formatMessage(globalMessages.email)}
                <UserEmailStatusTile user={userInfo} />
              </Typography>
            }
            detail={
              <div className={classes.contentPassword}>
                <Field
                  name="email"
                  component={CleanableTextField}
                  disabled
                  placeholder={intl.formatMessage(globalMessages.phone_number)}
                />
                <VerifyEmailButton userInfo={userInfo} />
                {!isIdp ? (
                  <Button onClick={() => props.onResetPassword(userInfo?.email)} variant="outlined">
                    <Edit width={16} height={16} color={theme.palette.secondary.main} />
                    {intl.formatMessage(globalMessages.reset_password)}
                  </Button>
                ) : null}
              </div>
            }
          />
          <UserDetailListItem
            label={intl.formatMessage(globalMessages.language)}
            detail={
              <Field
                name="locale"
                placeholder={intl.formatMessage(globalMessages.select_language)}
                component={SelectFieldOne}
                renderValue={() => getTempTemplateLanguagesDisplay(language)}
              >
                {tempTemplateLanguages.map(option => (
                  <MenuItem
                    key={option.code}
                    selected={language === option.code}
                    value={option.code}
                    data-guid={option.code}
                  >
                    <Typography variant="body" className={classes.item}>
                      {getTempTemplateLanguagesDisplay(option.code)}
                      {language === option.code && (
                        <Control className={classes.checkIcon} width={22} height={22} />
                      )}
                    </Typography>
                  </MenuItem>
                ))}
              </Field>
            }
          />
        </List>
      </div>
      <Typography variant="h6" sx={{ mb: 4 }}>
        {intl.formatMessage(globalMessages.account_settings_danger_zone)}
      </Typography>
      {featureFlags?.delete_account && (
        <DeleteAccount
          userEmail={userInfo.email}
          openModal={openDeleteModal}
          setOpenModal={handleSetOpenDeleteModal}
          onDeleteAccount={onDeleteAccount}
        />
      )}
    </form>
  );
}

UserForm.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
  onResetPassword: PropTypes.func,
  userInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  language: PropTypes.string,
  userAvatarComp: PropTypes.node,
  onDeleteAccount: PropTypes.func,
};
const mapStateToFormProps = (state, ownProps) => {
  const formString = JSON.stringify(state.form.get(ownProps.form), null, 2);
  const formValues = formString ? JSON.parse(formString) : '';
  const language = formValues?.values?.locale;
  const mission = formValues?.values?.mission;
  return { language, mission };
};
const withConnect = connect(
  mapStateToFormProps,
  null,
);
export default compose(
  injectIntl,
  withConnect,
  reduxForm({
    destroyOnUnmount: true,
    enableReinitialize: true,
  }),
  withStyles(styles),
)(UserForm);
