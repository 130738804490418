import { createSelector } from 'reselect';

import { initialState } from '../../reducer';

const globalState = state => state.global || initialState;

const selectPlan = id => createSelector(globalState, dataState => dataState.get('plans').get(id));
const selectPlanFolder = id => createSelector(globalState, dataState => dataState.get('planFolders').get(id));

export { selectPlan, selectPlanFolder };
