import { fromJS, Map } from 'immutable';

import { SET_IMAGE } from './constants';

export const initialState = fromJS({
  images: new Map(),
});

function oneProjectsPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_IMAGE:
      return state.set(
        'images',
        state.get('images').set(action.url + action.quality, action.image),
      );
    default:
      return state;
  }
}
export default oneProjectsPageReducer;
