import {
  SET_LOADING_FLAG,
  CLEAR_ITEMS,
  GET_ITEMS,
  UPDATE_ITEMS,
  SET_ITEMS,
  SET_CHECKED_ITEMS,
  CLEAR_CHECKED_ITEMS,
  RESET_TABLE,
  CLEAR_SEARCH_TEXT,
  SET_SEARCH_TEXT,
} from './constants';

export function setLoadingFlag(loading, namespace = 'tableDragDrop1') {
  return {
    type: `${namespace}/${SET_LOADING_FLAG}`,
    loading,
  };
}

export function clearItems(namespace = 'tableDragDrop1') {
  return {
    type: `${namespace}/${CLEAR_ITEMS}`,
  };
}

export function getItems(requestPayload, namespace = 'tableDragDrop1') {
  return {
    type: `${namespace}/${GET_ITEMS}`,
    requestPayload,
  };
}

export function updateItems(requestPayload, namespace = 'tableDragDrop1') {
  return {
    type: `${namespace}/${UPDATE_ITEMS}`,
    requestPayload,
  };
}

export function setItems(items, namespace = 'tableDragDrop1') {
  return {
    type: `${namespace}/${SET_ITEMS}`,
    items,
  };
}

export function setCheckedItems(items, namespace = 'tableDragDrop1') {
  return {
    type: `${namespace}/${SET_CHECKED_ITEMS}`,
    items,
  };
}

export function clearCheckedItems(namespace = 'tableDragDrop1') {
  return {
    type: `${namespace}/${CLEAR_CHECKED_ITEMS}`,
  };
}

export function resetTableState(namespace = 'tableDragDrop1') {
  return {
    type: `${namespace}/${RESET_TABLE}`,
  };
}

export function setSearchText(text, namespace = 'tableDragDrop1') {
  return {
    type: `${namespace}/${SET_SEARCH_TEXT}`,
    text,
  };
}

export function clearSearchText(namespace = 'tableDragDrop1') {
  return {
    type: `${namespace}/${CLEAR_SEARCH_TEXT}`,
  };
}
