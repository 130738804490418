import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

function Megaphone(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <title>
        {props.title || props.intl.formatMessage(globalMessages.megaphone)}
      </title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6769 1.09553C13.8772 1.21613 13.9997 1.43286 13.9997 1.66667V12.6667C13.9997 12.8949 13.8829 13.1073 13.6902 13.2296C13.4975 13.3518 13.2556 13.3671 13.0491 13.2699L7.66634 10.7368V13.1667C7.66634 14.1792 6.84553 15 5.83301 15C4.82049 15 3.99967 14.1792 3.99967 13.1667V10.6667C2.52692 10.6667 1.33301 9.47276 1.33301 8V6.66667C1.33301 5.19391 2.52692 4 3.99967 4H7.50076L13.0211 1.07747C13.2277 0.968077 13.4766 0.974928 13.6769 1.09553ZM5.33301 10.6667V13.1667C5.33301 13.4428 5.55687 13.6667 5.83301 13.6667C6.10915 13.6667 6.33301 13.4428 6.33301 13.1667V10.6667H5.33301ZM12.6663 2.77394L7.97827 5.25586C7.88217 5.30674 7.77508 5.33333 7.66634 5.33333H3.99967C3.26329 5.33333 2.66634 5.93029 2.66634 6.66667V8C2.66634 8.73638 3.26329 9.33333 3.99967 9.33333H7.66634C7.76448 9.33333 7.86141 9.355 7.95021 9.39679L12.6663 11.6161V2.77394Z"
        fill="#848F9D"
      />
    </svg>
  );
}
Megaphone.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

Megaphone.defaultProps = {
  title: '',
};

export default injectIntl(Megaphone);
