import React from 'react';

const ListView = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="16"
    height="16"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M1.17 2.5C.524 2.5 0 2.988 0 3.59c0 .603.524 1.092 1.17 1.092.646 0 1.17-.489 1.17-1.091 0-.603-.524-1.091-1.17-1.091zm3.9.364c-.282-.004-.543.134-.685.36-.142.227-.142.507 0 .734.142.226.403.364.685.36h10.139c.281.004.543-.134.685-.36.141-.227.141-.507 0-.734-.142-.226-.404-.364-.685-.36H5.069zm-3.9 4c-.646 0-1.17.488-1.17 1.09 0 .603.524 1.091 1.17 1.091.646 0 1.17-.488 1.17-1.09 0-.603-.524-1.091-1.17-1.091zm3.9.363c-.282-.003-.543.134-.685.36-.142.227-.142.508 0 .734.142.227.403.365.685.36h10.139c.281.005.543-.133.685-.36.141-.226.141-.507 0-.733-.142-.227-.404-.364-.685-.36H5.069zm-3.9 4c-.646 0-1.17.489-1.17 1.091 0 .603.524 1.091 1.17 1.091.646 0 1.17-.488 1.17-1.09 0-.603-.524-1.092-1.17-1.092zm3.9.364c-.282-.004-.543.134-.685.36-.142.227-.142.507 0 .734.142.226.403.364.685.36h10.139c.281.004.543-.134.685-.36.141-.227.141-.507 0-.733-.142-.227-.404-.365-.685-.361H5.069z"
    />
  </svg>
);

export default ListView;
