import { isEmpty } from 'lodash';
import { takeLatest, put, call, select } from 'redux-saga/effects';

import { selectDataUser } from 'containers/NavigationBar/selectors';
import { parseRequest, request } from 'utils/request';

import { setLoadingFlag, clearItems, setItems, clearCheckedItems } from './actions';
import { GET_ITEMS, UPDATE_ITEMS } from './constants';
import { selectSearchText } from './selectors';

export function fetchItems(namespace = 'tableDragDrop1') {
  return function* getItemsGen({ requestPayload }) {
    const { dataKey } = requestPayload;
    const user = yield select(selectDataUser());
    yield put(setLoadingFlag(true, namespace));
    const searchName = yield select(selectSearchText(namespace));

    const urlParamsPayload = requestPayload.urlParams || {};
    if (searchName) {
      urlParamsPayload.search_name = searchName;
    }
    const payload = {
      urlParams: urlParamsPayload,
      url: requestPayload.url,
      method: requestPayload.method,
    };

    const { requestURL, options } = parseRequest({ ...payload, user });

    const data = requestPayload?.redirectionUrl
      ? yield call(request, requestURL, options, {
          errorRedirect: true,
          redirectionUrl: requestPayload?.redirectionUrl,
        })
      : yield call(request, requestURL, options);

    if (data && data?.message) {
      yield put(clearItems(namespace));
    } else {
      yield put(clearCheckedItems(namespace));
      if (data[dataKey]) {
        yield put(setItems(data[dataKey], namespace));
      }
      if (requestPayload.callBack) {
        yield call(requestPayload.callBack, data);
      }
      yield put(setLoadingFlag(false, namespace));
    }
  };
}

export default function(namespace = 'tableDragDrop1') {
  return function* dragDropTableSelfStandingSaga() {
    yield takeLatest(`${namespace}/${GET_ITEMS}`, fetchItems(namespace));
    yield takeLatest(`${namespace}/${UPDATE_ITEMS}`, fetchItems(namespace));
  };
}
