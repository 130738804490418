import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
export const styles = theme => ({
  label: {
    color: `${theme.palette.info.main}`,
    backgroundColor: `${theme.palette.info.main}1A`,
    borderRadius: 4,
    padding: '0.250rem',
    fontSize: '0.750rem',
  },
});

export function BetaTag(props) {
  const { classes, intl } = props;

  return (
    <Typography
      classes={{
        root: classes.label,
      }}
    >
      {intl.formatMessage(globalMessages.beta)}
    </Typography>
  );
}

BetaTag.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

BetaTag.defaultProps = {};

export default compose(
  injectIntl,
  withStyles(styles),
)(BetaTag);
