// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/Effra-2/Effra.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/fonts/Effra-2/Effra-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/fonts/Effra-2/Effra-Bold.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/roboto);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'Effra';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n  font-weight: 100;\n  font-style: normal;\n  -webkit-font-smoothing: antialiased;\n}\n@font-face {\n  font-family: 'Effra Medium';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('truetype');\n  font-weight: normal;\n  font-style: normal;\n  -webkit-font-smoothing: antialiased;\n}\n@font-face {\n  font-family: 'Effra Bold';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n  font-weight: bold;\n  font-style: normal;\n  -webkit-font-smoothing: antialiased;\n}\n", "",{"version":3,"sources":["webpack://./app/containers/App/global-styles.css"],"names":[],"mappings":"AAEA;EACE,oBAAoB;EACpB,+DAAqE;EACrE,gBAAgB;EAChB,kBAAkB;EAClB,mCAAmC;AACrC;AACA;EACE,2BAA2B;EAC3B,+DAA4E;EAC5E,mBAAmB;EACnB,kBAAkB;EAClB,mCAAmC;AACrC;AACA;EACE,yBAAyB;EACzB,+DAA0E;EAC1E,iBAAiB;EACjB,kBAAkB;EAClB,mCAAmC;AACrC","sourcesContent":["@import url('https://fonts.cdnfonts.com/css/roboto');\n\n@font-face {\n  font-family: 'Effra';\n  src: url('../../assets/fonts/Effra-2/Effra.woff2') format('truetype');\n  font-weight: 100;\n  font-style: normal;\n  -webkit-font-smoothing: antialiased;\n}\n@font-face {\n  font-family: 'Effra Medium';\n  src: url('../../assets/fonts/Effra-2/Effra-Medium.woff2') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n  -webkit-font-smoothing: antialiased;\n}\n@font-face {\n  font-family: 'Effra Bold';\n  src: url('../../assets/fonts/Effra-2/Effra-Bold.woff2') format('truetype');\n  font-weight: bold;\n  font-style: normal;\n  -webkit-font-smoothing: antialiased;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
