import {
  GET_FORM_TREE,
  SET_FORM_TREE,
  SET_LOADING_FLAG,
  SET_SELECTED_TREE_NODE,
  GET_FORM,
  CLEAR_FORM,
  SET_FORM,
  SET_FORM_LANGUAGE,
  SAVE_FORM_TREE_ORDER,
} from './constants';

export function getForm({ params, updateTree }) {
  return {
    type: GET_FORM,
    updateTree,
    params,
  };
}

export function setForm(form) {
  return {
    type: SET_FORM,
    form,
  };
}

export function clearForm() {
  return {
    type: CLEAR_FORM,
  };
}

export function getFormTree(saveDataBeforeFetchData) {
  return {
    type: GET_FORM_TREE,
    saveDataBeforeFetchData,
  };
}

export function setFormTree(form_tree) {
  return {
    type: SET_FORM_TREE,
    form_tree,
  };
}

export function setSelectedTreeNode(node) {
  return {
    type: SET_SELECTED_TREE_NODE,
    node,
  };
}

export function setLoadingFlag(loading) {
  return {
    type: SET_LOADING_FLAG,
    loading,
  };
}

export function setFormLanguage(lang) {
  return {
    type: SET_FORM_LANGUAGE,
    lang,
  };
}

export function saveTreeOrder(tree) {
  return {
    type: SAVE_FORM_TREE_ORDER,
    tree,
  };
}
