import React from 'react';

function DeSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      width="60px"
      height="36px"
      {...props}
    >
      <rect
        y="85.331"
        style={{ fill: '#D80027' }}
        width="512"
        height="341.337"
      />
      <rect y="85.331" width="512" height="113.775" />
      <rect
        y="312.882"
        style={{ fill: '#FFDA44' }}
        width="512"
        height="113.775"
      />
    </svg>
  );
}

export default DeSvg;
