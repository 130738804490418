import React from 'react';

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import ChevronRight from 'components/SvgComponents/icons/ChevronRight';
import Layers from 'components/SvgComponents/icons/Layers';
import Plans from 'components/SvgComponents/icons/Plans';
import globalMessages from 'translations/messages/global-messages';
import { locationType } from 'utils/constants';

export const styles = theme => ({
  cellContainer: {
    display: 'flex',
    fontSize: '0.75rem',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cellNameContainer: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    width: '100%',
  },
  cellTypo: {
    color: theme.palette.text.dark,
    fontFamily: 'Effra medium, Roboto',
  },
});

export const PlanItemCell = props => {
  const { classes, type, name, plan, intl } = props;

  return (
    <div className={classes.cellContainer}>
      <div className={classes.cellNameContainer}>
        {type === locationType.plan || type === locationType.bim ? (
          <Plans width={16} height={16} />
        ) : (
          <Layers width={16} height={16} />
        )}
        <Typography className={classes.cellTypo} noWrap component="div">
          {name}
        </Typography>
        {!plan.enabled && (
          <Typography component="p" variant="subtitleLight">
            {intl.formatMessage(globalMessages.deleted)}
          </Typography>
        )}
      </div>
      {type === locationType.folder ? <ChevronRight width={16} height={16} /> : null}
    </div>
  );
};
PlanItemCell.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.oneOf([...Object.values(locationType)]).isRequired,
  name: PropTypes.string.isRequired,
  plan: PropTypes.object,
  intl: intlShape.isRequired,
};
export default compose(
  injectIntl,
  withStyles(styles),
)(PlanItemCell);
