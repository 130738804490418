import { GET_DOCUMENT, SET_DOCUMENT } from './constants';

export function getDocument(id, callback) {
  return {
    type: GET_DOCUMENT,
    id,
    callback,
  };
}

export function setDocument(id, document) {
  return {
    type: SET_DOCUMENT,
    id,
    document,
  };
}
