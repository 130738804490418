import React from 'react';
import PropTypes from 'prop-types';

export const Road = props => (
  <svg
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.20953 4.0222C8.74955 4.13792 9.09352 4.6695 8.9778 5.20953L5.9778 19.2095C5.86208 19.7496 5.3305 20.0935 4.79047 19.9778C4.25044 19.8621 3.90648 19.3305 4.0222 18.7905L7.0222 4.79047C7.13792 4.25045 7.6695 3.90648 8.20953 4.0222Z"
      fill="current"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4C12.5523 4 13 4.44772 13 5V10C13 10.5523 12.5523 11 12 11C11.4477 11 11 10.5523 11 10V5C11 4.44772 11.4477 4 12 4Z"
      fill="current"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 13C12.5523 13 13 13.4477 13 14V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V14C11 13.4477 11.4477 13 12 13Z"
      fill="current"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7905 4.0222C16.3305 3.90648 16.8621 4.25045 16.9778 4.79047L19.9778 18.7905C20.0935 19.3305 19.7496 19.8621 19.2095 19.9778C18.6695 20.0935 18.1379 19.7496 18.0222 19.2095L15.0222 5.20953C14.9065 4.6695 15.2504 4.13792 15.7905 4.0222Z"
      fill="current"
    />
  </svg>
);

Road.propTypes = {
  size: PropTypes.number,
};
