const getDomainFromEmail = email => {
  let emailDomain = null;
  const pos = email?.search('@');
  if (pos > 0) {
    emailDomain = email.slice(pos + 1);
  }
  return emailDomain;
};

export default getDomainFromEmail;
