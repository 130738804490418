import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Box, Card, ListItem, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized';

import ErrorBoundaryFallback from 'components/ErrorBoundaries/ErrorBoundaryFallback';
import combineStyles from 'theme/combineStyles';
import { styles as tableStyle } from 'theme/table-style';
import { apiFetchPreloadItemsCount } from 'utils/constants';

import { getFlexStyleForColumn, defaultHeaderRowRenderer } from './utils';

export const styles = theme => ({
  tableWrap: {
    marginBlock: theme.spacing(4),
    flex: '1 1 auto',
    '& *::-webkit-scrollbar': {
      width: '0.5rem',
      borderRadius: '2rem',
      backgroundColor: theme.palette.background.primary,
    },
    '& *::-webkit-scrollbar-thumb': {
      background: theme.palette.background.secondary,
      borderRadius: '2rem',
    },
  },
  row: {
    display: 'flex',
    flex: 'auto',
    alignItems: 'center',
    padding: `${theme.spacing()} 0`,
    borderBottom: `1px solid ${theme.palette.background.primary}`,
  },
  tableOverflowX: { overflowX: 'auto' },
  buttonWrap: { height: 80, paddingTop: theme.spacing(6), margin: '0 auto' },
  containerHeader: { display: 'flex' },
  noMargin: { marginBlock: 0 },
});

/**
 * ListView
 */
function TableView({
  hasNextPage,
  isNextPageLoading,
  list,
  onLoadMoreRows,
  classes,
  button,
  tableClassName,
  getRowClassName,
  checkedItems,
  checkedPropertyNameMatch,
  isEmptyTable,
  children,
  checkable,
  headerHeight,
  disableHeader,
  headerRowRenderer,
  headerClassName,
  headerStyle,
  rowHeight,
  onRowClick,
  onRowRightClick,
  noDataRenderer,
  showCursor,
  onHandleItems,
  showError,
  threshold,
  isFiltering,
  filteringNoResultMessage,
  noMargin,
}) {
  const handledList = onHandleItems ? onHandleItems(list) : list;
  const [responsiveWidth, setResponsiveWidth] = useState();
  const headerRef = useRef(null);

  function getWidthOfHeaderElementForList() {
    let widthOfHeaderElement = null;
    if (headerRef.current != null) {
      widthOfHeaderElement = headerRef.current.offsetWidth;
      if (widthOfHeaderElement) {
        setResponsiveWidth(widthOfHeaderElement);
      }
    }
  }

  useLayoutEffect(() => {
    getWidthOfHeaderElementForList();

    function handleResize() {
      getWidthOfHeaderElementForList();
    }
    window.addEventListener('resize', handleResize);
  }, []);

  const loadMoreRows = isNextPageLoading ? () => {} : onLoadMoreRows;
  const isRowLoaded = ({ index }) => !hasNextPage || index < handledList.size;

  const getRowClassNames = index => {
    let item = {};
    if (handledList.size) {
      const userMap = handledList.get(index);
      if (userMap) {
        const itemStr = JSON.stringify(userMap, null, 2);
        item = JSON.parse(itemStr);
      }
    }

    const disabledRow = item.deactivated || item.archived;

    const checkedRow = checkedItems.find(
      checkedItem => checkedItem[checkedPropertyNameMatch] === item[checkedPropertyNameMatch],
    );

    return classNames({
      [classes.rowTable]: true,
      [classes.showCursor]: showCursor,
      [classes.checkedRow]: !!checkedRow,
      [classes.disabledRow]: disabledRow,
      [getRowClassName && getRowClassName(index, handledList)]: getRowClassName,
    });
  };

  const getHeaderClassNames = () =>
    classNames({
      [classes.headerRowForInfineTable]: true,
      [classes.leftPaddingRowCell]: !checkable,
      [classes.hideHeaderColumn]: isEmptyTable && !isFiltering,
    });

  const handleRowClick = index => event => {
    const rowData = handledList.get(index);
    const rowDataStr = JSON.stringify(rowData, null, 2);

    onRowClick(JSON.parse(rowDataStr), { event, index, rowData });
  };

  const handleContextMenu = index => event => {
    const rowData = handledList.get(index);
    const rowDataStr = JSON.stringify(rowData, null, 2);
    onRowRightClick(JSON.parse(rowDataStr), { event, index, rowData });
  };

  // Render a list item or a loading indicator.
  const rowRenderer = params => {
    const { index, key, style } = params;
    const a11yProps = {
      'aria-rowindex': index + 1,
      tabIndex: 0,
      'aria-label': 'row',
      role: 'row',
      className: classNames(classes.row, getRowClassNames(index)),
      onClick: handleRowClick(index),
      onContextMenu: handleContextMenu(index),
    };

    // TODO not working!!!!
    let content = null;

    content = React.Children.map(children, (child, count) => ({
      ...child,
      props: {
        ...child.props,
        index: count,
        style: getFlexStyleForColumn(child),
        rowData: handledList.get(index),
        columnData: {
          loading: isNextPageLoading,
        },
        className: classNames({
          [classes.typoCell]: true,
          [classes.leftPaddingRowCell]: !checkable,
          [child.props.className]: child.props.className,
        }),
      },
    }));

    return (
      <ListItem component="div" key={key} style={style} {...a11yProps}>
        {content}
      </ListItem>
    );
  };

  const createHeader = params => {
    const { column, index } = params;
    const { columnData, dataKey, headerRenderer, id, label } = column.props;

    const style = getFlexStyleForColumn(column, {
      ...headerStyle,
      ...column.props.headerStyle,
    });

    const renderedHeader = headerRenderer(
      {
        columnData,
        dataKey,
        label,
      },
      handledList,
    );

    return (
      <div
        className={classNames(headerClassName, column.props.headerClassName)}
        id={id}
        key={`Header-Col${index}`}
        role="columnheader"
        style={style}
        tabIndex="0"
      >
        <Typography component="p" variant="subtitleLight">
          {renderedHeader}
        </Typography>
      </div>
    );
  };

  const getHeaderColumns = () => {
    const items = disableHeader ? [] : React.Children.toArray(children);

    return items.map((column, index) => createHeader({ column, index }));
  };

  const renderHeader = () =>
    !disableHeader &&
    headerRowRenderer({
      className: getHeaderClassNames(),
      columns: getHeaderColumns(),
      style: {
        height: headerHeight,
      },
      ref: headerRef,
    });

  const renderTable = () => (
    <>
      <div className={classes.containerHeader}>{renderHeader()}</div>
      {renderList()}
    </>
  );

  const errorRenderer = () => <ErrorBoundaryFallback showButton={false} />;

  const renderList = () => {
    if (showError) {
      return errorRenderer();
    }

    if (handledList?.size === 0 && !isFiltering) {
      return noDataRenderer();
    }
    if (handledList?.size === 0 && isFiltering) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <div>{filteringNoResultMessage}</div>
        </Box>
      );
    }

    return (
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        threshold={threshold || apiFetchPreloadItemsCount}
        rowCount={1000000}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer style={{ overflow: 'initial' }}>
            {({ width, height }) => (
              <List
                style={{ overflow: 'auto' }}
                ref={registerChild}
                width={responsiveWidth ?? width}
                onRowsRendered={onRowsRendered}
                rowRenderer={rowRenderer}
                rowHeight={rowHeight}
                height={height - headerHeight}
                rowCount={handledList?.size}
              />
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    );
  };

  return (
    <>
      <Card
        className={classNames({
          [classes.tableWrap]: true,
          [classes.tableOverflowX]: true,
          [tableClassName]: tableClassName,
          [classes.noMargin]: !!noMargin,
        })}
        elevation={0}
      >
        {renderTable()}
      </Card>
      {button && (
        <div className={classes.buttonWrap}>
          <div>{button}</div>
        </div>
      )}
    </>
  );
}

TableView.propTypes = {
  classes: PropTypes.object,
  isNextPageLoading: PropTypes.bool,
  button: PropTypes.node,
  headerClassName: PropTypes.string,
  checkedPropertyNameMatch: PropTypes.string,
  list: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  tableClassName: PropTypes.string,
  headerHeight: PropTypes.number,
  rowHeight: PropTypes.number,
  disableHeader: PropTypes.bool,
  getRowClassName: PropTypes.func,
  isEmptyTable: PropTypes.bool,
  checkedItems: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.node,
  hasNextPage: PropTypes.bool,
  headerStyle: PropTypes.object,
  checkable: PropTypes.bool,
  showCursor: PropTypes.bool,
  onLoadMoreRows: PropTypes.func,
  headerRowRenderer: PropTypes.func,
  onRowRightClick: PropTypes.func,
  onRowClick: PropTypes.func,
  noDataRenderer: PropTypes.func,
  onHandleItems: PropTypes.func,
  showError: PropTypes.bool,
  threshold: PropTypes.number,
  isFiltering: PropTypes.bool,
  filteringNoResultMessage: PropTypes.string,
  noMargin: PropTypes.bool,
};

TableView.defaultProps = {
  classes: {},
  disableHeader: false,
  isFiltering: true,
  headerRowRenderer: defaultHeaderRowRenderer,
  filteringNoResultMessage: '',
  noMargin: false,
};

export default injectIntl(withStyles(combineStyles(styles, tableStyle))(TableView));
