import React from 'react';

import PropTypes from 'prop-types';

export const Zap = props => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 24 24"
    fill={props.tint}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3982 1.0827C13.8054 1.25947 14.0473 1.68354 13.9923 2.12403L13.1328 9H21C21.388 9 21.741 9.22446 21.9056 9.57585C22.0702 9.92723 22.0166 10.3421 21.7682 10.6402L11.7682 22.6402C11.484 22.9812 11.009 23.0941 10.6018 22.9173C10.1946 22.7405 9.95266 22.3165 10.0077 21.876L10.8672 15H3C2.61198 15 2.25899 14.7755 2.09441 14.4242C1.92983 14.0728 1.98337 13.6579 2.23178 13.3598L12.2318 1.35982C12.516 1.01878 12.991 0.90593 13.3982 1.0827ZM5.13504 13H12C12.2868 13 12.5598 13.1232 12.7497 13.3382C12.9395 13.5532 13.0279 13.8394 12.9923 14.124L12.4153 18.7395L18.865 11H12C11.7132 11 11.4401 10.8768 11.2503 10.6618C11.0605 10.4468 10.9721 10.1606 11.0077 9.87597L11.5847 5.26046L5.13504 13Z"
      fill={props.tint}
    />
  </svg>
);

Zap.propTypes = {
  tint: PropTypes.string,
  size: PropTypes.number,
};

Zap.defaultProps = {
  tint: 'current',
  size: 24,
};
