import { fromJS } from 'immutable';

import {
  SET_STATUS_LIST,
  SET_TO_STATUS_LIST,
  SET_PRIORITIES,
  SET_LOADING_FLAG,
  SET_PHASES,
  SET_CONFIG,
} from './constants';

export const initialState = fromJS({
  loading: false,
  statusList: [],
  toStatusList: {},
  priorities: [],
  phases: [],
  lastTableConfig: {},
});

function observationsPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_FLAG:
      return state.set('loading', fromJS(action.loading));
    case SET_STATUS_LIST:
      return state.set('statusList', fromJS(action.statusList));
    case SET_PRIORITIES:
      return state.set('priorities', fromJS(action.priorities));
    case SET_PHASES:
      return state.set('phases', fromJS(action.phases));
    case SET_TO_STATUS_LIST:
      return state.set(
        'toStatusList',
        fromJS({
          ...state.get('toStatusList').toJS(),
          ...action.newToStatus,
        }),
      );
    case SET_CONFIG:
      return state.set('lastTableConfig', fromJS(action.config));
    default:
      return state;
  }
}
export default observationsPageReducer;
