import React, { useRef } from 'react';

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import MediaCell from 'components/FCTable/components/CoreCells/MediaCell';
import Tooltip from 'components/Tooltip';

export const styles = () => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    gap: '0.5rem',
  },
  containerName: {
    overflow: 'hidden',
    marginInlineEnd: '8px',
  },
  fullWidthToolTip: {
    maxWidth: 600,
  },
});

export function ObservationItem(props) {
  const { classes, observation } = props;
  const textRef = useRef(null);

  if (!observation) {
    return null;
  }
  const mediaResource = observation.observation_media_resources || [];

  const tradeName = observation?.trade?.name ? ` • ${observation?.trade?.name}` : '';

  return (
    <div className={classes.container}>
      <MediaCell media_resource={mediaResource[0]?.media_resource} />
      <div className={classes.containerName}>
        <Tooltip
          className={classes.fullWidthToolTip}
          title={observation?.name}
          placement="top-start"
          leaveDelay={10}
          forwardedRef={textRef}
          key={`tooltip-name-${observation?.name}`}
        >
          <Typography noWrap component="p" variant="subtitleBold" ref={textRef}>
            {observation?.name}
          </Typography>
        </Tooltip>
        <Typography noWrap component="p" variant="hint">
          {`#${observation?.legacy_id}${tradeName}`}
        </Typography>
      </div>
    </div>
  );
}

ObservationItem.propTypes = {
  classes: PropTypes.object.isRequired,
  observation: PropTypes.object,
};

ObservationItem.defaultProps = {};

export default withStyles(styles)(ObservationItem);
