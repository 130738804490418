import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

export const styles = theme => ({
  groupTitle: {
    marginBottom: theme.spacing(2),
  },
});

/**
 * GroupTitle
 */
function GroupTitle(props) {
  const { classes, label, children } = props;

  return (
    <>
      <Typography noWrap variant="h5" className={classes.groupTitle}>
        {label}
      </Typography>
      {children}
    </>
  );
}

GroupTitle.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  children: PropTypes.node,
};

GroupTitle.defaultProps = {
  classes: {},
};

export default withStyles(styles)(GroupTitle);
