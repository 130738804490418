import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

function UserAvatar(props) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>
        {props.title || props.intl.formatMessage(globalMessages.user)}
      </title>
      <rect x="0.75" width="24" height="24" rx="12" fill="#CED2D8" />
      <rect
        x="1.25"
        y="0.5"
        width="23"
        height="23"
        rx="11.5"
        stroke="#637381"
        strokeOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.72631 14.3096C8.35143 13.6844 9.19928 13.3333 10.0833 13.3333H15.4167C16.3007 13.3333 17.1486 13.6844 17.7737 14.3096C18.3988 14.9347 18.75 15.7825 18.75 16.6666V17.9999C18.75 18.3681 18.4515 18.6666 18.0833 18.6666C17.7151 18.6666 17.4167 18.3681 17.4167 17.9999V16.6666C17.4167 16.1362 17.206 15.6274 16.8309 15.2524C16.4558 14.8773 15.9471 14.6666 15.4167 14.6666H10.0833C9.5529 14.6666 9.04419 14.8773 8.66912 15.2524C8.29405 15.6274 8.08333 16.1362 8.08333 16.6666V17.9999C8.08333 18.3681 7.78486 18.6666 7.41667 18.6666C7.04848 18.6666 6.75 18.3681 6.75 17.9999V16.6666C6.75 15.7825 7.10119 14.9347 7.72631 14.3096Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 6.66659C11.6455 6.66659 10.75 7.56202 10.75 8.66658C10.75 9.77115 11.6455 10.6666 12.75 10.6666C13.8546 10.6666 14.75 9.77115 14.75 8.66658C14.75 7.56202 13.8546 6.66659 12.75 6.66659ZM9.41669 8.66658C9.41669 6.82564 10.9091 5.33325 12.75 5.33325C14.591 5.33325 16.0834 6.82564 16.0834 8.66658C16.0834 10.5075 14.591 11.9999 12.75 11.9999C10.9091 11.9999 9.41669 10.5075 9.41669 8.66658Z"
        fill="white"
      />
    </svg>
  );
}
UserAvatar.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

UserAvatar.defaultProps = {
  title: '',
};

export default injectIntl(UserAvatar);
