const scope = 'FCTable:';

export const GET_ITEMS = `${scope}GET_ITEMS`;
export const SET_ITEMS = `${scope}SET_ITEMS`;
export const UPDATE_ITEM = `${scope}UPDATE_ITEM`;
export const REMOVE_ITEM = `${scope}REMOVE_ITEM`;
export const CLEAR_ITEMS = `${scope}CLEAR_ITEMS`;
export const RESET_ITEMS = `${scope}RESET_ITEMS`;
export const SET_LOADING = `${scope}SET_LOADING`;
export const FILTER_ITEMS = `${scope}FILTER_ITEMS`;
