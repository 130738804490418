import { fromJS } from 'immutable';

import {
  SET_ITEM_TO_RENAME,
  SET_FOLDERS_BREADCRUMBS,
  RESET_BREADCRUMB,
  SET_DATA_ITEM,
  SET_ZONE_DATA,
  SET_LOADING_FLAG,
  SET_SMART_ZONING_TO_VALIDATE_COUNT,
} from './constants';

export const initialState = fromJS({
  itemToRename: '',
  loading: false,
  plansToUpload: [],
  folderToUpload: [],
  uploadedPlans: [],
  failedUploadPlans: [],
  breadcrumbs: [{ name: 'Plans', to: '/' }],
  dataItem: {},
  zonesData: null,
  smartZoningToValidateCount: 0,
});

function plansPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FOLDERS_BREADCRUMBS:
      return state.set('breadcrumbs', fromJS(action.breadcrumbs));
    case SET_ITEM_TO_RENAME:
      return state.set('itemToRename', action.id);
    case SET_DATA_ITEM:
      return state.set('dataItem', fromJS(action.dataItem));
    case SET_ZONE_DATA:
      return state.set('zonesData', fromJS(action.zonesData));
    case RESET_BREADCRUMB:
      return state.set('breadcrumbs', state.breadcrumbs);
    case SET_LOADING_FLAG:
      return state.set('loading', action.loading);
    case SET_SMART_ZONING_TO_VALIDATE_COUNT:
      return state.set('smartZoningToValidateCount', action.count);
    default:
      return state;
  }
}
export default plansPageReducer;
