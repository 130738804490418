import React from 'react';

import { Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';

import globalMessages from 'translations/messages/global-messages';

const HelpPlacePinStyled = styled(Typography)(({ theme }) => ({
  top: '70px',
  width: '300px',
  textAlign: 'center',
  position: 'absolute',
  padding: '1rem 1.5rem',
  backgroundColor: theme.palette.background.dark,
  color: theme.palette.text.tertiary,
  borderRadius: 12,
  fontSize: '0.75rem',
  margin: '1rem auto',
  left: '50%',
  transform: 'translateX(-50%)',
}));

const HelpPlacePin = ({ intl, message }) => (
  <HelpPlacePinStyled component="p" variant="body">
    {message || intl.formatMessage(globalMessages.click_to_place_pin_on_location)}
  </HelpPlacePinStyled>
);

HelpPlacePin.propTypes = {
  intl: PropTypes.object,
  message: PropTypes.string,
};

export default compose(injectIntl)(HelpPlacePin);
