const scope = 'InfiniteTableSelfStandingV3:';
export const SET_LOADING_FLAG = `${scope}SET_LOADING_FLAG`;
export const SET_SEARCH_TEXT = `${scope}SET_SEARCH_TEXT`;
export const CLEAR_SEARCH_TEXT = `${scope}CLEAR_SEARCH_TEXT`;
export const SET_ACTIVE_FILTERS = `${scope}SET_ACTIVE_FILTERS`;
export const CLEAR_ACTIVE_FILTERS = `${scope}CLEAR_ACTIVE_FILTERS`;
export const CLEAR_ITEMS = `${scope}CLEAR_ITEMS`;
export const GET_ITEMS = `${scope}GET_ITEMS`;
export const SET_ITEMS = `${scope}SET_ITEMS`;
export const SET_ITEM = `${scope}SET_ITEM`;
export const UPDATE_ITEMS = `${scope}UPDATE_ITEMS`;
export const SET_SORT_DATA = `${scope}SET_SORT_DATA`;
export const CLEAR_SORT_DATA = `${scope}CLEAR_SORT_DATA`;
export const SET_CHECKED_ITEMS = `${scope}SET_CHECKED_ITEMS`;
export const CLEAR_CHECKED_ITEMS = `${scope}CLEAR_CHECKED_ITEMS`;
export const RESET_TABLE = `${scope}RESET_TABLE`;
export const SET_TABLE_MOUNT_UNIQUE_KEY = `${scope}SET_TABLE_MOUNT_UNIQUE_KEY`;
export const UNCHECK_ITEM = `${scope}UNCHECK_ITEM`;
