import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import globalMessages from 'translations/messages/global-messages';

const SuperAdminFill = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{props.title || props.intl.formatMessage(globalMessages.super_admin)}</title>

    <g id="zap">
      <path
        id="Vector"
        d="M13 2L3 14H12L11 22L21 10H12L13 2Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

SuperAdminFill.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};
export default compose(injectIntl)(SuperAdminFill);
