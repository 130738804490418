import React, { useState, useRef, useEffect } from 'react';

import { Typography, Input } from '@mui/material';
import { withStyles } from '@mui/styles';
import bytes from 'bytes';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import DetailImageCellSkeleton from 'components/SkeletonScreen/components/DetailImageCellSkeleton';
import File from 'components/SvgComponents/icons/File';
import Folder from 'components/SvgComponents/icons/Folder';
import Tooltip from 'components/Tooltip';
import { documentType } from 'utils/constants';

export const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    gap: '1rem',
  },
  fullWidthToolTip: {
    maxWidth: 600,
  },
  input: {
    backgroundColor: `${theme.palette.info.main}08`,
    color: theme.palette.text.dark,
    padding: 1,
    borderRadius: 4,
    maxWidth: '99%',
    '&:focus, &:active': {
      border: `1px solid ${theme.palette.info.main}`,
      outline: 'none',
    },
  },
  ellipsisWrap: {
    minWidth: 0,
  },
});

export function DocumentCell(props) {
  const {
    loading,
    document,
    classes,
    documentToRename,
    setDocumentToRename,
    renameDocument,
    listRequestOption,
  } = props;
  const { type } = document;
  const textInput = useRef(null);
  const [name, setName] = useState(document?.name);

  useEffect(
    () => {
      if (document?.name) {
        setName(document.name);
      }
    },
    [document?.name],
  );

  const isEditMode = document?.id === documentToRename;

  const handleSubmit = () => {
    setDocumentToRename('');
    if (name.trim() === '') {
      setName(document?.name);
    } else if (name.trim() !== '' && name !== document?.name) {
      setName(name);
      renameDocument(
        {
          id: document.id,
          name,
        },
        listRequestOption,
      );
    }
  };

  const handleChange = event => {
    setName(event.target.value);
  };

  if (loading && isEmpty(document)) {
    return <DetailImageCellSkeleton />;
  }

  if (!loading && isEmpty(document)) {
    return <div>-</div>;
  }
  const handleFocus = e => {
    e.currentTarget.select();
  };

  return (
    <div className={classes.container}>
      {type === documentType.folder ? (
        <Folder width={24} height={24} color="inherit" />
      ) : (
        <File width={24} height={24} color="inherit" />
      )}
      <div className={classes.ellipsisWrap}>
        <Tooltip
          className={classes.fullWidthToolTip}
          title={document?.name}
          placement="top-start"
          leaveDelay={10}
          key={`tooltip-name-${document?.name}`}
        >
          {isEditMode ? (
            <form onSubmit={handleSubmit}>
              <Input
                ref={textInput}
                disableUnderline
                onFocus={handleFocus}
                classes={{ input: classNames(classes.input) }}
                onBlur={handleSubmit}
                value={name}
                onChange={handleChange}
                onClick={event => event.stopPropagation()}
                autoFocus
              />
            </form>
          ) : (
            <Typography noWrap variant="h6" component="p" sx={{ whiteSpace: 'pre' }}>
              {document?.name}
            </Typography>
          )}
        </Tooltip>
        <Typography noWrap component="p" variant="subtitleLight">
          {bytes(document?.media_resource?.size)}
        </Typography>
      </div>
    </div>
  );
}

DocumentCell.propTypes = {
  classes: PropTypes.object.isRequired,
  documentToRename: PropTypes.string,
  setDocumentToRename: PropTypes.func.isRequired,
  renameDocument: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  document: PropTypes.object,
  listRequestOption: PropTypes.shape({
    method: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    dataKey: PropTypes.string.isRequired,
    urlParams: PropTypes.object,
    callBack: PropTypes.func,
  }).isRequired,
};

export default compose(withStyles(styles))(DocumentCell);
