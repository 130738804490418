import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { withOktaAuth } from '@okta/okta-react';

import Button from 'components/Button';
import CleanableTextField from 'components/CleanableTextField';
import InputPassword from 'components/reduxFormComponentsV3/InputPassword';
import ArrowRight from 'components/SvgComponents/icons/ArrowRight';
import CheckSimple from 'components/SvgComponents/icons/CheckSimple';
import {
  checkEmailRegistration,
  getAllOrganizations,
  getBusinessOrganization,
} from 'containers/GlobalWrapper/actions';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';

import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

// form fields
// components
import { createStructuredSelector } from 'reselect';

import { Routing } from 'routing/routing';
import oktaTheme from 'theme/okta-login-theme';
import globalMessages from 'translations/messages/global-messages';
import Analytics from 'utils/Analytics';
import { setBusinessOrganizationInStorage } from 'utils/organisation-utils';

import { redirectAfterLogin } from 'utils/redirect-utils';
import sessionStorageUser from 'utils/sessionStorageUser';
import { isValidPassword } from 'utils/validators';
import { completeAccount, createAccount, resetLogin } from './actions';
import BackButton from './components/BackButton';
import TermsOfUse from './components/TermsOfUse';

function CreateAccount(props) {
  const [oktaIdpMail, setOktaIdpMail] = useState(null);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const invitations = userRegistrationInfo?.invitations;

  const {
    classes,
    intl,
    userRegistrationInfo,
    authState,
    dispatchCreateAccount,
    oktaAuth,
    completeIdp,
    history,
    dispatchCompleteAccount,
    dispatchCheckEmailRegistration,
    dispatchGetAllOrganizations,
    dispatchGetBusinessOrganization,
    dispatch,
  } = props;
  const deepLink = sessionStorageUser.getDeepLink();
  const invitationsLink = sessionStorageUser.getInvitationsLink();

  const showBackButton = !deepLink && !invitationsLink;

  const [initialValues, setInitialValues] = useState({
    first_name: '',
    last_name: '',
  });

  useEffect(
    () => {
      oktaAuth.token.getUserInfo().then(userInfo => {
        setOktaIdpMail(userInfo?.email);
        if (userInfo.given_name || userInfo.family_name) {
          setInitialValues({
            first_name: userInfo.given_name ?? '',
            last_name: userInfo.family_name ?? '',
          });
        }
      });
    },
    [oktaAuth, dispatch],
  );

  const validate = values => {
    const errors = {};
    const first_name = values?.first_name && !/^\s+$/.test(values?.first_name);
    const last_name = values?.last_name && !/^\s+$/.test(values?.last_name);

    const password = values?.password;

    if (!completeIdp) {
      if (!password || !isValidPassword(password)) {
        errors.password = <FormattedMessage {...globalMessages.password_instructions} />;
      }
    }
    if (!first_name) {
      errors.first_name = <FormattedMessage {...globalMessages.required} />;
    }
    if (!last_name) {
      errors.last_name = <FormattedMessage {...globalMessages.last_name} />;
    }

    return errors;
  };

  const onSubmit = values =>
    new Promise(resolve => {
      const valuesForm = JSON.stringify(values, null, 2);
      const form = JSON.parse(valuesForm);
      if (!completeIdp) {
        const email = userRegistrationInfo?.email || authState?.idToken?.claims?.email;
        const code = userRegistrationInfo?.code;
        dispatchCreateAccount(
          {
            ...form,
            email,
            validation_code: code,
          },
          () => {
            oktaAuth
              .signInWithCredentials({
                username: email,
                password: form.password,
              })
              .then(transaction => {
                if (transaction.status === 'SUCCESS') {
                  oktaAuth.signInWithRedirect({
                    sessionToken: transaction.sessionToken,
                    responseType: 'code',
                    prompt: 'none',
                    scopes: ['openid', 'profile', 'email', 'offline_access'],
                  });
                  Analytics.track('users_sign_up_succeeded', {
                    from_invitation: !!deepLink || !!invitationsLink || invitations.length >= 1,
                    source: 'password',
                    with_email_validation: !!code,
                  });
                  resolve();
                } else {
                  Analytics.track('users_sign_up_failed', {
                    source: 'password',
                    failure_reason: 'server_error',
                  });
                  resolve(new Error(`We cannot handle the ${transaction.status} status`));
                }
              })
              .catch(err => {
                Analytics.track('users_sign_up_failed', {
                  source: 'password',
                  failure_reason: err.name,
                });
                console.log('Found an error', err); //eslint-disable-line
                resolve(err);
              });
          },
          () => {
            resolve(false);
          },
        );
      } else {
        dispatchCompleteAccount(form, () => {
          dispatchCheckEmailRegistration(oktaIdpMail, () => {
            dispatchGetAllOrganizations(data => {
              const organization = data?.organizations[0];
              if (organization?.id) {
                dispatchGetBusinessOrganization(organization?.id, fullOrg => {
                  if (fullOrg) {
                    setBusinessOrganizationInStorage(fullOrg);
                  }

                  const currentUserEmail =
                    userRegistrationInfo?.email || authState?.idToken?.claims?.email;
                  const forward = sessionStorageUser.getForwardRedirect();
                  if (forward && currentUserEmail) {
                    const { projectId, organizationId, email } = forward;
                    sessionStorageUser.setForwardRedirect(null);
                    if (currentUserEmail === email && organizationId) {
                      if (projectId) {
                        history.push(Routing.home.home(organizationId, projectId));
                      } else {
                        history.push(Routing.home.projects(organizationId));
                      }
                    } else {
                      redirectAfterLogin(history);
                    }
                  } else {
                    redirectAfterLogin(history);
                  }
                  resolve();
                });
              } else {
                redirectAfterLogin(history);
              }
            });
          });
        });
      }
    });

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(globalMessages.create_your_account)} |{' '}
          {intl.formatMessage(globalMessages.finalcad)}
        </title>
      </Helmet>
      <div className={classes.containerLogin}>
        <div className={classes.centerLoginContainer}>
          <div className={classes.createAccountWrapper}>
            <div className={classes.wrapTitle}>
              {showBackButton && <BackButton className={classes.absoluteBtn} />}
              <div className={classes.wrapTitleCenter}>
                <Typography component="h1" variant="header">
                  {intl.formatMessage(globalMessages.create_your_account)}
                </Typography>
              </div>
            </div>

            <Form
              onSubmit={onSubmit}
              validate={validate}
              initialValues={initialValues}
              render={({ handleSubmit, submitting, valid }) => (
                <form className={classes.oktaForm} onSubmit={handleSubmit}>
                  <Field
                    name="first_name"
                    placeholder={intl.formatMessage(globalMessages.first_name)}
                    required
                    customClassName={classes.textField}
                    component={CleanableTextField}
                    autoComplete="given-name"
                    autoFocus
                  />
                  <Field
                    name="last_name"
                    placeholder={intl.formatMessage(globalMessages.last_name)}
                    required
                    customClassName={classes.textField}
                    component={CleanableTextField}
                    autoComplete="family-name"
                  />
                  {!completeIdp ? (
                    <div className={classes.passwordContainer}>
                      <Field
                        name="password"
                        autoComplete="new-password"
                        placeholder={intl.formatMessage(globalMessages.create_password)}
                        component={InputPassword}
                      />
                    </div>
                  ) : null}
                  <FormControlLabel
                    className={classes.checkboxField}
                    classes={classes}
                    control={
                      <Checkbox
                        checked={privacyChecked}
                        onChange={() => setPrivacyChecked(!privacyChecked)}
                        checkedIcon={<CheckSimple className={classes.checkboxCheck} />}
                      />
                    }
                    label={<TermsOfUse />}
                  />
                  <Button
                    type="submit"
                    disabled={!valid || !privacyChecked}
                    loading={submitting}
                    size="large"
                    startIcon={<ArrowRight />}
                  >
                    {intl.formatMessage(globalMessages.create_my_account)}
                  </Button>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}

CreateAccount.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  completeIdp: PropTypes.bool,
  userRegistrationInfo: PropTypes.object.isRequired,
  dispatchCreateAccount: PropTypes.func.isRequired,
  oktaAuth: PropTypes.object.isRequired,
  authState: PropTypes.object.isRequired,
  dispatchCompleteAccount: PropTypes.func.isRequired,
  dispatchGetAllOrganizations: PropTypes.func.isRequired,
  dispatchCheckEmailRegistration: PropTypes.func.isRequired,
  dispatchGetBusinessOrganization: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

CreateAccount.defaultProps = {
  completeIdp: false,
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {
  dispatchResetLogin: resetLogin,
  dispatchCreateAccount: createAccount,
  dispatchCompleteAccount: completeAccount,
  dispatchGetAllOrganizations: getAllOrganizations,
  dispatchGetBusinessOrganization: getBusinessOrganization,
  dispatchCheckEmailRegistration: checkEmailRegistration,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  withRouter,
  withOktaAuth,
  injectIntl,
  withStyles(oktaTheme),
)(CreateAccount);
