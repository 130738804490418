import { takeLatest, put, call, select } from 'redux-saga/effects';

import request from 'utils/request';
import config from 'config/config';
import checkOrSetSlash from 'utils/checkOrSetSlash';

import {
  GET_ALL_MEMBERS,
  GET_AVAILABLE_OFFERS,
  GET_CURRENT_PLAN,
  CANCEL_PLAN,
  MANAGE_PAYMENT_METHOD,
} from './constants';
import {
  setLoadingFlag,
  setCurrentPlan,
  setAvailableOffers,
  setAllMembers,
} from './actions';
import { selectRouteParams } from '../GlobalWrapper/selectors';

export function* fetchOffer() {
  yield put(setLoadingFlag(true));
  const route = yield select(selectRouteParams());
  const requestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/subscriptions/${route.organizationId}/current`;
  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURL, options, {
    errorCustom: true,
  });

  if (data && !data.message) {
    yield put(setCurrentPlan(data));
  }
  yield put(setLoadingFlag(false));
}

function* fetchOffers() {
  const route = yield select(selectRouteParams());
  yield put(setLoadingFlag(true));
  const requestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/offers/${route.organizationId}/available`;
  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURL, options, {
    errorCustom: true,
  });
  if (data && !data.message && data.status !== 500) {
    yield put(setAvailableOffers(data?.offers));
  }
  yield put(setLoadingFlag(false));
}

export function* fetchMembers() {
  const route = yield select(selectRouteParams());
  const requestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/organizations/${route.organizationId}/members-count`;
  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURL, options);

  if (data && !data.message) {
    yield put(setAllMembers(data.member_number));
  }
}

export function* cancelPlan({ callback }) {
  const route = yield select(selectRouteParams());
  const requestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/orders/${route.organizationId}/cancel`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURL, options);

  if (!data || (data && !data.message)) {
    if (callback) {
      callback();
    }
  }
}

export function* managePaymentMethod() {
  const route = yield select(selectRouteParams());
  const requestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/subscriptions/${route.organizationId}/manage`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      redirect: window.location.href,
    }),
  };

  const data = yield call(request, requestURL, options);
  window.location.replace(data);
}

export default function* initContractPageSaga() {
  yield takeLatest(GET_CURRENT_PLAN, fetchOffer);
  yield takeLatest(GET_AVAILABLE_OFFERS, fetchOffers);
  yield takeLatest(GET_ALL_MEMBERS, fetchMembers);
  yield takeLatest(CANCEL_PLAN, cancelPlan);
  yield takeLatest(MANAGE_PAYMENT_METHOD, managePaymentMethod);
}
