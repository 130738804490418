import {
  ADD_MESSAGE,
  ADD_PREVIOUS_MESSAGES,
  CLEAR_MESSAGES,
  GET_OBSERVATION_PREVIOUS_MESSAGE,
  GET_FORM_PREVIOUS_MESSAGE,
  SET_PREVIOUS_MESSAGES_LOADING_FLAG,
  GET_GROUP_PREVIOUS_MESSAGE,
} from './constants';

export function addMessage(messageType, content, id) {
  return {
    type: ADD_MESSAGE,
    messageType,
    content,
    id,
  };
}

export function addPreviousMessages(messages) {
  return {
    type: ADD_PREVIOUS_MESSAGES,
    messages,
  };
}

export function clearMessages() {
  return {
    type: CLEAR_MESSAGES,
  };
}

export function getObservationPreviousMessages(params, messageId, callback) {
  return {
    type: GET_OBSERVATION_PREVIOUS_MESSAGE,
    messageId,
    callback,
    params,
  };
}

export function getFormPreviousMessages(params, messageId, form, callback) {
  return {
    type: GET_FORM_PREVIOUS_MESSAGE,
    messageId,
    form,
    callback,
    params,
  };
}

export function getGroupPreviousMessages(params, messageId, callback) {
  return {
    type: GET_GROUP_PREVIOUS_MESSAGE,
    messageId,
    callback,
    params,
  };
}

export function setPreviousMessagesLoadingFlag(loading) {
  return {
    type: SET_PREVIOUS_MESSAGES_LOADING_FLAG,
    loading,
  };
}
