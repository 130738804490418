import React, { Fragment, useEffect, useState } from 'react';

import { ListItem, Typography, ListItemButton, Collapse, Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import ChevronDown from 'components/SvgComponents/icons/ChevronDown';
import ChevronUp from 'components/SvgComponents/icons/ChevronUp';
import { styles } from 'theme/nav-link-theme';
import { CustomNavLink } from 'utils/redirect-utils';

const permissionValid = (rightsList, permissions) =>
  permissions.filter(
    permission =>
      rightsList.filter(
        right => (right.action ? right.action === permission : right === permission),
      ).length > 0,
  ).length > 0;

const NavProjectLinkListItem = ({ classes, list, name }) => {
  const [openStates, setOpenStates] = useState({});

  useEffect(
    () => {
      const initialStates = {};
      list.forEach(item => {
        initialStates[`${name}-${item.key}`] = true;
      });
      setOpenStates(initialStates);
    },
    [list, name],
  );

  const handleClick = navKey => {
    setOpenStates(prev => ({ ...prev, [navKey]: !prev[navKey] }));
  };

  const displayItem = item => {
    const { url, label, icon, other, key } = item;
    const uniqueKey = `${name}-${key}`;

    return (
      <ListItem
        key={uniqueKey}
        button
        classes={{
          root: classes.projectListItem,
        }}
        to={url}
        activeClassName={classes.active}
        component={CustomNavLink}
      >
        {icon
          ? {
              ...icon,
              props: {
                height: 16,
                width: 16,
                ...icon?.props,
              },
            }
          : null}
        <Typography noWrap variant="body">
          {label}
        </Typography>
        {other}
      </ListItem>
    );
  };

  const displaySubItem = item => {
    const { url, label, icon, other, key, color, rightsList, permissions } = item;
    const uniqueKey = `${name}-${key}`;

    if (rightsList?.length && permissions) {
      if (!permissionValid(rightsList, permissions)) return null;
    }

    return (
      <ListItem
        key={uniqueKey}
        button
        classes={{
          root: classes.listSubItem,
        }}
        to={url}
        activeClassName={classes.active}
        component={CustomNavLink}
      >
        {icon ? (
          <Box
            sx={{
              color,
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {{
              ...icon,
              props: {
                height: 16,
                width: 16,
                'aria-hidden': true,
                ...icon?.props,
                color,
              },
            }}
          </Box>
        ) : null}
        <Typography noWrap variant="body">
          {label}
        </Typography>
        {other}
      </ListItem>
    );
  };

  return (
    <>
      {list.map(item => {
        const { component, key, permissions, rightsList, label, items } = item;

        const uniqueKey = `${name}-${key}`;
        if (rightsList?.length && permissions) {
          if (!permissionValid(rightsList, permissions)) return null;
        }
        if (component) {
          if (rightsList?.length && permissions) {
            if (permissionValid(rightsList, permissions)) return component;
            return null;
          }
          return <Fragment key={uniqueKey}>{component}</Fragment>;
        }
        if (items) {
          return (
            <Fragment key={uniqueKey}>
              <ListItemButton
                classes={{
                  root: classes.collapseListItem,
                }}
                onClick={() => handleClick(uniqueKey)}
              >
                <Typography noWrap variant="h5">
                  {label}
                </Typography>
                {openStates[uniqueKey] ? (
                  <ChevronUp height={16} width={16} />
                ) : (
                  <ChevronDown height={16} width={16} />
                )}
              </ListItemButton>
              <Collapse in={openStates[uniqueKey]} timeout="auto" unmountOnExit>
                {items.map(subItems => displaySubItem(subItems))}
              </Collapse>
            </Fragment>
          );
        }
        return displayItem(item);
      })}
    </>
  );
};

NavProjectLinkListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
      url: PropTypes.string,
      icon: PropTypes.node,
      component: PropTypes.any,
      items: PropTypes.array,
      other: PropTypes.node,
    }),
  ),
  name: PropTypes.string,
};

NavProjectLinkListItem.defaultProps = {
  list: [],
};

export default compose(withStyles(styles))(NavProjectLinkListItem);
