import {
  ADD_ATTACHMENT,
  SEND_MESSAGE,
  SEND_MESSAGE_CLUSTER,
  SEND_MESSAGE_CLUSTER_BATCH,
  SET_LOADING_FLAG,
} from './constants';

export function setLoadingFlag(loading) {
  return {
    type: SET_LOADING_FLAG,
    loading,
  };
}
export const addAttachment = (
  document,
  listRequestOption,
  sendMessageRequestOption,
  callback,
  scope,
  featureFlags,
) => ({
  type: ADD_ATTACHMENT,
  document,
  listRequestOption,
  sendMessageRequestOption,
  callback,
  scope,
  featureFlags,
});
export const sendMessage = (
  message,
  messageType,
  listRequestOption,
  mentions,
  callback,
  scope,
  featureFlags,
) => ({
  type: SEND_MESSAGE,
  message,
  messageType,
  listRequestOption,
  mentions,
  callback,
  scope,
  featureFlags,
});

export const sendMessageCluster = ({
  listRequestOption,
  itemList,
  callback,
  sourceType,
  sourceId,
  targetType,
}) => ({
  type: SEND_MESSAGE_CLUSTER,
  listRequestOption,
  itemList,
  callback,
  sourceType,
  sourceId,
  targetType,
});

export const sendMessageClusterBatch = ({ payloadList, callback }) => ({
  type: SEND_MESSAGE_CLUSTER_BATCH,
  payloadList,
  callback,
});
