import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

function Clear(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>
        {props.title || props.intl.formatMessage(globalMessages.clear)}
      </title>
      <circle cx="8" cy="8" r="8" />
      <g opacity="0.8">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9721 5.02864C11.2324 5.28899 11.2324 5.7111 10.9721 5.97145L5.97206 10.9714C5.71171 11.2318 5.2896 11.2318 5.02925 10.9714C4.7689 10.7111 4.7689 10.289 5.02925 10.0286L10.0292 5.02864C10.2896 4.76829 10.7117 4.76829 10.9721 5.02864Z"
          fill="#F4F6F8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.02925 5.02864C5.2896 4.76829 5.71171 4.76829 5.97206 5.02864L10.9721 10.0286C11.2324 10.289 11.2324 10.7111 10.9721 10.9714C10.7117 11.2318 10.2896 11.2318 10.0292 10.9714L5.02925 5.97145C4.7689 5.7111 4.7689 5.28899 5.02925 5.02864Z"
          fill="#F4F6F8"
        />
      </g>
    </svg>
  );
}
Clear.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

Clear.defaultProps = {
  title: '',
};

export default injectIntl(Clear);
