import { SET_DROPDOWN_DATA } from './constants';

export function setDropdownData({ id, visibility, reference, selected }) {
  return {
    type: SET_DROPDOWN_DATA,
    id,
    visibility,
    reference,
    selected,
  };
}
