const scope = 'superAdmin/trade/';
export const SET_RENAME_ITEM = `${scope}SET_RENAME_ITEM`;
export const RENAME_ITEM = `${scope}RENAME_ITEM`;
export const DELETE_ITEM = `${scope}DELETE_ITEM`;
export const ADD_TRADE = `${scope}ADD_TRADE`;
export const SET_LANGUAGE_DEFAULT = `${scope}SET_LANGUAGE_DEFAULT`;
export const RESET_LANGUAGE_DEFAULT = `${scope}RESET_LANGUAGE_DEFAULT`;
export const SET_CHECKED_PROJECTS = `${scope}SET_CHECKED_PROJECTS`;
export const SET_ALL_CHECKED_PROJECTS = `${scope}SET_ALL_CHECKED_PROJECTS`;
export const CLEAR_CHECKED_PROJECTS = `${scope}CLEAR_CHECKED_PROJECTS`;
export const PUSH_TO_PROJECTS = `${scope}PUSH_TO_PROJECTS`;
export const SET_MODIFY = `${scope}SET_MODIFY`;
