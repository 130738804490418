import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Placeholder from './Placeholder';

const styles = () => ({
  border: {
    borderRadius: '5px',
  },
});

const PriorityCellSkeleton = props => {
  const { classes } = props;
  return <Placeholder className={classes.border} height="20px" width="20px" />;
};

PriorityCellSkeleton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PriorityCellSkeleton);
