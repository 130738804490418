import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

function Minus(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>
        {props.title || props.intl.formatMessage(globalMessages.zoom_out)}
      </title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.6001 9.99971C3.6001 9.55788 3.95827 9.19971 4.4001 9.19971H15.6001C16.0419 9.19971 16.4001 9.55788 16.4001 9.99971C16.4001 10.4415 16.0419 10.7997 15.6001 10.7997H4.4001C3.95827 10.7997 3.6001 10.4415 3.6001 9.99971Z"
      />
    </svg>
  );
}
Minus.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

Minus.defaultProps = {
  title: '',
};

export default injectIntl(Minus);
