import { typeConst, cronConfigurationSelectType } from 'utils/constants';

const formTemplateMapApiToTree = formTemplateParts =>
  (formTemplateParts || []).map(item => {
    const it = item;
    it.key = item.id;
    it.title = item.name;
    if (it.form_template_sections) {
      formTemplateMapApiToTree(it.form_template_sections);
    }
    it.children = [];
    if (it.form_template_sections) {
      it.children.push(...it.form_template_sections);
    }
    if (it.form_fields) {
      it.children.push(...it.form_fields);
    }
    return it;
  });

const formTemplateOutputMapTreeToApi = formTemplateParts =>
  (formTemplateParts || []).map(item => {
    const it = item;
    if (it.children?.length > 0) {
      it.children.forEach(child => {
        if (child.type === typeConst.section) {
          if (it.type === typeConst.part) {
            it.form_template_sections = it.children || [];
          }
        }
        if (child.type === typeConst.field) {
          it.form_fields = it.children || [];
        }
      });
      formTemplateOutputMapTreeToApi(it.children);
    } else if (it.type === typeConst.part) {
      it.form_template_sections = [];
    } else if (it.type === typeConst.section) {
      it.form_fields = [];
    }
    return it;
  });

const mapCreateTemplatePayload = (reduxFormObj, template) => {
  const createTemplate = template;

  // set Image quality
  if (reduxFormObj.templateImageQualityValue)
    createTemplate.ImageQuality = parseInt(
      reduxFormObj.templateImageQualityValue,
      10,
    );

  // ----#Name: Set template name
  createTemplate.Name = reduxFormObj.templateName;

  // ----#DataSource split
  if (reduxFormObj.templateMainPlugin)
    createTemplate.DataSourceSplitId = reduxFormObj.templateMainPlugin;

  // ----#InternalDescription: Set template descriptions
  if (reduxFormObj.templateDescription)
    createTemplate.InternalDescription = reduxFormObj.templateDescription;
  // ----#DataSource: Check for Data Source if it is available store the selected Data sources Guid in store ----
  if (reduxFormObj.templateDataSet) {
    const dataSource = [];
    reduxFormObj.templateDataSet.forEach(ds => {
      dataSource.push({ Guid: ds });
    });
    createTemplate.DataSources = dataSource;
  }
  // ---- #Tags: Check for Tags if it is available store the tags Guid in store ----
  if (reduxFormObj.templateTags) {
    const tags = [];
    reduxFormObj.templateTags.forEach(tag => {
      tags.push({ Guid: tag });
    });
    createTemplate.Tags = tags;
  }
  // ---- #TemplateType: Set TemplateType ----
  if (reduxFormObj.templateType) {
    createTemplate.TemplateType = {
      Guid: reduxFormObj.templateType,
    };
  }
  // ---- #OutputType: Set OutputType ----
  if (reduxFormObj.templateOutPutSplitting) {
    createTemplate.OutputType = {
      Guid: reduxFormObj.templateOutPutSplitting,
    };
  }
  return createTemplate;
};

const mapUpdateEditDocTemplateTemplate = docData => {
  const docTemplate = {
    name: '',
    internalDescription: '',
    templateResourceId: '',
    libraryId: '',
    defaultTranslateResourceId: '',
    resourceExtention: 1,
    enabled: true,
    corelationId: '',
    templateType: {
      guid: '',
    },
    outputType: { guid: '' },
    tags: [],
    dataSources: [],
    resources: [],
    guid: '',
  };
  docTemplate.name = docData.name;
  docTemplate.internalDescription = docData.internalDescription;
  docTemplate.templateResourceId = docData.templateResourceId;
  docTemplate.libraryId = docData.libraryId;
  docTemplate.defaultTranslateResourceId = docData.defaultTranslateResourceId;
  docTemplate.resourceExtention = docData.resourceExtention;
  docTemplate.enabled = docData.enabled;
  docTemplate.corelationId = docData.corelationId;
  docTemplate.templateType.guid = docData.templateType.guid;
  docTemplate.outputType.guid = docData.outputType.guid;
  docTemplate.guid = docData.guid;
  docTemplate.dataSourceSplitId = docData.dataSourceSplitId;
  if (docData.tags.length > 0) {
    docData.tags.forEach(tag => {
      docTemplate.tags.push({
        guid: tag.guid,
      });
    });
  }
  if (docData.dataSources.length > 0) {
    docData.dataSources.forEach(ds => {
      docTemplate.dataSources.push({
        guid: ds.guid,
      });
    });
  }
  if (docData.resources.length > 0) {
    docData.resources.forEach(res => {
      docTemplate.resources.push({
        code: res.code,
        guid: res.guid,
        resourceExtention: res.resourceExtention,
        resourceId: res.resourceId,
      });
    });
  }

  return docTemplate;
};

const mapCreateSchedulerForProject = (payload, schedule) => {
  const { DAYS, WEEK, MONTH } = cronConfigurationSelectType;
  const {
    intervalType,
    repeatEveryWeekOn,
    repeatIntervalWeek,
    repeatIntervalMonth,
    repeatWeek,
    repeatDays,
    guid,
  } = schedule;

  const schedulerPayload = { ...payload };

  if (guid) {
    schedulerPayload.scheduleDto.guid = guid;
  }

  if (intervalType === DAYS) {
    // Days
    schedulerPayload.scheduleDto.jobParams.schedule.repeatDays = repeatDays;
  } else if (intervalType === WEEK) {
    // Weeks
    schedulerPayload.scheduleDto.jobParams.schedule.repeatIntervalWeek = repeatIntervalWeek;
    schedulerPayload.scheduleDto.jobParams.schedule.repeatEveryWeekOn = repeatEveryWeekOn.map(
      day => parseInt(day, 10),
    );
  } else if (intervalType === MONTH) {
    // Months
    schedulerPayload.scheduleDto.jobParams.schedule.repeatIntervalMonth = repeatIntervalMonth;
    schedulerPayload.scheduleDto.jobParams.schedule.repeatIntervalWeek = repeatWeek;
    schedulerPayload.scheduleDto.jobParams.schedule.repeatEveryWeekOn = repeatEveryWeekOn.map(
      day => parseInt(day, 10),
    );
  }

  return schedulerPayload;
};

const objectByString = (obj, path) => {
  // here we are parsing the object that has the key a string of form 'formfieldDtos[1].formFields[0].formFieldSaNumericDto';
  const parsedKeys = path.replace(/\[|\]\.?/g, '.').split('.');
  let newObj = obj;
  for (let index = 0; index < parsedKeys.length; index += 1) {
    if (newObj && parsedKeys[index] !== '') {
      newObj = newObj[parsedKeys[index]];
    }
  }
  return typeof newObj === 'number' ? String(newObj) : newObj;
};

const stringToObject = (str, obj, value) => {
  // verify last character from string, for formFields[0] || formFieldSaNumericDto
  const lastCharacter = str[str.length - 1] === ']';
  const parse = lastCharacter ? str.substring(0, str.length - 1) : str;
  const parsedKeys = parse.replace(/\[|\]\.?/g, '.').split('.');
  // here we are constructing an object that has a form of 'formfieldDtos[1].formFields[0].formFieldSaNumericDto'
  let newObj = obj;
  for (let index = 0; index < parsedKeys.length; index += 1) {
    // if next value is not off the array and next value is integer > 0 this means the next property inside object is array, ohterwise is object
    if (
      index + 1 < parsedKeys.length &&
      /^\+?\d[\d]*$/.test(parsedKeys[index + 1])
    ) {
      newObj[parsedKeys[index]] = [];
    } else {
      newObj[parsedKeys[index]] = {};
    }
    if (index === parsedKeys.length - 1) {
      newObj[parsedKeys[index]] = value;
    }
    newObj = newObj[parsedKeys[index]];
  }
  return obj;
};

const getArrayOrDefault = data => (Array.isArray(data) ? data : []);

export {
  formTemplateMapApiToTree,
  formTemplateOutputMapTreeToApi,
  mapCreateTemplatePayload,
  mapUpdateEditDocTemplateTemplate,
  mapCreateSchedulerForProject,
  objectByString,
  stringToObject,
  getArrayOrDefault,
};
