const scope = 'exportPage/';
export const SET_LOADING_FLAG = `${scope}SET_LOADING_FLAG`;
export const GET_RECIPIENTS = `${scope}GET_RECIPIENTS`;
export const SET_RECIPIENTS = `${scope}SET_RECIPIENTS`;
export const SET_RECIPIENTS_PREFERENCES = `${scope}SET_RECIPIENTS_PREFERENCES`;
export const SET_ACTIVE_FILTERS = `${scope}SET_ACTIVE_FILTERS`;
export const CLEAR_ACTIVE_FILTERS = `${scope}CLEAR_ACTIVE_FILTERS`;
export const GET_FORMS_PRIORITIES = `${scope}GET_FORMS_PRIORITIES`;
export const GET_OBSERVATIONS_PRIORITIES = `${scope}GET_OBSERVATIONS_PRIORITIES`;
export const SET_FORMS_PRIORITIES = `${scope}SET_FORMS_PRIORITIES`;
export const SET_OBSERVATIONS_PRIORITIES = `${scope}SET_OBSERVATIONS_PRIORITIES`;
export const SET_PERIODIC_PREFERENCES = `${scope}SET_PERIODIC_PREFERENCES`;
export const CLEAR_PERIODIC_PREFERENCES = `${scope}CLEAR_PERIODIC_PREFERENCES`;
export const SCHEDULE_REPORT = `${scope}SCHEDULE_REPORT`;
export const GET_REPORTS_LIST = `${scope}GET_REPORTS_LIST`;
export const DELETE_REPORT = `${scope}DELETE_REPORT`;
export const SET_CUSTOMIZATION_CONFIGS = `${scope}SET_CUSTOMIZATION_CONFIGS`;
export const CLEAR_CUSTOMIZATION_CONFIGS = `${scope}CLEAR_CUSTOMIZATION_CONFIGS`;
