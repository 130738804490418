import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectGroupsPage = state => state.groupsPage || initialState;

const selectLoadingFlag = () =>
  createSelector(selectGroupsPage, priorityDataState =>
    priorityDataState.get('loading'),
  );
const selectLoadingGroupDetailsFlag = () =>
  createSelector(selectGroupsPage, priorityDataState =>
    priorityDataState.get('loadingGroupDetails'),
  );
const selectSearchedFlag = () =>
  createSelector(selectGroupsPage, priorityDataState =>
    priorityDataState.get('searched'),
  );
const selectSearchText = () =>
  createSelector(selectGroupsPage, priorityDataState =>
    priorityDataState.get('searchText'),
  );
const selectGroups = () =>
  createSelector(selectGroupsPage, dataState => dataState.get('groups').toJS());
const selectProjectMembers = () =>
  createSelector(selectGroupsPage, dataState =>
    dataState.get('projectMembers').toJS(),
  );
const selectCurrentGroup = () =>
  createSelector(selectGroupsPage, dataState => dataState.get('currentGroup'));
const selectMembersGroups = () =>
  createSelector(selectGroupsPage, dataState => dataState.get('membersGroups'));
const selectGroupDetails = () =>
  createSelector(selectGroupsPage, dataState => dataState.get('groupDetails'));
const selectDisplayDetails = () =>
  createSelector(selectGroupsPage, dataState =>
    dataState.get('displayDetails'),
  );

export {
  selectLoadingFlag,
  selectGroups,
  selectCurrentGroup,
  selectSearchedFlag,
  selectSearchText,
  selectLoadingGroupDetailsFlag,
  selectMembersGroups,
  selectGroupDetails,
  selectDisplayDetails,
  selectProjectMembers,
};
