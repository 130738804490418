import { styled, TextField } from '@mui/material';

const formEngineTheme = theme => ({
  input: {
    minWidth: 90,
    background: theme.palette.background.primary,
    borderRadius: 12,
    padding: '0.625em',
    margin: `${theme.spacing(3)}  0`,
    border: '1px transparent solid',
    '&:focus': {
      border: `1px ${theme.palette.text.dark} solid`,
      borderRadius: 12,
    },
    '& input': {
      padding: 0,
    },
  },
  inputUserText: {
    color: theme.palette.text.dark,
    minWidth: 90,
    background: theme.palette.background.primary,
    borderRadius: 12,
    padding: '0.625em',
    margin: `${theme.spacing(3)}  0`,
    border: '1px transparent solid',
    '&:focus': {
      border: `1px ${theme.palette.text.dark} solid`,
      borderRadius: 12,
    },
  },
  inputColorWhite: {
    color: theme.palette.text.primary,
    background: theme.palette.background.white,
    borderRadius: 12,
    padding: '0.625em',
    margin: `${theme.spacing(3)}  0`,
    border: '1px transparent solid',
    '&:focus': {
      border: `1px ${theme.palette.text.dark} solid`,
      borderRadius: 12,
    },
  },
  root: {
    color: `${theme.palette.text.dark} !important`,
    fontSize: '1rem',
    fontWeight: 'normal',
    transform: 'translate(0, -10px) scale(1)',
    '&$disabled': {
      color: theme.palette.info.main,
    },
  },
  disabled: {},

  switchBase: {
    '&$switchChecked': {
      color: theme.palette.text.tertiary,
      '& + $track': {
        opacity: 1,
      },
      '&.Mui-disabled': {
        color: theme.palette.text.secondary,
      },
    },
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
  },
  switchChecked: {
    transform: 'translateX(21px)',
  },
  switchDisabled: {
    '&$switchChecked': {
      '& + $track': {
        backgroundColor: theme.palette.background.secondary,
        opacity: 0.3,
      },
    },
  },
  track: {
    opacity: 1,
    background: theme.palette.background.secondary,
  },
  switchIcon: {
    width: 20,
    height: 20,
  },
  rootLabel: {
    marginLeft: theme.spacing(3),
  },
  labelHeader: {
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%',
    fontSize: '1rem',
    color: theme.palette.text.dark,
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '120%',
    margin: 0,
    '&$disabled': {
      color: theme.palette.text.secondary,
    },
  },
});

export const TextField_InputUserText = styled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    border: `1px ${theme.palette.text.dark} solid`,
  },
  '& .MuiOutlinedInput-root': {
    background: theme.palette.background.primary,
    borderRadius: 12,
    padding: theme.spacing(4),
    lineHeight: '1.5rem',

    '& fieldset': {
      border: '1px transparent solid',
    },
    '&:hover fieldset': {
      border: '1px transparent solid',
    },
    '&.Mui-focused fieldset': {
      border: `1px ${theme.palette.text.dark} solid`,
    },
  },

  '& .MuiInput-input': {
    color: theme.palette.text.dark,
    minWidth: 90,
    background: theme.palette.background.primary,
    borderRadius: 12,
    padding: '0.625em',
    margin: `${theme.spacing(3)}  0`,
    border: '1px transparent solid',
    '&:focus': {
      border: `1px ${theme.palette.text.dark} solid`,
      borderRadius: 12,
    },
  },
}));

export default formEngineTheme;
