import {
  ADD_GROUP_MEMBERS,
  ADD_NEW_GROUP,
  UPDATE_GROUP,
  GET_GROUP_DETAILS,
  GET_GROUPS,
  GET_PROJECT_MEMBERS,
  SEARCH_PROJECT_MEMBERS,
  SELECT_GROUP,
  SET_DISPLAY_DETAILS,
  SET_GROUP_DETAILS,
  SET_GROUPS,
  SET_GROUPS_MEMBERS,
  SET_LOADING_FLAG,
  SET_LOADING_GROUP_DETAILS_FLAG,
  SET_PROJECT_MEMBERS,
  SET_SEARCH_TEXT,
  SET_SEARCHED,
  UPDATE_DISPLAYED_LIST,
} from './constants';

export const getGroups = (params, searchValue, searched) => ({
  type: GET_GROUPS,
  searchValue,
  searched,
  params,
});
export const updateGroup = (params, group) => ({
  type: UPDATE_GROUP,
  group,
  params,
});
export const setLoadingFlag = loading => ({
  type: SET_LOADING_FLAG,
  loading,
});
export const setLoadingGroupDetailsFlag = loading => ({
  type: SET_LOADING_GROUP_DETAILS_FLAG,
  loading,
});
export const setSearched = searched => ({
  type: SET_SEARCHED,
  searched,
});
export const setGroups = groups => ({
  type: SET_GROUPS,
  groups,
});
export const setGroupsMembers = (id, members) => ({
  type: SET_GROUPS_MEMBERS,
  id,
  members,
});
export const setSearchText = searchText => ({
  type: SET_SEARCH_TEXT,
  searchText,
});
export const addNewGroup = (organizationId, projectId, groupName) => ({
  type: ADD_NEW_GROUP,
  organizationId,
  projectId,
  groupName,
});
export const selectGroup = group => ({
  type: SELECT_GROUP,
  group,
});
export const getGroupDetails = (params, groupId, callback) => ({
  type: GET_GROUP_DETAILS,
  groupId,
  params,
  setLoader: false,
  callback,
});
export const setGroupDetails = groupDetails => ({
  type: SET_GROUP_DETAILS,
  groupDetails,
});

export const setDisplayDetails = displayDetails => ({
  type: SET_DISPLAY_DETAILS,
  displayDetails,
});
export const searchProjectMembers = searchText => ({
  type: SEARCH_PROJECT_MEMBERS,
  searchText,
});
export const addGroupMembers = members => ({
  type: ADD_GROUP_MEMBERS,
  members,
});
export function getProjectMembers(routeParams, params) {
  return {
    type: GET_PROJECT_MEMBERS,
    routeParams,
    params,
  };
}
export function setProjectMembers(members) {
  return {
    type: SET_PROJECT_MEMBERS,
    members,
  };
}
export function updateDisplayedList(groupId, target) {
  return {
    type: UPDATE_DISPLAYED_LIST,
    groupId,
    target,
  };
}
