import React from 'react';
import PropTypes from 'prop-types';
import globalMessages from 'translations/messages/global-messages';
import { compose } from 'redux';
import { injectIntl, intlShape } from 'react-intl';

import { Link } from '@mui/material';
import { withStyles } from '@mui/styles';

export const styles = theme => ({
  privacyTermsButton: {
    margin: `0 0.5ch`,
    color: theme.palette.text.dark,
    padding: 0,
    textDecorationColor: 'inherit',
  },
});

export const TermsOfUse = props => {
  const { classes, intl } = props;
  return (
    <>
      {intl.formatMessage(globalMessages.privacy_policy_terms_1)}
      <Link
        className={classes.privacyTermsButton}
        href="https://www.finalcad.com/apps-privacy-policy"
        target="_blank"
      >
        {intl.formatMessage(globalMessages.privacy_policy_terms_2)}
      </Link>
      {intl.formatMessage(globalMessages.privacy_policy_terms_3)}
      <Link
        className={classes.privacyTermsButton}
        href="https://www.finalcad.com/general-terms-of-use"
        target="_blank"
      >
        {intl.formatMessage(globalMessages.privacy_policy_terms_4)}
      </Link>
    </>
  );
};

TermsOfUse.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default compose(
  withStyles(styles),
  injectIntl,
)(TermsOfUse);
