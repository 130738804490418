import {
  SET_LOADING_FLAG,
  SAVE_EDIT_USER,
  UPLOAD_IMAGE_USER,
  RESET_PASSWORD,
  ADD_COMMUNICATION,
  DELETE_ACCOUNT,
} from './constants';

export function setLoadingFlag(loading) {
  return {
    type: SET_LOADING_FLAG,
    loading,
  };
}
export function setSaveUser(userEdit) {
  return {
    type: SAVE_EDIT_USER,
    userEdit,
  };
}
export function uploadImage(image, callback) {
  return {
    type: UPLOAD_IMAGE_USER,
    image,
    callback,
  };
}
export function resetPassword(email, callback) {
  return {
    type: RESET_PASSWORD,
    email,
    callback,
  };
}
export function addCommunication(communication) {
  return {
    type: ADD_COMMUNICATION,
    communication,
  };
}
export function deleteAccount(userId, successCallback, errorCallback) {
  return {
    type: DELETE_ACCOUNT,
    userId,
    successCallback,
    errorCallback,
  };
}
