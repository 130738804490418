import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import AvatarUserDropZone from 'components/Dropzone/AvatarUserDropZone';

export const styles = theme => ({
  avatarRoot: {
    width: '156px',
    height: '156px',
    borderRadius: theme.spacing(2),
    '& svg': {
      height: theme.spacing(7),
      width: theme.spacing(7),
    },
  },
  userInformationWrapper: {
    display: 'flex',
    position: 'relative',
  },
  avatarContainer: {
    textAlign: 'center',
  },
  avatarComponent: {
    display: 'grid',
  },
  hidden: {
    visibility: 'hidden',
  },
});

export function UserHeader(props) {
  const { classes, mediaResources } = props;

  return (
    <div className={classes.userInformationWrapper}>
      <div className={classes.avatarContainer}>
        <div className={classes.avatarComponent}>
          <AvatarUserDropZone
            input={{ onChange: props.onUpdateImages }}
            initialMedia={mediaResources?.url}
            alt={mediaResources?.display_name}
            materialUIClasses={{ root: classes.avatarRoot }}
          />
        </div>
      </div>
    </div>
  );
}

UserHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  onUpdateImages: PropTypes.func,
  mediaResources: PropTypes.object,
};
UserHeader.defaultProps = {
  mediaResources: {},
};
export default compose(withStyles(styles))(UserHeader);
