import { scope } from '../../constants';

export const GET_FORM_PHASE = `${scope}GET_FORM_PHASE`;
export const SET_FORM_PHASE = `${scope}SET_FORM_PHASE`;
export const GET_FORM = `${scope}GET_FORM`;
export const SET_FORM = `${scope}SET_FORM`;
export const REMOVE_FORM = `${scope}REMOVE_FORM`;
export const GET_FORM_STATUS = `${scope}GET_FORM_STATUS`;
export const SET_FORM_STATUS = `${scope}SET_FORM_STATUS`;
export const GET_FORM_PRIORITY = `${scope}GET_FORM_PRIORITY`;
export const SET_FORM_PRIORITY = `${scope}SET_FORM_PRIORITY`;
