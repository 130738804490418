import React from 'react';
import PropTypes from 'prop-types';

export const Bricks = props => (
  <svg
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9019 1.99995H2C1.44771 1.99995 0.999998 2.44767 0.999998 2.99995V9.08315V15.1665V21.2498C0.999998 21.802 1.44771 22.2498 2 22.2498H21.8037C22.356 22.2498 22.8037 21.802 22.8037 21.2498V15.1665V9.08315V2.99995C22.8037 2.44767 22.356 1.99995 21.8037 1.99995H11.9019ZM20.8037 8.08315H16.8527H12.9019V3.99995H20.8037V8.08315ZM20.8037 10.0832V14.1665H17.8527V10.0832H20.8037ZM20.8037 16.1665H12.9019V20.2498H20.8037V16.1665ZM10.9019 20.2498V16.1665H3V20.2498H10.9019ZM3 14.1665H5.95086V10.0832H3V14.1665ZM3 8.08315H6.95086H10.9019V3.99995H3V8.08315ZM15.8527 10.0832V14.1665H11.9019H7.95086V10.0832H11.8902L11.9019 10.0832L11.9135 10.0832H15.8527Z"
      fill="current"
    />
  </svg>
);

Bricks.propTypes = {
  size: PropTypes.number,
};
