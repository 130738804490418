export const displayFirstPageOnly = docViewer => {
  const pageCount = docViewer.getPageCount();
  const pagesToRemoveArray = Array.from({ length: pageCount - 1 }, (_, i) => i + 2); // pageCount = 4 => [2, 3, 4]
  const doc = docViewer.getDocument();
  doc.removePages(pagesToRemoveArray);
};

export const onViewerScroll = (e, documentViewer) => {
  let throttleTime;
  e.stopPropagation();
  e.preventDefault();

  if (!throttleTime) {
    throttleTime = setTimeout(() => {
      const scaleFactor = e.deltaMode === 0 ? 0.05 : 0.4;
      const newZoom =
        documentViewer.getZoomLevel() * (e.deltaY < 0 ? 1 + scaleFactor : 1 - scaleFactor);

      // Handle min and max zoom
      if (newZoom > 80 || newZoom < 0.2) {
        return;
      }

      documentViewer.zoomToMouse(newZoom, 0, 76);
      clearTimeout(throttleTime);
      throttleTime = null;
    }, 50);
  }
};
