import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectObservationsPage = state => state.observationsPage || initialState;

const selectStatusList = () =>
  createSelector(selectObservationsPage, dataState => dataState.get('statusList').toJS());

const selectToStatusList = () =>
  createSelector(selectObservationsPage, dataState => dataState.get('toStatusList').toJS());

const selectPriorities = () =>
  createSelector(selectObservationsPage, dataState => dataState.get('priorities').toJS());

const selectLoadingFlag = () =>
  createSelector(selectObservationsPage, collaboratorsState => collaboratorsState.get('loading'));

const selectPhases = () =>
  createSelector(selectObservationsPage, dataState => dataState.get('phases').toJS());

const selectLastTableConfig = () =>
  createSelector(selectObservationsPage, dataState => dataState.get('lastTableConfig').toJS());

export {
  selectStatusList,
  selectToStatusList,
  selectPriorities,
  selectLoadingFlag,
  selectPhases,
  selectLastTableConfig,
};
