import { alpha as fade } from '@mui/material/styles';

export const styles = theme => ({
  searchBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dragDropTable: {
    position: 'relative',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '100%',
  },
  quickFiltersWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 4,
  },
  quickFiltersWrapModalCategory: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    width: '100%',
  },
  quickFiltersWrapModalCategoryContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
  },
  clearFilterContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '2rem',
  },
  closeIcon: {
    color: 'inherit',
  },
  headerRowForInfineTable: {
    flexBasis: '100%',
    width: 'auto',
    display: 'flex',
    alignSelf: 'center',
    textOverflow: 'ellipsis',
    backgroundColor: theme.palette.background.primary,
    alignItems: 'center',
    borderRadius: 8,
    height: 48,
    marginBlockEnd: 4,
    justifyContent: 'space-between',
    // Reserving space for the scrollbar
    paddingRight: 8,
    '&:focus': {
      outline: 'none',
    },
  },
  headerRowForDragDropTable: {
    width: '100%',
    display: 'flex',
    alignSelf: 'center',
    textOverflow: 'ellipsis',
    backgroundColor: theme.palette.background.primary,
    alignItems: 'center',
    borderRadius: 8,
    height: 48,
    marginBlockEnd: 4,
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '120%',
    color: theme.palette.text.primary,
    '&:focus': {
      outline: 'none',
    },
  },
  showCursor: {
    cursor: 'pointer',
  },
  rowTable: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',
    // color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.white,
    padding: `${theme.spacing()} 0`,
    height: 48,
    minWidth: '100%',
    '&:hover': {
      backgroundColor: fade(theme.palette.background.primary, 0.4),
    },
    '&:focus': {
      outline: 'none',
    },
  },
  clearFilterBtn: {
    paddingLeft: theme.spacing(),
    background: theme.palette.background.primary,
    borderWidth: 0,
    '&:hover': {
      color: theme.palette.info.main,
      background: `${theme.palette.info.main}33`,
      boxShadow: 'none',
      '& svg': {
        color: theme.palette.info.main,
      },
    },
    '&:focus': {
      color: theme.palette.info.main,
      background: `${theme.palette.info.main}33`,
      boxShadow: 'none',
      '& svg': {
        color: theme.palette.info.main,
      },
    },
  },
  checkedRow: {
    backgroundColor: fade(theme.palette.background.primary, 0.4),
  },
  searchText: {
    margin: '0 auto',
  },
  typoHeader: {
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '120%',
    color: theme.palette.text.primary,
  },
  cellWrap: {
    display: 'flex',
    alignSelf: 'center',
    overflow: 'hidden',
    padding: 0,
  },
  leftPaddingRowCell: {
    '&:first-child': {
      paddingLeft: theme.spacing(3),
    },
  },
  withPaddingLeft: {
    paddingLeft: theme.spacing(3),
  },
  allCheckBox: {
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  checkBoxColumn: {
    paddingRight: `${theme.spacing()}`,
    paddingLeft: `${theme.spacing()}`,
    minWidth: 52,
    '&:focus': {
      outline: 'none',
    },
    '&:first-child': {
      paddingLeft: `${theme.spacing(2)}`,
    },
  },
  row: {
    display: 'flex',
    flex: 'auto',
    overflow: 'hidden',
  },
  hideHeaderColumn: {
    display: 'none',
  },
  loadingWrap: {
    fontSize: '0.75rem',
    display: 'flex',
    flex: 'auto',
    justifyContent: 'center',
    width: '100%',
    '&:hover': {
      backgroundColor: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  emptyTable: {
    height: '100%',
  },
});
