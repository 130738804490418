import localStorageUser from './localStorageUser';

export const setBusinessOrganizationInStorage = organization => {
  const oktaToken = localStorageUser.getOktaTokenStorage();
  const userId = oktaToken?.accessToken?.claims?.fcUserId;
  if (userId) {
    localStorageUser.addRecentlyViewedOrganization(userId, organization);
  }
  localStorageUser.setBusinessOrganizationLocalStorage(organization);
  localStorageUser.setUserStore({});
};
