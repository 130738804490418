import { withStyles } from '@mui/styles';
import React from 'react';
import PropTypes from 'prop-types';
import Placeholder from './Placeholder';

const styles = () => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
});

const DateCellSkeleton = props => {
  const { classes } = props;
  return (
    <div className={classes.wrapper}>
      <Placeholder height="10px" width="40%" />
    </div>
  );
};

DateCellSkeleton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DateCellSkeleton);
