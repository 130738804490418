import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';
import globalMessages from 'translations/messages/global-messages';

const Disclaimer = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>
      {props.title || props.intl.formatMessage(globalMessages.disclaimer)}
    </title>

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 3.33333C3.82319 3.33333 3.65362 3.40357 3.5286 3.5286C3.40357 3.65362 3.33333 3.82319 3.33333 4V13.3333C3.33333 13.5101 3.40357 13.6797 3.5286 13.8047C3.65362 13.9298 3.82319 14 4 14H12C12.1768 14 12.3464 13.9298 12.4714 13.8047C12.5964 13.6797 12.6667 13.5101 12.6667 13.3333V4C12.6667 3.82319 12.5964 3.65362 12.4714 3.5286C12.3464 3.40357 12.1768 3.33333 12 3.33333H10.6667C10.2985 3.33333 10 3.03486 10 2.66667C10 2.29848 10.2985 2 10.6667 2H12C12.5304 2 13.0391 2.21071 13.4142 2.58579C13.7893 2.96086 14 3.46957 14 4V13.3333C14 13.8638 13.7893 14.3725 13.4142 14.7475C13.0391 15.1226 12.5304 15.3333 12 15.3333H4C3.46957 15.3333 2.96086 15.1226 2.58579 14.7475C2.21071 14.3725 2 13.8638 2 13.3333V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H5.33333C5.70152 2 6 2.29848 6 2.66667C6 3.03486 5.70152 3.33333 5.33333 3.33333H4Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.6665 2.00002C4.6665 1.26364 5.26346 0.666687 5.99984 0.666687H9.99984C10.7362 0.666687 11.3332 1.26364 11.3332 2.00002V3.33335C11.3332 4.06973 10.7362 4.66669 9.99984 4.66669H5.99984C5.26346 4.66669 4.6665 4.06973 4.6665 3.33335V2.00002ZM9.99984 2.00002H5.99984V3.33335H9.99984V2.00002Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1289 6.85314C11.3941 7.10853 11.4021 7.53057 11.1467 7.79578L7.29487 11.7958C7.1692 11.9263 6.99583 12 6.81465 12C6.63348 12 6.46011 11.9263 6.33444 11.7958L4.85296 10.2573C4.59757 9.99211 4.60553 9.57007 4.87074 9.31468C5.13596 9.05929 5.55799 9.06725 5.81339 9.33246L6.81465 10.3722L10.1863 6.87093C10.4417 6.60571 10.8637 6.59775 11.1289 6.85314Z"
    />
  </svg>
);

Disclaimer.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};
export default compose(injectIntl)(Disclaimer);
