import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';
import { useTheme } from '@mui/styles';

function CircleCheck({ bgColor, ...props }) {
  const theme = useTheme();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>
        {props.title || props.intl.formatMessage(globalMessages.check)}
      </title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
        fill={bgColor || theme.palette.secondary.main}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0404 7.29289C18.4309 7.68342 18.4309 8.31658 18.0404 8.70711L10.7071 16.0404C10.3165 16.431 9.68338 16.431 9.29285 16.0404L5.95952 12.7071C5.56899 12.3166 5.56899 11.6834 5.95952 11.2929C6.35004 10.9024 6.98321 10.9024 7.37373 11.2929L9.99996 13.9191L16.6262 7.29289C17.0167 6.90237 17.6499 6.90237 18.0404 7.29289Z"
        fill="white"
      />
    </svg>
  );
}
CircleCheck.propTypes = {
  title: PropTypes.string,
  bgColor: PropTypes.string,
  intl: intlShape.isRequired,
};

CircleCheck.defaultProps = {
  title: '',
};

export default injectIntl(CircleCheck);
