import React from 'react';
import PropTypes from 'prop-types';

export const Meetings = props => (
  <svg
    width={props.size || 12}
    height={props.size || 13}
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 1.125C7.20711 1.125 7.375 1.29289 7.375 1.5V4.125H10C10.2071 4.125 10.375 4.29289 10.375 4.5C10.375 4.70711 10.2071 4.875 10 4.875H7C6.79289 4.875 6.625 4.70711 6.625 4.5V1.5C6.625 1.29289 6.79289 1.125 7 1.125Z"
      fill="current"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.02773 1.52773C2.28559 1.26987 2.63533 1.125 3 1.125H7C7.09946 1.125 7.19484 1.16451 7.26517 1.23483L10.2652 4.23484C10.3355 4.30516 10.375 4.40054 10.375 4.5V10.5C10.375 10.8647 10.2301 11.2144 9.97227 11.4723C9.71441 11.7301 9.36467 11.875 9 11.875H3C2.63533 11.875 2.28559 11.7301 2.02773 11.4723C1.76987 11.2144 1.625 10.8647 1.625 10.5V2.5C1.625 2.13533 1.76987 1.78559 2.02773 1.52773ZM3 1.875C2.83424 1.875 2.67527 1.94085 2.55806 2.05806C2.44085 2.17527 2.375 2.33424 2.375 2.5V10.5C2.375 10.6658 2.44085 10.8247 2.55806 10.9419C2.67527 11.0592 2.83424 11.125 3 11.125H9C9.16576 11.125 9.32473 11.0592 9.44194 10.9419C9.55915 10.8247 9.625 10.6658 9.625 10.5V4.65533L6.84467 1.875H3Z"
      fill="current"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99875 5.99875V7.22347H5.22347V5.99875H3.99875ZM3.7381 5.5H5.48413C5.61562 5.5 5.72222 5.6066 5.72222 5.7381V7.48413C5.72222 7.61562 5.61562 7.72222 5.48413 7.72222H3.73809C3.6066 7.72222 3.5 7.61562 3.5 7.48413V5.7381C3.5 5.6066 3.6066 5.5 3.7381 5.5Z"
      fill="current"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.77653 5.99875V7.22347H8.00125V5.99875H6.77653ZM6.51587 5.5H8.26191C8.3934 5.5 8.5 5.6066 8.5 5.7381V7.48413C8.5 7.61562 8.3934 7.72222 8.26191 7.72222H6.51587C6.38438 7.72222 6.27778 7.61562 6.27778 7.48413V5.7381C6.27778 5.6066 6.38438 5.5 6.51587 5.5Z"
      fill="current"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.77653 8.77653V10.0013H8.00125V8.77653H6.77653ZM6.51587 8.27778H8.26191C8.3934 8.27778 8.5 8.38438 8.5 8.51587V10.2619C8.5 10.3934 8.3934 10.5 8.26191 10.5H6.51587C6.38438 10.5 6.27778 10.3934 6.27778 10.2619V8.51587C6.27778 8.38438 6.38438 8.27778 6.51587 8.27778Z"
      fill="current"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99875 8.77653V10.0013H5.22347V8.77653H3.99875ZM3.7381 8.27778H5.48413C5.61562 8.27778 5.72222 8.38438 5.72222 8.51587V10.2619C5.72222 10.3934 5.61562 10.5 5.48413 10.5H3.73809C3.6066 10.5 3.5 10.3934 3.5 10.2619V8.51587C3.5 8.38438 3.6066 8.27778 3.7381 8.27778Z"
      fill="current"
    />
  </svg>
);

Meetings.propTypes = {
  size: PropTypes.number,
}
