import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';

import { ListItem, ListItemIcon, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

import CircleCheck from 'components/SvgComponents/icons/CircleCheck';
import CircleCheckEmpty from 'components/SvgComponents/icons/CircleCheckEmpty';
import SlidersSvg from 'components/SvgComponents/icons/Sliders';

export const styles = theme => ({
  items: {
    display: 'flex',
    height: 40,
    boxShadow: 'inset 0px -0.5px 0px rgba(132, 143, 157, 0.2)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.primary,
    },
  },
  addedIcons: {
    color: theme.palette.secondary.main,
  },
  finalcadProject: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1px 6px',
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.main,
  },
  textName: {
    marginLeft: theme.spacing(2),
  },
  listItemIcon: {
    marginRight: 0,
  },
});

function ListTemplateAdd(props) {
  const { classes, items, onClick } = props;
  return (
    <>
      {items.map(template => (
        <ListItem
          onClick={onClick(template)}
          key={`${template.id}`}
          button
          className={classes.items}
        >
          <ListItemIcon
            classes={{
              root: classes.listItemIcon,
            }}
          >
            {template.is_already_in_project ? (
              <CircleCheck
                height={16}
                width={16}
                className={classes.addedIcons}
              />
            ) : (
              <CircleCheckEmpty height={16} width={16} />
            )}
          </ListItemIcon>
          <SlidersSvg />
          <Typography className={classes.textName} variant="bodyLight" noWrap>
            {template.name}
          </Typography>
        </ListItem>
      ))}
    </>
  );
}

ListTemplateAdd.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
};

ListTemplateAdd.defaultProps = {
  items: [],
};

export default compose(withStyles(styles))(ListTemplateAdd);
