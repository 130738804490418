import {
  SET_CURRENT_CLICK_POSITION,
  SET_MENU_DISPLAY,
  CLEAR_MENU,
  SET_MENU_REFERENCE,
} from './constants';

export function setCurrentClickPosition(position) {
  return {
    type: SET_CURRENT_CLICK_POSITION,
    position,
  };
}

export function setMenuDisplay(display) {
  return {
    type: SET_MENU_DISPLAY,
    display,
  };
}

export function clearContextMenu() {
  return {
    type: CLEAR_MENU,
  };
}

export function setMenuReference(reference) {
  return {
    type: SET_MENU_REFERENCE,
    reference,
  };
}
