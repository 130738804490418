/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { useEffect, useState, useMemo } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { compose } from 'redux';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import infiniteTableSagaV3 from 'components/InfiniteTableSelfStandingV3/saga';
import tableDragDropSaga from 'components/TableDragDropSelfStanding/saga';
import ErrorHandler from 'containers/ErrorHandler';
import MessageHandler from 'containers/MessageHandler';
import { ColorModeContext } from 'contexts/ColorModeProvider';
import theme from 'theme';
import { colorTheme } from 'utils/constants';
import injectSaga from 'utils/injectSaga';
import localStorageUser from 'utils/localStorageUser';

import firebase from '../../firebase';
import GlobalWrapper from '../GlobalWrapper';
import AppWithRouterAccess from './AppWithRouterAccess';
import saga from './saga';

import './global-styles.css';

export function App() {
  const storageFlags = localStorageUser.getFirebaseFeatureStorage();

  const dark_mode = storageFlags?.web_dark_mode || false;

  const themePreference = dark_mode ? localStorageUser.getThemePreference() : colorTheme.light;

  const [mode, setMode] = useState(themePreference);
  document.firstElementChild.setAttribute('color-scheme', themePreference);

  const colorMode = useMemo(
    () => ({
      mode,
      toggleColorMode: () => {
        setMode(prevMode => {
          const newMode =
            prevMode === colorTheme.light && dark_mode ? colorTheme.dark : colorTheme.light;
          localStorageUser.setThemePreference(newMode);
          document.firstElementChild.setAttribute('color-scheme', newMode);
          return newMode;
        });
      },
    }),
    [mode],
  );

  useEffect(() => {
    if (firebase.analytics) {
      firebase.analytics();
    }
    if (firebase.performance) {
      firebase.performance();
    }
  }, []);

  const currentTheme = theme(mode);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <StyledThemeProvider theme={currentTheme}>
        <ThemeProvider theme={currentTheme}>
          <ErrorHandler />
          <MessageHandler />
          <GlobalWrapper>
            <Router>
              <AppWithRouterAccess />
            </Router>
          </GlobalWrapper>
        </ThemeProvider>
      </StyledThemeProvider>
    </ColorModeContext.Provider>
  );
}

const withSaga = injectSaga({ key: 'appSaga', saga });
// the following sagas are injected here because we use their containers in multiple places`
// and it will inject saga multiple times resulting in multiple calls to saga at a singe event

const withDragDropSaga1 = injectSaga({
  key: 'tableDragDrop1',
  saga: tableDragDropSaga('tableDragDrop1'),
});

const withDragDropSaga2 = injectSaga({
  key: 'tableDragDrop2',
  saga: tableDragDropSaga('tableDragDrop2'),
});

const withTableSagaV3 = injectSaga({
  key: 'table',
  saga: infiniteTableSagaV3('table'),
});
const withTable1SagaV3 = injectSaga({
  key: 'table_1',
  saga: infiniteTableSagaV3('table_1'),
});

const withTable2SagaV3 = injectSaga({
  key: 'table_2',
  saga: infiniteTableSagaV3('table_2'),
});

const withTableSagaUsers = injectSaga({
  key: 'users',
  saga: infiniteTableSagaV3('users'),
});

const withFilter1SagaV3 = injectSaga({
  key: 'filter1',
  saga: infiniteTableSagaV3('filter1'),
});

const withFilter2SagaV3 = injectSaga({
  key: 'filter2',
  saga: infiniteTableSagaV3('filter2'),
});
const withFilter3SagaV3 = injectSaga({
  key: 'filter3',
  saga: infiniteTableSagaV3('filter3'),
});
const withFilter4SagaV3 = injectSaga({
  key: 'filter4',
  saga: infiniteTableSagaV3('filter4'),
});

export default compose(
  withSaga,
  withTableSagaUsers,
  withTableSagaV3,
  withTable1SagaV3,
  withTable2SagaV3,
  withFilter1SagaV3,
  withFilter2SagaV3,
  withFilter3SagaV3,
  withFilter4SagaV3,
  withDragDropSaga1,
  withDragDropSaga2,
)(App);
