import React from 'react';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import FCList from 'components/FCList';
import Key from 'components/SvgComponents/icons/Key';
import globalMessages from 'translations/messages/global-messages';

function ApiKeyGenerator(props) {
  const { intl, onCloseDropdown, onClickGenerate } = props;
  const theme = useTheme();

  return (
    <div>
      <FCList.ListItemButton
        onClick={onClickGenerate}
        onCloseDropdown={onCloseDropdown}
        title={intl.formatMessage(globalMessages.api_key)}
        aria-label={intl.formatMessage(globalMessages.api_key)}
      >
        <Key
          height={16}
          width={16}
          color={theme.palette.text.primary}
          style={{ marginRight: '8px' }}
        />
        <Typography variant="body">{intl.formatMessage(globalMessages.api_key)}</Typography>
      </FCList.ListItemButton>
    </div>
  );
}

ApiKeyGenerator.propTypes = {
  intl: intlShape.isRequired,
  onClickGenerate: PropTypes.func,
  onCloseDropdown: PropTypes.func,
};

export default compose(injectIntl)(ApiKeyGenerator);
