import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import combineStyles from 'theme/combineStyles';
import formEngineStyle from 'theme/formEngine-theme';

import RenderSwitch from 'components/reduxFormComponentsV3/SwitchRenderForm';
import { Field, reduxForm } from 'redux-form/immutable';

const styles = theme => ({
  input: {
    color: theme.palette.text.dark,
    background: theme.palette.background.primary,
    borderRadius: 12,
    padding: '0.625em',
    border: '1px transparent solid',
  },
  root: {
    width: '100%',
    color: theme.palette.text.dark,
    fontSize: '1rem',
    background: theme.palette.background.primary,
    borderRadius: 12,
  },
});

class SwitchFieldForm extends React.Component {
  debounceOnChange = debounce(
    (event, value) =>
      this.props.onFieldChange
        ? this.props.onFieldChange({ form: this.props.form, event, value })
        : null,
    300,
  );
  render() {
    const {
      name,
      label,
      className,
      classes,
      checked,
      materialUIClasses,
      ...custom
    } = this.props;

    return (
      <Field
        name={name}
        classes={materialUIClasses || classes}
        component={RenderSwitch}
        checked={checked}
        onChange={this.debounceOnChange}
        label={label}
        className={className}
        {...custom}
      />
    );
  }
}
SwitchFieldForm.propTypes = {
  name: PropTypes.string.isRequired,
  form: PropTypes.string,
  checked: PropTypes.bool,
  classes: PropTypes.object,
  materialUIClasses: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.object,
  onFieldChange: PropTypes.func,
};

export default compose(
  withStyles(combineStyles(formEngineStyle, styles)),
  reduxForm({
    enableReinitialize: true,
    destroyOnUnmount: false,
  }),
)(SwitchFieldForm);
