import React, { useState } from 'react';

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import DownloadQRCodeImg from 'assets/images/download_qrcode.svg';
import Button from 'components/Button';
import Dialog from 'components/dialogs/Dialog';
import globalMessages from 'translations/messages/global-messages';
import { handleToggleDialog } from 'utils/dialog';

export const styles = () => ({
  dialog: {
    height: 500,
  },
  container: {
    padding: '3.5em 5.5em',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: '1.6em',
  },
  button: {
    borderRadius: 4,
  },
  img: {
    width: 200,
  },
});

export function DownloadMobileApp(props) {
  const { intl, classes } = props;
  const [activeDialog, setActiveDialog] = useState(false);

  const onClose = () => {
    handleToggleDialog(setActiveDialog, activeDialog)();
  };

  return (
    <>
      <Button
        onClick={handleToggleDialog(setActiveDialog, activeDialog)}
        variant="info"
        fullWidth
        className={classes.button}
      >
        {intl.formatMessage(globalMessages.get_mobile_app)}
      </Button>
      <Dialog
        id="dialog-download-app"
        open={activeDialog}
        fullWidth
        maxWidth="sm"
        onCloseDialog={onClose}
        dialogTitleNode={intl.formatMessage(globalMessages.get_mobile_app)}
        dialogClasses={{ paper: classes.dialog }}
        dialogActionNode={
          <Button onClick={onClose} key="no" variant="plain">
            {intl.formatMessage(globalMessages.close)}
          </Button>
        }
      >
        <div className={classes.container}>
          <Typography className={classes.title} align="center" component="h1">
            {intl.formatMessage(globalMessages.get_ios_android_app)}
          </Typography>
          <img
            className={classes.img}
            src={DownloadQRCodeImg}
            alt={intl.formatMessage(globalMessages.download_in_progress)}
          />
        </div>
      </Dialog>
    </>
  );
}

DownloadMobileApp.propTypes = {
  intl: intlShape,
  classes: PropTypes.object.isRequired,
};

export default compose(
  injectIntl,
  withStyles(styles),
)(DownloadMobileApp);
