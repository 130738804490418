import { SET_ERROR, SET_INFO } from './constants';

export function setGlobalError(error) {
  return {
    type: SET_ERROR,
    error,
  };
}

export function setGlobalInfo(info) {
  return {
    type: SET_INFO,
    info,
  };
}
