import React, { memo, useContext } from 'react';

import { Box, CssBaseline } from '@mui/material';
import Gleap from 'gleap';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { findIcon } from 'components/icon-picker';
import LeftNavigation from 'components/LeftNavigation';
import NavLinkListItem from 'components/LeftNavigation/NavLinkListItem';
import NavProjectLinkListItem from 'components/LeftNavigation/NavProjectLinkListItem';
import { Alert } from 'components/SvgComponents/icons/module';
import { OrganizationContext } from 'containers/GlobalWrapper/BusinessOrganizationDataValidation';
import { ProjectContext } from 'containers/GlobalWrapper/ProjectDataValidation';
import { selectModules, selectSpecialRights } from 'containers/GlobalWrapper/selectors';
import NavigationBar from 'containers/NavigationBar';
import { useFeatureFlags } from 'contexts/FeatureFlagsProvider';
import { Routing } from 'routing/routing';
import { appPaths, MODULE_TYPES, oneProjectPages } from 'utils/constants';
import { getTranslation } from 'utils/localization';

import {
  formPage,
  oneProjectPage,
  oneSuperAdminLibraryPage,
  oneSuperAdminPage,
  organizationPage,
  workspacePage,
} from '../config';

function ResponsiveLayoutComp({ intl, children, match }) {
  const businessOrganization = useContext(OrganizationContext);
  const project = useContext(ProjectContext);
  const isWelcomePage = match?.path?.includes('/welcome');
  const specialRights = useSelector(selectSpecialRights());
  const moduleList = useSelector(selectModules());

  const featureFlags = useFeatureFlags();
  const { path, params } = match || {};
  const { organizationId, idData } = params;

  const isProjectGroupsPage =
    path === appPaths.projectPage && params.pageName === oneProjectPages.groups;

  const shouldHideChat = isProjectGroupsPage;
  const getModuleUrl = module => {
    if (module?.type === MODULE_TYPES.forms) {
      return Routing.forms.list(organizationId, idData, module.id);
    }
    if (module?.type === MODULE_TYPES.observations) {
      return Routing.observations.list(organizationId, idData, module.id);
    }
    if (module?.type === MODULE_TYPES.meetings) {
      return Routing.meetings.list(organizationId, idData, module.id);
    }
  };

  if (shouldHideChat) {
    Gleap.showFeedbackButton(false);
  } else {
    Gleap.showFeedbackButton(true);
  }

  const getMenuOptions = () => {
    const isOrganizationProjectList = match?.path?.includes(appPaths.organizationProjectList);

    if (
      match &&
      (path.includes('/form/:formId') ||
        path.includes('/organization/:organizationId/form/:formId') ||
        path.includes('/organization/:organizationId/workspace/:workspaceId/form/:formId'))
    ) {
      const { workspaceId } = params;
      return {
        menuList: formPage(intl, organizationId, workspaceId),
        component: NavLinkListItem,
        name: 'one-form',
      };
    }
    if (match?.path?.includes('/organization/:organizationId/workspace/:workspaceId')) {
      return {
        menuList: workspacePage(intl, match, businessOrganization?.authorizations, featureFlags),
        component: NavLinkListItem,
        name: 'workspace',
      };
    }
    if (
      match?.path?.includes('/organization/:organizationId') &&
      !isOrganizationProjectList &&
      !match?.path?.includes('project/:idData')
    ) {
      return {
        menuList: organizationPage(intl, match, businessOrganization?.authorizations, featureFlags),
        component: NavLinkListItem,
        name: 'organization',
      };
    }
    if (match?.path?.includes('project/:idData')) {
      return {
        menuList: oneProjectPage({
          intl,
          match,
          projectRights: project?.authorizations,
          modules: moduleList
            ?.filter(module => module?.enabled)
            .map(({ id, project_id, name, names, icon, color, type }) => {
              const Icon = findIcon({ name: icon }) || Alert;
              return {
                key: id,
                url: getModuleUrl({ id, project_id, type }),
                label: getTranslation(names?.translations, name),
                icon: <Icon color={color} />,
                color,
              };
            })
            .sort((a, b) => a.label.localeCompare(b.label)),
          featureFlags,
        }),
        component: NavProjectLinkListItem,
        name: 'one-project',
      };
    }

    if (match && !params.libraryName && path.includes('/super-admin')) {
      return {
        menuList: oneSuperAdminPage(intl, specialRights),
        component: NavLinkListItem,
        name: 'one-super-admin',
      };
    }

    if (match && params.libraryName && path.includes('/super-admin/:pageName')) {
      return {
        menuList: oneSuperAdminLibraryPage(intl, specialRights, featureFlags),
        component: NavLinkListItem,
        name: 'one-super-admin-library',
      };
    }
    return {
      menuList: [],
      component: null,
      name: 'empty',
    };
  };

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flex: 'auto',
          flexDirection: 'column',
        }}
      >
        <>
          {!isWelcomePage && <NavigationBar />}
          <Box
            as="main"
            sx={{
              display: 'flex',
              flexDirection: isWelcomePage ? 'column' : 'row',
              position: 'relative',
              flexGrow: 1,
              overflow: 'hidden',
            }}
          >
            {!isWelcomePage && <LeftNavigation menuOptions={getMenuOptions()} />}
            {children}
          </Box>
        </>
      </Box>
    </>
  );
}

ResponsiveLayoutComp.propTypes = {
  children: PropTypes.node,
  intl: intlShape.isRequired,
  match: PropTypes.object,
};

export const ResponsiveLayout = memo(
  compose(
    withRouter,
    injectIntl,
  )(ResponsiveLayoutComp),
);
