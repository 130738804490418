import {
  AlertTriangle,
  Alert,
  BookOpen,
  Bricks,
  Brush,
  Calendar,
  Camera,
  CheckCircle,
  Clean,
  Clipboard,
  Clock,
  Draw,
  Environment,
  Euro,
  Eye,
  FilesAndFolders,
  Flag,
  Hammer,
  Helmet,
  Help,
  Info,
  Key,
  Measure,
  Parcel,
  People,
  Plan,
  Rail,
  Road,
  Search,
  Signature,
  Star,
  Truck,
  TypePin,
  Zap,
  Meetings,
} from '../SvgComponents/icons/module';

/**
 * Do not update the list of icons without asking the back and front team.
 * The list of ids are synchronized with different parts of the application.
 * And may lead to unexpected behavior if not updated in all places.
 */
export const moduleIcons = [
  {
    name: 'typePin',
    icon: TypePin,
    filledIcon: TypePin,
  },
  {
    name: 'alert',
    icon: Alert,
    filledIcon: Alert,
  },
  {
    name: 'clipboard',
    icon: Clipboard,
    filledIcon: Clipboard,
  },
  {
    name: 'bookOpen',
    icon: BookOpen,
    filledIcon: BookOpen,
  },
  {
    name: 'draw',
    icon: Draw,
    filledIcon: Draw,
  },
  {
    name: 'checkCircle',
    icon: CheckCircle,
    filledIcon: CheckCircle,
  },
  {
    name: 'filesAndFolders',
    icon: FilesAndFolders,
    filledIcon: FilesAndFolders,
  },
  {
    name: 'people',
    icon: People,
    filledIcon: People,
  },
  {
    name: 'alertTriangle',
    icon: AlertTriangle,
    filledIcon: AlertTriangle,
  },
  {
    name: 'measure',
    icon: Measure,
    filledIcon: Measure,
  },
  {
    name: 'plan',
    icon: Plan,
    filledIcon: Plan,
  },
  {
    name: 'camera',
    icon: Camera,
    filledIcon: Camera,
  },
  {
    name: 'clock',
    icon: Clock,
    filledIcon: Clock,
  },
  {
    name: 'calendar',
    icon: Calendar,
    filledIcon: Calendar,
  },
  {
    name: 'flag',
    icon: Flag,
    filledIcon: Flag,
  },
  {
    name: 'eye',
    icon: Eye,
    filledIcon: Eye,
  },
  {
    name: 'help',
    icon: Help,
    filledIcon: Help,
  },
  {
    name: 'info',
    icon: Info,
    filledIcon: Info,
  },
  {
    name: 'key',
    icon: Key,
    filledIcon: Key,
  },
  {
    name: 'signature',
    icon: Signature,
    filledIcon: Signature,
  },
  {
    name: 'rail',
    icon: Rail,
    filledIcon: Rail,
  },
  {
    name: 'road',
    icon: Road,
    filledIcon: Road,
  },
  {
    name: 'zap',
    icon: Zap,
    filledIcon: Zap,
  },
  {
    name: 'euro',
    icon: Euro,
    filledIcon: Euro,
  },
  {
    name: 'search',
    icon: Search,
    filledIcon: Search,
  },
  {
    name: 'truck',
    icon: Truck,
    filledIcon: Truck,
  },
  {
    name: 'parcel',
    icon: Parcel,
    filledIcon: Parcel,
  },
  {
    name: 'star',
    icon: Star,
    filledIcon: Star,
  },
  {
    name: 'helmet',
    icon: Helmet,
    filledIcon: Helmet,
  },
  {
    name: 'clean',
    icon: Clean,
    filledIcon: Clean,
  },
  {
    name: 'environment',
    icon: Environment,
    filledIcon: Environment,
  },
  {
    name: 'brush',
    icon: Brush,
    filledIcon: Brush,
  },
  {
    name: 'hammer',
    icon: Hammer,
    filledIcon: Hammer,
  },
  {
    name: 'bricks',
    icon: Bricks,
    filledIcon: Bricks,
  },
  {
    name: 'meetings',
    icon: Meetings,
    filledIcon: Meetings,
  },
];

export const findIcon = ({ name = 'alert' }) =>
  moduleIcons.find(icon => icon.name === name)?.icon || Alert;

export const findFilledIcon = ({ name = 'alert' }) =>
  moduleIcons.find(icon => icon.name === name)?.filledIcon || Alert;
