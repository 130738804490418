import { call, put, select, takeEvery } from 'redux-saga/effects';

import Config from 'config/config';
import { selectAuthorsMap } from 'containers/GlobalWrapper/globalStore/users/selectors';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import request from 'utils/request';

import { setAuthor } from './actions';
import { GET_AUTHOR } from './constants';

function* fetchUser({ authorId }) {
  if (authorId) {
    const authors = yield select(selectAuthorsMap());
    const author = authors?.find(item => item.user_id === authorId);

    if (author) {
      return;
    }
    try {
      yield put(
        setAuthor({
          author: {
            user_id: authorId,
            isLoading: true,
          },
        }),
      );

      const requestURL = `${checkOrSetSlash(
        Config.apiHostGateway,
        'apiHostGateway',
      )}api/user-infos/${authorId}`;
      const options = {
        method: 'GET',
        headers: {
          'Cache-Control': 'No-Store',
          'Content-Type': 'application/json',
        },
      };
      const data = yield call(request, requestURL, options);
      if (data.message) {
        throw new Error(data.message);
      }
      yield put(setAuthor({ author: data }));
    } catch (error) {
      console.error(error);
    }
  }
}

export default function* initUsersSaga() {
  yield takeEvery(GET_AUTHOR, fetchUser);
}
