import React from 'react';

import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import Minus from 'components/SvgComponents/icons/Minus';
import Plus from 'components/SvgComponents/icons/Plus';

import { ActionButton } from './action-button';

export const ZoomControls = ({
  zoomIn,
  zoomOut,
  zoomOutIsDisabled = false,
  btnOrientation = 'column',
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: btnOrientation,
      justifyContent: 'center',
      gap: 3,
      zIndex: 9999,
    }}
  >
    <ActionButton onClick={zoomIn}>
      <Plus />
    </ActionButton>

    <ActionButton disabled={zoomOutIsDisabled} onClick={zoomOut}>
      <Minus />
    </ActionButton>
  </Box>
);

ZoomControls.propTypes = {
  zoomIn: PropTypes.func.isRequired,
  zoomOut: PropTypes.func.isRequired,
  zoomOutIsDisabled: PropTypes.bool,
  btnOrientation: PropTypes.string,
};
