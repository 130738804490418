import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import DetailImageCellSkeleton from 'components/SkeletonScreen/components/DetailImageCellSkeleton';
import ObservationItem from 'containers/OneProjectPage/ObservationsPage/components/ObservationItem';

export const ObservationCell = props => {
  const { loading, observation } = props;

  if (loading && isEmpty(observation)) {
    return <DetailImageCellSkeleton />;
  }

  if (!loading && isEmpty(observation)) {
    return <div>-</div>;
  }

  return <ObservationItem observation={observation} />;
};

ObservationCell.propTypes = {
  loading: PropTypes.bool,
  observation: PropTypes.object,
};

export default ObservationCell;
