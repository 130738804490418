import { GET_TRADE, SET_TRADE } from './constants';

export function getTrade(params, id, callback) {
  return {
    type: GET_TRADE,
    id,
    callback,
    params,
  };
}

export function setTrade(id, trade) {
  return {
    type: SET_TRADE,
    id,
    trade,
  };
}
