import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { OrganizationContext } from 'containers/GlobalWrapper/BusinessOrganizationDataValidation';

import globalMessages from 'translations/messages/global-messages';

import CustomizedSnackbar from 'components/Snackbar';
import Copy from 'components/SvgComponents/icons/Copy';

const styles = ({ spacing, palette }) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${spacing(2)}`,
    borderRadius: 12,
    color: palette.text.primary,
    textTransform: 'none',

    '&:hover': {
      color: '#172134',
    },

    '& .MuiButton-endIcon': {
      margin: 0,
    },
  },

  text: {
    fontSize: '0.75rem',
    color: 'currentColor',
    whiteSpace: 'pre-wrap',
  },
});

const OrganizationPublicID = ({ classes, intl }) => {
  const [notificationOpen, setNotificationOpen] = useState(false);

  const organization = useContext(OrganizationContext);
  const toggleNotification = () => setNotificationOpen(!notificationOpen);

  const handleCopy = () => {
    navigator.clipboard.writeText(organization?.public_id);
    toggleNotification();
  };

  return (
    <Button
      className={classes.wrapper}
      onClick={handleCopy}
      endIcon={<Copy height="11" width="11" className={classes.icon} />}
    >
      <Typography className={classes.text}>
        {organization?.public_id}
      </Typography>

      <CustomizedSnackbar
        horizontalPosition="left"
        variant="success"
        onClose={toggleNotification}
        open={notificationOpen}
        autoHideDuration={6000}
        message={intl.formatMessage(globalMessages.copied_to_clipboard)}
      />
    </Button>
  );
};

OrganizationPublicID.propTypes = {
  classes: PropTypes.object,
  intl: intlShape.isRequired,
};

export default compose(
  injectIntl,
  withStyles(styles),
)(OrganizationPublicID);
