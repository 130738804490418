import React from 'react';
import PropTypes from 'prop-types';

import { injectIntl, intlShape } from 'react-intl';

import EmptyTable from 'components/EmptyTable';

import { Typography } from '@mui/material';
import globalMessages from 'translations/messages/global-messages';
function NoResultsFound(props) {
  const { intl, details } = props;

  return (
    <>
      <EmptyTable
        title={
          <Typography
            variant="body"
            component="p"
            align="center"
            gutterBottom
            paragraph
          >
            {intl.formatMessage(globalMessages.no_result_found)}
          </Typography>
        }
        subtitle={
          <Typography
            component="p"
            variant="subtitleLight"
            align="center"
            gutterBottom
            paragraph
          >
            {details}
          </Typography>
        }
      />
    </>
  );
}

NoResultsFound.propTypes = {
  intl: intlShape.isRequired,
  details: PropTypes.string,
};

export default injectIntl(NoResultsFound);
