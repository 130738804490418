import React from 'react';
import PropTypes from 'prop-types';

export const Measure = props => (
  <svg
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.70711 3.29289C6.09763 3.68342 6.09763 4.31658 5.70711 4.70711L4.41421 6H19.5858L18.2929 4.70711C17.9024 4.31658 17.9024 3.68342 18.2929 3.29289C18.6834 2.90237 19.3166 2.90237 19.7071 3.29289L22.7071 6.29289C23.0976 6.68342 23.0976 7.31658 22.7071 7.70711L19.7071 10.7071C19.3166 11.0976 18.6834 11.0976 18.2929 10.7071C17.9024 10.3166 17.9024 9.68342 18.2929 9.29289L19.5858 8H4.41421L5.70711 9.29289C6.09763 9.68342 6.09763 10.3166 5.70711 10.7071C5.31658 11.0976 4.68342 11.0976 4.29289 10.7071L1.29289 7.70711C0.902369 7.31658 0.902369 6.68342 1.29289 6.29289L4.29289 3.29289C4.68342 2.90237 5.31658 2.90237 5.70711 3.29289ZM12 15C12.5523 15 13 15.4477 13 16V19H16V18C16 17.4477 16.4477 17 17 17C17.5523 17 18 17.4477 18 18V19H21V16C21 15.4477 21.4477 15 22 15C22.5523 15 23 15.4477 23 16V20C23 20.5523 22.5523 21 22 21H2C1.44772 21 1 20.5523 1 20V16C1 15.4477 1.44772 15 2 15C2.55228 15 3 15.4477 3 16V19H6V18C6 17.4477 6.44772 17 7 17C7.55228 17 8 17.4477 8 18V19H11V16C11 15.4477 11.4477 15 12 15Z"
      fill="current"
    />
  </svg>
);

Measure.propTypes = {
  size: PropTypes.number,
}
