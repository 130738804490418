import React from 'react';

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import CardMedia from 'components/CardMedia';
import DetailImageCellSkeleton from 'components/SkeletonScreen/components/DetailImageCellSkeleton';
import Bim from 'components/SvgComponents/icons/Bim';
import Dwg from 'components/SvgComponents/icons/Dwg';
import Layers from 'components/SvgComponents/icons/Layers';
import Pdf from 'components/SvgComponents/icons/Pdf';
import Plans from 'components/SvgComponents/icons/Plans';
import globalMessages from 'translations/messages/global-messages';
import { locationType } from 'utils/constants';

export const styles = theme => ({
  nameWrap: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  ellipsisFix: {
    tableLayout: 'fixed',
    width: '100%',
    overflow: 'hidden',
    paddingRight: theme.spacing(2),
  },
  planInfoWrap: {
    '& svg': {
      marginRight: theme.spacing(),
    },
    display: 'flex',
  },
  imageWrap: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    marginRight: 12,
    objectFit: 'cover',
    color: theme.palette.text.primary,
    height: 32,
    width: 32,
    minWidth: 32,
  },
  iconWrap: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    marginRight: 12,
    objectFit: 'cover',
    color: theme.palette.text.secondary,
    height: 32,
    width: 32,
    padding: theme.spacing(),
  },
  iconNameBim: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.info.main,
  },
  iconNamePlan: {
    display: 'flex',
    alignItems: 'center',
  },
  deletedTag: {
    marginLeft: theme.spacing(1),
  },
});

export const PlanFolderCell = props => {
  const { classes, item, loading, intl } = props;
  const {
    name,
    type,
    thumbnail_url,
    thumbnail_mime_type,
    media_resource_name,
    media_resource_mime_type,
    media_resource_url,
  } = item;

  const renderImage = () => {
    const isPdf =
      thumbnail_mime_type?.includes('application/pdf') ||
      media_resource_mime_type?.includes('application/pdf');
    const isDwg =
      thumbnail_mime_type?.includes('application/acad') ||
      media_resource_name?.includes('.dwg') ||
      media_resource_name?.includes('acad');
    if (type === locationType.bim) {
      return <Bim className={classes.iconWrap} />;
    }
    if ((thumbnail_url || media_resource_url) && !isPdf && !isDwg) {
      return (
        <CardMedia
          component="img"
          alt={name}
          componentProps={{
            className: classes.imageWrap,
          }}
          src={thumbnail_url || media_resource_url}
          title={name}
          type={name}
        />
      );
    }
    if (isPdf && !thumbnail_url) {
      return <Pdf className={classes.iconWrap} />;
    }
    if (thumbnail_url && isDwg) {
      return <Dwg className={classes.iconWrap} />;
    }
    if (type === 'plan') {
      return <Plans className={classes.iconWrap} />;
    }

    return <Layers className={classes.iconWrap} />;
  };

  const getTypeIcon = type => {
    if (type === locationType.bim) {
      return <Bim width={8} height={8} color="inherit" />;
    }
    if (type === locationType.plan) {
      return <Plans width={8} height={8} color="inherit" />;
    }
    return <Layers width={8} height={8} color="inherit" />;
  };

  const getTypeName = type => {
    if (type === locationType.bim) {
      return 'Bim';
    }
    if (type === locationType.plan) {
      return intl.formatMessage(globalMessages.plans);
    }
    return intl.formatMessage(globalMessages.folder);
  };

  const getIconNameClass = type => {
    if (type === locationType.bim) {
      return classes.iconNameBim;
    }
    return classes.iconNamePlan;
  };

  if (loading) {
    return <DetailImageCellSkeleton />;
  }

  return (
    <div className={classes.nameWrap}>
      {renderImage(item, classes)}
      <div className={classes.ellipsisFix}>
        <Typography noWrap component="span" variant="h6">
          {name}
        </Typography>
        {!item.enabled && (
          <Typography component="span" variant="subtitleLight" className={classes.deletedTag}>
            {intl.formatMessage(globalMessages.deleted)}
          </Typography>
        )}
        <Typography className={classes.planInfoWrap} component="div" noWrap variant="subtitleLight">
          <span className={getIconNameClass(item.type)}>
            {getTypeIcon(item.type)}
            {getTypeName(item.type)}
          </span>
        </Typography>
      </div>
    </div>
  );
};

PlanFolderCell.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default compose(
  injectIntl,
  withStyles(styles),
)(PlanFolderCell);
