import { scope } from '../../constants';

export const GET_COMMON_OBSERVATION = `${scope}GET_COMMON_OBSERVATION`;
export const SET_COMMON_OBSERVATION = `${scope}SET_COMMON_OBSERVATION`;
export const GET_OBSERVATION_PRIORITY = `${scope}GET_OBSERVATION_PRIORITY`;
export const SET_OBSERVATION_PRIORITY = `${scope}SET_OBSERVATION_PRIORITY`;
export const GET_OBSERVATION_STATUS = `${scope}GET_OBSERVATION_STATUS`;
export const SET_OBSERVATION_STATUS = `${scope}SET_OBSERVATION_STATUS`;
export const GET_OBSERVATION = `${scope}GET_OBSERVATION`;
export const SET_OBSERVATION = `${scope}SET_OBSERVATION`;
export const REMOVE_OBSERVATION = `${scope}REMOVE_OBSERVATION`;
export const GET_OBSERVATION_PHASE = `${scope}GET_OBSERVATION_PHASE`;
export const SET_OBSERVATION_PHASE = `${scope}SET_OBSERVATION_PHASE`;
