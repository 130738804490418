/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// wdyr needs to be imported first, see: https://github.com/welldone-software/why-did-you-render
/* eslint-disable import/first */
/* eslint-disable no-restricted-globals */
// import './config/wdyr';

// Needed for redux-saga es6 generator support
import '@babel/polyfill';

// Import all the third party stuff
import React from 'react';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ConnectedRouter } from 'connected-react-router/immutable';
import Gleap from 'gleap';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import config from 'config/config';
import App from 'containers/App';
import LanguageProvider from 'containers/LanguageProvider';
import history from 'utils/history';

import 'sanitize.css/sanitize.css';
import 'typeface-roboto'; // eslint-disable-line

// Import root app

// Import Language Provider
import localStorageUser from 'utils/localStorageUser';

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./assets/images/favicon.ico';
import 'file-loader?name=[name].[ext]!./.htaccess';
/* eslint-enable import/no-unresolved, import/extensions */

// Import i18n messages
import { translationMessages } from './i18n';

// Import CSS reset and Global Styles
import './global-styles';
import packageJson from '../package.json';

import { LicenseInfo } from '@mui/x-license-pro';

import configureStoreFC from './configureStore';

LicenseInfo.setLicenseKey(
  '7f87c1e89b8de9472212bd4319259098Tz05OTk1NCxFPTE3NjA1MzI1NDAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg==',
);

const initialState = {};
const store = configureStoreFC(initialState, history);
window.finalcadHistory = history;
window.finalcadReduxStore = store;
window.blobStore = {};
window.chunkBlobStore = {};
window.contextMenuStore = {};

const container = document.getElementById('app');
const root = createRoot(container);

const oktaToken = localStorageUser.getOktaTokenStorage();
const userEmail = oktaToken?.idToken?.claims?.email || oktaToken?.accessToken?.claims?.sub;
const userId = oktaToken?.accessToken?.claims?.fcUserId;

if (config.sentry.environment !== 'localhost') {
  Sentry.init({
    dsn: config.sentry.dsn,
    dist: `${process.env.SENTRY_PROJECT}-dist`,
    release: `${process.env.SENTRY_PROJECT}@${packageJson.version}`,
    integrations: [
      new Integrations.BrowserTracing({
        beforeNavigate: context => ({
          ...context,
          name: location.pathname
            .replace(
              /\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g,
              '/<uuid>',
            )
            .replace(
              /\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g,
              '/<uuid>',
            ),
        }),
      }),
    ],
    environment: config.sentry.environment,
    tracesSampleRate: 1.0,
  });
}

Sentry.setUser({
  id: userId,
  email: userEmail,
});

if (process.env.NODE_ENV !== 'development') {
  Gleap.initialize(config.gleap.appToken);
}

const customerIoScript = document.createElement('script');
customerIoScript.innerHTML = `var _cio = _cio || [];
(function() {
  var a,b,c;a=function(f){return function(){_cio.push([f].
  concat(Array.prototype.slice.call(arguments,0)))}};b=["load","identify",
  "sidentify","track","page","on","off"];for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])};
  var t = document.createElement('script'),
      s = document.getElementsByTagName('script')[0];
  t.async = true;
  t.id    = 'cio-tracker';
  t.setAttribute('data-site-id', '${config.customerIo.siteId}');
  t.setAttribute('data-use-in-app', 'true');
  t.src = 'https://assets.customer.io/assets/track-eu.js';
  s.parentNode.insertBefore(t, s);
})();`;
document.head.appendChild(customerIoScript);

const render = messages => {
  root.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <LanguageProvider messages={messages}>
          <App />
        </LanguageProvider>
      </ConnectedRouter>
    </Provider>,
  );
};

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  Promise.resolve(import('intl'))
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}
