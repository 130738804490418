import React from 'react';

import { Box, IconButton } from '@mui/material';
import PropTypes from 'prop-types';

export const ActionButton = ({ onClick, children, disabled = false }) => (
  <Box
    as={IconButton}
    disabled={disabled}
    sx={{
      height: 32,
      width: 32,
      padding: 0,
      backgroundColor: 'background.white',
      color: 'text.dark',
      borderRadius: '50%',
      boxShadow: `0px 4.8px 12px rgba(23, 33, 52, 0.15)`,
      cursor: 'pointer',
    }}
    onClick={onClick}
  >
    {children}
  </Box>
);

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};
