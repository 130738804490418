import {
  GET_FORM,
  GET_FORM_PHASE,
  GET_FORM_PRIORITY,
  GET_FORM_STATUS,
  REMOVE_FORM,
  SET_FORM,
  SET_FORM_PHASE,
  SET_FORM_PRIORITY,
  SET_FORM_STATUS,
} from './constants';

export function getFormStatus(params, id, form_id, callback) {
  return {
    type: GET_FORM_STATUS,
    id,
    form_id,
    callback,
    params,
  };
}

export function setFormStatus(id, status) {
  return {
    type: SET_FORM_STATUS,
    id,
    status,
  };
}

export function getForm(params, id, callback) {
  return {
    type: GET_FORM,
    id,
    callback,
    params,
  };
}

export function setForm(id, form) {
  return {
    type: SET_FORM,
    id,
    form,
  };
}

export function removeForm(id) {
  return {
    type: REMOVE_FORM,
    id,
  };
}

export function getFormPriority(params, id, callback) {
  return {
    type: GET_FORM_PRIORITY,
    id,
    callback,
    params,
  };
}

export function setFormPriority(id, priority) {
  return {
    type: SET_FORM_PRIORITY,
    id,
    priority,
  };
}

export function getFormPhase(params, id, callback) {
  return {
    type: GET_FORM_PHASE,
    id,
    callback,
    params,
  };
}

export function setFormPhase(id, phase) {
  return {
    type: SET_FORM_PHASE,
    id,
    phase,
  };
}
