import { injectGlobal } from 'styled-components';
/* eslint no-unused-expressions: 0 */
injectGlobal`
  html,
  body {
    height: 100%;
    width: 100%;
  }
  body {
    font-family: 'Effra', 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    display: block;
    position: relative;
    overflow: hidden;
    margin: 0;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Effra', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  }

  #app {
    background-color: #ffffff;
    height: 100%;
  }
  [color-scheme="dark"] #app {
    background:  #172134
  }

  input::-ms-clear {
   display: none;
  }
  svg {
    flex-shrink: 0;
  }
  .scrollbarWidthCalculator {
    width: 100px;
    height: 100px;
    overflow: scroll;
    position: absolute;
    top: -9999px;
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }

  .MuiAutocomplete-noOptions {
    color: #848F9D;
  }
`;
