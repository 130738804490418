import React, { useEffect, useRef, useState } from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';
import { reduxForm } from 'redux-form/immutable';

import Button from 'components/Button';
import Mail from 'components/SvgComponents/icons/Mail';
import VerifyCode from 'components/VerifyCode';
import globalMessages from 'translations/messages/global-messages';
import { handleToggleDialog } from 'utils/dialog';
import localStorageUser from 'utils/localStorageUser';

export const styles = theme => ({
  icon: {
    transition: 'transform 250ms',
  },
  iconOpen: {
    transform: 'rotate(180deg)',
  },
  '@media (prefers-reduced-motion: reduce)': {
    icon: {
      transition: 'transform 0ms',
    },
  },
  menuPaper: {
    borderRadius: 12,
    boxShadow: theme.shadows[2],
  },
  item: {
    display: 'flex',
    gap: '0.5rem',
  },
});

export function VerifyEmailButton(props) {
  const { intl, classes, userInfo } = props;
  const { email_validated } = userInfo;
  const [activeDialog, setActiveDialog] = useState(false);
  const buttonRef = useRef(null);
  const [validationData, setValidationData] = useState(null);

  useEffect(() => {
    const emailVerificationLink = localStorageUser.getEmailVerificationLink();
    if (emailVerificationLink) {
      setValidationData(emailVerificationLink);
      setActiveDialog(true);
    }
    localStorageUser.setEmailVerificationLink(null);
    return () => setValidationData(null);
  }, []);
  const onVerifyClick = () => {
    handleToggleDialog(setActiveDialog, activeDialog)();
  };

  return (
    !email_validated && (
      <>
        <Button ref={buttonRef} onClick={onVerifyClick} variant="outlined">
          <Mail
            height={16}
            width={16}
            className={classes.icon}
            title={intl.formatMessage(globalMessages.verify_your_email)}
          />
          {intl.formatMessage(globalMessages.verify_your_email)}
        </Button>
        <VerifyCode
          userInfo={userInfo}
          onClose={handleToggleDialog(setActiveDialog, activeDialog)}
          open={activeDialog}
          validationCode={validationData?.code}
        />
      </>
    )
  );
}

VerifyEmailButton.propTypes = {
  intl: intlShape,
  classes: PropTypes.object.isRequired,
  userInfo: PropTypes.shape({
    user_id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    email_validated: PropTypes.bool.isRequired,
  }).isRequired,
};

export default compose(
  injectIntl,
  withStyles(styles),
  reduxForm({
    destroyOnUnmount: true,
    enableReinitialize: true,
    form: 'enter-code',
  }),
)(VerifyEmailButton);
