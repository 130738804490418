import React, { useState, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';

import FinalcadImage from 'assets/images/logos/finalcad.svg';
import config from 'config/config';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { isValidSecureURL } from 'utils/validators';

import { getImage } from './actions';
import reducer from './reducer';
import saga from './saga';

export const quality = {
  original: 'Original', // Original Quality is the original file without any transformation
  large: 'Large', // Large Quality is the large quality of the file resized
  web: 'Web',
  pdf: 'Pdf', // Pdf Quality is the medium quality of the file
  thumbnail: 'Thumbnail', // Thumbnail Quality is the small quality of the file
  thumbnailOrOriginal: 'ThumbnailOrOriginal', // Thumbnail if available, Original otherwise
};

const SecuredImage = React.memo(
  ({
    children,
    componentSrc = 'src',
    imgQuality = quality.original,
    placeholder = null,
    withPlaceholder = true,
    showFallback = false,
  }) => {
    const dispatch = useDispatch();
    const [image64, setImage64] = useState(withPlaceholder ? placeholder || FinalcadImage : null);

    const { ...childrenProps } = children.props;
    const image = children.props[componentSrc];

    const dispatchGetImage = useCallback(
      (url, q, callback) => dispatch(getImage(url, q, callback, showFallback)),
      [dispatch],
    );

    useEffect(
      () => {
        let isMounted = true;

        let imageURL = image;
        if (config.overridesMediaServiceUrl) {
          imageURL = image?.replace(
            /https:\/\/api(\.eu|\.ap)?\.(sandbox|staging)\.finalcad\.cloud/gi,
            config.apiHostGateway,
          );
        }

        if (isValidSecureURL(imageURL)) {
          dispatchGetImage(
            imageURL,
            imgQuality,
            img64 => {
              if (isMounted) {
                setImage64(img64);
              }
            },
            showFallback,
          );
        } else if (isMounted && imageURL) {
          setImage64(imageURL);
        }

        return () => {
          isMounted = false;
        };
      },
      [image, dispatchGetImage, imgQuality],
    );

    return React.cloneElement(children, {
      ...childrenProps,
      [componentSrc]: image64,
    });
  },
);

const mapDispatchToProps = {
  dispatchGetImage: getImage,
};

SecuredImage.propTypes = {
  children: PropTypes.node.isRequired,
  componentSrc: PropTypes.string,
  imgQuality: PropTypes.oneOf(Object.values(quality)),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
  withPlaceholder: PropTypes.bool,
  showFallback: PropTypes.bool,
};

const withConnect = connect(
  null,
  mapDispatchToProps,
);

const withSaga = injectSaga({ key: 'securedImage', saga });
const withReducer = injectReducer({ key: 'securedImage', reducer });

export default compose(
  withConnect,
  withSaga,
  withReducer,
)(SecuredImage);
