import isEqualWith from 'lodash/isEqualWith';
import uniqWith from 'lodash/uniqWith';
import { takeLatest, put, call, select } from 'redux-saga/effects';

import config from 'config/config';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import request from 'utils/request';

import { setLoadingFlag, setUserWorkSpaces } from './actions';
import { GET_USER_WORKSPACES } from './constants';
import { selectUserWorkspaces, selectSelectedWorkspace } from './selectors';

export function* fetchUserWorkSpaces({ organizationId, parentId, searchTerm }) {
  yield put(setLoadingFlag(true));

  const requestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/organizations/${organizationId}/workspaces/user_workspaces`;
  const payload = {};
  if (parentId !== 'root') {
    payload.parent_id = parentId;
  }

  if (searchTerm) {
    yield put(setUserWorkSpaces([]));
    payload.search_term = searchTerm;
  }

  const prevWorkspaces = yield select(selectUserWorkspaces());
  const selectedWorkspaces = yield select(selectSelectedWorkspace());
  const existingWorkspaceByParent = prevWorkspaces.filter(
    item => item.parentId === parentId, // selectedWorkspaces.id,
  );

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };
  if (!existingWorkspaceByParent?.length) {
    const data = yield call(request, requestURL, options);
    if (data && !data.message) {
      const workspaces = [
        ...prevWorkspaces,
        ...data.workspaces.map(item => ({
          ...item,
          organizationId,
          parentId: selectedWorkspaces?.id,
        })),
      ];

      yield put(
        setUserWorkSpaces(
          uniqWith(workspaces, (ws1, ws2) => isEqualWith(ws1, ws2, 'id')).filter(
            ws => ws.organizationId === organizationId,
          ),
        ),
      );
    }
  }

  yield put(setLoadingFlag(false));
}

export default function* workspacesPageSaga() {
  yield takeLatest(GET_USER_WORKSPACES, fetchUserWorkSpaces);
}
