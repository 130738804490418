import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';

import { DialogActions } from '@mui/material';
import { withStyles } from '@mui/styles';

import Button from 'components/Button';
import globalMessages from 'translations/messages/global-messages';

export const styles = theme => ({
  actionsRoot: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: '0 1em',
    margin: '0',
    height: '3.5em',
  },
});

export function DialogSubmit(props) {
  const {
    classes,
    onClose,
    disabled,
    loading,
    intl,
    closeLabel,
    submitLabel,
    className,
    renderMoreActions,
    form,
  } = props;

  return (
    <>
      {props.children}
      <DialogActions
        classes={{
          root: classNames({
            [classes.actionsRoot]: true,
            [className]: className,
          }),
        }}
      >
        {renderMoreActions()}
        <Button onClick={onClose} variant="plain">
          {closeLabel || intl.formatMessage(globalMessages.cancel)}
        </Button>

        <Button form={form} type="submit" disabled={disabled} loading={loading}>
          {submitLabel || intl.formatMessage(globalMessages.create)}
        </Button>
      </DialogActions>
    </>
  );
}

DialogSubmit.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  submitLabel: PropTypes.string,
  closeLabel: PropTypes.string,
  form: PropTypes.string,
  renderMoreActions: PropTypes.func,
  onClose: PropTypes.func,
};

DialogSubmit.defaultProps = {
  renderMoreActions: () => null,
};

export default compose(
  injectIntl,
  withStyles(styles),
)(DialogSubmit);
