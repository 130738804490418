/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form/immutable';

import mouseReducer from 'components/ContextMenu/reducer';
import infiniteTableReducerV3 from 'components/InfiniteTableSelfStandingV3/reducer';
import tableDragDropReducer from 'components/TableDragDropSelfStanding/reducer';
import languageProviderReducer from 'containers/LanguageProvider/reducer';
import history from 'utils/history';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */

export default function reducers(injectedReducers = {}) {
  return combineReducers({
    router: connectRouter(history),
    form: formReducer,
    language: languageProviderReducer,
    mouse: mouseReducer,
    users: infiniteTableReducerV3('users'),
    table: infiniteTableReducerV3('table'),
    table_1: infiniteTableReducerV3('table_1'),
    table_2: infiniteTableReducerV3('table_2'),
    filter1: infiniteTableReducerV3('filter1'),
    filter2: infiniteTableReducerV3('filter2'),
    filter3: infiniteTableReducerV3('filter3'),
    filter4: infiniteTableReducerV3('filter4'),
    tableDragDrop1: tableDragDropReducer('tableDragDrop1'),
    tableDragDrop2: tableDragDropReducer('tableDragDrop2'),
    ...injectedReducers,
  });
}
