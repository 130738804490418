import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

function Users(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>
        {props.title || props.intl.formatMessage(globalMessages.users)}
      </title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.976311 10.3096C1.60143 9.68452 2.44928 9.33333 3.33333 9.33333H8.66667C9.55072 9.33333 10.3986 9.68452 11.0237 10.3096C11.6488 10.9348 12 11.7826 12 12.6667V14C12 14.3682 11.7015 14.6667 11.3333 14.6667C10.9651 14.6667 10.6667 14.3682 10.6667 14V12.6667C10.6667 12.1362 10.456 11.6275 10.0809 11.2525C9.70581 10.8774 9.1971 10.6667 8.66667 10.6667H3.33333C2.8029 10.6667 2.29419 10.8774 1.91912 11.2525C1.54405 11.6275 1.33333 12.1362 1.33333 12.6667V14C1.33333 14.3682 1.03486 14.6667 0.666667 14.6667C0.298477 14.6667 0 14.3682 0 14V12.6667C0 11.7826 0.35119 10.9348 0.976311 10.3096Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00002 2.66667C4.89545 2.66667 4.00002 3.5621 4.00002 4.66667C4.00002 5.77124 4.89545 6.66667 6.00002 6.66667C7.10459 6.66667 8.00002 5.77124 8.00002 4.66667C8.00002 3.5621 7.10459 2.66667 6.00002 2.66667ZM2.66669 4.66667C2.66669 2.82572 4.15907 1.33333 6.00002 1.33333C7.84097 1.33333 9.33335 2.82572 9.33335 4.66667C9.33335 6.50762 7.84097 8 6.00002 8C4.15907 8 2.66669 6.50762 2.66669 4.66667Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6878 9.92C12.7799 9.56351 13.1435 9.34913 13.5 9.44117C14.2151 9.62581 14.8486 10.0427 15.3011 10.6264C15.7536 11.2101 15.9994 11.9276 16 12.6662L16 14C16 14.3682 15.7015 14.6667 15.3333 14.6667C14.9651 14.6667 14.6667 14.3682 14.6667 14V12.6669C14.6663 12.2238 14.5188 11.7935 14.2473 11.4433C13.9759 11.0931 13.5957 10.843 13.1667 10.7322C12.8102 10.6401 12.5958 10.2765 12.6878 9.92Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0208 1.92131C10.1121 1.56463 10.4753 1.34951 10.832 1.44084C11.549 1.62442 12.1845 2.04142 12.6384 2.62609C13.0922 3.21077 13.3385 3.92986 13.3385 4.67C13.3385 5.41014 13.0922 6.12924 12.6384 6.71391C12.1845 7.29858 11.549 7.71558 10.832 7.89917C10.4753 7.99049 10.1121 7.77538 10.0208 7.4187C9.92949 7.06201 10.1446 6.69883 10.5013 6.6075C10.9315 6.49735 11.3128 6.24715 11.5851 5.89635C11.8574 5.54554 12.0052 5.11409 12.0052 4.67C12.0052 4.22592 11.8574 3.79446 11.5851 3.44366C11.3128 3.09285 10.9315 2.84265 10.5013 2.7325C10.1446 2.64118 9.92949 2.27799 10.0208 1.92131Z"
      />
    </svg>
  );
}
Users.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

Users.defaultProps = {
  title: '',
};

export default injectIntl(Users);
