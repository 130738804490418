import React, { useContext, useEffect, useState } from 'react';

import { Typography, List, TextField, IconButton, Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import { fromJS } from 'immutable';
import { differenceWith } from 'lodash';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import NoResultsFound from 'components/AddList/NoResultsFound';
import OverSeatOfferBanner from 'components/Banner/components/OverSeatOfferBanner';
import DialogSubmit from 'components/dialogs/DialogSubmit';
import InfiniteTableSelect from 'components/InfiniteTableSelect';
import LoadingIndicator from 'components/LoadingIndicator';
import RemoveScrollbar from 'components/RemoveScrollbar';
import SearchTextField from 'components/SearchTextFieldV3';
import Close from 'components/SvgComponents/icons/Close';
import Config from 'config/config';
import { ProjectContext } from 'containers/GlobalWrapper/ProjectDataValidation';
import { selectGroups } from 'containers/OneProjectPage/GroupsPage/selectors';
import { useOverSeatLimitOffer } from 'hooks/use-over-seat-limit-offer';
import globalMessages from 'translations/messages/global-messages';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { authorizations } from 'utils/constants';
import { normalize } from 'utils/validators';

import { useFeatureFlags } from '../../../../contexts/FeatureFlagsProvider';
import FormFolderPicker from './folder/FormFolderPicker';

export const styles = theme => ({
  formAddList: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    overflow: 'auto',
  },
  formAddListContainer: {
    display: 'flex',
    flex: 'auto',
    position: 'relative',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '2rem 3.5rem 0',
  },
  list: {
    width: '100%',
    position: 'relative',
  },
  search: {
    marginTop: theme.spacing(6),
    fontSize: '1rem',
    visibility: 'hidden',
  },
  show: {
    visibility: 'visible',
  },
  input: {
    border: '1px  solid transparent',
    borderRadius: 12,
    '&:hover:not(:disabled)': {
      border: `1px solid ${theme.palette.text.primary}`,
    },
    '&:focus:not(:disabled)': {
      border: `1px solid ${theme.palette.text.primary}`,
    },
  },
  searchTextField: {
    maxWidth: '100%',
  },
  textField: {
    backgroundColor: theme.palette.background.primary,
    borderRadius: 12,
    border: 'none',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',

    '& fieldset': {
      border: 'none',
    },
    '& input': {
      padding: '10px 32px 10px 16px',
    },
    '& textarea': {
      padding: '10px 32px 10px 16px',
      width: 'calc(100% - 50px)',
    },
  },
  textLabel: {
    marginBottom: theme.spacing(2),
  },
  textLabelTemplate: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  close: {
    position: 'absolute',
    padding: 0,
    backgroundColor: theme.palette.text.primary,
    display: 'flex',
    right: theme.spacing(2),
    top: 13,
  },
  describeFormContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },
  describeFormContainerRequired: {
    color: theme.palette.text.primary,
    paddingLeft: theme.spacing(2),
  },
});

function FormAddList(props) {
  const {
    match,
    classes,
    intl,
    actions,
    itemsRendered,
    itemComparison,
    displayOtherOptionOnNoResult,
    searchText,
    searchPlaceHolder,
    onSearchTextChange,
    displayInfoMessage,
    infoMessage,
    noResultsFoundText,
    onSubmit,
    onClose,
    loading,
    submitting,
    groups,
    moduleId,
  } = props;
  const project = useContext(ProjectContext);
  const [initialList, setInitialList] = useState(props.items);
  const [selectedForm, setSelectedForm] = useState();
  const [currentCategory, setSelectedCategory] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const { idData } = match.params;
  const projectId = idData;
  const [nameForm, setNameForm] = useState('');
  const { isOverSeatLimitOffer, currentPlan } = useOverSeatLimitOffer();

  const shouldShowSelectGroups =
    !project?.authorizations?.includes(authorizations.project.createObservation) &&
    groups.some(group => group?.authorizations?.includes(authorizations.project.createObservation));

  const hasGroupSelected = !shouldShowSelectGroups || !!selectedGroup;
  const hasFormSelected = !!selectedForm;
  const hasNameFilled = nameForm.length > 0;
  const canSubmitHierarchy = hasGroupSelected && hasNameFilled && hasFormSelected && !submitting;
  const isLegacyDisabled =
    !(initialList.find(item => item.is_already_in_project === true) && nameForm !== '') ||
    (shouldShowSelectGroups && !selectedGroup);
  const featureFags = useFeatureFlags();

  const canSubmit = featureFags?.module_forms_hierarchy ? canSubmitHierarchy : !isLegacyDisabled;

  const listRequestOptionGroups = {
    method: 'GET',
    url: `${checkOrSetSlash(
      Config.apiHostGateway,
      'apiHostGateway',
    )}api/v1.3/projects/${projectId}/groups/search`,
    dataKey: 'groups',
    namespace: 'filter2',
    callBack: data => {
      // Sort groups by name then put default group at the end
      if (!data?.sort) return data;
      const sortedGroups = data?.sort((grp1, grp2) =>
        grp1.get('name')?.localeCompare(grp2.get('name')),
      );
      const defaultGroup = sortedGroups?.find(grp => grp.get('is_default'));
      const otherGroups = sortedGroups?.filter(grp => !grp.get('is_default')) || [];

      return fromJS(defaultGroup ? [...otherGroups, defaultGroup] : [...otherGroups]);
    },
  };

  const handleSelectChangeGroup = item => {
    setSelectedGroup(item);
  };

  useEffect(
    () => {
      if (props.items.length || searchText) {
        setInitialList(props.items);
      }
    },
    [props.items.length],
  );

  const filteredItems = differenceWith(initialList, 1, itemComparison) || [];

  const onChange = template => () => {
    const temporarList = initialList.map(item => ({
      ...item,
      is_already_in_project: item.id === template.id && !item.is_already_in_project,
    }));
    setInitialList(temporarList);
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    props.onClearTemplates();

    if (featureFags?.module_forms_hierarchy) {
      onSubmit({ ...selectedForm, group: selectedGroup, nameForm, categoryId: currentCategory?.id });
    } else {
      const formTemplate = initialList.find(temp => temp.is_already_in_project === true);
      onSubmit({ ...formTemplate, group: selectedGroup, nameForm });
    }
  };

  const handleDisplayInfoMessage = () =>
    displayInfoMessage({
      items: filteredItems,
      initialList,
    });

  const handleInfoMessage = () =>
    infoMessage({
      items: filteredItems,
      initialList,
    });

  const handleDisplayOtherOptionOnNoResult = () =>
    displayOtherOptionOnNoResult({
      items: filteredItems,
      initialList,
    });

  const displayNoResults =
    searchText && !filteredItems.length && !handleDisplayOtherOptionOnNoResult();

  const renderSelectGroups = () => (
    <InfiniteTableSelect
      listRequestOption={listRequestOptionGroups}
      customStyle={{ width: '100%' }}
      placeholder={
        selectedGroup?.name || intl.formatMessage(globalMessages.item_select_group_placeholder)
      }
      label={intl.formatMessage(globalMessages.groups)}
      onChange={handleSelectChangeGroup}
    />
  );

  const handleChange = ev => {
    setNameForm(normalize(ev.target.value));
  };
  const handleClear = () => {
    setNameForm('');
    setSelectedGroup(null);
  };

  return (
    <>
      <form onSubmit={handleOnSubmit} className={classes.formAddList} id="add-form-instance">
        {loading && <LoadingIndicator size="40" />}
        <div className={classes.formAddListContainer}>
          {actions}
          <div className={classes.describeFormContainer}>
            <Typography variant="body" className={classes.textLabel}>
              {intl.formatMessage(globalMessages.describe_form)}
            </Typography>
            <Typography variant="subtitle" className={classes.describeFormContainerRequired}>
              {intl.formatMessage(globalMessages.required)}
            </Typography>
          </div>
          <div className={classes.textField}>
            <TextField
              autoFocus
              variant="outlined"
              placeholder={intl.formatMessage(globalMessages.describe_form_placeholder)}
              fullWidth
              onChange={handleChange}
              value={nameForm}
              InputProps={{
                classes: {
                  input: classes.input,
                  multiline: classes.multiline,
                },
              }}
            />
            {nameForm && (
              <IconButton className={classes.close} onClick={handleClear}>
                <Close width="16" height="16" />
              </IconButton>
            )}
          </div>
          {shouldShowSelectGroups && renderSelectGroups()}
          <Typography variant="body" className={classes.textLabelTemplate} sx={{ marginTop: 5 }}>
            {intl.formatMessage(globalMessages.choose_template)}
          </Typography>
          {featureFags?.module_forms_hierarchy && (
            <FormFolderPicker
              sx={{ marginTop: 2 }}
              selectedFormId={selectedForm?.id}
              onSelectForm={setSelectedForm}
              onSelectCategory={setSelectedCategory}
              moduleId={moduleId}
            />
          )}
          {!featureFags?.module_forms_hierarchy && (
            <>
              <SearchTextField
                className={classes.searchTextField}
                searchText={searchText}
                onChange={onSearchTextChange}
                searchInputProps={{
                  placeholder: searchPlaceHolder,
                }}
              />

              <Typography
                component="p"
                className={classNames(classes.search, {
                  [classes.show]: handleDisplayInfoMessage(),
                })}
              >
                {handleInfoMessage()}
              </Typography>
              <RemoveScrollbar>
                <List className={classes.list}>
                  {{
                    ...itemsRendered,
                    props: {
                      ...itemsRendered.props,
                      items: initialList,
                      onClick: onChange,
                    },
                  }}

                  {displayNoResults && <NoResultsFound details={noResultsFoundText} />}
                </List>
              </RemoveScrollbar>
            </>
          )}
          {isOverSeatLimitOffer && (
            <Box sx={{ marginTop: '20px', marginBottom: '20px' }}>
              <OverSeatOfferBanner currentPlan={currentPlan} fullWidth blockItemsCreation />
            </Box>
          )}
        </div>
      </form>
      <DialogSubmit
        form="add-form-instance"
        submitLabel={intl.formatMessage(globalMessages.create)}
        onClose={onClose}
        disabled={!canSubmit || isOverSeatLimitOffer}
      />
    </>
  );
}

FormAddList.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  actions: PropTypes.node,
  items: PropTypes.array.isRequired,
  itemsRendered: PropTypes.node.isRequired,
  itemComparison: PropTypes.func,
  displayOtherOptionOnNoResult: PropTypes.func,
  searchText: PropTypes.string.isRequired,
  searchPlaceHolder: PropTypes.string,
  onSearchTextChange: PropTypes.func.isRequired,
  displayInfoMessage: PropTypes.func,
  infoMessage: PropTypes.func,
  noResultsFoundText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  onClearTemplates: PropTypes.func,
  groups: PropTypes.array,
  moduleId: PropTypes.string,
};

FormAddList.defaultProps = {
  groups: [],
  actions: null,
  itemComparison: isEqual,
  displayOtherOptionOnNoResult: () => false,
  displayInfoMessage: () => false,
  infoMessage: () => null,
  noResultsFoundText: '',
  loading: false,
};

const mapStateToProps = createStructuredSelector({
  groups: selectGroups(),
});

const withConnect = connect(
  mapStateToProps,
  null,
);

export default compose(
  injectIntl,
  withConnect,
  withRouter,
  withStyles(styles),
)(FormAddList);
