import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';

export const CarouselItem = ({ image, title, description, image2x }) => (
  <Box
    sx={{
      height: '75vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
  >
    <Box
      component="img"
      sx={{
        maxHeight: '70%',
        objectFit: 'contain',
        maxWidth: 'fit-content',
        marginInline: 'auto',
      }}
      src={image}
      alt=""
      srcset={`${image} 1x, ${image2x} 2x`}
    />
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        justifyContent: 'center',
        maxWidth: 450,
        marginInline: 'auto',
      }}
    >
      <Typography align="center" variant="header" component="h2">
        {title}
      </Typography>
      <Typography align="center" component="p" variant="bodyLight">
        {description}
      </Typography>
    </Box>
  </Box>
);

CarouselItem.propTypes = {
  image: PropTypes.string.isRequired,
  image2x: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
