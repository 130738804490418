const pagesContentTheme = theme => ({
  pageWrapper: {
    display: 'flex',
    flex: 'auto',
    overflow: 'hidden',
    position: 'relative',
    justifyContent: 'center',
    background: theme.palette.background.white,
  },
  contentWrapper: {
    display: 'flex',
    flex: 'auto',
    width: '100%',
    flexDirection: 'column',
  },
  contentWrapperPadding: {
    padding: '0 1em',
    margin: '0',
    [theme.breakpoints.up('sm')]: {
      padding: '0 2.5em',
      margin: '1.7500em 0 0 0',
    },
  },
  contentWrapperSmallPadding: {
    padding: '0 1em',
    margin: '0',
    [theme.breakpoints.up('sm')]: {
      padding: '0 1.5em',
    },
  },
  cardWrapper: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    background: theme.palette.background.white,
  },
});

export default pagesContentTheme;
