import React, { useMemo } from 'react';

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import Avatar from 'components/AvatarV3';
import highlightText from 'utils/highlightText';
import { getUserFullName } from 'utils/users';

export const styles = theme => ({
  memberWrap: {
    display: 'flex',
    alignItems: 'center',
    tableLayout: 'fixed',
    width: '100%',
    overflow: 'hidden',
  },
  imageWrap: {
    border: `0.75px solid ${theme.palette.divider}`,
    marginRight: theme.spacing(3),
    objectFit: 'cover',
    height: 32,
    width: 32,
  },
  wrapEmail: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  nameTypoWrap: {
    width: '100%',
    minWidth: 0,
  },
  mark: {
    backgroundColor: 'transparent',
    color: theme.palette.secondary.main,
  },
});

export function Member(props) {
  const {
    classes,
    member,
    hideEmail,
    nameRerenderer,
    classNamesAvatar,
    typographyClassName,
    nameTypoWrapCls,
    imageWrapCls,
    children,
    searchValue,
    isFirstColumnInTable,
    isBlackTextInList,
    displayAvatar,
  } = props;

  const re = useMemo(
    () => {
      const SPECIAL_CHAR_RE = /([.?*+^$[\]\\(){}|-])/g;
      const escapedSearch = searchValue.replace(SPECIAL_CHAR_RE, '\\$1');
      return new RegExp(`(${escapedSearch})`, 'i');
    },
    [searchValue],
  );

  if (!member) {
    return null;
  }

  const name = getUserFullName(member);

  let renderEmail = !hideEmail && (
    <div className={classes.wrapEmail}>
      <Typography noWrap variant="subtitleLight" component="div">
        {member?.email}
      </Typography>
      {children}
    </div>
  );
  let renderHighlightEmail = () =>
    !hideEmail && (
      <div className={classes.wrapEmail}>
        <Typography noWrap variant="subtitleLight" component="div">
          {highlightText(member?.email, re, classes.mark)}
        </Typography>
        {member?.is_pending && !name ? null : children}
      </div>
    );

  if (member?.is_pending && !name) {
    renderEmail = children;
    renderHighlightEmail = children;
  }

  const renderName = () => {
    if (nameRerenderer) {
      return nameRerenderer(member);
    }
    return name || member?.email;
  };

  const renderImage = () => (
    <Avatar
      materialUIClasses={{ root: classNamesAvatar }}
      className={imageWrapCls || classes.imageWrap}
      src={member?.media_resource?.url || member?.picture?.url}
      alt={`${member?.first_name} ${member?.last_name}`}
      user={member}
    />
  );

  const renderHighlightContent = () => (
    <span>
      <Typography noWrap className={typographyClassName} component="div" variant="body">
        {highlightText(renderName(), re, classes.mark)}
      </Typography>
      {member?.is_pending && !name ? children : renderHighlightEmail()}
    </span>
  );

  const getColor = () => {
    if (isFirstColumnInTable) {
      return 'subtitleBold';
    }
    if (isBlackTextInList) {
      return 'body';
    }
    return 'subtitle';
  };

  const renderNormalContent = () => (
    <>
      <Typography noWrap className={typographyClassName} component="div" variant={getColor()}>
        {renderName()}
      </Typography>
      {renderEmail}
    </>
  );

  return (
    <div className={classes.memberWrap}>
      {displayAvatar ? renderImage() : null}
      <div className={nameTypoWrapCls || classes.nameTypoWrap}>
        {searchValue ? renderHighlightContent() : renderNormalContent()}
      </div>
    </div>
  );
}

Member.propTypes = {
  classes: PropTypes.object,
  member: PropTypes.object,
  hideEmail: PropTypes.bool,
  typographyClassName: PropTypes.string,
  classNamesAvatar: PropTypes.string,
  nameTypoWrapCls: PropTypes.string,
  imageWrapCls: PropTypes.string,
  searchValue: PropTypes.string,
  children: PropTypes.node,
  nameRerenderer: PropTypes.func,
  isFirstColumnInTable: PropTypes.bool,
  isBlackTextInList: PropTypes.bool,
  displayAvatar: PropTypes.bool,
};

Member.defaultProps = {
  classes: {},
  hideEmail: false,
  searchValue: '',
  isFirstColumnInTable: false,
  isBlackTextInList: false,
  displayAvatar: true,
};

export default withStyles(styles)(Member);
