import { find } from 'lodash';
import { delay } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import Config from 'config/config';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { getFormsApiVersion } from 'utils/constants';
import { checkParams } from 'utils/errors';
import request from 'utils/request';

import { setFormStatus, setFormPriority, setFormPhase, setForm } from './actions';
import { GET_FORM, GET_FORM_PHASE, GET_FORM_PRIORITY, GET_FORM_STATUS } from './constants';
import { selectFormPriority, selectFormPhase, selectFormStatus, selectForm } from './selectors';

export function* getFormPriority({ id, callback, params }) {
  const priorityData = yield select(selectFormPriority(id));
  if (priorityData) {
    if (callback) yield call(callback, priorityData);
  } else {
    checkParams({ params, keys: ['idData'] });

    const requestURL = `${checkOrSetSlash(
      Config.apiHostGateway,
      'apiHostGateway',
    )}api/${getFormsApiVersion()}/projects/${params.idData}/forms-priorities`;

    const options = {
      method: 'GET',
      headers: {
        'Cache-Control': 'No-Store',
        'Content-Type': 'application/json',
      },
    };

    const data = yield call(request, requestURL, options, {
      errorRedirect: false,
    });
    if (data && !data?.message) {
      yield data.priorities.map(priority => put(setFormPriority(priority.id, priority)));
      if (callback) yield call(callback, find(data.priorities, { id }));
    }
  }
}

export function* getFormPhase({ id, callback, params }) {
  const phaseData = yield select(selectFormPhase(id));

  if (phaseData) {
    if (callback) yield call(callback, phaseData);
  } else {
    checkParams({ params, keys: ['idData'] });

    const requestURL = `${checkOrSetSlash(Config.apiHostGateway, 'apiHostGateway')}api/projects/${
      params.idData
    }/phases/${id}`;

    const options = {
      method: 'GET',
      headers: {
        'Cache-Control': 'No-Store',
        'Content-Type': 'application/json',
      },
    };
    const data = yield call(request, requestURL, options, {
      errorRedirect: false,
    });
    if (data && !data?.message) {
      yield put(setFormPhase(id, data));
      if (callback) yield call(callback, data);
    }
  }
}

export function* getFormStatus({ id, form_id, callback, params }) {
  const statusData = yield select(selectFormStatus(id));

  if (statusData) {
    if (callback) yield call(callback, statusData);
  } else {
    checkParams({ params, keys: ['idData'] });

    const requestURL = `${checkOrSetSlash(
      Config.apiHostGateway,
      'apiHostGateway',
    )}api/${getFormsApiVersion()}/projects/${params.idData}/forms/${form_id}/statuses`;

    const options = {
      method: 'GET',
      headers: {
        'Cache-Control': 'No-Store',
        'Content-Type': 'application/json',
      },
    };

    const data = yield call(request, requestURL, options, {
      errorRedirect: false,
    });
    if (data && !data?.message) {
      yield data.statuses.map(status => put(setFormStatus(status.id, status)));
      if (callback) yield call(callback, find(data.statuses, { id }));
    }
  }
}

export function* getForm({ id, callback, params }) {
  const formData = yield select(selectForm(id));
  const maxRetries = 2;
  const retryDelay = 1000;

  if (formData) {
    if (callback) yield call(callback, formData);
  } else {
    checkParams({ params, keys: ['idData'] });

    const requestURL = `${checkOrSetSlash(
      Config.apiHostGateway,
      'apiHostGateway',
    )}api/${getFormsApiVersion()}/projects/${params.idData}/form-instances/${id}`;

    const options = {
      method: 'GET',
      headers: {
        'Cache-Control': 'No-Store',
        'Content-Type': 'application/json',
      },
    };

    for (let i = 0; i < maxRetries; i++) {
      try {
        const data = yield call(request, requestURL, options, {
          errorRedirect: false,
          errorCustom: true,
        });

        if (data && data?.status !== 403) {
          yield put(setForm(id, data?.status === 403 ? { forbidden: true } : data));
          if (callback) yield call(callback, data?.status === 403 ? { forbidden: true } : data);
          break;
        }

        if (i < maxRetries - 1) {
          yield delay(retryDelay);
        }
      } catch (error) {
        if (error.status !== 403 || i === maxRetries - 1) {
          break;
        }
      }
    }
  }
}

export default function* initFormsSaga() {
  yield takeEvery(GET_FORM_PRIORITY, getFormPriority);
  yield takeEvery(GET_FORM_PHASE, getFormPhase);
  yield takeEvery(GET_FORM_STATUS, getFormStatus);
  yield takeEvery(GET_FORM, getForm);
}
