import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

function Plan(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>
        {props.title || props.intl.formatMessage(globalMessages.plan)}
      </title>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 3C4.67157 3 4 3.67157 4 4.5V14.3368C4.45463 14.1208 4.9632 14 5.5 14C6.0368 14 6.54537 14.1208 7 14.3368V4.5C7 3.67157 6.32843 3 5.5 3ZM9 6V4.5C9 2.567 7.433 1 5.5 1C3.567 1 2 2.567 2 4.5V17.5C2 19.433 3.567 21 5.5 21H21C21.5523 21 22 20.5523 22 20V7C22 6.44772 21.5523 6 21 6H9ZM9 8V17.5C9 18.0523 8.55228 18.5 8 18.5C7.44772 18.5 7 18.0523 7 17.5C7 16.6716 6.32843 16 5.5 16C4.67157 16 4 16.6716 4 17.5C4 18.3284 4.67157 19 5.5 19H20V8H9Z"
      />
    </svg>
  );
}
Plan.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

Plan.defaultProps = {
  title: '',
};

export default injectIntl(Plan);
