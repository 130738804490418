import React, { Component } from 'react';

import { Box, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import Gleap from 'gleap';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import CustomizedSnackbar from 'components/Snackbar';
import ArrowRight from 'components/SvgComponents/icons/ArrowRight';
import injectReducer from 'utils/injectReducer';

import { setGlobalMessage } from './actions';
import messages from './messages';
import reducer from './reducer';
import { selectGlobalMessage } from './selectors';

const styles = theme => ({
  message: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(5),
  },
  multipleErrorsContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '70vh',
    overflowY: 'auto',
  },
  gleapAccess: {
    marginRight: '40px',
  },
});

class MessageHandler extends Component {
  setGlobalMessage = () => {
    const { msg } = this.props;
    this.props.setGlobalMessage({
      ...msg,
      active: false,
    });
  };

  openGleap = () => {
    Gleap.open();
  };

  supportAction = () => {
    const { classes } = this.props;
    return (
      <Box onClick={() => this.openGleap()} className={classes.gleapAccess}>
        <IconButton key="support" aria-label="Support" color="inherit">
          <ArrowRight />
        </IconButton>
      </Box>
    );
  };

  getIntlMessage = intlMessage => {
    const { intl } = this.props;
    if (intlMessage) {
      if (intlMessage.id) {
        return {
          multipleErrors: false,
          message: intl.formatMessage(messages[intlMessage.id], intlMessage.values),
        };
      }
      if (intlMessage.length) {
        // if message is array
        return {
          multipleErrors: true,
          message: intlMessage.map(iMessage =>
            intl.formatMessage(messages[iMessage.id], iMessage.values),
          ),
        };
      }
    }

    return null;
  };

  renderCurrentMessage = result => {
    const { classes } = this.props;
    if (!result) {
      return <></>;
    }
    if (!result.multipleErrors) {
      return result.message;
    }
    if (result.multipleErrors) {
      const mees = result.message.map(cMess => <p>{cMess}</p>);
      return <div className={classes.multipleErrorsContainer}>{mees}</div>;
    }
    return <></>;
  };

  render() {
    const { msg, classes } = this.props;
    const {
      type = 'success',
      active = false,
      duration = 6000,
      intlMessage,
      message,
      contactSupport = false,
      verticalPosition,
      horizontalPosition,
    } =
      msg || {};
    const action = contactSupport ? [this.supportAction()] : undefined;
    return (
      <CustomizedSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        variant={type}
        verticalPosition={verticalPosition || 'bottom'}
        horizontalPosition={horizontalPosition || 'left'}
        onClose={this.setGlobalMessage}
        open={active}
        autoHideDuration={duration}
        message={
          <span id="client-snackbar" className={classes.message}>
            {intlMessage ? this.renderCurrentMessage(this.getIntlMessage(intlMessage)) : <></>}
            {message}
          </span>
        }
        action={action}
      />
    );
  }
}

MessageHandler.propTypes = {
  classes: PropTypes.object.isRequired,
  msg: PropTypes.shape({
    duration: PropTypes.number,
    type: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    intlMessage: PropTypes.shape({
      id: PropTypes.string,
      values: PropTypes.object,
    }),
    active: PropTypes.bool,
    contactSupport: PropTypes.bool,
  }),
  intl: intlShape.isRequired,
  setGlobalMessage: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  msg: selectGlobalMessage(),
});

const mapDispatchToProps = dispatch => ({
  setGlobalMessage: error => {
    dispatch(setGlobalMessage(error));
  },
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);
const withReducer = injectReducer({ key: 'snackbar', reducer });

export default compose(
  withReducer,
  injectIntl,
  withConnect,
  withStyles(styles),
)(MessageHandler);
