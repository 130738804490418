import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';

const ThreeDots = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" {...props}>
    {props.title && <title>{props.title}</title>}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66675 8.49984C6.66675 7.76346 7.2637 7.1665 8.00008 7.1665C8.73646 7.1665 9.33341 7.76346 9.33341 8.49984C9.33341 9.23622 8.73646 9.83317 8.00008 9.83317C7.2637 9.83317 6.66675 9.23622 6.66675 8.49984Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3333 8.49984C11.3333 7.76346 11.9302 7.1665 12.6666 7.1665C13.403 7.1665 13.9999 7.76346 13.9999 8.49984C13.9999 9.23622 13.403 9.83317 12.6666 9.83317C11.9302 9.83317 11.3333 9.23622 11.3333 8.49984Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 8.49984C2 7.76346 2.59695 7.1665 3.33333 7.1665C4.06971 7.1665 4.66667 7.76346 4.66667 8.49984C4.66667 9.23622 4.06971 9.83317 3.33333 9.83317C2.59695 9.83317 2 9.23622 2 8.49984Z"
    />
  </svg>
);

ThreeDots.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

export default injectIntl(ThreeDots);
