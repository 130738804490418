import { call, put, select, takeLatest } from 'redux-saga/effects';

import config from 'config/config';
import { request } from 'utils/request';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { checkParams } from 'utils/errors/check-params';
import { GET_LIMIT_OFFER } from './constants';
import {
  setLimitOfferObservation,
  setLimitOfferForms,
  setLoadingFlag,
} from './actions';

export function* fetchObservationsLimitsOffer({ params }) {
  yield put(setLoadingFlag(true));
  checkParams({ params, keys: ['idData'] });

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/projects/${
    params.idData
  }/observations/limitations`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
    },
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message && data?.status !== 410) {
    yield put(setLimitOfferObservation(data || {}));
  }
  yield put(setLoadingFlag(false));
}

export function* fetchFormInstancesLimitsOffer({ params }) {
  yield put(setLoadingFlag(true));
  checkParams({ params, keys: ['idData'] });

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/projects/${
    params.idData
  }/form-instances/limitations`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
    },
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message && data?.status !== 410) {
    yield put(setLimitOfferForms(data || {}));
  }
  yield put(setLoadingFlag(false));
}

export function* fetchLimitsOffer({ params }) {
  yield call(fetchObservationsLimitsOffer, { params });
  yield call(fetchFormInstancesLimitsOffer, { params });

}

export default function* initLimitationOfferSaga() {
  yield takeLatest(GET_LIMIT_OFFER, fetchLimitsOffer);
}
