import React from 'react';
import PropTypes from 'prop-types';

function Four(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{props.title || '4'}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8.15 4.25H9.6V8.875H10.4V10.075H9.6V11.675H8.15V10.075H5.125L5.05 9.125L8.15 4.25ZM8.075 6.325L6.475 8.875H8.075V6.325Z"
      />
    </svg>
  );
}
Four.propTypes = {
  title: PropTypes.string,
};

Four.defaultProps = {
  title: '',
};

export default Four;
