import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectExportPage = state => state.exportPage || initialState;

const selectLoadingFlag = () =>
  createSelector(selectExportPage, dataState => dataState.get('loading'));

const selectRecipients = () =>
  createSelector(selectExportPage, dataState => dataState.get('recipients').toJS());

const selectRecipientIds = () =>
  createSelector(selectExportPage, dataState => dataState.get('recipientIds').toJS());

const selectActiveFilters = () =>
  createSelector(selectExportPage, dataState => dataState.get('activeFilters').toJS());

const selectPeriodicReportPreferences = () =>
  createSelector(selectExportPage, dataState => dataState.get('periodicReportPreferences').toJS());

const selectFormsPriorities = () =>
  createSelector(selectExportPage, dataState => dataState.get('formsPriorities').toJS());

const selectObservationsPriorities = () =>
  createSelector(selectExportPage, dataState => dataState.get('observationsPriorities').toJS());

const selectReportsList = () =>
  createSelector(selectExportPage, dataState => dataState.get('reportsList').toJS());

const selectCustomizationConfigs = () =>
  createSelector(selectExportPage, dataState => dataState.get('customizationConfigs').toJS());

export {
  selectLoadingFlag,
  selectRecipients,
  selectRecipientIds,
  selectActiveFilters,
  selectFormsPriorities,
  selectPeriodicReportPreferences,
  selectObservationsPriorities,
  selectReportsList,
  selectCustomizationConfigs,
};
