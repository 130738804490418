import React, { memo } from 'react';

import { Typography, Tooltip, styled } from '@mui/material';
import PropTypes from 'prop-types';

const Container = styled('div')({
  marginInlineEnd: '8px',
});

const FullWidthTooltip = styled(Tooltip)({
  maxWidth: 600,
});

const FormCell = memo(({ form }) => {
  if (!form) {
    return null;
  }

  return (
    <Container>
      <FullWidthTooltip
        title={form.name}
        placement="top-start"
        leaveDelay={10}
        key="tooltip-name-form"
      >
        <Typography noWrap component="p" variant="subtitleBold">
          {form.name}
        </Typography>
      </FullWidthTooltip>
      <Typography noWrap component="p" variant="hint">
        {`#${form?.num}`}
      </Typography>
    </Container>
  );
});

FormCell.propTypes = {
  form: PropTypes.object,
};

FormCell.defaultProps = {
  form: {},
};

export default FormCell;
