import { useContext, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getCurrentPlan } from 'containers/ContractPage/actions';
import { selectCurrentPlan } from 'containers/ContractPage/selectors';
import { OrganizationContext } from 'containers/GlobalWrapper/BusinessOrganizationDataValidation';
import { isFeatureAccessible } from 'containers/GlobalWrapper/offer/FeatureAccessibility';
import { useFeatureFlags } from 'contexts/FeatureFlagsProvider';

export const useOverSeatLimitOffer = () => {
  const featureFlags = useFeatureFlags();
  const businessOrganization = useContext(OrganizationContext);
  const dispatch = useDispatch();
  const currentPlan = useSelector(selectCurrentPlan());

  const isOverSeatLimitOffer =
    businessOrganization?.used_licenses_count > (currentPlan?.total_user_plan || 0) &&
    isFeatureAccessible(
      businessOrganization?.id,
      currentPlan?.offer_id,
      'seats_limit',
      featureFlags,
    )?.isFeatureAccessible;

  useEffect(
    () => {
      dispatch(getCurrentPlan());
    },
    [dispatch],
  );

  return { isOverSeatLimitOffer, currentPlan };
};
