import React from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { preventDefault } from 'utils/event';

import styles from './button.styles';

const Button = React.forwardRef(
  (
    {
      size,
      variant,
      startIcon,
      endIcon,
      fullWidth,
      classes,
      children,
      onClick,
      prevent,
      loading,
      sx,
      ...otherProps
    },
    ref,
  ) => {
    const getIconSize = () => {
      if (size === 'large') {
        return '1rem';
      }
      if (size === 'small') {
        return '0.75rem';
      }
      return '0.875rem';
    };

    const displayIcon = icon => {
      if (icon)
        return {
          ...icon,
          props: {
            height: getIconSize(),
            width: getIconSize(),
            'aria-hidden': true,
            ...icon?.props,
          },
        };
      return null;
    };

    return (
      <LoadingButton
        classes={{
          root: classnames(classes.root, {
            [classes.default]: variant === 'default',
            [classes.danger]: variant === 'danger',
            [classes.dangerOutlined]: variant === 'danger outlined',
            [classes.warning]: variant === 'warning',
            [classes.contained]: variant === 'contained',
            [classes.outlined]: variant === 'outlined',
            [classes.text]: variant === 'text',
            [classes.textFlat]: variant === 'text flat',
            [classes.plain]: variant === 'plain' || variant === 'plain outlined',
            [classes.plainOutlined]: variant === 'plain outlined',
            [classes.info]: variant === 'info',
            [classes.infoDense]: variant === 'info dense',
            [classes.infoMore]: variant === 'info more',
            [classes.infoPlain]: variant === 'info plain',
            [classes.infoOutlined]: variant === 'info outlined',
            [classes.action]: variant === 'action',
            [classes.filter]: variant === 'filter',
            [classes.flat]: variant === 'flat',
            [classes.large]: size === 'large',
            [classes.medium]: size === 'medium',
            [classes.small]: size === 'small',
            [classes.fullWidth]: fullWidth,
            [classes.loading]: loading,
          }),
          loadingIndicator: classnames(classes.loadingIndicator, {
            [classes.loadingIndicatorDark]: variant === 'text',
            [classes.loadingIndicatorOrange]: variant === 'outlined',
          }),
        }}
        disableElevation
        disableRipple
        onClick={prevent ? preventDefault(onClick) : onClick}
        loading={loading}
        sx={sx}
        {...otherProps}
        ref={ref}
      >
        {displayIcon(startIcon)}
        {children}
        {displayIcon(endIcon)}
      </LoadingButton>
    );
  },
);

Button.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf([
    'default',
    'contained',
    'danger',
    'warning',
    'outlined',
    'plain',
    'plain outlined',
    'text',
    'text flat',
    'info',
    'info dense',
    'info plain',
    'info outlined',
    'action',
    'filter',
    'flat',
  ]),
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  prevent: PropTypes.bool,
  loading: PropTypes.bool,
  sx: PropTypes.object,
};

Button.defaultProps = {
  disabled: false,
  size: 'medium',
  variant: 'default',
  startIcon: null,
  endIcon: null,
  fullWidth: false,
  loading: false,
  prevent: false,
  onClick: () => null,
};

export default compose(withStyles(styles))(Button);
