import React from 'react';
import PropTypes from 'prop-types';

export const Euro = props => (
  <svg
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 4C19 4.55228 18.5523 5 18 5H12.4615C11.0904 5 9.71933 5.67291 8.67048 6.97148C7.61701 8.27578 7 10.0825 7 12C7 13.9175 7.61701 15.7242 8.67048 17.0285C9.71933 18.3271 11.0904 19 12.4615 19H18C18.5523 19 19 19.4477 19 20C19 20.5523 18.5523 21 18 21H12.4615C10.4053 21 8.4893 19.9872 7.1146 18.2852C5.74452 16.5889 5 14.326 5 12C5 9.674 5.74452 7.4111 7.1146 5.71481C8.4893 4.0128 10.4053 3 12.4615 3H18C18.5523 3 19 3.44772 19 4Z"
      fill="current"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 10C2 9.44772 2.44772 9 3 9H15C15.5523 9 16 9.44772 16 10C16 10.5523 15.5523 11 15 11H3C2.44772 11 2 10.5523 2 10Z"
      fill="current"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 14C2 13.4477 2.44772 13 3 13H15C15.5523 13 16 13.4477 16 14C16 14.5523 15.5523 15 15 15H3C2.44772 15 2 14.5523 2 14Z"
      fill="current"
    />
  </svg>
);

Euro.propTypes = {
  size: PropTypes.number,
}
