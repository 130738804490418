import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

function Key(props) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{props.title || props.intl.formatMessage(globalMessages.sign_out)}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7071 1.79289C21.3166 1.40237 20.6834 1.40237 20.2929 1.79289L11.3346 10.7512C10.26 9.965 8.93368 9.5 7.5 9.5C3.91015 9.5 1 12.4101 1 16C1 19.5899 3.91015 22.5 7.5 22.5C11.0899 22.5 14 19.5899 14 16C14 14.5663 13.535 13.24 12.7488 12.1654L15.5 9.41421L17.7929 11.7071C17.9804 11.8946 18.2348 12 18.5 12C18.7652 12 19.0196 11.8946 19.2071 11.7071L22.7071 8.2071C23.0976 7.81658 23.0976 7.18341 22.7071 6.79289L20.4142 4.5L21.7071 3.20711C22.0976 2.81658 22.0976 2.18342 21.7071 1.79289ZM19 5.91421L20.5858 7.49999L18.5 9.58579L16.9142 8L19 5.91421ZM7.5 11.5C8.74292 11.5 9.86655 12.0026 10.682 12.818C11.4974 13.6335 12 14.7571 12 16C12 18.4853 9.98528 20.5 7.5 20.5C5.01472 20.5 3 18.4853 3 16C3 13.5147 5.01472 11.5 7.5 11.5Z"
        fill="#848F9D"
      />
    </svg>
  );
}
Key.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

Key.defaultProps = {
  title: '',
};

export default injectIntl(Key);
