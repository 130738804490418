import React from 'react';

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Button from 'components/Button';
import ArrowLeft from 'components/SvgComponents/icons/ArrowLeft';
import { setEmailRegistration } from 'containers/GlobalWrapper/actions';
import globalMessages from 'translations/messages/global-messages';
import sessionStorageUser from 'utils/sessionStorageUser';

import { resetLogin } from '../actions';

export const styles = theme => ({
  backArrow: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(2),
    width: '24px',
    height: '24px',
  },
});

function BackButton(props) {
  const { classes, intl, className, dispatchResetLogin, dispatchSetEmailRegistration } = props;
  const { authState, oktaAuth } = useOktaAuth();
  const logoutOrResetLogin = () => {
    sessionStorageUser.setFinalcadTempRegistrationInfo(null);
    sessionStorageUser.setIsSignUp(null);
    if (authState?.isAuthenticated) {
      oktaAuth.signOut();
    }
    dispatchResetLogin();
    dispatchSetEmailRegistration();
  };

  return (
    <Button
      className={className}
      size="large"
      variant="plain"
      onClick={logoutOrResetLogin}
      startIcon={<ArrowLeft className={classes.backArrow} />}
    >
      <Typography variant="body" component="p">
        {intl.formatMessage(globalMessages.back)}
      </Typography>
    </Button>
  );
}

BackButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  intl: intlShape.isRequired,
  dispatchResetLogin: PropTypes.func.isRequired,
  dispatchSetEmailRegistration: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dispatchResetLogin: resetLogin,
  dispatchSetEmailRegistration: setEmailRegistration,
};

const withConnect = connect(
  null,
  mapDispatchToProps,
);

export default compose(
  injectIntl,
  withStyles(styles),
  withConnect,
)(BackButton);
