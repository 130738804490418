export const splitName = name => {
  const items = name.split(' ');
  const initialData = '';
  const dataJoin = items.map(item => initialData.concat(item.charAt(0)));
  return dataJoin.slice(0, 2).join('');
};

export default splitName;

export const splitCenterEllipse = (str, limit) => {
  const maxLimit = limit * 2;
  if (str && str.length > maxLimit) {
    return `${str.substr(0, limit)} ... ${str.substr(
      str.length - (limit - 3),
      str.length,
    )}`;
  }
  return str;
};
