import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import { withStyles } from '@mui/styles';
import Tile from 'components/Tile/Tile';
import Tooltip from 'components/Tooltip';

import globalMessages from 'translations/messages/global-messages';

export const getUserStatusTranslation = (intl, user) => {
  if (user?.is_processing) {
    return intl.formatMessage(globalMessages.processing);
  }

  if (user?.is_pending) {
    return intl.formatMessage(globalMessages.pending);
  }

  return null;
};

const styles = theme => ({
  pending: {
    borderRadius: theme.spacing(),
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.light,
    margin: `0 ${theme.spacing()}`,
  },
  pendingLabel: {
    fontSize: '0.688rem',
    padding: `0 ${theme.spacing(2)}`,
  },
});

export function UserMemberTile(props) {
  const { classes, intl, showTooltip, user } = props;

  const titleLabel = getUserStatusTranslation(intl, user);

  if (!titleLabel) {
    return null;
  }

  const tile = (
    <Tile
      label={titleLabel}
      classes={{ root: classes.pending, label: classes.pendingLabel }}
    />
  );

  if (!showTooltip) {
    return tile;
  }
  const title = intl.formatMessage(globalMessages.confirm_their_account);
  return (
    <Tooltip
      title={title}
      placement="top-start"
      leaveDelay={10}
      key="tooltip-pending"
    >
      <span>{tile}</span>
    </Tooltip>
  );
}

UserMemberTile.propTypes = {
  intl: intlShape.isRequired,
  user: PropTypes.object.isRequired,
  showTooltip: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

UserMemberTile.defaultProps = {
  showTooltip: false,
};

export default compose(
  injectIntl,
  withStyles(styles),
)(UserMemberTile);
