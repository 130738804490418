import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@mui/styles';

export const styles = theme => ({
  divider: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '1rem!important',
    },
  },
  extraSmall: {
    height: '1rem',
  },
  small: {
    height: '2rem',
  },
  medium: {
    height: '4rem',
  },
  large: {
    height: '6rem',
  },
});

export function NavDivider(props) {
  const { classes, size } = props;

  return (
    <div
      className={classNames(classes.divider, {
        [classes.extraSmall]: size === 'extra-small',
        [classes.small]: size === 'small',
        [classes.medium]: size === 'medium',
        [classes.large]: size === 'large',
      })}
    />
  );
}

NavDivider.propTypes = {
  classes: PropTypes.object,
  size: PropTypes.oneOf(['extra-small', 'small', 'medium', 'large']),
};

NavDivider.defaultProps = {
  size: 'small',
};

export default withStyles(styles)(NavDivider);
