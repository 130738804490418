import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import Tile from 'components/Tile/Tile';
import { getTranslation } from 'utils/localization';

const styles = theme => ({
  labelAdmin: {
    color: `${theme.palette.info.main}`,
    backgroundColor: `${theme.palette.info.main}1A`,
    borderRadius: 4,
    marginLeft: theme.spacing(2),
    padding: `0 ${theme.spacing()}`,
    fontSize: '0.750rem',
  },
});

export function UserTile(props) {
  const { classes, user } = props;
  const userRole =
    user?.organization_role?.names?.translations || user?.project_role?.names?.translations;
  const userRoleNameFallback = user?.organization_role?.name || user?.project_role?.name;

  return (
    <Tile label={getTranslation(userRole, userRoleNameFallback)} className={classes.labelAdmin} />
  );
}

UserTile.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
};

UserTile.defaultProps = {};

export default compose(withStyles(styles))(UserTile);
