export const buttonStyle = theme => ({
  root: {
    fontFamily: 'Effra Medium, Roboto',
    textTransform: 'none',
    lineHeight: 1,
    display: 'inline-flex',
    minWidth: 0,
    width: 'fit-content',
    height: 'fit-content',
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
    borderRadius: '2em',
    '&.Mui-disabled': {
      color: 'red',
    },
  },
  fullWidth: {
    width: '100%',
  },
  default: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    border: `2px solid ${theme.palette.secondary.main}`,
    '&.Mui-disabled': {
      color: theme.palette.common.white,
      opacity: '0.3',
    },
    '&:hover:not(disabled)': {
      backgroundColor: theme.palette.custom.button.orange.medium,
      border: `2px solid ${theme.palette.custom.button.orange.medium}`,
    },
    '&:focus:not(disabled)': {
      backgroundColor: theme.palette.custom.button.orange.medium,
      border: `2px solid ${theme.palette.custom.button.orange.medium}`,
    },
    '&:active:not(disabled)': {
      backgroundColor: theme.palette.custom.button.orange.black,
      border: `2px solid ${theme.palette.custom.button.orange.black}`,
    },
  },
  contained: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.dark,
    border: `2px solid ${theme.palette.background.dark}`,
    '&.Mui-disabled': {
      color: theme.palette.common.white,
      opacity: '0.3',
    },
    '&:hover:not(disabled)': {
      backgroundColor: theme.palette.custom.button.blue.black,
      border: `2px solid ${theme.palette.custom.button.blue.black}`,
    },
    '&:focus:not(disabled)': {
      backgroundColor: theme.palette.custom.button.blue.black,
      border: `2px solid ${theme.palette.custom.button.blue.black}`,
    },
  },
  warning: {
    color: theme.palette.text.tertiary,
    backgroundColor: theme.palette.warning.main,
    border: `2px solid ${theme.palette.warning.main}`,
    '&.Mui-disabled': {
      color: theme.palette.text.tertiary,
      opacity: '0.3',
    },
    '&:hover:not(disabled)': {
      backgroundColor: theme.palette.warning.dark,
      border: `2px solid ${theme.palette.warning.dark}`,
    },
    '&:focus:not(disabled)': {
      backgroundColor: theme.palette.warning.dark,
      border: `2px solid ${theme.palette.warning.dark}`,
    },
    '&:active:not(disabled)': {
      backgroundColor: theme.palette.warning.dark,
      border: `2px solid ${theme.palette.warning.dark}`,
    },
  },
  info: {
    color: theme.palette.info.main,
    backgroundColor: theme.palette.info.light,
    border: `2px solid ${theme.palette.info.light}`,
    '&.Mui-disabled': {
      color: theme.palette.info.main,
      opacity: '0.5',
    },
    '&:hover:not(disabled)': {
      backgroundColor: theme.palette.custom.button.blue.medium,
      border: `2px solid ${theme.palette.custom.button.blue.medium}`,
    },
    '&:focus:not(disabled)': {
      backgroundColor: theme.palette.custom.button.blue.medium,
      border: `2px solid ${theme.palette.custom.button.blue.medium}`,
    },
    '&:active:not(disabled)': {
      backgroundColor: theme.palette.custom.button.blue.medium,
      border: `2px solid ${theme.palette.custom.button.blue.medium}`,
    },
  },
  infoPlain: {
    fontFamily: 'Effra, Roboto',
    color: theme.palette.info.main,
    backgroundColor: theme.palette.background.white,
    border: `2px solid ${theme.palette.background.white}`,
    '&.Mui-disabled': {
      color: theme.palette.info.main,
      opacity: '0.5',
    },
    '&:hover:not(disabled)': {
      color: theme.palette.text.dark,
      backgroundColor: theme.palette.background.white,
      border: `2px solid ${theme.palette.background.white}`,
    },
    '&:focus:not(disabled)': {
      color: theme.palette.text.dark,
      backgroundColor: theme.palette.background.white,
      border: `2px solid ${theme.palette.background.white}`,
    },
    '&:active:not(disabled)': {
      color: theme.palette.text.dark,
      backgroundColor: theme.palette.background.white,
      border: `2px solid ${theme.palette.background.white}`,
    },
  },
  infoDense: {
    borderRadius: '0.25rem',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.info.main,
    border: `2px solid ${theme.palette.info.main}`,
    '&.Mui-disabled': {
      color: theme.palette.common.white,
      opacity: '0.5',
    },
    '&:hover:not(disabled)': {
      backgroundColor: theme.palette.custom.button.blue.dark,
      border: `2px solid ${theme.palette.custom.button.blue.dark}`,
    },
    '&:focus:not(disabled)': {
      backgroundColor: theme.palette.custom.button.blue.dark,
      border: `2px solid ${theme.palette.custom.button.blue.dark}`,
    },
    '&:active:not(disabled)': {
      backgroundColor: theme.palette.custom.button.blue.darker,
      border: `2px solid ${theme.palette.custom.button.blue.darker}`,
    },
  },
  infoMore: {
    borderRadius: '30px',
    color: theme.palette.common.white,
    backgroundColor: '#76C4FF',
    border: `2px solid ${theme.palette.info.main}`,
    '&.Mui-disabled': {
      color: theme.palette.common.white,
      opacity: '0.5',
    },
    '&:hover:not(disabled)': {
      backgroundColor: theme.palette.custom.button.blue.dark,
      border: `2px solid ${theme.palette.custom.button.blue.dark}`,
    },
    '&:focus:not(disabled)': {
      backgroundColor: theme.palette.custom.button.blue.dark,
      border: `2px solid ${theme.palette.custom.button.blue.dark}`,
    },
    '&:active:not(disabled)': {
      backgroundColor: theme.palette.custom.button.blue.darker,
      border: `2px solid ${theme.palette.custom.button.blue.darker}`,
    },
  },
  infoOutlined: {
    color: theme.palette.info.main,
    backgroundColor: 'transparent',
    border: `2px solid currentColor`,
    '&.Mui-disabled': {
      color: theme.palette.info.main,
      opacity: '0.3',
    },
    '&:hover:not(disabled)': {
      backgroundColor: 'transparent',
      color: theme.palette.text.dark,
      border: `2px solid currentColor`,
    },
    '&:focus:not(disabled)': {
      backgroundColor: 'transparent',
      color: theme.palette.text.dark,
      border: `2px solid currentColor`,
    },
    '&:active:not(disabled)': {
      backgroundColor: 'transparent',
      color: theme.palette.text.dark,
      border: `2px solid currentColor`,
    },
  },
  plain: {
    fontFamily: 'Effra, Roboto',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.white,
    border: `2px solid ${theme.palette.background.white}`,
    '&.Mui-disabled': {
      color: theme.palette.text.dark,
      opacity: '0.3',
    },
    '&:hover:not(disabled)': {
      backgroundColor: theme.palette.background.white,
      border: `2px solid ${theme.palette.background.white}`,
      color: theme.palette.text.dark,
    },
    '&:focus:not(disabled)': {
      backgroundColor: theme.palette.background.white,
      border: `2px solid ${theme.palette.background.white}`,
      color: theme.palette.text.dark,
    },
    '&:active:not(disabled)': {
      backgroundColor: theme.palette.background.white,
      border: `2px solid ${theme.palette.background.white}`,
      color: theme.palette.text.dark,
    },
  },
  filter: {
    fontFamily: 'Effra, Roboto',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.white,
    border: `2px solid ${theme.palette.background.primary}`,
    '&.Mui-disabled': {
      color: theme.palette.text.primary,
      opacity: '0.3',
    },
    '&:hover:not(disabled)': {
      backgroundColor: theme.palette.background.white,
      border: `2px solid ${theme.palette.background.secondary}`,
      color: theme.palette.text.primary,
    },
    '&:focus:not(disabled)': {
      backgroundColor: theme.palette.background.white,
      border: `2px solid ${theme.palette.background.secondary}`,
      color: theme.palette.text.primary,
    },
    '&:active:not(disabled)': {
      backgroundColor: theme.palette.background.white,
      border: `2px solid ${theme.palette.background.secondary}`,
      color: theme.palette.text.primary,
    },
  },
  text: {
    fontFamily: 'Effra, Roboto',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.background.white,
    border: `2px solid ${theme.palette.background.white}`,
    '&.Mui-disabled': {
      color: theme.palette.secondary.main,
      opacity: '0.3',
    },
    '&:hover:not(disabled)': {
      backgroundColor: theme.palette.background.white,
      border: `2px solid ${theme.palette.background.white}`,
      color: theme.palette.custom.button.orange.darker,
    },
    '&:focus:not(disabled)': {
      backgroundColor: theme.palette.background.white,
      border: `2px solid ${theme.palette.background.white}`,
      color: theme.palette.custom.button.orange.medium,
    },
    '&:active:not(disabled)': {
      backgroundColor: theme.palette.background.white,
      border: `2px solid ${theme.palette.background.white}`,
      color: theme.palette.custom.button.orange.medium,
    },
  },
  textFlat: {
    fontFamily: 'Effra, Roboto',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.background.white,
    borderRadius: 0,
    border: `0`,
    '&.Mui-disabled': {
      color: theme.palette.secondary.main,
      opacity: '0.3',
    },
    '&:hover:not(disabled)': {
      backgroundColor: theme.palette.background.primary,
      color: theme.palette.custom.button.orange.black,
    },
    '&:focus:not(disabled)': {
      backgroundColor: theme.palette.background.white,
      color: theme.palette.custom.button.orange.medium,
    },
    '&:active:not(disabled)': {
      backgroundColor: theme.palette.background.white,
      color: theme.palette.custom.button.orange.medium,
    },
  },
  flat: {
    color: theme.palette.text.dark,
    backgroundColor: 'transparent',
    border: `2px solid transparent`,
    '&.Mui-disabled': {
      color: theme.palette.text.dark,
      opacity: '0.3',
    },
    '&:hover:not(disabled)': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
      border: `2px solid transparent`,
      color: theme.palette.custom.button.blue.black,
      cursor: 'pointer',
    },
    '&:focus:not(disabled)': {
      backgroundColor: 'transparent',
      border: `2px solid transparent`,
      color: theme.palette.custom.button.blue.black,
    },
    '&:active:not(disabled)': {
      backgroundColor: 'transparent',
      border: `2px solid transparent`,
      color: theme.palette.custom.button.blue.black,
    },
  },
  plainOutlined: {
    border: `2px solid currentColor`,
    '&:hover:not(disabled)': {
      border: `2px solid currentColor`,
    },
    '&:focus:not(disabled)': {
      border: `2px solid currentColor`,
    },
    '&:active:not(disabled)': {
      border: `2px solid currentColor`,
    },
  },
  outlined: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.background.white,
    border: `2px solid currentColor`,
    '&.Mui-disabled': {
      color: theme.palette.secondary.main,
      opacity: '0.3',
    },
    '&:hover:not(disabled)': {
      backgroundColor: theme.palette.background.white,
      color: theme.palette.custom.button.orange.medium,
      border: `2px solid currentColor`,
    },
    '&:focus:not(disabled)': {
      backgroundColor: theme.palette.background.white,
      color: theme.palette.custom.button.orange.medium,
      border: `2px solid currentColor`,
    },
    '&:active:not(disabled)': {
      backgroundColor: theme.palette.background.white,
      color: theme.palette.custom.button.orange.black,
      border: `2px solid currentColor`,
    },
  },
  danger: {
    color: theme.palette.error.light,
    backgroundColor: theme.palette.error.main,
    border: `2px solid ${theme.palette.error.main}`,
    '&.Mui-disabled': {
      color: theme.palette.text.white,
      opacity: '0.5',
    },
    '&:hover:not(disabled)': {
      backgroundColor: theme.palette.error.dark,
      border: `2px solid ${theme.palette.error.dark}`,
    },
    '&:focus:not(disabled)': {
      backgroundColor: theme.palette.error.dark,
      border: `2px solid ${theme.palette.error.dark}`,
    },
    '&:active:not(disabled)': {
      backgroundColor: theme.palette.error.dark,
      border: `2px solid ${theme.palette.error.dark}`,
    },
  },
  dangerOutlined: {
    color: theme.palette.error.main,
    backgroundColor: 'transparent',
    border: `2px solid currentColor`,
    '&.Mui-disabled': {
      color: theme.palette.error.dark,
      opacity: '0.3',
    },
    '&:hover:not(disabled)': {
      backgroundColor: 'transparent',
      color: theme.palette.error.dark,
      border: `2px solid currentColor`,
    },
    '&:focus:not(disabled)': {
      backgroundColor: 'transparent',
      color: theme.palette.error.dark,
      border: `2px solid currentColor`,
    },
    '&:active:not(disabled)': {
      backgroundColor: 'transparent',
      color: theme.palette.error.dark,
      border: `2px solid currentColor`,
    },
  },
  large: {
    fontSize: '1rem',
    padding: '0.625em 1em',
  },
  medium: {
    fontSize: '0.875rem',
    padding: `0.5em 1em`,
  },
  small: {
    fontSize: '0.75rem',
    padding: `0.250em 0.5em`,
    gap: '0.125rem',
  },
  action: {
    fontFamily: 'Effra, Roboto',
    borderRadius: '0.75rem',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.primary,
    border: `2px solid ${theme.palette.background.primary}`,
    '&.Mui-disabled': {
      color: theme.palette.text.primary,
      opacity: '0.5',
    },
    '&:hover:not(disabled)': {
      color: theme.palette.text.dark,
      backgroundColor: theme.palette.background.primary,
      border: `2px solid ${theme.palette.background.primary}`,
    },
    '&:focus:not(disabled)': {
      color: theme.palette.text.dark,
      backgroundColor: theme.palette.background.primary,
      border: `2px solid ${theme.palette.background.primary}`,
    },
    '&:active:not(disabled)': {
      color: theme.palette.text.dark,
      backgroundColor: theme.palette.background.secondary,
      border: `2px solid ${theme.palette.background.secondary}`,
    },
  },
  loading: {
    color: 'transparent !important',
  },
  loadingIndicator: {
    color: theme.palette.common.white,
  },
  loadingIndicatorDark: {
    color: theme.palette.common.black,
  },
  loadingIndicatorOrange: {
    color: theme.palette.secondary.main,
  },
});

export default buttonStyle;
