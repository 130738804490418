import React from 'react';

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import Button from 'components/Button';
import Dialog from 'components/dialogs/Dialog';
import AlertCircle from 'components/SvgComponents/icons/AlertCircle';
import globalMessages from 'translations/messages/global-messages';

export const styles = theme => ({
  container: {
    padding: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      padding: '2.5em 4.5em',
    },
  },
  title: {
    color: theme.palette.text.dark,
    fontSize: '1.250rem',
    fontStyle: 'normal',
    fontWeight: 'bold',
    marginBottom: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  placement: { textAlign: 'center', marginBottom: '1.5rem' },
  icon: { marginRight: '0.5em', color: theme.palette.error.main },
});
const ConfirmDialog = ({
  classes,
  intl,
  id,
  open,
  onClose,
  onSubmit,
  title,
  message,
  displayConfirm,
  submitLabel,
  areYouSureLabel,
  showAlertIcon,
  displayCancel,
  additionalMessage,
  additionalElement,
  disabledOnSubmit,
  children,
}) => {
  let renderMessage = null;
  if (typeof message === 'string') {
    renderMessage = (
      <Typography className={classes.placement} component="p" variant="bodyLight">
        {message}
      </Typography>
    );
  } else {
    renderMessage = message;
  }
  const cancelButton = displayCancel ? (
    <Button onClick={onClose} key="no" variant="plain">
      {intl.formatMessage(globalMessages.cancel)}
    </Button>
  ) : null;
  return (
    <Dialog
      id={`${id}_dialog-confirm-remove`}
      open={open}
      fullWidth
      maxWidth="sm"
      onCloseDialog={onClose}
      dialogTitleNode={title}
      dialogActionNode={[
        cancelButton,
        <Button onClick={onSubmit} key="yes" variant="danger" disabled={disabledOnSubmit}>
          {submitLabel || intl.formatMessage(globalMessages.yes_remove)}
        </Button>,
      ]}
    >
      <div className={classes.container}>
        <Typography className={classes.title} align="center" component="h1">
          {showAlertIcon && <AlertCircle width={16} height={16} className={classes.icon} />}
          {areYouSureLabel || intl.formatMessage(globalMessages.are_you_sure)}
        </Typography>
        {renderMessage}
        {additionalMessage && (
          <Typography className={classes.placement} component="p" variant="bodyLight">
            {additionalMessage}
          </Typography>
        )}
        {additionalElement && <div className={classes.placement}>{additionalElement}</div>}
        {displayConfirm && (
          <Typography className={classes.placement} component="p" variant="bodyLight">
            {intl.formatMessage(globalMessages.confirm_continue)}
          </Typography>
        )}
        {children}
      </div>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  id: PropTypes.string,
  submitLabel: PropTypes.string,
  areYouSureLabel: PropTypes.string,
  open: PropTypes.bool,
  showAlertIcon: PropTypes.bool,
  displayConfirm: PropTypes.bool,
  displayCancel: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  additionalMessage: PropTypes.string,
  additionalElement: PropTypes.func,
  disabledOnSubmit: PropTypes.bool,
  children: PropTypes.node,
};

ConfirmDialog.defaultType = {
  title: '',
  message: '',
  displayConfirm: true,
  displayCancel: true,
  showAlertIcon: true,
  disabledOnSubmit: false,
};

export default compose(
  injectIntl,
  withStyles(styles),
)(ConfirmDialog);
