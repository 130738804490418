import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Typography, Box } from '@mui/material';

import globalMessages from 'translations/messages/global-messages';

const EndMessageComp = ({ intl, message }) => (
  <Box
    sx={{
      mt: 4,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
    }}
  >
    <Typography variant="hint">
      {intl.formatMessage(message || globalMessages.end_of_list)}
    </Typography>
  </Box>
);

EndMessageComp.propTypes = {
  intl: intlShape,
  message: PropTypes.string,
};

export const EndMessage = injectIntl(EndMessageComp);
