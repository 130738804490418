import React, { useContext, useMemo } from 'react';

import { IconButton, styled } from '@mui/material';
import { useTheme } from '@mui/styles';

import Settings from 'components/SvgComponents/icons/Settings';
import SettingsFill from 'components/SvgComponents/icons/SettingsFill';
import { OrganizationContext } from 'containers/GlobalWrapper/BusinessOrganizationDataValidation';
import { useColorMode } from 'contexts/ColorModeProvider';
import { Routing } from 'routing/routing';
import { authorizations, colorTheme } from 'utils/constants';
import { CustomNavLink } from 'utils/redirect-utils';

import { LOGO_TOOLBAR_HEIGHT, LOGO_TOOLBAR_WIDTH } from '../constants';

const SettingsButtonStyle = styled(IconButton)(() => ({
  '& .settingsSvgFill': {
    opacity: 0,
    position: 'absolute',
  },
  '&:hover .settingsSvg': {
    opacity: 0,
  },
  '&:hover .settingsSvgFill': {
    opacity: 1,
  },
}));

function SettingsButton() {
  const { mode } = useColorMode();
  const theme = useTheme();
  const isDarkMode = mode === colorTheme.dark;
  const organization = useContext(OrganizationContext);

  const iconColor = useMemo(
    () => (isDarkMode ? theme.palette.background.white : theme.palette.text.primary),
    [isDarkMode, theme.palette.background.white, theme.palette.text.primary],
  );

  const seeButton =
    organization?.authorizations?.indexOf(authorizations.organization.readSettings) > -1;

  return (
    seeButton && (
      <SettingsButtonStyle
        component={CustomNavLink}
        to={Routing.organizations.info(organization?.id)}
        variant="plain outlined"
      >
        <Settings
          width={LOGO_TOOLBAR_WIDTH}
          height={LOGO_TOOLBAR_HEIGHT}
          className={isDarkMode ? 'settingsSvgFill' : 'settingsSvg'}
          color={iconColor}
        />
        <SettingsFill
          width={LOGO_TOOLBAR_WIDTH}
          height={LOGO_TOOLBAR_HEIGHT}
          className={isDarkMode ? 'settingsSvg' : 'settingsSvgFill'}
          color={iconColor}
        />
      </SettingsButtonStyle>
    )
  );
}

SettingsButton.propTypes = {};

export default React.memo(SettingsButton);
