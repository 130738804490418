import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

function Bim(props) {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>
        {props.title || props.intl.formatMessage(globalMessages.file)}
      </title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00144 0.935094C6.30513 0.760092 6.64949 0.667969 7.00001 0.667969C7.35055 0.667969 7.69492 0.7601 7.99862 0.935116C7.99908 0.935383 7.99955 0.93565 8.00001 0.935918L12.6667 3.60258C12.9704 3.77795 13.2227 4.0301 13.3982 4.33375C13.5737 4.63739 13.6663 4.98186 13.6667 5.33258V10.6673C13.6663 11.018 13.5737 11.3625 13.3982 11.6661C13.2227 11.9698 12.9704 12.2219 12.6667 12.3973L12.6641 12.3988L8.00001 15.064C7.99961 15.0642 7.99921 15.0644 7.99881 15.0646C7.69507 15.2397 7.35062 15.3319 7.00001 15.3319C6.64941 15.3319 6.30498 15.2397 6.00124 15.0647C6.00083 15.0644 6.00042 15.0642 6.00001 15.064L1.33592 12.3988L1.33334 12.3973C1.02961 12.2219 0.777324 11.9698 0.601805 11.6661C0.426286 11.3625 0.333703 11.018 0.333344 10.6673V5.33258C0.333703 4.98186 0.426286 4.63739 0.601805 4.33375C0.777324 4.0301 1.02961 3.77795 1.33334 3.60258L1.33591 3.6011L6.00144 0.935094ZM7.66668 13.7188L12 11.2426C12.0004 11.2424 12.0007 11.2422 12.0011 11.242C12.1018 11.1835 12.1856 11.0997 12.2439 10.9989C12.3024 10.8976 12.3332 10.7828 12.3333 10.6659V5.71478L7.66668 8.38652V13.7188ZM7.00001 7.23181L11.6608 4.56345L7.33334 2.09062C7.23199 2.03211 7.11703 2.0013 7.00001 2.0013C6.88299 2.0013 6.76802 2.03211 6.66668 2.09062L6.66411 2.0921L2.33924 4.56345L7.00001 7.23181ZM1.66668 5.71478V10.6662C1.66685 10.783 1.69771 10.8977 1.75616 10.9989C1.81447 11.0997 1.89818 11.1835 1.99896 11.242C1.99931 11.2422 1.99966 11.2424 2.00001 11.2426L6.33334 13.7188V8.38652L1.66668 5.71478Z"
      />
    </svg>
  );
}
Bim.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

Bim.defaultProps = {
  title: '',
};

export default injectIntl(Bim);
