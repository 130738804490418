import React, { useEffect, useState } from 'react';

import { Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const styles = theme => ({
  styleTooltip: {
    backgroundColor: theme.palette.background.dark,
    borderRadius: 8,
    color: theme.palette.common.white,
    fontSize: '0.875rem',
    padding: theme.spacing(2),
    whiteSpace: 'pre-wrap',
  },
});

function UITooltip(props) {
  const {
    classes,
    children,
    placement,
    className,
    title,
    open,
    key,
    enterDelay,
    leaveDelay,
    forwardedRef,
    disableHoverListener,
  } = props;

  const [showTooltip, setShowTooltip] = useState(true);

  useEffect(
    () => {
      const checkForEllipsis = () => {
        const element = forwardedRef.current;
        const isOverflowing = element && element.offsetWidth < element.scrollWidth;
        setShowTooltip(isOverflowing);
      };
      if (forwardedRef?.current) {
        checkForEllipsis();
      } else {
        setShowTooltip(true);
      }
    },
    [forwardedRef],
  );

  return (
    <Tooltip
      classes={{
        tooltip: classNames({
          [classes.styleTooltip]: true,
          [className]: className,
        }),
      }}
      key={key}
      title={showTooltip ? title || '' : ''}
      placement={placement}
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      open={open}
      disableHoverListener={disableHoverListener}
    >
      {children}
    </Tooltip>
  );
}

UITooltip.defaultProps = {
  key: 'UITooltip',
  placement: 'top',
  enterDelay: 100,
};

UITooltip.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.any,
  open: PropTypes.bool,
  className: PropTypes.string,
  enterDelay: PropTypes.number,
  leaveDelay: PropTypes.number,
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  key: PropTypes.string,
  forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  disableHoverListener: PropTypes.bool,
};

export default withStyles(styles)(UITooltip);
