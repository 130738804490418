import React from 'react';

import { Box } from '@mui/material';

export const LoadMoreHackBox = () => (
  <Box
    sx={{
      position: 'absolute',
      bottom: '-50px',
      height: 40,
      width: 10, // just a random size so it exists
    }}
  />
);

// The point of this component is to force scroll ability in case of the actual data doesn't fill the height but there is more to load.
