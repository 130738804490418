import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

function ArrowHigh(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>
        {props.title || props.intl.formatMessage(globalMessages.high)}
      </title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C8.55228 0 9 0.447715 9 1V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V1C7 0.447715 7.44772 0 8 0Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29289 0.292893C7.65338 -0.0675907 8.22061 -0.0953203 8.6129 0.209705L8.70711 0.292893L13.7071 5.29289C14.0976 5.68342 14.0976 6.31658 13.7071 6.70711C13.3466 7.06759 12.7794 7.09532 12.3871 6.7903L12.2929 6.70711L8 2.415L3.70711 6.70711C3.34662 7.06759 2.77939 7.09532 2.3871 6.7903L2.29289 6.70711C1.93241 6.34662 1.90468 5.77939 2.2097 5.3871L2.29289 5.29289L7.29289 0.292893Z"
      />
    </svg>
  );
}
ArrowHigh.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

ArrowHigh.defaultProps = {
  title: '',
};

export default injectIntl(ArrowHigh);
