import React from 'react';
import PropTypes from 'prop-types';

export const Environment = props => (
  <svg
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25394 16.3837L14.2929 8.34476C14.6834 7.95424 15.3166 7.95424 15.7071 8.34476C16.0976 8.73529 16.0976 9.36845 15.7071 9.75898L7.66817 17.7979C10.6539 19.1316 13.4488 18.6082 15.5374 16.6637C17.8418 14.5181 19.4521 10.4838 19.291 4.76081C13.568 4.59977 9.53371 6.21001 7.38817 8.51448C5.44365 10.603 4.92028 13.3979 6.25394 16.3837ZM4.75777 17.8799C2.73896 14.0085 3.21538 10.0613 5.92438 7.15164C8.73052 4.13764 13.7603 2.41833 20.3088 2.80353C20.8148 2.8333 21.2185 3.23703 21.2483 3.74309C21.6335 10.2916 19.9142 15.3213 16.9002 18.1275C13.9905 20.8364 10.0434 21.3129 6.172 19.2941L4.45711 21.009C4.06658 21.3995 3.43342 21.3995 3.04289 21.009C2.65237 20.6185 2.65237 19.9853 3.04289 19.5948L4.75777 17.8799Z"
      fill="current"
    />
  </svg>
);

Environment.propTypes = {
  size: PropTypes.number,
}
