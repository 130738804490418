import { GET_AUTHOR, SET_AUTHOR } from './constants';

export const getAuthor = ({ authorId }) => ({
  type: GET_AUTHOR,
  authorId,
});

export const setAuthor = ({ author }) => ({
  type: SET_AUTHOR,
  author,
});
