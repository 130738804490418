import React from 'react';

import { Typography } from '@mui/material';
import moment from 'moment';

import DateCell from 'components/FCTable/components/CoreCells/DateCell';
import MediaCell from 'components/FCTable/components/CoreCells/MediaCell';
import TextCell from 'components/FCTable/components/CoreCells/TextCell';
import CompanyCell from 'components/FCTable/components/ModuleCells/CompanyCell';
import FormCell from 'components/FCTable/components/ModuleCells/FormCell';
import ObservationCell from 'components/FCTable/components/ModuleCells/ObservationCell';
import StatusCell from 'components/FCTable/components/ModuleCells/StatusCell';
import Member from 'components/Member';
import DetailCellSkeleton from 'components/SkeletonScreen/components/DetailCellSkeleton';
import MemberCellSkeleton from 'components/SkeletonScreen/components/MemberCellSkeleton';
import StatusCellSkeleton from 'components/SkeletonScreen/components/StatusCellSkeleton';
import TextCellSkeleton from 'components/SkeletonScreen/components/TextCellSkeleton';
import { getIcon } from 'components/SuperAdminLibrary/constantsPriority';
import UserPendingTile from 'components/Tile/Status/UserMemberTile';
import UserTile from 'components/Tile/Status/UserTile';
import StatusForm from 'containers/OneProjectPage/FormsPage/components/Status';
import FormTemplate from 'containers/SuperAdmin/FormsTemplateLibrary/components/FormTemplate';
import { getDocumentTypeTranslation } from 'utils/constants';
import { anonymizeEmail } from 'utils/email';
import { getArrayOrDefault, objectByString } from 'utils/mappers';

import { TypographyEllipsis } from 'components/Templates/TypographyEllipsis';
import DetailCell from './DetailCell';
import DetailedDateCell from './DetailedDateCell';
import DocumentCell from './DocumentCell';
import MemberCell from './MemberCell';
import MemberCellWithUserId from './MemberCellWithUserId';
import MembersAvatarsCell from './MembersAvatarsCell';
import PlanFolderCell from './PlanFolderCell';
import PlanItemCell from './PlanItemCell';
import PriorityCell from './PriorityCell';
import SwitchCell from './SwitchCell';

const parseData = rowData => {
  const stringRowData = JSON.stringify(rowData, null, 2);
  return JSON.parse(stringRowData);
};

/**
 * Should be used when member is already existing in a list and
 * should not self-load itself
 * @see /app/containers/OneProjectPage/MembersPage/index.js
 */
// Black bold member with mail in first column
export const renderLoadedMemberCell = params => {
  const { rowData, columnData, isRestrictedInformation, currentUserId } = params;
  const parsedRowData = parseData(rowData);
  const { loading } = columnData.loading || {};

  if (isRestrictedInformation) {
    if (parsedRowData?.email != null && parsedRowData?.user_id !== currentUserId) {
      parsedRowData.email = anonymizeEmail(parsedRowData.email);
    }
  }

  if (loading) {
    return <MemberCellSkeleton />;
  }

  if (!Object.keys(parsedRowData).length) {
    return null;
  }

  return (
    <Member member={parsedRowData} isFirstColumnInTable>
      <UserPendingTile showTooltip user={parsedRowData} />
      {parsedRowData.user_id === currentUserId && <UserTile showTooltip user={parsedRowData} />}
    </Member>
  );
};

// Gray member not in first column without avatar
export const renderMemberCell = params => {
  const { cellData, columnData } = params;
  const { loading } = columnData.loading || {};

  return <MemberCell userId={cellData} loading={loading} />;
};

export const renderMemberCellWithUserId = params => {
  const { dataKey, rowData } = params;
  const parsedRowData = parseData(rowData);
  const { loading } = params?.columnData?.loading || {};
  const keyValue = objectByString(parsedRowData, dataKey);
  return <MemberCellWithUserId userId={keyValue} loading={loading} />;
};

export const renderMembersAvatarsCell = params => {
  const { users, columnData } = params;
  const { loading } = columnData.loading || {};

  return <MembersAvatarsCell users={users} loading={loading} />;
};

// Gray member or assignee without mail and not in first column
export const renderAssigneeCell = params => {
  const { rowData, members, classNamesAvatar, columnData, dataKey } = params;
  const parsedRowData = parseData(rowData);
  const { loading } = columnData?.loading || {};
  const memberFound = getArrayOrDefault(members).find(
    member =>
      member.user_id === parsedRowData.assignee_id || member.user_id === parsedRowData[dataKey],
  );
  return (
    <MemberCell
      member={memberFound}
      hideEmail
      loading={loading}
      memberId={parsedRowData.assignee_id || parsedRowData[dataKey]}
      classNamesAvatar={classNamesAvatar}
    />
  );
};

export const renderDateCell = params => {
  const { cellData, imperative = false, showIcon, columnData } = params;
  const daysLeft = moment(cellData)
    .startOf('day')
    .diff(moment().endOf('day'), 'days');

  return (
    <DateCell
      cellData={cellData}
      imperative={imperative ? daysLeft < 0 : false}
      loading={columnData?.loading}
      showIcon={showIcon}
    />
  );
};

export const renderDetailedDateCell = params => {
  const { cellData, columnData } = params;
  const { loading } = columnData.loading;

  return <DetailedDateCell cellData={cellData} loading={loading} />;
};

export const renderTextCell = params => {
  const { dataKey, rowData, description } = params;
  const parsedRowData = parseData(rowData);
  const { loading } = params?.columnData?.loading || {};
  const keyValue = objectByString(parsedRowData, dataKey);

  return <TextCell loading={loading} keyValue={keyValue} description={description} />;
};

export const renderMediaCell = params => {
  const { cellData = {}, columnData } = params;

  const { loading } = columnData.loading || {};
  const stringRowData = JSON.stringify(cellData, null, 2);
  const parsedRowData = JSON.parse(stringRowData);

  return <MediaCell media_resource={parsedRowData} loading={loading} />;
};

export const renderCommunicationCell = params => {
  const { rowData, columnData } = params;
  const parsedRowData = parseData(rowData);
  const { loading } = columnData.loading || {};
  const phoneNumber = parsedRowData?.communications
    ? parsedRowData?.communications?.find(com => com.communication_type === 'Phone')
    : '';
  return <TextCell loading={loading} keyValue={phoneNumber?.value || ''} />;
};

export const renderDetailCell = params => {
  const { rowData, columnData } = params;
  const parsedRowData = parseData(rowData);
  const { loading } = columnData.loading || {};

  return <DetailCell data={parsedRowData} loading={loading} />;
};

export const renderCompanyCell = params => {
  const { rowData } = params;
  const stringRowData = JSON.stringify(rowData, null, 2);
  const parsedRowData = JSON.parse(stringRowData);

  return <CompanyCell companyId={parsedRowData.assigned_company_id} />;
};

export const renderPriorityCell = params => {
  const { rowData, columnData } = params;
  const parsedRowData = parseData(rowData);
  const { loading } = columnData.loading;

  return <PriorityCell priority={parsedRowData} loading={loading} />;
};

export const renderObservationCell = params => {
  const { rowData, columnData } = params;
  const parsedRowData = parseData(rowData);
  const { loading } = columnData?.loading || {};

  return <ObservationCell loading={loading} observation={parsedRowData} />;
};

export const renderDocumentCell = params => {
  const {
    rowData,
    columnData,
    documentToRename,
    renameDocument,
    setDocumentToRename,
    listRequestOption,
  } = params;
  const parsedRowData = parseData(rowData);
  const { loading } = columnData.loading;

  return (
    <DocumentCell
      loading={loading}
      document={parsedRowData}
      listRequestOption={listRequestOption}
      documentToRename={documentToRename}
      renameDocument={renameDocument}
      setDocumentToRename={setDocumentToRename}
    />
  );
};

export const renderSwitchCell = params => {
  const { rowData, cellData, onValueChange, disabled, columnData } = params;
  const parsedRowData = parseData(rowData);
  const { loading } = columnData.loading || {};

  if (loading) {
    return <DetailCellSkeleton />;
  }

  return (
    <SwitchCell
      value={cellData || false}
      onValueChange={(_, value) => onValueChange(value, parsedRowData)}
      loading={loading}
      disabled={disabled}
    />
  );
};

export const renderPriority = params => {
  const { rowData } = params;
  const stringRowData = JSON.stringify(rowData, null, 2);
  const parsedRowData = JSON.parse(stringRowData);

  if (!Object.keys(parsedRowData).length) {
    return null;
  }

  return <div>{getIcon(parsedRowData?.priority?.icon, parsedRowData?.priority?.color)}</div>;
};

export const renderStatus = params => {
  const { rowData, columnData, status } = params;
  const stringRowData = JSON.stringify(rowData, null, 2);
  const parsedRowData = JSON.parse(stringRowData);
  const { loading } = columnData.loading || {};

  if (loading) {
    return <StatusCellSkeleton />;
  }

  if (!Object.keys(parsedRowData).length) {
    return null;
  }

  return (
    <StatusForm
      status={status}
      listsRequestOption={columnData}
      selected={parsedRowData?.status}
      formId={parsedRowData.id}
    />
  );
};

export const renderStatusCell = params => {
  const { rowData, columnData, loading, disable } = params;
  const parsedRowData = parseData(rowData);

  return (
    <StatusCell
      disabled={disable}
      listsRequestOption={columnData}
      selected={parsedRowData.status}
      observationId={parsedRowData.id}
      loading={loading}
    />
  );
};

export const renderPlanFolder = params => {
  const { rowData, columnData } = params;

  const stringRowData = JSON.stringify(rowData, null, 2);
  const parsedRowData = JSON.parse(stringRowData);

  const { loading } = columnData.loading || {};

  if (loading) {
    return <TextCellSkeleton />;
  }

  if (!Object.keys(parsedRowData).length) {
    return null;
  }

  return <PlanFolderCell item={parsedRowData} />;
};

export const renderPlanItem = params => {
  const { rowData, columnData } = params;

  const stringRowData = JSON.stringify(rowData, null, 2);
  const parsedRowData = JSON.parse(stringRowData);

  const { loading } = columnData.loading || {};

  if (loading) {
    return <TextCellSkeleton />;
  }

  if (!Object.keys(parsedRowData).length) {
    return null;
  }

  return (
    <PlanItemCell
      type={parsedRowData.type || parsedRowData?.plan_type?.toLowerCase()}
      name={parsedRowData.name}
      plan={parsedRowData}
      listsRequestOption={columnData}
    />
  );
};

export const renderForm = param => {
  const { members, rowData, columnData } = param;
  const stringRowData = JSON.stringify(rowData, null, 2);
  const parsedRowData = JSON.parse(stringRowData);
  const { loading } = columnData.loading || {};

  if (loading) {
    return <DetailCellSkeleton />;
  }
  const memberFound = members.find(member => member.user_id === parsedRowData.assignee_id);

  if (!Object.keys(parsedRowData).length) {
    return null;
  }
  return <FormCell form={parsedRowData} user={memberFound} />;
};

export const renderFormTemplate = params => {
  const { rowData, columnData } = params;
  const stringRowData = JSON.stringify(rowData, null, 2);
  const parsedRowData = JSON.parse(stringRowData);
  const { loading } = columnData.loading || {};

  if (loading) {
    return <DetailCellSkeleton />;
  }

  if (!Object.keys(parsedRowData).length) {
    return null;
  }

  return (
    <FormTemplate
      name={parsedRowData.form_template_name}
      createdAt={parsedRowData.created_at}
      isFinalcadTemplate={parsedRowData.is_from_finalcad_template}
      isOrganizationTemplate={parsedRowData.is_inherited}
      state={parsedRowData.state}
    />
  );
};

export const renderLanguage = params => {
  const { rowData, columnData } = params;
  const stringRowData = JSON.stringify(rowData, null, 2);
  const parsedRowData = JSON.parse(stringRowData);
  const { loading } = columnData.loading || {};

  if (loading) {
    return <TextCellSkeleton />;
  }

  if (!Object.keys(parsedRowData).length) {
    return null;
  }
  return (
    <Typography variant="subtitleLight">
      {(parsedRowData.form_languages || []).map(item => item.language).join(', ')}
    </Typography>
  );
};

export const renderCustomReport = params => {
  const { rowData, columnData } = params;
  const stringRowData = JSON.stringify(rowData, null, 2);
  const parsedRowData = JSON.parse(stringRowData);
  const { loading } = columnData.loading || {};

  if (loading) {
    return <TextCellSkeleton />;
  }

  if (!Object.keys(parsedRowData).length) {
    return null;
  }
  return (
    <TypographyEllipsis
      component="p"
      variant="subtitleLight"
      sx={{ minWidth: '200px', marginRight: '8px' }}
    >
      {parsedRowData.custom_template_name || '-'}
    </TypographyEllipsis>
  );
};

export const renderTypeDocumentCell = params => {
  const { dataKey, rowData, description, intl } = params;
  const parsedRowData = parseData(rowData);
  const { loading } = params?.columnData?.loading || {};
  const keyValue = objectByString(parsedRowData, dataKey);

  return (
    <TextCell
      loading={loading}
      keyValue={getDocumentTypeTranslation(intl, keyValue)}
      description={description}
    />
  );
};
