import { createSelector } from 'reselect';

import { initialState } from '../reducer';

const globalState = state => state.global || initialState;

export const selectCurrentModuleMap = () =>
  createSelector(globalState, dataState => dataState.get('current_module'));

export const selectModules = () =>
  createSelector(globalState, dataState =>
    dataState
      .get('modules')
      .toJS()
      .sort((a, b) => a.name.localeCompare(b.name)),
  );

export const selectFormModuleMap = () =>
  createSelector(globalState, dataState => dataState.get('module_forms'));
