import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { CardMedia } from '@mui/material';
import { withStyles } from '@mui/styles';
import FinalcadImage from 'assets/images/logos/finalcad.svg';
import SecuredImage, { quality } from 'components/SecuredImage';
import MediaCellSkeleton from 'components/SkeletonScreen/components/MediaCellSkeleton';

const styles = theme => ({
  media: {
    objectFit: 'cover',
    width: '2rem',
    height: '2rem',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
  },
  empty: {
    padding: 8,
  },
});

const MediaCell = props => {
  const { classes, media_resource, imagePlaceHolder, loading } = props;

  if (loading && isEmpty(media_resource)) {
    return <MediaCellSkeleton />;
  }
  return (
    <SecuredImage imgQuality={quality.thumbnail}>
      <CardMedia
        component="img"
        alt={media_resource?.file_name || media_resource?.display_name}
        classes={{
          media: classNames(classes.media, {
            [classes.empty]: !media_resource?.url && !imagePlaceHolder,
          }),
        }}
        src={media_resource?.url || imagePlaceHolder || FinalcadImage}
        title={media_resource?.file_name || media_resource?.display_name}
        type={media_resource?.file_name || media_resource?.display_name}
      />
    </SecuredImage>
  );
};

MediaCell.propTypes = {
  classes: PropTypes.object.isRequired,
  imagePlaceHolder: PropTypes.string,
  media_resource: PropTypes.shape({
    file_name: PropTypes.string,
    display_name: PropTypes.string,
    url: PropTypes.string,
  }),
  loading: PropTypes.bool,
};

export default withStyles(styles)(MediaCell);
