import { createSelector } from 'reselect';

import { initialState } from '../../reducer';

const globalState = state => state.global || initialState;

const selectDocument = id =>
  createSelector(globalState, dataState => dataState.get('documents').get(id));

export { selectDocument };
