import React, { useState, useEffect, useRef } from 'react';

import { CircularProgress, IconButton, Avatar } from '@mui/material';
import { useTheme, withStyles } from '@mui/styles';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import AvatarUI from 'components/AvatarV3';
import { AcceptImages } from 'components/Dropzone';
import AlertCircle from 'components/SvgComponents/icons/AlertCircle';
import Edit from 'components/SvgComponents/icons/Edit';
import Trash from 'components/SvgComponents/icons/Trash';
import Upload from 'components/SvgComponents/icons/Upload';
import combineStyles from 'theme/combineStyles';
import dropZoneStyle from 'theme/dropzone-theme';
import globalMessages from 'translations/messages/global-messages';
import { generateChunks } from 'utils/chunkUploader';
import { usePrevious } from 'utils/hooks';

export const styles = theme => ({
  avatarContainer: {
    display: 'flex',
    width: '156px',
    height: '156px',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
  },
  initialAvatar: {
    width: 60,
    height: 60,
    backgroundColor: theme.palette.background.secondary,
  },
  iconButton: {
    width: '24px',
    height: '24px',
    position: 'absolute',
  },
  editAvatar: {
    backgroundColor: theme.palette.background.white,
    color: theme.palette.primary.main,
    display: 'flex',
    right: theme.spacing(2),
    top: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.background.white,
      opacity: 0.8,
    },
  },
  removeAvatar: {
    position: 'absolute',
    zIndex: 1,
    right: theme.spacing(2),
    top: `calc(24px + ${theme.spacing(4)})`,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.white,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      opacity: 0.8,
    },
  },
});

function AvatarUserDropZone(props) {
  const { classes, intl, initialMedia, materialUIClasses, alt, input } = props;
  const theme = useTheme();
  const dropzoneRef = useRef();
  const [loading, setLoading] = useState(false);
  const [reject, setReject] = useState(false);
  const [preview, setPreview] = useState('');
  const [files, setFiles] = useState([]);
  const prevFiles = usePrevious(files);

  useEffect(
    () => {
      if (!isEqual(files, prevFiles)) {
        input.onChange(files[0]);
      }
    },
    [files, input, prevFiles],
  );

  const onButtonClick = () => {
    // Open the file dialog
    dropzoneRef.current.open();
  };

  const handleOnDrop = filesToUpload => {
    setReject(false);
    setLoading(true);
    setFiles([]);
    setPreview('');
    const filesUpload = [];
    filesToUpload.forEach(file => {
      generateChunks({
        file,
        filesUpload,
        filesToUpload,
        setFiles,
        setReject,
        clearUpload,
        setLoading,
        setPreview,
      });
    });
  };

  const handleOnDropRejected = () => {
    setFiles([{}]);
    setReject(true);
    setLoading(false);
    setPreview('');
  };

  const clearUpload = () => {
    setFiles([{}]);
    setReject(false);
    setLoading(false);
    setPreview('');
  };

  const renderPreview = () => (
    <Avatar
      classes={materialUIClasses}
      className={classes.image}
      src={preview}
      alt={files[0]?.name}
    />
  );

  const renderContent = () => {
    if (loading) {
      return <CircularProgress size={64} thickness={2} className={classes.circularProgress} />;
    }

    if (reject) {
      return (
        <>
          <IconButton className={classes.floatIcon}>
            <Upload width="5" height="5" color="white" viewBox="0 0 12 12" />
          </IconButton>
          <AlertCircle className={classes.icon} />
          <p className={classes.instruction}>
            {intl.formatMessage(globalMessages.file_incompatible)}
          </p>
        </>
      );
    }
    return (
      <div className={classes.avatarContainer}>
        {files[0] ? (
          renderPreview()
        ) : (
          <AvatarUI
            className={classes.initialAvatar}
            materialUIClasses={materialUIClasses}
            alt={alt}
            src={initialMedia}
          />
        )}
        <IconButton
          onClick={onButtonClick}
          className={classnames(classes.iconButton, classes.editAvatar)}
          color="default"
        >
          <Edit width={16} height={16} color={theme.palette.text.primary} />
        </IconButton>
        {((files?.length > 0 && files[0]?.name) || (!files?.length && initialMedia)) && (
          <IconButton
            className={classnames(classes.iconButton, classes.removeAvatar)}
            onClick={clearUpload}
            color="error"
          >
            <Trash width={16} height={16} color={theme.palette.text.white} />
          </IconButton>
        )}
      </div>
    );
  };

  return (
    <Dropzone
      accept={AcceptImages}
      onDrop={handleOnDrop}
      onDropRejected={handleOnDropRejected}
      noClick
      ref={dropzoneRef}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          {...getRootProps()}
          className={classnames(classes.avatarContainer, {
            [classes.containerActive]: isDragActive,
          })}
        >
          <input {...getInputProps()} />
          {renderContent()}
        </div>
      )}
    </Dropzone>
  );
}

AvatarUserDropZone.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  input: PropTypes.object,
  initialMedia: PropTypes.string,
  alt: PropTypes.string,
  materialUIClasses: PropTypes.object,
};

export default compose(
  injectIntl,
  withStyles(combineStyles(styles, dropZoneStyle)),
)(AvatarUserDropZone);
