import { call, put, select, takeEvery } from 'redux-saga/effects';

import Config from 'config/config';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { checkParams } from 'utils/errors';
import request from 'utils/request';

import { setTrade } from './actions';
import { GET_TRADE } from './constants';
import { selectTrade } from './selectors';

export function* getTrade({ id, callback, params }) {
  const tradeData = yield select(selectTrade(id));

  if (tradeData) {
    if (callback) yield call(callback, tradeData);
  } else {
    checkParams({ params, keys: ['idData'] });

    const requestURL = `${checkOrSetSlash(Config.apiHostGateway, 'apiHostGateway')}api/projects/${
      params.idData
    }/trades/${id}`;

    const options = {
      method: 'GET',
      headers: {
        'Cache-Control': 'No-Store',
        'Content-Type': 'application/json',
      },
    };

    const data = yield call(request, requestURL, options, {
      errorRedirect: false,
    });
    if (data && !data.message) {
      yield put(setTrade(id, data));
      if (callback) yield call(callback, data);
    }
  }
}

export default function* initTradesSaga() {
  yield takeEvery(GET_TRADE, getTrade);
}
