import React from 'react';

import { AppBar, Drawer } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import BusinessOrganisation from 'containers/BusinessOrganisation';
import { selectLoggedUserData, selectSpecialRights } from 'containers/GlobalWrapper/selectors';
import AccountSettingsButton from 'containers/NavigationBar/components/AccountSettingsButton';
import SettingsButton from 'containers/NavigationBar/components/SettingsButton';
import SwitchThemeColorButton from 'containers/NavigationBar/components/SwitchThemeColorButton';
import { authorizations } from 'utils/constants';
import injectReducer from 'utils/injectReducer';

import Finalcad from '../../components/SvgComponents/icons/Finalcad';
import HelpCenter from './components/HelpCenter';
import SuperAdminLogo from './components/SuperAdminLogo';
import { NAVBAR_HEIGHT } from './constants';
import reducer from './reducer';

export { NAVBAR_HEIGHT };

export const styles = theme => ({
  drawer: {
    flexShrink: 0,
    height: NAVBAR_HEIGHT,
  },
  drawerPaper: {
    width: '100%',
    height: NAVBAR_HEIGHT,
    overflow: 'inherit',
    borderRight: 'none',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    height: `${NAVBAR_HEIGHT}px`,
    backgroundColor: theme.palette.background.white,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(4)}`,
  },
  toolbarIconWrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  logo: {
    marginTop: '32px',
  },
});

function NavigationBar(props) {
  const { children, classes, className, history, specialRights, dataUser } = props;
  let isAccountSettingsPage = path => {
    if (path === '/accountSettings') {
      return true;
    }
    return false;
  };

  isAccountSettingsPage = isAccountSettingsPage(history?.location?.pathname);

  const filteredPermisions = specialRights.filter(
    right =>
      right.action === authorizations.specialUser.readObservationFcLibrary ||
      right.action === authorizations.specialUser.writeObservationFcLibrary ||
      right.action === authorizations.specialUser.readFormFcLibrary ||
      right.action === authorizations.specialUser.writeFormFcLibrary ||
      right.action === authorizations.specialUser.readAdminPortal,
  );

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="top"
    >
      <AppBar
        position="static"
        elevation={0}
        classes={{
          root: classNames({
            [classes.toolbar]: true,
            [className]: className,
          }),
        }}
      >
        <div>
          {isAccountSettingsPage ? <Finalcad className={classes.logo} /> : <BusinessOrganisation />}
        </div>
        <div className={`icons-toolbar ${classes.toolbarIconWrap}`}>
          {children}
          {filteredPermisions.length > 0 && <SuperAdminLogo />}
          <HelpCenter dataUser={dataUser} />
          <SwitchThemeColorButton />
          <SettingsButton />
          <AccountSettingsButton dataUser={dataUser} />
        </div>
      </AppBar>
    </Drawer>
  );
}

NavigationBar.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  dataUser: PropTypes.object,
  specialRights: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const mapStateToProps = createStructuredSelector({
  specialRights: selectSpecialRights(),
  dataUser: selectLoggedUserData(),
});

const withConnect = connect(
  mapStateToProps,
  null,
);
const withReducer = injectReducer({ key: 'verticalNavBar', reducer });

export default compose(
  withReducer,
  withConnect,
  withRouter,
  withStyles(styles),
)(NavigationBar);
