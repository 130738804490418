import React, { useState } from 'react';

import { TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import ConfirmDialog from 'components/dialogs/ConfirmDialog';
import globalMessages from 'translations/messages/global-messages';

const styles = theme => ({
  contentWrap: {
    minHeight: 170,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentText: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    padding: theme.spacing(2),
    flexDirection: 'column',
  },
});

function DialogDeleteAccount(props) {
  const { userEmail, intl, onSubmitDialog } = props;

  const [isEmpty, setIsEmpty] = useState(true);
  const onChange = event => {
    const inputValue = event.currentTarget.value;
    setIsEmpty(inputValue !== userEmail);
  };
  const renderTextField = (
    <div>
      <TextField onChange={onChange} />
    </div>
  );

  return (
    <ConfirmDialog
      open
      onClose={props.onCloseDialog}
      onSubmit={onSubmitDialog}
      disabledOnSubmit={isEmpty}
      title={intl.formatMessage(globalMessages.account_settings_delete_account_title)}
      submitLabel={intl.formatMessage(globalMessages.account_settings_delete_account_button)}
      areYouSureLabel={intl.formatMessage(globalMessages.account_settings_delete_account_message)}
      message={intl.formatMessage(globalMessages.delete_account_confirmation_restriction)}
      displayCancel
      showAlertIcon={false}
      additionalElement={renderTextField}
    />
  );
}
DialogDeleteAccount.propTypes = {
  intl: intlShape.isRequired,
  onCloseDialog: PropTypes.func,
  onSubmitDialog: PropTypes.func,
  userEmail: PropTypes.string,
};

export default compose(
  withStyles(styles),
  injectIntl,
)(DialogDeleteAccount);
