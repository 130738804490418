import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { change, Field, getFormValues, reduxForm } from 'redux-form/immutable';
import classNames from 'classnames';

import { createStructuredSelector } from 'reselect';

import { connect } from 'react-redux';
import Analytics from 'utils/Analytics';

import { injectIntl, intlShape } from 'react-intl';
import Dialog from 'components/dialogs/Dialog';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import LoadingIndicator from 'components/LoadingIndicator';
import globalMessages from 'translations/messages/global-messages';
import CleanableTextField from 'components/CleanableTextField';
import DialogSubmit from 'components/dialogs/DialogSubmit';
import RemoveScrollbar from 'components/RemoveScrollbar';
import Button from 'components/Button';
import InputAdornment from '@mui/material/InputAdornment';

import {
  getUserData,
} from 'containers/GlobalWrapper/actions';
import { setGlobalMessage } from 'containers/MessageHandler/actions';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import localStorageUser from 'utils/localStorageUser';
import saga from './saga';
import reducer from './reducer';
import { selectLoadingFlag } from './selectors';
import { sendValidationEmail, validateCode } from './actions';

export const styles = theme => ({
  container: {
    padding: '3.5rem 2.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    alignItems: 'center',
  },
  subtitle: {
    color: theme.palette.text.primary,
    fontSize: '1rem',
  },
  form: {
    width: '100%',
  },
});

export function VerifyEmailButton(props) {
  const {
    intl,
    classes,
    loading,
    open,
    onClose,
    handleSubmit,
    dispatchValidateCode,
    userInfo,
    formValues,
    dispatchSendValidationEmail,
    dispatchGetUserData,
    dispatchSetGlobalInfo,
    dispatchChangeFormValue,
    validationCode,
  } = props;
  let email;
  let user_id;
  if (userInfo) {
    ({ email, user_id } = userInfo);
  }
  const isMountedRef = useRef(null);

  useEffect(
    () => {
      if (validationCode) {
        dispatchChangeFormValue('enter-code', 'code', validationCode);
      }
      return () => {
        localStorageUser.setEmailValidationLocation(null);
        localStorageUser.setEmailVerificationLink(null);
      };
    },
    [validationCode, dispatchChangeFormValue],
  );
  useEffect(
    () => {
      isMountedRef.current = true;
      if (open && isMountedRef && !validationCode) {
        localStorageUser.setEmailValidationLocation(window.location.pathname);
        dispatchSendValidationEmail(email, () => {
          setGlobalInfo();
        });
      }
      return () => {
        isMountedRef.current = false;
      };
    },
    [open],
  );

  const [disableResend, setDisableResend] = useState(false);

  const setGlobalInfo = () => {
    dispatchSetGlobalInfo({
      type: 'success',
      intlMessage: {
        id: 'verification_email_sent',
        values: { email },
      },
      active: true,
    });
  };

  const handleSendValidationEmail = () => {
    setDisableResend(true);
    Analytics.track('users_validate_email_resend', {
      source: 'user_settings',
    });
    setTimeout(() => {
      setDisableResend(false);
    }, 30000);
    localStorageUser.setEmailValidationLocation(window.location.pathname);
    dispatchSendValidationEmail(email, () => {
      setGlobalInfo();
    });
  };

  const valuesForm = JSON.stringify(formValues, null, 2);
  const { code } = valuesForm ? JSON.parse(valuesForm) : '';
  const onSubmit = () => {
    dispatchValidateCode(email, `F-${code}`, () =>
      dispatchGetUserData(
        user_id,
        () => {
          onClose();
        },
        true,
      ),
    );
  };

  return (
    <Dialog
      id="dialog-enter-code"
      open={open}
      fullWidth
      maxWidth="sm"
      onCloseDialog={onClose}
      dialogTitleNode={intl.formatMessage(globalMessages.enter_your_code)}
    >
      <RemoveScrollbar>
        {loading && <LoadingIndicator size="40" />}
        <div className={classes.container}>
          <Typography className={classes.subtitle}>
            {intl.formatMessage(globalMessages.validation_email_sent)}
          </Typography>
          <form
            id="enter-code"
            className={classNames(classes.form, {
              [classes.loading]: loading,
            })}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Field
              name="code"
              required
              type="text"
              autoComplete="one-time-code"
              inputMode="numeric"
              pattern="\d{6}"
              customClassName={classes.textField}
              component={CleanableTextField}
              placeholder={intl.formatMessage(globalMessages.type_code_here)}
              customValueOutput={value => value.replace(/\D/g, '')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">F-</InputAdornment>
                ),
              }}
            />
          </form>
          <Button
            variant="plain"
            disabled={disableResend}
            onClick={handleSendValidationEmail}
          >
            {intl.formatMessage(globalMessages.resend_the_email)}
          </Button>
        </div>
        <DialogSubmit
          form="enter-code"
          onClose={onClose}
          submitLabel={intl.formatMessage(globalMessages.validate)}
        />
      </RemoveScrollbar>
    </Dialog>
  );
}

VerifyEmailButton.propTypes = {
  intl: intlShape,
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  dispatchValidateCode: PropTypes.func.isRequired,
  dispatchSendValidationEmail: PropTypes.func.isRequired,
  dispatchGetUserData: PropTypes.func.isRequired,
  dispatchSetGlobalInfo: PropTypes.func.isRequired,
  dispatchChangeFormValue: PropTypes.func,
  userInfo: PropTypes.shape({
    user_id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  formValues: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  validationCode: PropTypes.string,
};

VerifyEmailButton.defaultProps = {
  loading: false,
  validationCode: null,
};

const mapStateToProps = createStructuredSelector({
  formValues: getFormValues('enter-code'),
  loading: selectLoadingFlag(),
});

const mapDispatchToProps = {
  dispatchValidateCode: validateCode,
  dispatchSendValidationEmail: sendValidationEmail,
  dispatchGetUserData: getUserData,
  dispatchSetGlobalInfo: setGlobalMessage,
  dispatchChangeFormValue: change,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'verifyCodePage', reducer });
const withSaga = injectSaga({ key: 'verifyCodePage', saga });

export default compose(
  injectIntl,
  withStyles(styles),
  withConnect,
  withReducer,
  withSaga,
  reduxForm({
    destroyOnUnmount: true,
    enableReinitialize: true,
    form: 'enter-code',
  }),
)(VerifyEmailButton);
