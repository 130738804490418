import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

function ArrowLow(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>
        {props.title || props.intl.formatMessage(globalMessages.low)}
      </title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C8.55228 16 9 15.5523 9 15V1C9 0.447715 8.55228 0 8 0C7.44772 0 7 0.447715 7 1V15C7 15.5523 7.44772 16 8 16Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29289 15.7071C7.65338 16.0676 8.22061 16.0953 8.6129 15.7903L8.70711 15.7071L13.7071 10.7071C14.0976 10.3166 14.0976 9.68342 13.7071 9.29289C13.3466 8.93241 12.7794 8.90468 12.3871 9.2097L12.2929 9.29289L8 13.585L3.70711 9.29289C3.34662 8.93241 2.77939 8.90468 2.3871 9.2097L2.29289 9.29289C1.93241 9.65338 1.90468 10.2206 2.2097 10.6129L2.29289 10.7071L7.29289 15.7071Z"
      />
    </svg>
  );
}
ArrowLow.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

ArrowLow.defaultProps = {
  title: '',
};

export default injectIntl(ArrowLow);
