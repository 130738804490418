import {
  ADD_MODULE,
  CREATE_MODULE,
  EDIT_MODULE,
  GET_MODULES_OBSERVATIONS_AND_MEETING,
  SET_NEW_MODULE,
  SET_UPDATED_MODULE,
  SET_MODULES_OBSERVATIONS_AND_MEETING,
  GET_CURRENT_MODULE,
  SET_CURRENT_MODULE,
  UPDATE_MODULE_SETTINGS,
  SET_MODULES_FORMS,
  GET_MODULES_FORMS,
} from './constants';

export const getModulesObservationsAndMeeting = (project_id, featureFlags, callback) => ({
  type: GET_MODULES_OBSERVATIONS_AND_MEETING,
  project_id,
  featureFlags,
  callback,
});

export const getModulesForms = (project_id, append = true) => ({
  type: GET_MODULES_FORMS,
  project_id,
  append,
});

export const getCurrentModule = (projectId, moduleId, templatesModuleManagement, moduleType) => ({
  type: GET_CURRENT_MODULE,
  projectId,
  moduleId,
  templatesModuleManagement,
  moduleType,
});

export const setCurrentModule = currentModule => ({
  type: SET_CURRENT_MODULE,
  currentModule,
});

export const addModule = ({ projectId, selectedModule }) => ({
  type: ADD_MODULE,
  projectId,
  selectedModule,
});

export const createModule = payload => ({
  type: CREATE_MODULE,
  payload,
});

export const editModule = (payload, templatesModuleManagement) => ({
  type: EDIT_MODULE,
  payload,
  templatesModuleManagement,
});

export const setModulesObservationsAndMeeting = modules => ({
  type: SET_MODULES_OBSERVATIONS_AND_MEETING,
  modules,
});

export const setModulesForms = (modules, append) => ({
  type: SET_MODULES_FORMS,
  modules,
  append,
});

export const setNewModule = newModule => ({
  type: SET_NEW_MODULE,
  newModule,
});

export const setUpdatedModule = module => ({
  type: SET_UPDATED_MODULE,
  module,
});

export const updateModuleSettings = ({ projectId, moduleId, settings }) => ({
  type: UPDATE_MODULE_SETTINGS,
  projectId,
  moduleId,
  settings,
});
