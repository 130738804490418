import {
  idpUser,
  finalcadTempRegistrationInfoTypeKey,
  finalcadStorageDeepLink,
  finalcadStorageInviteLink,
  isSignUp,
  LIBRARY_UPDATE_AKNOWLEDGEMENT,
  finalcadForwardRedirect,
} from 'utils/constants';

const setIdp = user => {
  if (user === null) {
    sessionStorage.setItem(idpUser, null);
    return;
  }
  sessionStorage.setItem(idpUser, JSON.stringify({ ...user, type: user.type.toLowerCase() }));
};

const getIdp = () => JSON.parse(sessionStorage.getItem(idpUser));

const setIsSignUp = value => {
  sessionStorage.setItem(isSignUp, JSON.stringify(value));
};

const getIsSignUp = () => JSON.parse(sessionStorage.getItem(isSignUp));

const getFinalcadTempRegistrationInfo = () => {
  const finalcadTempRegistrationInfoRaw = sessionStorage.getItem(
    finalcadTempRegistrationInfoTypeKey,
  );
  let finalcadTempRegistrationInfo;
  try {
    finalcadTempRegistrationInfo = finalcadTempRegistrationInfoRaw
      ? JSON.parse(finalcadTempRegistrationInfoRaw)
      : null;
  } catch (e) {
    finalcadTempRegistrationInfo = null;
  }
  return finalcadTempRegistrationInfo;
};

const setFinalcadTempRegistrationInfo = info => {
  sessionStorage.setItem(finalcadTempRegistrationInfoTypeKey, JSON.stringify(info));
};

const setDeepLink = value => {
  sessionStorage.setItem(finalcadStorageDeepLink, JSON.stringify(value));
};

const getDeepLink = () => {
  let deepLink = sessionStorage.getItem(finalcadStorageDeepLink);
  if (!deepLink) {
    return null;
  }

  try {
    deepLink = deepLink ? JSON.parse(deepLink) : null;
  } catch (e) {
    return null;
  }

  return deepLink;
};

const setInvitationsLink = value => {
  sessionStorage.setItem(finalcadStorageInviteLink, JSON.stringify(value));
};

const getInvitationsLink = () => {
  let inviteLink = sessionStorage.getItem(finalcadStorageInviteLink);
  if (!inviteLink) {
    return null;
  }

  try {
    inviteLink = inviteLink ? JSON.parse(inviteLink) : null;
  } catch (e) {
    return null;
  }

  return inviteLink;
};

const setLibraryUpdateAknowledgements = value =>
  sessionStorage.setItem(LIBRARY_UPDATE_AKNOWLEDGEMENT, JSON.stringify(value));

const getLibraryUpdateAknowledgements = () => {
  const aknowledgement = sessionStorage.getItem(LIBRARY_UPDATE_AKNOWLEDGEMENT);

  return JSON.parse(aknowledgement);
};

const setForwardRedirect = (projectId, organizationId, email) => {
  const data = JSON.stringify({
    projectId,
    organizationId,
    email,
  });

  sessionStorage.setItem(finalcadForwardRedirect, data);
};

const getForwardRedirect = () => {
  const jsonData = sessionStorage.getItem(finalcadForwardRedirect);
  return jsonData ? JSON.parse(jsonData) : null;
};

export default {
  setIdp,
  getIdp,
  setIsSignUp,
  getIsSignUp,
  getFinalcadTempRegistrationInfo,
  setFinalcadTempRegistrationInfo,
  setDeepLink,
  getDeepLink,
  getInvitationsLink,
  setInvitationsLink,
  setLibraryUpdateAknowledgements,
  getLibraryUpdateAknowledgements,
  setForwardRedirect,
  getForwardRedirect,
};
