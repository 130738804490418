import { call, put, select, takeEvery } from 'redux-saga/effects';

import Config from 'config/config';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import request from 'utils/request';

import { selectRouteParams } from 'containers/GlobalWrapper/selectors';
import { setDocument } from './actions';
import { GET_DOCUMENT } from './constants';
import { selectDocument } from './selectors';

export function* getDocument({ id, callback }) {
  const documentData = yield select(selectDocument(id));
  const route = yield select(selectRouteParams());

  if (documentData) {
    if (callback) yield call(callback, documentData);
  } else {

    const requestURL = `${checkOrSetSlash(Config.apiHostGateway, 'apiHostGateway')}api/projects/${
      route.idData
    }/documents/${id}`;

    const options = {
      method: 'GET',
      headers: {
        'Cache-Control': 'No-Store',
        'Content-Type': 'application/json',
      },
    };

    const data = yield call(request, requestURL, options, {
      errorRedirect: false,
    });
    if (data && !data.message) {
      yield put(setDocument(id, data));
      if (callback) yield call(callback, data);
    }
  }
}

export default function* initDocumentsSaga() {
  yield takeEvery(GET_DOCUMENT, getDocument);
}
