import React, { Component } from 'react';

import { Avatar } from '@mui/material';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import SecuredImage, { quality } from 'components/SecuredImage';
import Finalcad from 'components/SvgComponents/icons/Finalcad';
import User from 'components/SvgComponents/icons/User';

export const styles = theme => ({
  icon: {
    color: theme.palette.common.white,
    fontSize: '50%',
  },
});

const Error = props => {
  const { className } = props;
  return (
    <div className={className}>
      <Finalcad />
    </div>
  );
};

Error.propTypes = {
  className: PropTypes.string,
};

export const UserComponent = props => {
  const { className } = props;
  return (
    <div className={className}>
      <User height="50%" width="50%" />
    </div>
  );
};

UserComponent.propTypes = {
  className: PropTypes.string,
};

export const InitialComponent = props => {
  const { className, user } = props;

  return (
    <div className={className}>
      {user?.first_name?.substring(0, 1)} {user?.last_name?.substring(0, 1)}
    </div>
  );
};

InitialComponent.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
};

class UIAvatar extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.src !== nextProps.src && nextState.error) {
      this.setState({
        error: false,
      });
    }
    return true;
  }

  state = {
    error: false,
  };

  render() {
    const { src, materialUIClasses, alt, className, classes, user } = this.props;
    const { error } = this.state;

    // const COMPONENT = !src ? InitialComponent : Error;
    let COMPONENT = Error;

    if (user) {
      COMPONENT = InitialComponent;
    }
    if (!user && !src) {
      COMPONENT = UserComponent;
    }

    if (!src || error) {
      return (
        <Avatar
          classes={materialUIClasses}
          component={COMPONENT}
          alt={alt}
          user={user}
          className={classnames(className, classes.icon)}
          sx={{ bgcolor: theme => theme.palette.background.secondary }}
        />
      );
    }

    return (
      <SecuredImage imgQuality={quality.thumbnail} showFallback>
        <Avatar
          classes={materialUIClasses}
          className={classnames(className, classes.icon)}
          src={src}
          onError={this.handleError}
          alt={alt}
        />
      </SecuredImage>
    );
  }

  handleError = error => {
    const { onError } = this.props;
    this.setState({
      error: true,
    });
    if (onError) {
      onError(error);
    }
  };
}

UIAvatar.propTypes = {
  classes: PropTypes.object.isRequired,
  materialUIClasses: PropTypes.object,
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  onError: PropTypes.func,
  user: PropTypes.object,
};

export default compose(withStyles(styles))(UIAvatar);
