const scope = 'groups/';
export const SET_LOADING_FLAG = `${scope}SET_LOADING_FLAG`;
export const SET_LOADING_GROUP_DETAILS_FLAG = `${scope}SET_LOADING_GROUP_DETAILS_FLAG`;
export const GET_GROUPS = `${scope}GET_GROUPS`;
export const UPDATE_GROUP = `${scope}UPDATE_GROUP`;
export const GET_MEMBERS = `${scope}GET_MEMBERS`;
export const ADD_NEW_GROUP = `${scope}ADD_NEW_GROUP`;
export const SELECT_GROUP = `${scope}SELECT_GROUP`;
export const SET_GROUPS = `${scope}SET_GROUPS`;
export const SET_GROUPS_MEMBERS = `${scope}SET_GROUPS_MEMBERS`;
export const SET_MEMBERS = `${scope}SET_MEMBERS`;
export const SET_SEARCH_TEXT = `${scope}SET_SEARCH_TEXT`;
export const SET_SEARCHED = `${scope}SET_SEARCHED`;
export const GET_GROUP_DETAILS = `${scope}GET_GROUP_DETAILS`;
export const SET_GROUP_DETAILS = `${scope}SET_GROUP_DETAILS`;
export const SET_DISPLAY_DETAILS = `${scope}SET_DISPLAY_DETAILS`;
export const SEARCH_PROJECT_MEMBERS = `${scope}SEARCH_PROJECT_MEMBERS`;
export const ADD_GROUP_MEMBERS = `${scope}ADD_GROUP_MEMBERS`;
export const GET_PROJECT_MEMBERS = `${scope}GET_PROJECT_MEMBERS`;
export const SET_PROJECT_MEMBERS = `${scope}SET_PROJECT_MEMBERS`;
export const UPDATE_DISPLAYED_LIST = `${scope}UPDATE_DISPLAYED_LIST`;
export const groupUpdateActions = {
  DefaultGroupCreated: 'DefaultGroupCreated',
  GroupCreated: 'GroupCreated',
  GroupMemberAdded: 'GroupMemberAdded',
  GroupMemberLeft: 'GroupMemberLeft',
  GroupNameEdited: 'GroupNameEdited',
  GroupDescriptionEdited: 'GroupDescriptionEdited',
  GroupPlanAdded: 'GroupPlanAdded',
  FolderPlanAdded: 'FolderPlanAdded',
};
