import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';
import globalMessages from 'translations/messages/global-messages';

const SingleChoiceSvg = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <title>
      {props.title || props.intl.formatMessage(globalMessages.single_choice)}
    </title>

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99984 0.666687C3.94975 0.666687 0.666504 3.94993 0.666504 8.00002C0.666504 12.0501 3.94975 15.3334 7.99984 15.3334C12.0499 15.3334 15.3332 12.0501 15.3332 8.00002C15.3332 3.94993 12.0499 0.666687 7.99984 0.666687ZM12.0268 5.80476C12.2871 5.54441 12.2871 5.1223 12.0268 4.86195C11.7664 4.6016 11.3443 4.6016 11.084 4.86195L6.6665 9.27943L4.91569 7.52862C4.65534 7.26827 4.23323 7.26827 3.97288 7.52862C3.71253 7.78897 3.71253 8.21108 3.97288 8.47143L6.1951 10.6936C6.45545 10.954 6.87756 10.954 7.13791 10.6936L12.0268 5.80476Z"
    />
  </svg>
);

SingleChoiceSvg.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};
export default compose(injectIntl)(SingleChoiceSvg);
