import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

function EmojiNeutral(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>
        {props.title || props.intl.formatMessage(globalMessages.neutral)}
      </title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 15C7 14.4477 7.44772 14 8 14H16C16.5523 14 17 14.4477 17 15C17 15.5523 16.5523 16 16 16H8C7.44772 16 7 15.5523 7 15Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 11C7.67157 11 7 10.3284 7 9.5V9.4C7 8.57157 7.67157 7.9 8.5 7.9C9.32843 7.9 10 8.57157 10 9.4V9.5C10 10.3284 9.32843 11 8.5 11Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 11C14.6716 11 14 10.3284 14 9.5V9.4C14 8.57157 14.6716 7.9 15.5 7.9C16.3284 7.9 17 8.57157 17 9.4V9.5C17 10.3284 16.3284 11 15.5 11Z"
      />
    </svg>
  );
}
EmojiNeutral.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

EmojiNeutral.defaultProps = {
  title: '',
};

export default injectIntl(EmojiNeutral);
