import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import { Typography, Box } from '@mui/system';

const styles = () => ({
  emptyTable: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 200,
  },
});
export function EmptyTable({
  children,
  className,
  classes,
  image,
  style,
  subtitle,
  title,
}) {
  const getTitle = () => {
    if (title && typeof title === 'string') {
      return (
        <Typography align="center" variant="h3" component="h2">
          {title}
        </Typography>
      );
    } else if (title && typeof title !== 'undefined') {
      return <>{title}</>;
    }
  };

  const getSubtitle = () => {
    if (subtitle && typeof subtitle === 'string') {
      return (
        <Typography align="center" variant="bodyLight" component="p">
          {' '}
          {subtitle}{' '}
        </Typography>
      );
    } else if (subtitle && typeof subtitle !== 'undefined') {
      return <>{subtitle}</>;
    }
  };

  return (
    <div className={classNames(classes.emptyTable, className)} style={style}>
      {image}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {getTitle()}
        {getSubtitle()}
      </Box>
      {children}
    </div>
  );
}

EmptyTable.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
};

export default withStyles(styles)(EmptyTable);
