import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

function ArrowLeft(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>
        {props.title || props.intl.formatMessage(globalMessages.left)}
      </title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6C2 6.27614 2.22386 6.5 2.5 6.5L9.5 6.5C9.77614 6.5 10 6.27614 10 6C10 5.72386 9.77614 5.5 9.5 5.5L2.5 5.5C2.22386 5.5 2 5.72386 2 6Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.14645 5.64645C1.95118 5.84171 1.95118 6.15829 2.14645 6.35355L5.64645 9.85355C5.84171 10.0488 6.15829 10.0488 6.35355 9.85355C6.54882 9.65829 6.54882 9.34171 6.35355 9.14645L3.20711 6L6.35355 2.85355C6.54882 2.65829 6.54882 2.34171 6.35355 2.14645C6.15829 1.95118 5.84171 1.95118 5.64645 2.14645L2.14645 5.64645Z"
      />
    </svg>
  );
}
ArrowLeft.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
  size: PropTypes.number,
};

ArrowLeft.defaultProps = {
  title: '',
  size: 12,
};

export default injectIntl(ArrowLeft);
