import moment from 'moment';
import { takeEvery } from 'redux-saga';
import { call, put, all, select } from 'redux-saga/effects';
import uuid from 'uuid';

import { addMessage } from 'components/MessageThread/actions';
import { convertToMessage } from 'components/MessageThread/utils';
import { selectLoggedUserData } from 'containers/GlobalWrapper/selectors';
import Analytics from 'utils/Analytics';
import { uploadMedia } from 'utils/chunkUploader';
import { messageTypes } from 'utils/constants';
import localStorageUser from 'utils/localStorageUser';
import { request } from 'utils/request';

import { scopeType } from '../MessageThread/constants';
import { sendMessage, setLoadingFlag } from './actions';
import {
  ADD_ATTACHMENT,
  MESSAGE_TYPES,
  SEND_MESSAGE,
  SEND_MESSAGE_CLUSTER,
  SEND_MESSAGE_CLUSTER_BATCH,
} from './constants';

export function* addAttachment({
  document,
  listRequestOption,
  sendMessageRequestOption,
  callback,
  scope,
  featureFlags,
}) {
  const { mediaUrl, url, namespace } = listRequestOption;

  yield put(setLoadingFlag(true, namespace));

  const dataUpload = yield call(uploadMedia, document, {
    baseUrl: mediaUrl,
    parallel: false,
    requestHeaders: {
      'Content-Type': 'application/json',
    },
  });

  const payload = {
    media_resource: {
      root_media_resource_id: dataUpload.id,
      media_resource_id: dataUpload.id,
      media_resource_label: dataUpload.file_name,
    },
  };

  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  const response = yield call(request, url, options);
  if (response && !response.message) {
    const data = { ...response, attachmentType: document?.attachmentType };
    yield put(
      sendMessage(
        data,
        MESSAGE_TYPES.ATTACHMENT,
        sendMessageRequestOption,
        [],
        callback,
        scope,
        featureFlags,
      ),
    );
  } else {
    yield put(setLoadingFlag(false, namespace));
  }
}

export function* sendItemMessageClusterSplit({
  listRequestOption,
  targetType,
  itemList,
  callback,
  sourceType,
  sourceId,
}) {
  const { url, namespace, analyticsType } = listRequestOption;
  yield put(setLoadingFlag(true, namespace));
  const analyticsOperation = `groups_links_to_${targetType}_added`;

  const body = {
    client_created_at: moment(),
    content_type: 'Content',
    client_uuid: uuid.v4(),
    message: {
      text: null,
      references: itemList.map(item => ({
        linked_item_id: null,
        source_id: sourceId,
        source_type: sourceType,
        target_type: targetType,
        target_id: item.id,
      })),
      media_resources: [],
    },
  };

  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json-patch+json',
    },
    body: JSON.stringify(body),
  };

  const data = yield call(request, url, options);

  if (data && !data.message) {
    Analytics.track(analyticsOperation.toLowerCase(), {
      type: analyticsType,
    });
    Analytics.track('groups_engagement', {
      type: analyticsType,
    });
  }

  if (callback) {
    yield call(callback);
  }

  yield put(setLoadingFlag(false, namespace));
}

function chunkArray(array, chunkSize) {
  const result = [];
  for (let i = 0; i < array?.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    result.push(chunk);
  }
  return result;
}

export function* sendItemMessageCluster({
  listRequestOption,
  targetType,
  itemList,
  callback,
  sourceType,
  sourceId,
}) {
  const chunkList = chunkArray(itemList, 100);
  const callList = chunkList.map(itemList =>
    call(sendItemMessageClusterSplit, {
      listRequestOption,
      targetType,
      itemList,
      callback: null,
      sourceType,
      sourceId,
    }),
  );

  yield all(callList);
  if (callback) {
    yield callback();
  }
}

export function* sendChatMessage({
  message,
  messageType,
  listRequestOption,
  mentions,
  callback,
  scope,
  featureFlags,
}) {
  const { url, namespace, from, analyticsType } = listRequestOption;
  yield put(setLoadingFlag(true, namespace));
  let analyticsOperation = `${from}s`;
  let body = {
    client_created_at: moment(),
    content_type: 'Content',
    client_uuid: uuid.v4(),
  };

  // TODO - try to fix double message send on staging
  // const mediaResources = [message?.media_resource];

  if (featureFlags) {
    switch (messageType.toUpperCase()) {
      case MESSAGE_TYPES.ATTACHMENT:
        body = {
          ...body,
          message: {
            media_resources: [message?.media_resource?.id],
            references: [],
          },
        };
        analyticsOperation += `_${message?.attachmentType}`;
        break;
      case MESSAGE_TYPES.OBSERVATION:
        body = {
          ...body,
          message: {
            references: [
              {
                source_type: scope?.type,
                source_id: scope?.identifier,
                target_type: messageType,
                target_id: message,
              },
            ],
          },
        };
        analyticsOperation += `_links_to_${messageType}`;
        break;
      case MESSAGE_TYPES.FORM:
        body = {
          ...body,
          message: {
            references: [
              {
                source_type: scope?.type,
                source_id: scope?.identifier,
                target_type: messageType,
                target_id: message,
                // TODO - try to fix double message send on staging
                // type: messageType,
                // id: message,
              },
            ],
          },
        };
        analyticsOperation += `_links_to_${messageType}`;
        break;
      case MESSAGE_TYPES.TEXT_WITH_MENTION:
        body = {
          ...body,
          message: {
            text: {
              value: message,
              inline_references: [
                ...mentions.map(el => ({
                  start: el.start,
                  length: el.length,
                  content: {
                    source_type: scope?.type,
                    source_id: scope?.identifier,
                    target_type: 'User',
                    target_id: el?.userId,
                  },
                })),
              ],
            },
          },
        };
        break;
      default:
        body = {
          ...body,
          message: {
            text: {
              value: message,
            },
          },
        };
        analyticsOperation += `_message_text`;
    }
  } else {
    switch (messageType.toUpperCase()) {
      case MESSAGE_TYPES.ATTACHMENT:
        body = {
          ...body,
          message: {
            media_resources: [message?.media_resource?.id],
            references: [],
          },
        };
        analyticsOperation += `_${message?.attachmentType}`;
        break;
      case MESSAGE_TYPES.OBSERVATION:
      case MESSAGE_TYPES.FORM:
        body = {
          ...body,
          message: {
            references: [
              {
                type: messageType,
                id: message,
              },
            ],
          },
        };
        analyticsOperation += `_links_to_${messageType}`;
        break;
      case MESSAGE_TYPES.TEXT_WITH_MENTION:
        body = {
          ...body,
          message: {
            text: {
              value: message,
              inline_references: [
                ...mentions.map(el => ({
                  start: el.start,
                  length: el.length,
                  content: {
                    source_type: scope?.type,
                    source_id: scope?.identifier,
                    target_type: 'User',
                    target_id: el?.userId,
                  },
                })),
              ],
            },
          },
        };
        break;
      default:
        body = {
          ...body,
          message: {
            text: {
              value: message,
            },
          },
        };
        analyticsOperation += `_message_text`;
    }
  }

  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json-patch+json',
    },
    body: JSON.stringify(body),
  };

  const data = yield call(request, url, options);

  if (data && !data.message) {
    analyticsOperation += `_added`;

    if (from === scopeType.group) {
      Analytics.track(analyticsOperation.toLowerCase(), {
        type: analyticsType,
      });
      Analytics.track('groups_engagement', {
        type: analyticsType,
      });
    } else if (from === scopeType.formAnswer) {
      if (messageType === messageTypes.form) {
        Analytics.track('form_links_to_form_added', {
          source: 'form_field',
        });
      } else if (messageType === messageTypes.observation) {
        Analytics.track('form_links_to_observation_added', {
          source: 'form_field',
        });
      } else {
        Analytics.track(analyticsOperation.toLowerCase(), {
          type: analyticsType,
        });
      }
    } else if (from === scopeType.form) {
      if (messageType === messageTypes.form) {
        Analytics.track('form_links_to_form_added', {
          source: 'form',
        });
      } else if (messageType === messageTypes.observation) {
        Analytics.track('form_links_to_observation_added', {
          source: 'form',
        });
      } else {
        Analytics.track(analyticsOperation.toLowerCase(), {
          type: analyticsType,
        });
      }
    } else {
      Analytics.track(analyticsOperation.toLowerCase());
    }
    if (callback) {
      yield call(callback);
    }
  }
  yield put(setLoadingFlag(false, namespace));
  // TODO - try to fix double message send on staging
/*
  const user = yield select(selectLoggedUserData());

  const newObject = body.message.media_resources
    ? {
        ...body,
        message: { ...body.message, media_resources: { ...body.message.media_resources } },
      }
    : body;

  if (body.message.media_resources) {
    newObject.message.media_resources = mediaResources;
  }

  const content = convertToMessage(
    {
      ...newObject,
      author: { id: user?.user_id },
      createdAt: body.client_created_at.toString(),
      messageId: data,
    },
    scope?.type,
    scope?.identifier,
  );

  yield put(addMessage(content.type, content.content, content.id));
*/
}

export function* sendItemMessageClusterBatch({ payloadList, callback }) {
  yield put(setLoadingFlag(true));
  const sendMessageClusters = payloadList.map(payload => call(sendItemMessageCluster, { ...payload }));
  yield all(sendMessageClusters);
  callback();
  yield put(setLoadingFlag(false));
}

export default function* initAddMessageSaga() {
  yield takeEvery(ADD_ATTACHMENT, addAttachment);
  yield takeEvery(SEND_MESSAGE, sendChatMessage);
  yield takeEvery(SEND_MESSAGE_CLUSTER, sendItemMessageCluster);
  yield takeEvery(SEND_MESSAGE_CLUSTER_BATCH, sendItemMessageClusterBatch);
}
