/* eslint-disable prettier/prettier */
import { takeLatest, put, call } from 'redux-saga/effects';

import config from 'config/config';
import { getUserData } from 'containers/GlobalWrapper/actions';
import Analytics from 'utils/Analytics';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { uploadMedia } from 'utils/chunkUploader';
import localStorageUser from 'utils/localStorageUser';
import request from 'utils/request';

import { setLoadingFlag } from './actions';
import { RESET_PASSWORD, SAVE_EDIT_USER, UPLOAD_IMAGE_USER, ADD_COMMUNICATION, DELETE_ACCOUNT } from './constants';

function* saveUser({ userEdit }) {
  if (userEdit) {
    yield put(setLoadingFlag(true));

    const payload = {
      ...userEdit,
    };

    const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/v2.1/user-infos/${userEdit.user_id
      }`;

    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };
    const data = yield call(request, requestURL, options, {
      errorRedirect: false,
    });
    if (data && !data.message && userEdit.user_id) {
      yield put(getUserData(userEdit.user_id, null, true));
    }
    yield put(setLoadingFlag(false));
  }
}

export function* updateImage({ image, callback }) {
  yield put(setLoadingFlag(true));
  let data;
  let dataUpload = {};

  if(image?.hash && image?.size) {
    const requestUploadMediaUrl = `${checkOrSetSlash(
      config.apiHostGateway,
      'apiHostGateway',
    )}api/v1.2/users/${image.userId}/`;

    dataUpload = yield call(uploadMedia, image, {
      baseUrl: requestUploadMediaUrl,
      parallel: false,
    });

    const requestURL = `${checkOrSetSlash(
      config.apiHostGateway,
      'apiHostGateway',
    )}api/v2.1/user-infos/${image.userId}/attach-media`;

    const payload = {
      media_resource: {
        root_media_resource_id: dataUpload?.id || '',
        media_resource_id: dataUpload?.id || '',
        media_resource_label: dataUpload?.file_name || '',
      },
    };

    const options = {
      method: 'POST',
      headers: {
        'Cache-Control': 'No-Store',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };

    data = yield call(request, requestURL, options, {
      errorRedirect: false,
    });
  } else if (image?.previous_media_id){
    const requestURL = `${checkOrSetSlash(
      config.apiHostGateway,
      'apiHostGateway',
    )}api/v2.1/user-infos/${image.userId}/detach-media`;

    const payload = {
      detach_media_resource_ids: [image.previous_media_id],
    };

    const options = {
      method: 'POST',
      headers: {
        'Cache-Control': 'No-Store',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };

    data = yield call(request, requestURL, options, {
      errorRedirect: false,
    });
  }

  if (data && (data.message || data.status === 500)) {
    yield put(setLoadingFlag(false));
  }
  if (image.userId) {
    yield put(getUserData(image.userId, null, true));
  }
  if (callback) {
    callback();
  } else {
    yield put(setLoadingFlag(false));
  }
}
export function* resetPassword({ email, callback }) {
  yield put(setLoadingFlag(true));
  const requestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/v1.1/user/reset-password/${email}`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${config.apiRegistrationToken}`,
    },
    body: JSON.stringify({
      send_reset_password_email: true,
    }),
  };
  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    if (callback) {
      callback();
    }
  }
  yield put(setLoadingFlag(false));
}
export function* deleteAccount(args) {
  const { userId, successCallback } = args;
  const oktaToken = localStorageUser.getOktaTokenStorage();

  yield put(setLoadingFlag(true));
  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/user-infos/${userId}/delete-account`;
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${oktaToken?.accessToken?.accessToken}`,
    },
    body: JSON.stringify(),
  };
  const data = yield call(request, requestURL, options, { errorRedirect: true });
  if (data && !data.message) {
    Analytics.track('users_delete_account_succeeded');
    if (successCallback) {
      successCallback();
    }
  } else {
    Analytics.track('users_delete_account_failed');
  }
  yield put(setLoadingFlag(false));
}
export function* updateCommunication({ communication }) {
  const oktaToken = localStorageUser.getOktaTokenStorage();
  const userId = oktaToken?.accessToken?.claims?.fcUserId;
  const { method, ...dataCommunication } = communication;
  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/user-infos/${userId}/communications`;
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      communications: [dataCommunication],
    }),
  };
  yield call(request, requestURL, options, {
    errorRedirect: false,
  });
  if (userId) {
    yield put(getUserData(userId, null, true));
  }
}

export default function* initAccountSettingsPageSaga() {
  yield takeLatest(SAVE_EDIT_USER, saveUser);
  yield takeLatest(UPLOAD_IMAGE_USER, updateImage);
  yield takeLatest(RESET_PASSWORD, resetPassword);
  yield takeLatest(DELETE_ACCOUNT, deleteAccount);
  yield takeLatest(ADD_COMMUNICATION, updateCommunication);
}
