import { fromJS } from 'immutable';

import { SET_GLOBAL_MESSAGE } from './constants';

export const initialState = fromJS({
  message: {
    type: '',
    intlMessage: {},
    active: false,
  },
  globalRef: null,
});

function messageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_GLOBAL_MESSAGE:
      return state.set('message', action.msg);
    default:
      return state;
  }
}

export default messageReducer;
