import React from 'react';

import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

export const styles = () => ({
  transparentBorder: {
    boxShadow: `inset 0 0 0 1px rgba(99, 115, 129, 0.2)`,
    zIndex: 1,
  },
  grid: {
    display: 'grid',
    zIndex: 1,
  },
  gridChildOnEach: {
    gridArea: '1 / -1',
  },
});

const TransparentBorder = React.forwardRef(({ classes, borderClassName, children }, ref) => (
  <div className={classes.grid} ref={ref}>
    <div className={classes.gridChildOnEach}>{children}</div>
    <div
      className={classnames(classes.gridChildOnEach, classes.transparentBorder, borderClassName)}
    />
  </div>
));

TransparentBorder.propTypes = {
  borderClassName: PropTypes.string,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default compose(withStyles(styles))(TransparentBorder);
