import { call, put, select, takeLatest } from 'redux-saga/effects';
import uuid from 'uuid';

import { selectDataUser } from 'containers/NavigationBar/selectors';
import { parseRequest, request } from 'utils/request';

import { clearCheckedItems, clearItems, setItems, setLoadingFlag, setUniqueKey } from './actions';
import { GET_ITEMS, UPDATE_ITEMS } from './constants';
import {
  selectActiveFilters,
  selectItems,
  selectItemsSortData,
  selectSearchText,
} from './selectors';

export const addFilterPayload = (urlParams, activeFilters, exportFromForms) => {
  const filterKeys = Object.keys(activeFilters);
  if (filterKeys.length) {
    const tempActiveFilter = {};
    filterKeys.forEach(key => {
      const activeFilterByKey = activeFilters[key];
      if (exportFromForms && key === 'form_ids') {
        const formIds = [];
        activeFilterByKey.forEach(item => {
          if (item?.ids) {
            formIds.push(...item.ids);
            return;
          }
          if (item?.id) {
            formIds.push(item.id);
          }
        });
        tempActiveFilter[key] = formIds;
      } else if (Array.isArray(activeFilterByKey)) {
        tempActiveFilter[key] = activeFilterByKey.map(
          item => (typeof item?.id !== 'undefined' ? item.id : item),
        );
      } else {
        tempActiveFilter[key] = activeFilterByKey;
      }
    });
    return { ...urlParams, ...tempActiveFilter };
  }

  return urlParams ? { ...urlParams } : {};
};

function* processItems(offset, namespace, items, data, requestPayload, searchText) {
  let oldData = [];
  if (!offset || offset === 0) {
    yield put(clearCheckedItems(namespace));

    yield put(clearItems(namespace));
    if (searchText) {
      yield put(setItems([], 0, namespace));
    }

    yield put(setUniqueKey(uuid.v4(), namespace));
  } else {
    oldData = yield select(selectItems(namespace));
  }
  const combinedData = [...oldData, ...items];
  yield put(setLoadingFlag(false, namespace));
  yield put(setItems(combinedData, data.count, namespace));

  if (requestPayload.callBack) {
    yield call(requestPayload.callBack, data);
  }
}

export function fetchItems(namespace = 'table') {
  // To Mock Data for reports - TODO Remove when API Ready
  return function* fetchItemsGen({ requestPayload, callback }) {
    const payload = {
      urlParams: {},
      url: requestPayload.url,
      method: requestPayload.method,
    };
    const { dataKey, urlParams, excludeParamsAtSearch, onError } = requestPayload;
    const { offset } = urlParams;
    yield put(setLoadingFlag(true, namespace));
    const user = yield select(selectDataUser());

    const searchText = yield select(selectSearchText(namespace));
    const sortData = yield select(selectItemsSortData(namespace));
    const activeFilters = yield select(selectActiveFilters(namespace));

    payload.urlParams = addFilterPayload(requestPayload.urlParams, activeFilters);

    if (searchText) {
      if (requestPayload.searchTextLabel) {
        payload.urlParams[requestPayload.searchTextLabel] = searchText.trim();
      } else {
        payload.urlParams.search_term = searchText.trim();
      }

      if (excludeParamsAtSearch) {
        excludeParamsAtSearch.forEach(key => {
          delete payload.urlParams[key];
        });
      }
    }

    if (Object.keys(sortData).length) {
      payload.urlParams.sortOrder = sortData.sortOrder;
      payload.urlParams.sortPropertyName = sortData.propertyName;
    }
    const { requestURL, options } = parseRequest({ ...payload, user });

    const data = requestPayload?.redirectionUrl
      ? yield call(request, requestURL, options, {
          errorRedirect: true,
          redirectionUrl: requestPayload?.redirectionUrl,
        })
      : yield call(request, requestURL, options);

    const items = data && data[dataKey];

    if (
      (data && data?.status === 403) ||
      data?.status === 400 ||
      data?.status === 410 ||
      (data && data?.message)
    ) {
      yield put(clearItems(namespace));
      yield put(setItems([], 0, namespace));
      yield put(setLoadingFlag(false, namespace));
      if (onError) yield call(onError, data);
      if (callback) {
        callback(false);
      }
    } else if (items) {
      yield* processItems(offset, namespace, items, data, requestPayload, searchText);
      if (callback) {
        callback(true);
      }
    }
  };
}

function processSearch(requestPayload, payload, searchText) {
  const payloadCopy = { ...payload };
  if (requestPayload.searchTextLabel) {
    payloadCopy.urlParams[requestPayload.searchTextLabel] = searchText.trim();
  } else {
    payloadCopy.urlParams.search_term = searchText.trim();
  }
  if (requestPayload?.excludeParamsAtSearch) {
    requestPayload.excludeParamsAtSearch.forEach(key => {
      delete payloadCopy.urlParams[key];
    });
  }
  return payloadCopy;
}

export function updateItems(namespace = 'table') {
  return function* updateItemsGen({ requestPayload }) {
    const { dataKey, onError } = requestPayload;

    let payload = {
      urlParams: {},
      url: requestPayload.url,
      method: requestPayload.method,
    };
    yield put(setLoadingFlag(true, namespace));
    const user = yield select(selectDataUser());

    const searchText = yield select(selectSearchText(namespace));
    const previousList = yield select(selectItems(namespace));
    const sortData = yield select(selectItemsSortData(namespace));
    const activeFilters = yield select(selectActiveFilters(namespace));

    if (requestPayload.urlParams) {
      payload.urlParams = addFilterPayload(requestPayload.urlParams, activeFilters);
    }

    if (activeFilters) {
      payload.urlParams = addFilterPayload(requestPayload.urlParams, activeFilters);
    }

    if (searchText) {
      payload = processSearch(requestPayload, payload, searchText);
    }

    if (Object.keys(sortData).length) {
      payload.urlParams.sortOrder = sortData.sortOrder;
      payload.urlParams.sortPropertyName = sortData.propertyName;
    }

    payload.urlParams.offset = 0;
    payload.urlParams.limit = previousList.size;

    const { requestURL, options } = parseRequest({ ...payload, user });

    const data = yield call(request, requestURL, options);
    const items = data && data[dataKey];
    yield put(setUniqueKey(uuid.v4(), namespace));
    if ((data && data?.status === 403) || data?.status === 410 || (data && data?.message)) {
      yield put(setItems([], 0, namespace));
      yield put(setLoadingFlag(false, namespace));
      if (onError) yield call(onError, data);
    } else if (items) {
      yield put(setItems(items, data.count, namespace));
      yield put(setLoadingFlag(false, namespace));
    }
    if (requestPayload.callBack) {
      yield call(requestPayload.callBack, data);
    }
  };
}

export default function(namespace = 'table') {
  return function* initInfinitePageSaga() {
    yield takeLatest(`${namespace}/${GET_ITEMS}`, fetchItems(namespace));
    yield takeLatest(`${namespace}/${UPDATE_ITEMS}`, updateItems(namespace));
  };
}
