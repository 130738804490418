import React, { useEffect, useMemo, useState } from 'react';

import { Box, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import FolderImage from 'assets/images/folder.svg';
import ArrowLeft from 'components/SvgComponents/icons/ArrowLeft';
import { TypographyEllipsis } from 'components/Templates/TypographyEllipsis';
import { getModulesForms } from 'containers/GlobalWrapper/modules/actions';
import { selectFormModuleMap } from 'containers/GlobalWrapper/modules/selectors';
import { ROW_TYPE_CATEGORY, ROW_TYPE_FORM } from 'containers/ModulesPage/ModuleTableRowType';
import globalMessages from 'translations/messages/global-messages';

import { getInformationsAboutAvailableForms } from '../../actions';
import ItemFolder from './ItemFolder';
import ItemForm from './ItemForm';

const ITEM_FOLDER = 'Folder';
const ITEM_FORM = 'Form';

const Box_ListContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(1)};
  overflow-x: hidden;
  margin-bottom: ${props => props.theme.spacing(10)};
`;

const Box_BackContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.spacing(1)};
  align-items: center;
  cursor: pointer;
  margin-bottom: ${props => props.theme.spacing(2)};
  padding-left: ${props => props.theme.spacing(1)};
  min-height: 28px;
`;

const Box_RootEmptyContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing(8)};
`;

const Box_FolderEmptyContainer = styled(Box)`
  margin-top: ${props => props.theme.spacing(8)};
  margin-bottom: ${props => props.theme.spacing(16)};
  text-align: center;
`;

function formatCategory(category) {
  return {
    type: ITEM_FOLDER,
    id: category.id,
    name: category.name,
    children: category.children,
  };
}

function formatForm(intl, form, formTemplates) {
  const formTemplate = formTemplates.find(template => template.id === form.id);
  const version = formTemplate?.version
    ? intl.formatMessage(globalMessages.version_number, {
        number: formTemplate?.version,
      })
    : null;
  return {
    id: form?.id,
    type: ITEM_FORM,
    name: formTemplate?.name,
    version,
    loading: formTemplate === undefined,
  };
}

function formatItem(intl, item, formTemplates) {
  if (item.content_type === ROW_TYPE_CATEGORY) {
    return formatCategory(item);
  }
  return formatForm(intl, item, formTemplates);
}

function FormFolderPicker({ selectedFormId, onSelectForm, onSelectCategory, moduleId, sx, intl }) {
  const formModuleMap = useSelector(selectFormModuleMap());
  const [currentItems, setCurrentItems] = useState([]);
  const [currentNode, setCurrentNode] = useState();
  const [formTemplates, setFormTemplates] = useState([]);
  const [pathStack, setPathStack] = useState([]);
  const params = useParams();
  const dispatch = useDispatch();
  const { itemId } = params;
  const formModuleId = moduleId || itemId;
  const currentModule = useMemo(
    () => formModuleMap.toJS().find(module => module?.id === formModuleId),
    [formModuleMap, itemId],
  );
  const currentFolder = pathStack[pathStack.length - 1];
  const isRoot = pathStack.length < 2;

  const handleFolderClick = folder => {
    const enabledForms =
      folder.children?.filter(item => item.enabled || item.content_type !== ROW_TYPE_FORM) || [];
    setCurrentNode(enabledForms);
    setPathStack([...pathStack, folder]);
  };

  const handleFormClick = form => {
    const formTemplate = formTemplates.find(template => template.id === form.id);
    if (formTemplate) {
      const categoryId = pathStack.length > 1 ? pathStack[pathStack.length - 2].id : null;
      onSelectForm({ ...formTemplate, category_id: categoryId });
    }
  };

  const handleNavBack = () => {
    const previousFolder =
      pathStack.length > 2
        ? pathStack[pathStack.length - 2].children
        : pathStack[pathStack.length - 2];

    const filteredFolder =
      previousFolder.filter(item => item?.enabled || item?.content_type !== ROW_TYPE_FORM) || [];
    setCurrentNode(filteredFolder);
    setPathStack(pathStack.slice(0, pathStack.length - 1));
  };

  useEffect(
    () => {
      dispatch(getModulesForms(params.idData, false));
    },
    [params.idData],
  );

  useEffect(
    () => {
      onSelectCategory(currentFolder);
    },
    [currentFolder],
  );

  useEffect(
    () => {
      const items =
        currentNode?.map(item => formatItem(intl, item, formTemplates))?.sort((a, b) => {
          const nameA = a.name?.toUpperCase() || '';
          const nameB = b.name?.toUpperCase() || '';
          return nameA.localeCompare(nameB);
        }) || [];
      setCurrentItems(items);
    },
    [currentNode, formTemplates],
  );

  useEffect(
    () => {
      const formTemplateIds =
        currentNode?.filter(item => item.content_type === ROW_TYPE_FORM)?.map(item => item.id) ||
        [];
      if (formTemplateIds.length > 0) {
        dispatch(
          getInformationsAboutAvailableForms({
            formsIds: formTemplateIds,
            callback: data => {
              setFormTemplates(data.forms);
            },
            width_disabled: true,
          }),
        );
      }
    },
    [currentNode],
  );

  useEffect(
    () => {
      const items = currentModule?.module_content || currentModule?.children || [];
      const enabledForms = items.filter(
        item => item.enabled || item.content_type !== ROW_TYPE_FORM,
      );

      setPathStack([enabledForms]);
      setCurrentNode(enabledForms);
    },
    [currentModule],
  );

  return (
    <Box_ListContainer sx={sx}>
      {!isRoot && (
        <Box_BackContainer onClick={handleNavBack} key="back-button">
          <>
            <ArrowLeft size={14} />
            <TypographyEllipsis variant="body">{currentFolder.name}</TypographyEllipsis>
          </>
        </Box_BackContainer>
      )}
      {currentItems.map(item => (
        <Box key={item.id}>
          {item.type === ROW_TYPE_FORM && (
            <ItemForm
              form={item}
              onClick={handleFormClick}
              isSelected={selectedFormId === item.id}
            />
          )}
          {item.type === 'Folder' && <ItemFolder folder={item} onClick={handleFolderClick} />}
        </Box>
      ))}

      {currentItems.length === 0 &&
        isRoot && (
          <Box_RootEmptyContainer>
            <img
              style={{ maxWidth: '150px' }}
              src={FolderImage}
              alt={intl.formatMessage(globalMessages.no_data_available)}
            />
            <Typography variant="subtitleLight" component="p" sx={{ marginTop: '16px' }}>
              {intl.formatMessage(globalMessages.no_data_available)}
            </Typography>
          </Box_RootEmptyContainer>
        )}
      {currentItems.length === 0 &&
        !isRoot && (
          <Box_FolderEmptyContainer>
            <Typography variant="h6" component="p" sx={{ marginBottom: '8px' }}>
              {intl.formatMessage(globalMessages.category_empty_view_title)}
            </Typography>

            <Typography variant="subtitleLight" component="p">
              {intl.formatMessage(globalMessages.category_empty_view_description)}
            </Typography>
          </Box_FolderEmptyContainer>
        )}
    </Box_ListContainer>
  );
}

FormFolderPicker.propTypes = {
  selectedFormId: PropTypes.string,
  onSelectForm: PropTypes.func,
  onSelectCategory: PropTypes.func,
  sx: PropTypes.object,
  intl: intlShape.isRequired,
  moduleId: PropTypes.string,
};

export default injectIntl(FormFolderPicker);
