import React from 'react';
import PropTypes from 'prop-types';

export const CheckCircle = props => (
  <svg
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.663 3.77915C13.8902 2.98926 11.9096 2.79357 10.0166 3.22128C8.12351 3.649 6.41942 4.67719 5.15845 6.15252C3.89749 7.62785 3.14721 9.47126 3.01951 11.4078C2.89181 13.3444 3.39354 15.2704 4.44987 16.8985C5.50619 18.5266 7.06051 19.7697 8.88102 20.4423C10.7015 21.1149 12.6907 21.1809 14.5518 20.6307C16.413 20.0804 18.0464 18.9432 19.2084 17.3888C20.3705 15.8344 20.9989 13.9459 21 12.0052V11.0857C21 10.5334 21.4477 10.0857 22 10.0857C22.5523 10.0857 23 10.5334 23 11.0857V12.0057C22.9986 14.3778 22.2306 16.6864 20.8103 18.5863C19.39 20.4862 17.3936 21.876 15.1189 22.5486C12.8442 23.2211 10.413 23.1404 8.18792 22.3183C5.96285 21.4963 4.06312 19.977 2.77206 17.9871C1.48099 15.9971 0.86777 13.6432 1.02384 11.2762C1.17992 8.90932 2.09693 6.65625 3.63811 4.85308C5.17929 3.0499 7.26206 1.79322 9.57581 1.27046C11.8896 0.747697 14.3103 0.986867 16.477 1.9523C16.9815 2.17708 17.2082 2.76825 16.9834 3.27272C16.7587 3.7772 16.1675 4.00393 15.663 3.77915Z"
      fill="current"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7068 3.29828C23.0975 3.68861 23.0978 4.32177 22.7075 4.71249L12.7075 14.7225C12.52 14.9102 12.2656 15.0157 12.0002 15.0157C11.7349 15.0158 11.4805 14.9104 11.2929 14.7228L8.29289 11.7228C7.90237 11.3323 7.90237 10.6992 8.29289 10.3086C8.68342 9.91811 9.31658 9.91811 9.70711 10.3086L11.9996 12.6012L21.2925 3.29898C21.6829 2.90826 22.316 2.90795 22.7068 3.29828Z"
      fill="current"
    />
  </svg>
);

CheckCircle.propTypes = {
  size: PropTypes.number,
}
