import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

function CircleCheckEmpty(props) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>
        {props.title || props.intl.formatMessage(globalMessages.uncheck)}
      </title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11Z"
      />
    </svg>
  );
}
CircleCheckEmpty.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

CircleCheckEmpty.defaultProps = {
  title: '',
};

export default injectIntl(CircleCheckEmpty);
