import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { messageType } from './constants';

export const displayExtraInfo = (
  createdAt,
  previousCreatedAt,
  authorId,
  previousAuthorId,
  type,
  previousType,
) => {
  if (type !== messageType.action && previousType === messageType.action)
    return true;

  const minutesBetweenMessages = moment(createdAt).diff(
    previousCreatedAt || 0,
    'minutes',
    true,
  );

  const isTimeLongBetweenMessages = minutesBetweenMessages >= 5;
  const isPreviousSameAuthor = authorId === previousAuthorId;

  return !isPreviousSameAuthor || isTimeLongBetweenMessages;
};

const processResponseAction = (
  message,
  responseAction,
  scopeType,
  scopeId,
  responseAuthorId,
  createdAt,
  responseId,
) => {
  const form = message?.form;
  const responseActionTarget = responseAction?.target;
  const responseActionNewState =
    responseAction?.newState || responseAction?.new;

  let responseActionNewStateId;
  if (responseActionNewState) {
    responseActionNewStateId = responseActionNewState?.id;
  }
  let responseActionNewStateValue;
  if (responseActionNewState) {
    responseActionNewStateValue = responseActionNewState?.value;
  }
  return {
    type: messageType.action,
    content: {
      actionTracking: responseActionTarget,
      scopeType,
      scopeId,
      authorId: responseAuthorId,
      newStateId: responseActionNewStateId,
      newStateValue: responseActionNewStateValue,
      createdAt,
      form,
    },
    id: responseId,
  };
};

const processMessage = (message, responseAuthorId, createdAt, responseId) => {
  const responseMessage = message?.message;
  const referencesList =
    responseMessage?.referencesList || responseMessage?.references;
  const mediaResourcesList =
    responseMessage?.mediaResourcesList || responseMessage?.media_resources;
  // classic item
  if (!isEmpty(referencesList) && referencesList.length === 1) {
    const type = referencesList[0]?.type;
    const id = referencesList[0]?.id;
    return {
      type,
      content: {
        id,
        authorId: responseAuthorId,
        createdAt,
      },
      id: responseId,
    };
  // cluster items
  } else if (!isEmpty(referencesList) && referencesList.length > 1) {
    return {
      type: messageType.cluster,
      content: {
        authorId: responseAuthorId,
        references: referencesList,
        createdAt,
      },
      id: responseId,
    };
  } else if (!isEmpty(mediaResourcesList)) {
    const url = mediaResourcesList[0]?.url;
    if (
      !isEmpty(mediaResourcesList[0]?.imageMetadata) ||
      mediaResourcesList[0]?.height ||
      mediaResourcesList[0]?.width
    ) {
      return {
        type: messageType.image,
        content: {
          url,
          authorId: responseAuthorId,
          createdAt,
        },
        id: responseId,
      };
    }
    const displayName =
      mediaResourcesList[0]?.displayName || mediaResourcesList[0]?.display_name;
    const size = mediaResourcesList[0]?.size;
    return {
      type: messageType.file,
      content: {
        url,
        size,
        displayName,
        authorId: responseAuthorId,
        createdAt,
      },
      id: responseId,
    };
  }
  return {
    type: messageType.text,
    content: {
      text: responseMessage?.text?.text || responseMessage?.text?.value,
      inline_references: responseMessage?.text?.inline_references || [],
      authorId: responseAuthorId,
      createdAt,
    },
    id: responseId,
  };
};

export const convertToMessage = (message, scopeType, scopeId) => {
  const responseId = message?.messageId || message?.message_id;
  const responseAuthor = message?.author;
  const responseCreatedAt = message?.createdAt || message?.created_at;
  const createdAt = moment(
    responseCreatedAt.seconds
      ? responseCreatedAt.seconds * 1000
      : responseCreatedAt,
  );
  const responseAuthorId = responseAuthor?.id || responseAuthor.identity_id;

  const responseAction = message.actionTracking || message.action_tracking;
  // if message has responseAction, it is an action message
  if (!isEmpty(responseAction)) {
    return processResponseAction(
      message,
      responseAction,
      scopeType,
      scopeId,
      responseAuthorId,
      createdAt,
      responseId,
    );
  }
  // if message has message, it is a text message or an item message
  if (!isEmpty(message?.message)) {
    return processMessage(message, responseAuthorId, createdAt, responseId);
  }
  return null;
};
