import React from 'react';

import { Player } from '@lottiefiles/react-lottie-player';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import Button from 'components/Button';
import ArrowRight from 'components/SvgComponents/icons/ArrowRight';
import { Routing } from 'routing/routing';
import globalMessages from 'translations/messages/global-messages';
import { stripeStatus } from 'utils/constants';
import localStorageUser from 'utils/localStorageUser';

import useStripeCheckout from './hook/useStripeCheckout';

const styles = () => ({
  containerPage: {
    height: '100%',
    position: 'relative',
  },
  containerText: {
    margin: '20px auto',
    width: '80%',
  },
  containerSuccess: {
    width: '100%',
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
});

function CallBackPaymentPage(props) {
  const { classes, history, intl } = props;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sessionId = urlParams.get('session_id');
  const businessOrganization = localStorageUser.getBusinessOrganizationLocalStorage();

  const stripeCheckout = useStripeCheckout({
    sessionId,
    businessOrganization,
  });

  const handleOnClick = () => {
    history.push(Routing.organizations.contract(businessOrganization?.id));
  };

  if (stripeCheckout.status === stripeStatus.complete) {
    return (
      <div id="success" className={classes.containerPage}>
        <div className={classes.containerSuccess}>
          <Player
            autoplay
            keepLastFrame
            src="https://lottie.host/2e1fc419-c68b-4118-8fae-515019af8cb1/kstyq9jZYF.json"
            style={{ height: '80px', width: '80px' }}
          />
          <div className={classes.containerText}>
            <Typography variant="h3" component="h1" sx={{ marginBottom: '16px' }}>
              {intl.formatMessage(globalMessages.title_payment_success)}
            </Typography>
            <Typography variant="h5" component="p" sx={{ marginBottom: '16px' }}>
              {intl.formatMessage(globalMessages.text_successfully, {
                email: stripeCheckout.customerEmail || '',
              })}
            </Typography>
            <Typography variant="bodyLight" component="p">
              {intl.formatMessage(globalMessages.subscription_date_payment_success)}
            </Typography>
          </div>
          <Button startIcon={<ArrowRight />} onClick={handleOnClick}>
            {intl.formatMessage(globalMessages.back_to_subscriptions)}
          </Button>
        </div>
      </div>
    );
  }
  return null;
}

CallBackPaymentPage.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object,
  intl: intlShape.isRequired,
};

export default compose(
  withStyles(styles),
  injectIntl,
)(CallBackPaymentPage);
