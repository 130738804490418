import { fromJS } from 'immutable';

import {
  SET_LOADING_FLAG,
  SET_SEARCH_IN_ALL_DOCUMENTS,
  SET_DOCUMENT_TO_RENAME,
} from './constants';

export const initialState = fromJS({
  loading: false,
  searchInAllDocuments: [],
  documentToRename: '',
});

function oneDocumentsPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_FLAG:
      return state.set('loading', action.loading);
    case SET_SEARCH_IN_ALL_DOCUMENTS:
      return state.set('searchInAllDocuments', fromJS(action.results));
    case SET_DOCUMENT_TO_RENAME:
      return state.set('documentToRename', action.id);
    default:
      return state;
  }
}
export default oneDocumentsPageReducer;
