import React from 'react';
import PropTypes from 'prop-types';
import TextCellSkeleton from 'components/SkeletonScreen/components/TextCellSkeleton';
import { Switch } from '@mui/material';
import { withStyles } from '@mui/styles';
import formEngineStyle from 'theme/formEngine-theme';

export const SwitchCell = props => {
  const { loading, value, onValueChange, classes, disabled } = props;

  if (loading) {
    return <TextCellSkeleton />;
  }

  return (
    <Switch
      checked={value}
      onChange={onValueChange}
      disabled={disabled}
      classes={{
        switchBase: classes.switchBase,
        icon: classes.switchIcon,
        checked: classes.switchChecked,
        disabled: classes.switchDisabled,
        track: classes.track,
      }}
    />
  );
};

SwitchCell.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onValueChange: PropTypes.func,
};

SwitchCell.defaultProps = {
  onValueChange: () => null,
  disabled: false,
};

export default withStyles(formEngineStyle)(SwitchCell);
