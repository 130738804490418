import React from 'react';

function JaSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512.001 512.001"
      width="60px"
      height="36px"
      {...props}
    >
      <path
        style={{ fill: '#F5F5F5' }}
        d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828
	h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.773,508.047,423.725,503.172,423.725z"
      />
      <circle style={{ fill: '#FF4B55' }} cx="256" cy="256.001" r="97.1" />
    </svg>
  );
}

export default JaSvg;
