import React, { useState } from 'react';

import { ErrorBoundary } from '@sentry/react';
import PropTypes from 'prop-types';

import ErrorBoundaryFallback from 'components/ErrorBoundaries/ErrorBoundaryFallback';

function ErrorBoundaries(props) {
  const { children, message, showButton } = props;
  const [hasError, setHasError] = useState(false);

  const onReset = () => {
    setHasError(false);
  };

  return (
    <ErrorBoundary
      fallback={({ resetError }) => (
        <ErrorBoundaryFallback
          onReset={() => {
            resetError();
            onReset();
          }}
          fullWidth
          message={message}
          showButton={showButton}
        />
      )}
      onError={() => setHasError(true)}
    >
      {hasError ? null : children}
    </ErrorBoundary>
  );
}

ErrorBoundaries.propTypes = {
  children: PropTypes.node.isRequired,
  message: PropTypes.string,
  showButton: PropTypes.bool,
};

ErrorBoundaries.defaultProps = {
  message: '',
  showButton: true,
};

export default ErrorBoundaries;
