import moment from 'moment-timezone';
import { delay } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { addFilterPayload } from 'components/InfiniteTableSelfStandingV3/saga';
import config from 'config/config';
import { setGlobalError } from 'containers/ErrorHandler/actions';
import { setPopoupBlockerObject } from 'containers/GlobalWrapper/actions';
import { selectRouteParams } from 'containers/GlobalWrapper/selectors';
import { clearCustomizationConfigs } from 'containers/OneProjectPage/ExportPage/store/actions';
import {
  selectActiveFilters,
  selectRecipientIds,
  selectCustomizationConfigs,
} from 'containers/OneProjectPage/ExportPage/store/selectors';
import globalMessages from 'translations/messages/global-messages';
import Analytics from 'utils/Analytics';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { oneProjectPages } from 'utils/constants';
import { checkParams } from 'utils/errors/check-params';
import { downloadFile } from 'utils/file';
import localStorageUser from 'utils/localStorageUser';
import request from 'utils/request';

import { setLoadingExportFlag, getFile } from './actions';
import { AnalyticsSuccess, AnalyticsError } from './analytics';
import { EXPORT_ITEM, GET_EXPORT_FILE } from './constants';

export function* exportItem({
  callback,
  isHTML = false,
  onlyPDF = false,
  exportFromForms = false,
}) {
  yield put(setLoadingExportFlag(true));

  const route = yield select(selectRouteParams());
  const filters = yield select(selectActiveFilters());
  const recipientIds = yield select(selectRecipientIds());
  const customizationConfigs = yield select(selectCustomizationConfigs());
  const persistentConfigs =
    localStorageUser.getReportCustomization({ projectId: route.idData }) || {};
  const configs = { ...customizationConfigs, ...persistentConfigs };
  const tempFilters = addFilterPayload({}, { ...filters, ...configs }, exportFromForms);

  if (filters.title) {
    Analytics.track('reports_title_added');
  }

  if (filters.description) {
    Analytics.track('reports_paragraph_added');
  }

  localStorageUser.saveFilterByProjectAndModule({
    filters,
    projectId: route.idData,
    module: oneProjectPages.export,
  });

  let generation_type = !isHTML ? 'PDF' : 'HTML';
  if (onlyPDF) {
    generation_type = 'PDF';
  }
  yield put(clearCustomizationConfigs());

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/report/handover/generate`;

  const { module_ids, ...tempsFiltersWithoutModuleIds } = tempFilters;

  const tempsFiltersComplete =
    module_ids && module_ids[0] !== undefined
      ? { ...tempsFiltersWithoutModuleIds, module_ids: tempFilters.module_ids }
      : tempsFiltersWithoutModuleIds;

  const payload = {
    organization_id: route.organizationId,
    'api-version': '1.2',
    time_zone: moment.tz.guess(),
    ...tempsFiltersComplete,
    generation_type,
    recipient_ids: recipientIds,
  };
  if (tempFilters.split_by) {
    payload.split_by = 'Company';
  } else {
    delete payload.split_by;
  }
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  const data = yield call(request, requestURL, options, { errorCustom: true });
  if (data) {
    if (callback) {
      yield call(callback, data, payload);
    } else {
      AnalyticsSuccess(tempFilters?.entity_type, route.organizationId, route.idData);
    }
  } else {
    AnalyticsError(tempFilters?.entity_type, route.organizationId, route.idData);
  }
  yield put(setLoadingExportFlag(false));
}

export function* fetchFileUrl({ params, key, isHTML = false, entityType }) {
  if (key) {
    checkParams({ params, keys: ['idData'] });
    const route = yield select(selectRouteParams());

    const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
      params.idData
    }/report/file?key=${key}`;

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const data = yield call(request, requestURL, options);
    const filters = yield select(selectActiveFilters());
    const tempFilters = addFilterPayload({}, filters);

    if (!data.url && (data.is_in_error || data?.response?.is_in_error)) {
      AnalyticsError(tempFilters?.entity_type || entityType);
    } else if (!data?.url && !data.message) {
      yield delay(1000);
      yield put(getFile(params, key, isHTML, entityType));
    } else if (data && !data.message && data.url) {
      yield put(setLoadingExportFlag(false));
      if (isHTML) {
        AnalyticsSuccess(
          tempFilters?.entity_type || entityType,
          route.organizationId,
          route.idData,
        );
        yield put(setPopoupBlockerObject({ url: data.url }));
      } else downloadFile(data.url, `file-${params.idData}.pdf`);
    }
  } else {
    yield put(
      setGlobalError({
        data: {},
        customErrorMessageIntl: globalMessages.export_fail,
      }),
    );
  }
}

export default function* initGlobalExportSaga() {
  yield takeLatest(EXPORT_ITEM, exportItem);
  yield takeLatest(GET_EXPORT_FILE, fetchFileUrl);
}
