import { pathFallback } from 'utils/constants';

function replaceUrlFallback(url) {
  let newUrl = url;
  // TODO Remove this URL replacement when mobile app will be updated to handle new URL
  pathFallback.forEach(([replace, search]) => {
    newUrl = newUrl.replace(new RegExp(search.slice(0, -1), 'g'), replace.slice(0, -1));
  });
  return newUrl;
}

async function copyLinkInClipboard() {
  await navigator.clipboard.writeText(replaceUrlFallback(window.location.href));
}

export { copyLinkInClipboard, replaceUrlFallback };
