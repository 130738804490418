import {
  CREATE_ACCOUNT,
  COMPLETE_ACCOUNT,
  RESET_LOGIN_STATE,
  SET_LOADING_FLAG,
  SET_FORGOT_PASSWORD_VISIBLE,
  SEND_RESET_PASSWORD_EMAIL,
  SHOW_RELOGIN_BUTTON,
  CHECK_INVITATION_LINK,
  GET_IDP_INFO_DATA,
  VALIDATE_CODE,
  SEND_VALIDATION_EMAIL,
} from './constants';

export function setLoadingFlag(loading) {
  return {
    type: SET_LOADING_FLAG,
    loading,
  };
}

export function setForgotPasswordVisible(forgotPasswordVisible) {
  return {
    type: SET_FORGOT_PASSWORD_VISIBLE,
    forgotPasswordVisible: !!forgotPasswordVisible,
  };
}

export function sendResetPasswordEmail(email, callback) {
  return {
    type: SEND_RESET_PASSWORD_EMAIL,
    email,
    callback,
  };
}

export function showReloginButton(shouldShowRelogin) {
  return {
    type: SHOW_RELOGIN_BUTTON,
    shouldShowRelogin,
  };
}

export function getIdpInfoData(email, callback) {
  return {
    type: GET_IDP_INFO_DATA,
    email,
    callback,
  };
}

export function resetLogin() {
  return {
    type: RESET_LOGIN_STATE,
  };
}

export function createAccount(values, callbackSuccess, callbackError) {
  return {
    type: CREATE_ACCOUNT,
    values,
    callbackSuccess,
    callbackError,
  };
}

export function completeAccount(values, callback) {
  return {
    type: COMPLETE_ACCOUNT,
    values,
    callback,
  };
}

export function checkInvitationLink(id, callback) {
  return {
    type: CHECK_INVITATION_LINK,
    id,
    callback,
  };
}

export function validateCode(email, code, callback) {
  return {
    type: VALIDATE_CODE,
    email,
    code,
    callback,
  };
}

export function sendValidationEmail(email, callback) {
  return {
    type: SEND_VALIDATION_EMAIL,
    email,
    callback,
  };
}
