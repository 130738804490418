const dropzoneTheme = theme => ({
  container: {
    display: 'flex',
    outline: 'none',
    width: '100%',
    height: 160,
    border: `2px dashed ${theme.palette.divider}`,
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
    '&:focus': {
      border: `2px dashed ${theme.palette.secondary.main}`,
    },
  },
  containerActive: {
    backgroundColor: theme.palette.text.tertiary,
  },
  circularProgress: {
    position: 'absolute',
    top: 60,
    left: 228,
  },
  icon: {
    marginTop: theme.spacing(5),
  },
  instruction: {
    fontSize: '0.875rem',
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(),
    marginInline: theme.spacing(6),
  },
  supported: {
    marginTop: 0,
    fontSize: '.8rem',
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginInline: theme.spacing(6),
  },
});

export default dropzoneTheme;
