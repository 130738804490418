import { scope } from '../constants';

export const ADD_MODULE = `${scope}ADD_MODULE`;
export const CREATE_MODULE = `${scope}CREATE_MODULE`;
export const EDIT_MODULE = `${scope}EDIT_MODULE`;
export const GET_MODULES_OBSERVATIONS_AND_MEETING = `${scope}GET_MODULES_OBSERVATIONS_AND_MEETING`;
export const SET_MODULES_OBSERVATIONS_AND_MEETING = `${scope}SET_MODULES_OBSERVATIONS_AND_MEETING`;
export const GET_MODULES_FORMS = `${scope}GET_MODULES_FORMS`;
export const SET_MODULES_FORMS = `${scope}SET_MODULES_FORMS`;
export const SET_CURRENT_MODULE = `${scope}SET_CURRENT_MODULE`;
export const GET_CURRENT_MODULE = `${scope}GET_CURRENT_MODULE`;
export const UPDATE_MODULE_SETTINGS = `${scope}UPDATE_MODULE_SETTINGS`;
export const SET_NEW_MODULE = `${scope}SET_NEW_MODULE`;
export const SET_UPDATED_MODULE = `${scope}SET_UPDATED_MODULE`;
export const UPDATE_FORM_MODULES = `${scope}UPDATE_FORM_MODULES`;
