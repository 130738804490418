import { fromJS } from 'immutable';

import {
  SET_STATUS,
  SET_PRIORITIES,
  SET_TEMPLATES,
  CLEAR_TEMPLATES,
  SET_LOADING_FLAG,
  SET_PHASES,
  SET_LAST_CONFIGS,
} from './constants';

export const initialState = fromJS({
  loading: false,
  status: [],
  priorities: [],
  phases: [],
  members: [],
  templates: [],
  lastConfigs: [],
});

function formsPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_FLAG:
      return state.set('loading', action.loading);
    case SET_STATUS:
      return state.set('status', fromJS(action.status));
    case SET_PRIORITIES:
      return state.set('priorities', fromJS(action.priorities));
    case SET_PHASES:
      return state.set('phases', fromJS(action.phases));
    case SET_TEMPLATES:
      return state.set('templates', fromJS(action.templates));
    case CLEAR_TEMPLATES:
      return state.set('templates', fromJS([]));
    case SET_LAST_CONFIGS:
      return state.set('lastConfigs', fromJS(action.configs));
    default:
      return state;
  }
}
export default formsPageReducer;
