import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import { Paper, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import loginTextLogo from 'assets/images/loginTextLogo.svg';
import loginTextLogoDark from 'assets/images/loginTextLogoDark.svg';
import initialLoader from 'utils/initialLoader';
import ArrowRight from 'components/SvgComponents/icons/ArrowRight';
import { colorTheme } from 'utils/constants';
import { useColorMode } from 'contexts/ColorModeProvider';
import { Helmet } from 'react-helmet';

import globalMessages from 'translations/messages/global-messages';

const styles = theme => ({
  container: {
    background: theme.palette.background.primary,
    width: '100%',
    height: '100%',
    display: 'flex',
    color: theme.palette.text.dark,

    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  paper: {
    width: 420,
    height: 345,
    padding: '40px 28px',
    zIndex: theme.zIndex.tooltip,
    boxShadow: 'none',
    backgroundColor: theme.palette.background.white,
  },
  title: {
    fontSize: '1.125rem',
    marginBottom: 8,
  },
  content: {
    fontSize: '0.875rem',
    marginBottom: 24,
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 48,

    [theme.breakpoints.up('sm')]: {
      marginBottom: 40,
    },
  },
  arrowRight: {
    marginLeft: 4,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  redirect: {
    width: 211,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 24,
    backgroundColor: theme.palette.secondary.main,
    textDecoration: 'none',
    color: theme.palette.common.white,
    padding: '8px 24px',
  },
});

const InvitationExpired = ({ classes, intl }) => {
  const { mode } = useColorMode();

  React.useEffect(() => {
    initialLoader.hideLoader();
  }, []);
  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(globalMessages.link_no_longer_active)} |{' '}
          {intl.formatMessage(globalMessages.finalcad)}
        </title>
      </Helmet>
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <picture className={classes.logo}>
            <img
              src={mode === colorTheme.dark ? loginTextLogoDark : loginTextLogo}
              alt="Finalcad"
            />
          </picture>
          <Typography className={classes.title} component="h1">
            {intl.formatMessage(globalMessages.link_no_longer_active)}
          </Typography>
          <Typography className={classes.content}>
            {intl.formatMessage(globalMessages.link_contact_admin)}
          </Typography>
          <a href="https://www.finalcad.com" className={classes.redirect}>
            {intl.formatMessage(globalMessages.go_on_finalcad)}
            <ArrowRight className={classes.arrowRight} />
          </a>
        </Paper>
      </div>
    </>
  );
};

InvitationExpired.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

InvitationExpired.defaultProps = {};

export default compose(
  injectIntl,
  withStyles(styles),
)(InvitationExpired);
