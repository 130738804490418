import {
  GET_ITEMS,
  SET_ITEMS,
  CLEAR_ITEMS,
  RESET_ITEMS,
  SET_LOADING,
  UPDATE_ITEM,
  REMOVE_ITEM,
  FILTER_ITEMS,
} from './constants';

export function getItems({
  path,
  params,
  body,
  method,
  index,
  rootKey,
  totalCountKey,
  onHandleResponse,
  isDifferential = false,
  useStorage = false,
  callback,
}) {
  return {
    type: GET_ITEMS,
    path,
    params,
    body,
    method,
    index,
    rootKey,
    totalCountKey,
    onHandleResponse,
    isDifferential,
    useStorage,
    callback,
  };
}

export function setItems({ items, index, totalCount }) {
  return {
    type: SET_ITEMS,
    items,
    index,
    totalCount,
  };
}

export function filterItems({ key, value }) {
  return {
    type: FILTER_ITEMS,
    key,
    value,
  };
}

export function updateItem({ item, index }) {
  return {
    type: UPDATE_ITEM,
    item,
    index,
  };
}

export function removeItem({ item, index }) {
  return {
    type: REMOVE_ITEM,
    item,
    index,
  };
}

export function clearItems() {
  return {
    type: CLEAR_ITEMS,
  };
}

export function resetItems() {
  return {
    type: RESET_ITEMS,
  };
}

export function setLoading(isLoading) {
  return {
    type: SET_LOADING,
    isLoading,
  };
}
