import React from 'react';

import PropTypes from 'prop-types';

import { GREEN } from '../../theme/colors';

export function PlanPinSvg({ color, height, width, showCrossHair }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height={height}
      width={width}
      viewBox="0 0 60 80"
      fill={color}
      style={{
        zIndex: 2,
        pointerEvents: 'none',
      }}
    >
      {!showCrossHair && (
        <filter
          id="filter0_f"
          x="14"
          y="56"
          width="32"
          height="18"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur" />
        </filter>
      )}
      <path
        d="M37.3852 57.0158C49.2658 53.7756 58 42.9078 58 30C58 14.536 45.464 2 30 2C14.536 2 2 14.536 2 30C2 43.293 11.2632 54.4224 23.6867 57.2853L28.9869 62.5856C29.768 63.3666 31.0343 63.3666 31.8154 62.5856L37.3852 57.0158Z"
        fill={color}
      />
      <path
        d="M37.7757 57.686C49.8757 54.2944 58.75 43.1845 58.75 30C58.75 14.1218 45.8782 1.25 30 1.25C14.1218 1.25 1.25 14.1218 1.25 30C1.25 43.5747 10.6573 54.9504 23.3075 57.9668L28.4566 63.1159C29.5306 64.1899 31.2718 64.1899 32.3457 63.1159L37.7757 57.686Z"
        stroke="white"
        strokeOpacity="0.9"
        strokeWidth="1.5"
      />
      {showCrossHair && (
        <>
          <line
            transform="translate(18.5, 60)"
            id="primary"
            x1="19"
            y1="19"
            x2="5"
            y2="5"
            style={{
              fill: 'none',
              stroke: 'rgb(0, 0, 0)',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: 2,
            }}
          />
          <line
            transform="translate(18.5, 60)"
            id="primary-2"
            data-name="primary"
            x1="19"
            y1="5"
            x2="5"
            y2="19"
            style={{
              fill: 'none',
              stroke: 'rgb(0, 0, 0)',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: 2,
            }}
          />
        </>
      )}
    </svg>
  );
}

PlanPinSvg.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  showCrossHair: PropTypes.bool,
};

PlanPinSvg.defaultProps = {
  showCrossHair: false,
  color: GREEN,
  height: 75,
  width: 60,
};

export default PlanPinSvg;
