import { withStyles } from '@mui/styles';
import React from 'react';
import PropTypes from 'prop-types';
import Placeholder from './Placeholder';

const styles = () => ({
  wrapper: {
    display: 'flex',
    height: '32px',
    width: '100%',
  },
  alignBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginLeft: '10px',
  },
  image: {
    borderRadius: '5px',
  },
});

const DetailImageCellSkeleton = props => {
  const { classes } = props;
  return (
    <div className={classes.wrapper}>
      <Placeholder height="32px" width="32px" className={classes.image} />
      <div className={classes.alignBetween}>
        <Placeholder height="10px" width="40%" />
        <Placeholder height="8px" width="30%" />
      </div>
    </div>
  );
};

DetailImageCellSkeleton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DetailImageCellSkeleton);
