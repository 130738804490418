import React from 'react';
import PropTypes from 'prop-types';

import { Switch, FormControlLabel, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import formEngineStyle from 'theme/formEngine-theme';

const RenderSwitchForm = ({
  input,
  label,
  checked,
  className,
  disabled,
  meta,
  classes,
}) => (
  <div>
    <FormControlLabel
      classes={{ label: className }}
      className={className}
      control={
        <Switch
          checked={checked !== undefined ? checked : input.value}
          onChange={input.onChange}
          color="secondary"
          disabled={disabled}
          classes={{
            switchBase: classes.switchBase,
            icon: classes.switchIcon,
            checked: classes.switchChecked,
            disabled: classes.switchDisabled,
            track: classes.track,
          }}
        />
      }
      label={<Typography>{label || meta?.error}</Typography>}
    />
  </div>
);

RenderSwitchForm.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  className: PropTypes.string,
  meta: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(formEngineStyle)(RenderSwitchForm);
