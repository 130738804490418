import React, { memo, useEffect } from 'react';

import { SecureRoute } from '@okta/okta-react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import ErrorBoundaries from 'components/ErrorBoundaries';
import DataValidation from 'containers/GlobalWrapper/DataValidation';
import { Layout } from 'containers/GlobalWrapper/layout';
import { useLastVisitedPage } from 'utils/localStorageUser';

import BusinessOrganizationDataValidation from '../GlobalWrapper/BusinessOrganizationDataValidation';
import ProjectDataValidation from '../GlobalWrapper/ProjectDataValidation';

function ProtectedRouteComp({ component: Component, organizationLink, ...rest }) {
  const location = useLocation();
  const [, setPage] = useLastVisitedPage();

  useEffect(
    () => {
      setPage(location.pathname);
    },
    [location.pathname, setPage],
  );

  return (
    <SecureRoute {...rest}>
      <BusinessOrganizationDataValidation>
        <ProjectDataValidation>
          <DataValidation>
            <Layout>
              <ErrorBoundaries>
                <Component />
              </ErrorBoundaries>
            </Layout>
          </DataValidation>
        </ProjectDataValidation>
      </BusinessOrganizationDataValidation>
    </SecureRoute>
  );
}

ProtectedRouteComp.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  organizationLink: PropTypes.bool,
};

export const ProtectedRoute = memo(ProtectedRouteComp);
