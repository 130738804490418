import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';
import globalMessages from 'translations/messages/global-messages';

const ShortAnswer = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <title>
      {props.title || props.intl.formatMessage(globalMessages.short_answer)}
    </title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6667 6.00002H1.33333L1.33333 10H14.6667V6.00002ZM1.33333 4.66669C0.596954 4.66669 0 5.26364 0 6.00002V10C0 10.7364 0.596953 11.3334 1.33333 11.3334H14.6667C15.403 11.3334 16 10.7364 16 10V6.00002C16 5.26364 15.403 4.66669 14.6667 4.66669H1.33333Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.6665 7.99998C2.6665 7.63179 2.96498 7.33331 3.33317 7.33331L8.6665 7.33331C9.03469 7.33331 9.33317 7.63179 9.33317 7.99998C9.33317 8.36817 9.03469 8.66665 8.6665 8.66665L3.33317 8.66665C2.96498 8.66665 2.6665 8.36817 2.6665 7.99998Z"
    />
  </svg>
);

ShortAnswer.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};
export default compose(injectIntl)(ShortAnswer);
