const scope = 'homePage/';
export const SET_LOADING_FLAG = `${scope}SET_LOADING_FLAG`;
export const FETCH_PROJECT = `${scope}FETCH_PROJECT`;
export const UPDATE_PROJECT_STANDARD_INFO = `${scope}UPDATE_PROJECT_STANDARD_INFO`;
export const DETACH_PROJECT_MEDIA = `${scope}DETACH_PROJECT_MEDIA`;
export const ATTACH_PROJECT_MEDIA = `${scope}ATTACH_PROJECT_MEDIA`;
export const SET_PROJECT = `${scope}SET_PROJECT`;
export const GET_ACTIVITIES = `${scope}GET_ACTIVITIES`;
export const SET_ACTIVITIES = `${scope}SET_ACTIVITIES`;
export const RESET_ACTIVITIES = `${scope}RESET_ACTIVITIES`;
export const GET_OBSERVATION = `${scope}GET_OBSERVATION`;
export const GET_FORM_TEMPLATE = `${scope}GET_FORM_TEMPLATE`;
export const GET_FORM = `${scope}GET_FORM`;
export const GET_PLAN = `${scope}GET_PLAN`;
export const GET_MODULE_SUGGESTIONS = `${scope}GET_MODULE_SUGGESTIONS`;
export const SET_MODULE_SUGGESTIONS = `${scope}SET_MODULE_SUGGESTIONS`;
