export const styles = theme => ({
  mapControls: {
    position: 'absolute',
    top: 'calc( 50% - 48px)',
    right: 30,
    zIndex: 2,
  },
  mapButtons: {
    cursor: 'pointer',
    backgroundColor: theme.palette.background.white,
    borderRadius: 40,
    width: 32,
    height: 32,
    padding: 0,
    boxShadow: `0px 4.8px 12px rgba(23, 33, 52, 0.15)`,
    marginBottom: 10,
    color: theme.palette.text.dark,
  },
});
