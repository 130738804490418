import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import globalMessages from 'translations/messages/global-messages';
import Button from 'components/Button';
import Dialog from 'components/dialogs/Dialog';
import {
  setIsPopoupBlockerActive,
  setPopoupBlockerObject,
  clearPopupBlockObject,
} from 'containers/GlobalWrapper/actions';
import {
  selectIsPopoupBlockerActive,
  selectDownloadBlob,
} from 'containers/GlobalWrapper/selectors';

const styles = theme => ({
  dialogDesc: {
    position: 'relative',
    display: 'flex',
    textAlign: 'center',
    color: theme.palette.text.primary,
    justifyContent: 'center',
    marginTop: `${theme.spacing(2)}`,
  },
  dialogTitleTextRoot: {
    margin: 'auto',
    fontSize: 20,
    flex: 1,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  downloadLink: {
    textDecoration: 'none',
    marginRight: theme.spacing(),
    padding: `${theme.spacing()} ${theme.spacing(3)}`,
    borderRadius: `${theme.spacing(4)}`,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
  },
  dialogContent: {
    position: 'relative',
    padding: 40,
  },
});

export function PopupBlockerFallback(props) {
  const {
    classes,
    intl,
    downloadBlob,
    isPopoupBlockerActive,
    dispatchSetIsPopoupBlockerActive,
    dispatchSetPopoupBlockerObject,
    dispatchClearDownloadBlob,
  } = props;
  const processDownload = popup => {
    if (
      !popup ||
      popup.closed ||
      typeof popup.closed === 'undefined' ||
      (popup && popup.pbreason)
    ) {
      dispatchSetIsPopoupBlockerActive(true);
    } else {
      popup.close();
      if (downloadBlob.url.startsWith('blob')) {
        const link = document.createElement('a');
        link.href = downloadBlob.url;
        link.setAttribute('download', downloadBlob.name);
        window.document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        dispatchClearDownloadBlob();
      } else {
        window.open(downloadBlob.url, '_blank');
      }
    }
  };
  React.useEffect(
    () => {
      if (downloadBlob) {
        setTimeout(() => {
          // hacky but unavoidable code because of different browser behavior regarding popups in case blocker is active:
          // 3000 ms is the time required for chrome to consider popup not a 'user action'.
          try {
            const popup = window.open(window.location.origin, '_blank');
            setTimeout(() => {
              processDownload(popup);
            }, 10);
          } catch (e) {
            dispatchSetIsPopoupBlockerActive(true);
          }
        }, 3500);
      }
    },
    [downloadBlob],
  );

  const onCloseDialog = () => {
    dispatchSetIsPopoupBlockerActive(false);
    dispatchSetPopoupBlockerObject(null);
  };

  if (!isPopoupBlockerActive) {
    return <></>;
  }

  return (
    <Dialog
      id="popup-blocker-fallback-dialog"
      open={isPopoupBlockerActive}
      dialogContentClasses={{ root: classes.dialogContent }}
      fullWidth
      maxWidth="sm"
      onCloseDialog={onCloseDialog}
      dialogTitleNode={intl.formatMessage(globalMessages.popup_blocker_header)}
      dialogActionNode={[
        <Button
          key="popup-blocker-fallback-cancel"
          onClick={onCloseDialog}
          variant="plain"
        >
          {intl.formatMessage(globalMessages.cancel)}
        </Button>,
        <Link
          key="popup-blocker-fallback-link"
          className={classes.downloadLink}
          href={downloadBlob.url}
          download={downloadBlob.name}
          target="_blank"
          component="a"
          onClick={onCloseDialog}
        >
          {intl.formatMessage(globalMessages.popup_blocker_allow)}
        </Link>,
      ]}
    >
      <>
        <Typography
          classes={{
            root: classes.dialogTitleTextRoot,
          }}
          variant="h6"
          component="h3"
          noWrap
        >
          {intl.formatMessage(globalMessages.popup_blocker_title)}
        </Typography>
        <Typography className={classes.dialogDesc}>
          {intl.formatMessage(globalMessages.popup_blocker_desc)}
        </Typography>
      </>
    </Dialog>
  );
}

PopupBlockerFallback.propTypes = {
  classes: PropTypes.object,
  downloadBlob: PropTypes.object,
  isPopoupBlockerActive: PropTypes.bool,
  dispatchSetIsPopoupBlockerActive: PropTypes.func,
  dispatchSetPopoupBlockerObject: PropTypes.func,
  dispatchClearDownloadBlob: PropTypes.func,
  intl: intlShape.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isPopoupBlockerActive: selectIsPopoupBlockerActive(),
  downloadBlob: selectDownloadBlob(),
});

const mapDispatchToProps = {
  dispatchSetIsPopoupBlockerActive: setIsPopoupBlockerActive,
  dispatchSetPopoupBlockerObject: setPopoupBlockerObject,
  dispatchClearDownloadBlob: clearPopupBlockObject,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withStyles(styles),
  withConnect,
  injectIntl,
)(PopupBlockerFallback);
