import { createSelector } from 'reselect';

import { initialState } from './reducer';

const securedImageState = state => state.securedImage || initialState;

const selectImage = (url, quality) =>
  createSelector(securedImageState, imageState => imageState.get('images').get(url + quality));

export { selectImage };
