import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

function User(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>
        {props.title || props.intl.formatMessage(globalMessages.user)}
      </title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.72039 12.8871C4.50179 12.1057 5.5616 11.6667 6.66667 11.6667H13.3333C14.4384 11.6667 15.4982 12.1057 16.2796 12.8871C17.061 13.6685 17.5 14.7283 17.5 15.8333V17.5C17.5 17.9602 17.1269 18.3333 16.6667 18.3333C16.2064 18.3333 15.8333 17.9602 15.8333 17.5V15.8333C15.8333 15.1703 15.5699 14.5344 15.1011 14.0656C14.6323 13.5967 13.9964 13.3333 13.3333 13.3333H6.66667C6.00363 13.3333 5.36774 13.5967 4.8989 14.0656C4.43006 14.5344 4.16667 15.1703 4.16667 15.8333V17.5C4.16667 17.9602 3.79357 18.3333 3.33333 18.3333C2.8731 18.3333 2.5 17.9602 2.5 17.5V15.8333C2.5 14.7283 2.93899 13.6685 3.72039 12.8871Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0002 3.33333C8.61945 3.33333 7.50016 4.45262 7.50016 5.83333C7.50016 7.21405 8.61945 8.33333 10.0002 8.33333C11.3809 8.33333 12.5002 7.21405 12.5002 5.83333C12.5002 4.45262 11.3809 3.33333 10.0002 3.33333ZM5.8335 5.83333C5.8335 3.53215 7.69898 1.66667 10.0002 1.66667C12.3013 1.66667 14.1668 3.53215 14.1668 5.83333C14.1668 8.13452 12.3013 10 10.0002 10C7.69898 10 5.8335 8.13452 5.8335 5.83333Z"
      />
    </svg>
  );
}
User.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

User.defaultProps = {
  title: '',
};

export default injectIntl(User);
