import React, { useEffect } from 'react';

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { injectIntl, intlShape } from 'react-intl';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import Button from 'components/Button';
import PageWrapper from 'components/PageWrapper';
import RemoveScrollbar from 'components/RemoveScrollbar';
import ArrowRight from 'components/SvgComponents/icons/ArrowRight';
import { getAllOrganizations } from 'containers/GlobalWrapper/actions';
import { Routing } from 'routing/routing';
import globalMessages from 'translations/messages/global-messages';
import initialLoader from 'utils/initialLoader';
import localStorageUser from 'utils/localStorageUser';

import Error from './components/Error';

const styles = theme => ({
  errorPageWrapper: {
    alignSelf: 'center',
    margin: 'auto',
  },
  title: {
    maxWidth: '400px',
  },
  description: {
    marginTop: theme.spacing(3),
    maxWidth: '400px',
  },
  button: {
    display: 'block',
    margin: 'auto',
    marginBlockStart: `${theme.spacing(10)}`,
  },
  container: {
    display: 'flex',
    height: '100%',
  },
});

function Errors(props) {
  useEffect(() => {
    initialLoader.hideLoader();
  }, []);
  const {
    classes,
    intl,
    title,
    description,
    history,
    codeTitle,
    codeDescription,
    fullWidth,
    showButton,
    buttonMessage,
    onButtonClick,
  } = props;

  const recentProjects = localStorageUser.getRecentlyViewedProjects();
  const dispatch = useDispatch();

  const onHomeClick = () => {
    if (codeTitle === '403') {
      history.push(Routing.general.welcome);
    } else if (recentProjects?.length) {
      const project = recentProjects[0];
      history.push(Routing.home.home(project.business_organization_id, project.project_id));
    } else {
      const lastOrganization = localStorageUser.getBusinessOrganizationLocalStorage();

      if (lastOrganization?.id) {
        history.push(Routing.organizations.projectList(lastOrganization.id));
      } else {
        dispatch(
          getAllOrganizations(data => {
            const organization = data?.organizations[0];
            if (organization?.id) {
              history.push(Routing.organizations.projectList(organization.id));
            }
          }),
        );
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {title || intl.formatMessage(globalMessages.page_not_found)} |{' '}
          {intl.formatMessage(globalMessages.finalcad)}
        </title>
      </Helmet>
      <PageWrapper>
        <RemoveScrollbar>
          <div className={classes.container}>
            <div className={classes.errorPageWrapper}>
              <Error
                title={codeTitle}
                description={codeDescription}
                customStyle={{
                  width: fullWidth ? '100%' : 'auto',
                }}
              />
              <Typography
                component="h1"
                variant="header"
                align="center"
                classes={{
                  root: classes.title,
                }}
              >
                {title || intl.formatMessage(globalMessages.page_not_found)}
              </Typography>
              <Typography
                align="center"
                component="p"
                variant="bodyLight"
                classes={{
                  root: classes.description,
                }}
              >
                {description}
              </Typography>

              {showButton && (
                <Button
                  className={classes.button}
                  onClick={() => {
                    if (onButtonClick) {
                      onButtonClick();
                    } else {
                      onHomeClick();
                    }
                  }}
                  endIcon={<ArrowRight className={classes.icon} />}
                >
                  {buttonMessage || intl.formatMessage(globalMessages.take_me_home)}
                </Button>
              )}
            </div>
          </div>
        </RemoveScrollbar>
      </PageWrapper>
    </>
  );
}
Errors.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  codeTitle: PropTypes.string,
  codeDescription: PropTypes.string,
  history: PropTypes.object,
  fullWidth: PropTypes.bool,
  showButton: PropTypes.bool,
  buttonMessage: PropTypes.string,
  onButtonClick: PropTypes.func,
};
Errors.defaultProps = {
  showButton: true,
  fullWidth: false,
  title: '',
  description: '',
  codeTitle: '',
  codeDescription: '',
};

export default compose(
  withRouter,
  injectIntl,
  withStyles(styles),
)(Errors);
