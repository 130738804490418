import 'whatwg-fetch';
import * as Sentry from '@sentry/react';

import { redirect } from 'containers/App/actions';
import { setGlobalMessage } from 'containers/MessageHandler/actions';
import localStorageUser from 'utils/localStorageUser';
import sessionStorageUser from 'utils/sessionStorageUser';

function checkErrorStatus(error) {
  return (
    error?.response?.status &&
    (error.response.status === 400 ||
      error.response.status === 405 ||
      (error.response.status !== 410 &&
        error.response.status >= 407 &&
        error.response.status <= 502) ||
      (error.response.status >= 504 && error.response.status <= 510) ||
      (error.response.status >= 512 && error.response.status < 600))
  );
}

function replaceUUIDInURL(url) {
  // Split the URL by '?' to separate the base URL from the parameters
  const [baseUrl] = url.split('?');

  // Replace the UUID in the base URL with '<uuid>'
  return baseUrl
    .replace(
      /\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g,
      '/<uuid>',
    )
    .replace(
      /\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g,
      '/<uuid>',
    );
}

/**
 * Returns the thrown error message
 *
 * @param  {object} Error response from a network request
 * @param  {errorCustom} Bool do not catch the error
 *
 * @return {object} The constructed Error Message from the request
 */
async function handleError(error, errorCustom = false, params = {}) {
  let processedErrorResponse;
  let message;

  try {
    processedErrorResponse = await error.response.json();
  } catch (e) {
    processedErrorResponse = null;
  }

  if (processedErrorResponse) {
    message = processedErrorResponse.message || processedErrorResponse.Message;
  } else {
    message = error.message || error.Message;
  }
  if (!errorCustom && error?.response?.status && error.response.status === 403) {
    // 403 Forbidden
    window.finalcadReduxStore.dispatch(
      setGlobalMessage({
        type: 'error',
        active: true,
        intlMessage: {
          id: 'forbidden_error',
        },
        contactSupport: true,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      }),
    );
  }
  if (!errorCustom && checkErrorStatus(error)) {
    // if error status is 400, 405, 407-502, 504-510, 512-599
    window.finalcadReduxStore.dispatch(
      setGlobalMessage({
        type: 'error',
        active: true,
        intlMessage: {
          id: 'error_refresh_contact_support',
        },
        contactSupport: true,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      }),
    );
  } else if (error?.response?.status === 503) {
    // 503 Service Unavailable
    window.finalcadReduxStore.dispatch(
      setGlobalMessage({
        type: 'error',
        active: true,
        intlMessage: {
          id: 'services_under_maintenance',
        },
        contactSupport: true,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      }),
    );
  } else if (error?.response?.status === 511) {
    // 511 Network Authentication Required
    window.finalcadReduxStore.dispatch(
      setGlobalMessage({
        type: 'error',
        active: true,
        intlMessage: {
          id: 'error_login_expired',
          values: { id: error.response.status },
        },
        contactSupport: true,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      }),
    );
  }
  Sentry.withScope(scope => {
    scope.setTag('network', 'fetch_error');

    const fingerprintUrl = replaceUUIDInURL(params.url);

    scope.setFingerprint(['{{ default }}', fingerprintUrl, params.request.method]);
    scope.setContext('Request details', {
      errorStatus: error?.response?.status,
      message,
      referer: window.location.href,
      url: params.url,
      method: params.request.method,
      body: params.request.body,
      headers: { ...params.request.headers },
    });
    Sentry.captureException(error);
  });

  return {
    status: error && error.response ? error.response.status : null,
    response: processedErrorResponse,
    message,
  };
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 */
function checkStatus(response, errorRedirect = true, redirectionUrl) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const oktaToken = localStorageUser.getOktaTokenStorage();
  if (response.status === 401 && window.globalOktaAuth && oktaToken) {
    sessionStorageUser.setFinalcadTempRegistrationInfo(null);
    sessionStorageUser.setIsSignUp(null);
    window.globalOktaAuth.signOut();
  }
  if (errorRedirect && response.status === 403) {
    window.finalcadReduxStore.dispatch(redirect(`/${response.status}`));
    window.location.reload();
  }
  if (
    errorRedirect &&
    (response.status === 401 || response.status === 404) &&
    redirectionUrl !== undefined
  ) {
    window.finalcadReduxStore.dispatch(redirect(`/${redirectionUrl}`));
    window.location.reload();
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @param params
 * @param noHeaders
 *
 * @return {object}           The response data
 */
export function request(url, options, params = {}, noHeaders) {
  const { errorCustom = false, errorRedirect = true, redirectionUrl } = params;
  const updatedOptions = options;
  const oktaToken = localStorageUser.getOktaTokenStorage();
  const lang = localStorageUser.getLanguageUserFromLocalStorage();

  if (!noHeaders) {
    updatedOptions.headers = {
      'Cache-Control': 'No-Store',
      'Accept-Language': lang,
      'Access-Control-Allow-Origin': '*',
      lang: `${lang}`,
      ...options.headers,
    };
    if (oktaToken?.accessToken?.accessToken) {
      updatedOptions.headers = {
        Authorization: `Bearer ${oktaToken?.accessToken?.accessToken}`,
        ...updatedOptions.headers,
      };
    } else {
      updatedOptions.headers = {
        'Cache-Control': 'No-Store',
        'Accept-Language': lang,
        ...options.headers,
      };
    }
  }

  if (options.headers.Authorization === 'disable') {
    delete updatedOptions.headers.Authorization;
  }

  return window
    .fetch(url, updatedOptions)
    .then(e => checkStatus(e, errorRedirect, redirectionUrl))
    .then(response => response.text())
    .then(data => (data ? JSON.parse(data) : {}))
    .catch(e => handleError(e, errorCustom, { url, request: updatedOptions, params }));
  // TODO Nicolas - uncomment this when we will have a better error handling
  /*
    .catch(e => {
      const enhancedError = new Error(
        `Failed to fetch URL: ${url} with Method: ${updatedOptions.method}. Original Error: ${
          e.message
        }`,
      );
      handleError(enhancedError, errorCustom, { url, request: updatedOptions, params });
    }); */
}

export const requestFile = (url, options, params = {}) => {
  const { errorCustom = false, errorRedirect = true } = params;

  const lang = localStorageUser.getLanguageUserFromLocalStorage();
  const updatedOptions = options;
  const oktaToken = localStorageUser.getOktaTokenStorage();
  updatedOptions.headers = {
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${oktaToken?.accessToken?.accessToken}`,
    'Accept-Language': lang,
    ...options.headers,
  };
  return window
    .fetch(url, options)
    .then(e => checkStatus(e, errorRedirect))
    .catch(e => handleError(e, errorCustom, { url, request: updatedOptions, params }));
};

export const searchParams = params =>
  Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

const processRequestWithPayload = (urlParams, requestURL, url, method, payload) => {
  let requestURLCopy = requestURL;
  const urlParamsCopy = urlParams;
  if (urlParams?.offset !== undefined && urlParams?.limit) {
    requestURLCopy = `${url}?offset=${urlParams.offset}&limit=${urlParamsCopy.limit}`;
    delete urlParamsCopy.offset;
    delete urlParamsCopy.limit;
    if (urlParams?.business_organization_id) {
      requestURLCopy = `${requestURLCopy}&business_organization_id=${
        urlParamsCopy?.business_organization_id
      }`;
      delete urlParamsCopy.business_organization_id;
    }
  } else if (urlParamsCopy?.business_organization_id) {
    requestURLCopy = `${requestURL}?business_organization_id=${
      urlParamsCopy?.business_organization_id
    }`;
    delete urlParamsCopy.business_organization_id;
  }
  const requestOptions = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'No-Store',
    },
    body: JSON.stringify(payload),
  };
  return { requestURLCopy, requestOptions };
};

export const parseRequest = params => {
  const { url, urlParams, method /* , user */ } = params || {};
  let requestURL = `${url}`;
  const payload = {
    ...urlParams,
  };
  let options;
  if ((method === 'GET' || method === 'DELETE') && urlParams) {
    let requestPayloadString = '';
    Object.keys(urlParams).forEach((key, index) => {
      if (index) {
        requestPayloadString += `&${key}=${urlParams[key]}`;
      } else {
        requestPayloadString += `${key}=${urlParams[key]}`;
      }
    });
    requestURL = `${requestURL}`;
    if (requestPayloadString) {
      requestURL = `${requestURL}?${requestPayloadString}`;
    }
    options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'No-Store',
      },
    };
  } else if ((method === 'GET' || method === 'DELETE') && !urlParams) {
    requestURL = `${requestURL}`;
    options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'No-Store',
      },
    };
  } else {
    const { requestURLCopy, requestOptions } = processRequestWithPayload(
      urlParams,
      requestURL,
      url,
      method,
      payload,
    );
    requestURL = requestURLCopy;
    options = {
      ...requestOptions,
    };
  }
  return { requestURL, options };
};

export const getNameFromContentDisposition = headers => {
  let name = headers
    .get('content-disposition')
    .split(';')
    .find(n => n?.includes('filename='))
    .replace('filename=', '')
    .trim();

  if (name?.charAt(0) === '"' && name?.charAt(name.length - 1) === '"') {
    name = name?.slice(1, -1);
  }

  return name;
};

export function prepareFCHeader(prepare = headers => headers) {
  return (headers, { getState }) => {
    const oktaToken = localStorageUser.getOktaTokenStorage();
    const token = oktaToken?.accessToken?.accessToken;
    headers.set('content-type', 'application/json');
    headers.set('Cache-Control', 'No-Store');
    headers.set('Allow-Access-Control-Origin', '*');

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return prepare(headers, { getState });
  };
}

export default request;
