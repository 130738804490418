import QRCode from 'qrcode';

export function generateQRCode(text, size) {
  const options = {
    width: size,
  };

  return QRCode.toDataURL(text, options)
    .then(dataUri => dataUri.split(',')[1])
    .catch(err => {
      console.error(err);
      throw err;
    });
}
