import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import { withStyles } from '@mui/styles';
import { Typography } from '@mui/material';

import Button from 'components/Button';
import Dialog from 'components/dialogs/Dialog';

import globalMessages from 'translations/messages/global-messages';

const styles = theme => ({
  contentWrap: {
    minHeight: 170,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentText: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    padding: theme.spacing(2),
    flexDirection: 'column',
  },
});

function DialogLogOut(props) {
  const { classes, intl, onCloseDialog, onSubmitDialog } = props;

  return (
    <Dialog
      id="logOut"
      open
      fullWidth
      maxWidth="sm"
      onCloseDialog={props.onCloseDialog}
      dialogTitleNode={intl.formatMessage(globalMessages.sign_out)}
      dialogActionNode={[
        <Button onClick={onCloseDialog} variant="plain" key="no">
          {intl.formatMessage(globalMessages.cancel)}
        </Button>,
        <Button onClick={onSubmitDialog} key="yes" variant="danger">
          {intl.formatMessage(globalMessages.yes_sign_out)}
        </Button>,
      ]}
    >
      <div className={classes.contentWrap}>
        <Typography component="h1" variant="h3">
          {intl.formatMessage(globalMessages.are_you_sure)}
        </Typography>
        <div className={classes.contentText}>
          <Typography component="p" variant="bodyLight">
            {intl.formatMessage(globalMessages.confirm_continue)}
          </Typography>
        </div>
      </div>
    </Dialog>
  );
}
DialogLogOut.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  onCloseDialog: PropTypes.func,
  onSubmitDialog: PropTypes.func,
};

export default compose(
  withStyles(styles),
  injectIntl,
)(DialogLogOut);
