export function customerIoTrackerWithParams(trackParams, organizationId, projectId) {
  const customerIoScript = document.createElement('script');
  customerIoScript.innerHTML = ` _cio.track(
            '${trackParams}',
            {
            project_id: '${projectId}',
            organization_id: '${organizationId}',
          }
        );`;
  document.body.appendChild(customerIoScript);
}
