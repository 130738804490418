import { call, put, select, takeEvery } from 'redux-saga/effects';

import Config from 'config/config';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { checkParams } from 'utils/errors';
import request from 'utils/request';

import { setCompany } from './actions';
import { GET_COMPANY } from './constants';
import { selectCompany } from './selectors';

const pendingRequests = new Map();

export function* getCompany({ id, callback, params }) {
  checkParams({ params, keys: ['idData'] });

  const companyData = yield select(selectCompany(id));
  if (companyData) {
    if (callback) yield call(callback, companyData);
    return;
  }

  if (pendingRequests.has(id)) {
    pendingRequests.get(id).push(callback);
    return;
  }

  pendingRequests.set(id, [callback]);

  const requestURL = `${checkOrSetSlash(Config.apiHostGateway, 'apiHostGateway')}api/projects/${
    params.idData
  }/companies/${id}`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
  };

  const data = yield call(request, requestURL, options, {
    errorRedirect: false,
  });

  const callbacks = pendingRequests.get(id) || [];
  pendingRequests.delete(id);

  if (data && !data.message) {
    yield put(setCompany(id, data));

    // eslint-disable-next-line no-restricted-syntax
    for (const cb of callbacks) {
      if (cb) yield call(cb, data);
    }
  }
}

export default function* initCompanySaga() {
  yield takeEvery(GET_COMPANY, getCompany);
}
