import { createSelector } from 'reselect';

import { initialState } from '../../reducer';

const globalState = state => state.global || initialState;

const selectCommonObservation = id =>
  createSelector(globalState, dataState => dataState.get('commonObservations').get(id));

const selectObservationPriority = id =>
  createSelector(globalState, dataState => dataState.get('observationPriorities').get(id));

const selectObservationPhase = id =>
  createSelector(globalState, dataState => dataState.get('observationPhases').get(id));

const selectObservationStatus = id =>
  createSelector(globalState, dataState => dataState.get('observationStatuses').get(id));

const selectObservation = id =>
  createSelector(globalState, dataState => dataState.get('observations').get(id));

export {
  selectCommonObservation,
  selectObservationPriority,
  selectObservationPhase,
  selectObservationStatus,
  selectObservation,
};
