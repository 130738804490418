const scope = 'OneProjectPage/components/MainProperties';
export const SET_LOADING_FLAG = `${scope}SET_LOADING_FLAG`;
export const GET_STATUSES = `${scope}GET_STATUSES`;
export const SET_STATUSES = `${scope}SET_STATUSES`;
export const GET_STATUS = `${scope}GET_STATUS`;
export const SET_STATUS = `${scope}SET_STATUS`;
export const GET_PRIORITIES = `${scope}GET_PRIORITIES`;
export const SET_PRIORITIES = `${scope}SET_PRIORITIES`;
export const GET_COMPANIES = `${scope}GET_COMPANIES`;
export const SET_COMPANIES = `${scope}SET_COMPANIES`;
export const GET_MEMBERS = `${scope}GET_MEMBERS`;
export const SET_MEMBERS = `${scope}SET_MEMBERS`;
export const CREATE_COMPANY = `${scope}CREATE_COMPANY`;
export const SET_PHASES = `${scope}SET_PHASES`;
export const GET_PHASES = `${scope}GET_PHASES`;

export const MODULE_SETTINGS = {
  libraryStatus: 'is_observation_library_mandatory',
  descriptionStatus: 'is_description_mandatory',
  is_group_mandatory: 'is_group_mandatory',
};
