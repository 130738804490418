import { fromJS } from 'immutable';

export const filterSuccessfulUpload = (items, itemToUpload) => {
  const values = JSON.parse(JSON.stringify(items, null, 2));

  return fromJS(
    values.map(item => {
      if (item.id === itemToUpload.id) {
        return { ...itemToUpload, loading: false };
      }
      return item;
    }),
  );
};

export const filterFailedUpload = (items, itemToUpload) => {
  const values = JSON.parse(JSON.stringify(items, null, 2));
  return fromJS(values.filter(item => item.id !== itemToUpload.id));
};
