import React, { Children, useEffect, useState } from 'react';

import {
  ClickAwayListener,
  Divider,
  ListItem,
  ListItemButton,
  Paper,
  Popper,
  styled,
} from '@mui/material';
import PropTypes from 'prop-types';

const PopperWrapper = styled(Popper)(() => ({
  marginLeft: '8px',
  marginBottom: '15px',
  zIndex: 99999,
}));

const PaperWrapper = styled(Paper)(({ theme }) => ({
  padding: 0,
  borderRadius: theme.spacing(3),
  boxShadow: '0px 6px 15px 0px rgba(23, 33, 52, 0.15)',
  overflow: 'hidden',
}));

export const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
}));

export const ListItemStyled = styled(ListItem)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
}));

const FCList = ({
  anchor,
  children,
  placement,
  noDivider,
  isOpen,
  onClose,
  attacheEventToAnchor,
}) => {
  const arrayChildren = Children.toArray(children);
  const [isListOpen, setIsListOpen] = useState(false);

  const toggleDropdown = () => {
    setIsListOpen(prevOpen => !prevOpen);
  };

  const closeDropdown = () => {
    setIsListOpen(false);
    onClose();
  };

  useEffect(
    () => {
      if (anchor) setIsListOpen(isOpen);
    },
    [isOpen],
  );

  useEffect(
    () => {
      const handleKeydown = event => {
        if (event.key === 'Escape' && (isOpen || isListOpen)) {
          setIsListOpen(false);
          onClose();
        }
      };

      document.addEventListener('keydown', handleKeydown);
      return () => {
        document.removeEventListener('keydown', handleKeydown);
      };
    },
    [isOpen, isListOpen],
  );

  useEffect(
    () => {
      if (!attacheEventToAnchor) return;
      const anchorElement = typeof anchor === 'string' ? document.querySelector(anchor) : anchor;
      if (anchorElement) {
        anchorElement.addEventListener('click', toggleDropdown);
      }

      // eslint-disable-next-line consistent-return
      return () => {
        if (anchorElement) {
          anchorElement.removeEventListener('click', toggleDropdown);
        }
      };
    },
    [anchor],
  );

  return (
    <PopperWrapper
      open={isListOpen}
      placement={placement}
      anchorEl={typeof anchor === 'string' ? document.querySelector(anchor) : anchor}
    >
      <ClickAwayListener onClickAway={closeDropdown}>
        <PaperWrapper>
          {Children.map(arrayChildren, (child, index) => {
            const isLast = index === arrayChildren.length - 1;

            return (
              <React.Fragment key={arrayChildren.indexOf(child)}>
                {React.cloneElement(child, { onCloseDropdown: closeDropdown })}
                {!isLast && !noDivider && <Divider variant="inset" sx={{ ml: 0 }} />}
              </React.Fragment>
            );
          })}
        </PaperWrapper>
      </ClickAwayListener>
    </PopperWrapper>
  );
};

FCList.defaultProps = {
  placement: 'bottom-start',
  noDivider: false,
  onClose: () => {},
};

FCList.propTypes = {
  anchor: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Element)]),
  placement: PropTypes.oneOf(['bottom-start', 'bottom-end', 'top-start', 'top-end']),
  children: PropTypes.node.isRequired,
  noDivider: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  attacheEventToAnchor: PropTypes.bool,
};

const ListItemButtonComponent = ({ children, ...props }) => (
  <ListItemButtonStyled
    {...props}
    onClick={() => {
      if (props.onCloseDropdown) {
        props.onCloseDropdown();
      }
      if (props.onClick) {
        props.onClick();
      }
    }}
  >
    {children}
  </ListItemButtonStyled>
);

ListItemButtonComponent.propTypes = {
  children: PropTypes.node.isRequired,
  onCloseDropdown: PropTypes.func,
  onClick: PropTypes.func,
};

FCList.ListItem = ListItemStyled;
FCList.ListItemButton = ListItemButtonComponent;

export default FCList;
