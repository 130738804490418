import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

function AlertOnly(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>
        {props.title || props.intl.formatMessage(globalMessages.alert)}
      </title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 6C13 5.44772 12.5523 5 12 5C11.4477 5 11 5.44772 11 6V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V6ZM12 17C11.4477 17 11 17.4477 11 18C11 18.5523 11.4477 19 12 19H12.01C12.5623 19 13.01 18.5523 13.01 18C13.01 17.4477 12.5623 17 12.01 17H12Z"
      />
    </svg>
  );
}
AlertOnly.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

AlertOnly.defaultProps = {
  title: '',
};

export default injectIntl(AlertOnly);
