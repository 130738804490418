const scope = 'OktaLogin/';

export const SET_LOADING_FLAG = `${scope}SET_LOADING_FLAG`;
export const CREATE_ACCOUNT = `${scope}CREATE_ACCOUNT`;
export const COMPLETE_ACCOUNT = `${scope}COMPLETE_ACCOUNT`;
export const RESET_LOGIN_STATE = `${scope}RESET_LOGIN_STATE`;
export const SET_FORGOT_PASSWORD_VISIBLE = `${scope}SET_FORGOT_PASSWORD_VISIBLE`;
export const SEND_RESET_PASSWORD_EMAIL = `${scope}SEND_RESET_PASSWORD_EMAIL`;
export const SHOW_RELOGIN_BUTTON = `${scope}SHOW_RELOGIN_BUTTON`;
export const CHECK_INVITATION_LINK = `${scope}CHECK_INVITATION_LINK`;
export const GET_IDP_INFO_DATA = `${scope}GET_IDP_INFO_DATA`;
export const VALIDATE_CODE = `${scope}VALIDATE_CODE`;
export const SEND_VALIDATION_EMAIL = `${scope}SEND_VALIDATION_EMAIL`;
