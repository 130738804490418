import React from 'react';

import PropTypes from 'prop-types';

import InlineDropdownButton from 'components/Dropdown/InlineDropdownButton';
import StatusCellSkeleton from 'components/SkeletonScreen/components/StatusCellSkeleton';

export const StatusCell = props => {
  const { listsRequestOption, selected, observationId, loading, disabled } = props;
  if (loading && !observationId) {
    return <StatusCellSkeleton />;
  }

  if (!loading && !observationId) {
    return <div>-</div>;
  }

  return (
    <InlineDropdownButton
      disabled={disabled}
      listsRequestOption={listsRequestOption}
      selected={selected}
      itemId={observationId}
    />
  );
};

StatusCell.propTypes = {
  listsRequestOption: PropTypes.object,
  selected: PropTypes.object,
  disabled: PropTypes.bool,
  observationId: PropTypes.string,
  loading: PropTypes.bool,
};

export default StatusCell;
