import { GET_PLAN, FETCH_PLAN, GET_PLAN_FOLDER, SET_PLAN, SET_PLAN_FOLDER } from './constants';

export function getPlan(params, id, callback) {
  return {
    type: GET_PLAN,
    id,
    callback,
    params,
  };
}

export function fetchPlan(id, callback) {
  return {
    type: FETCH_PLAN,
    id,
    callback,
  };
}

export function setPlan(id, plan) {
  return {
    type: SET_PLAN,
    id,
    plan,
  };
}

export function getPlanFolder(params, id, callback) {
  return {
    type: GET_PLAN_FOLDER,
    id,
    callback,
    params,
  };
}

export function setPlanFolder(id, folder) {
  return {
    type: SET_PLAN_FOLDER,
    id,
    folder,
  };
}
