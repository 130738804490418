import React from 'react';

import { getRemoteConfig, fetchAndActivate, getAll, getString } from 'firebase/remote-config';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import localStorageUser from 'utils/localStorageUser';

import firebaseApp from '../firebase';

export const FlagsContext = React.createContext();

const remoteConfig = getRemoteConfig(firebaseApp);
remoteConfig.settings.minimumFetchIntervalMillis = 60000;

export const useFeatureFlags = () => React.useContext(FlagsContext);

export const FeatureFlagsConsumer = FlagsContext.Consumer;

const updateFLagsLocalStorage = (flags, newFlags) => {
  const isMatchingValues = isEqual(flags, newFlags);
  const emptyNewFLags = isEmpty(newFlags);
  if (!emptyNewFLags && !isMatchingValues) {
    localStorageUser.setFirebaseFeatureStorage(newFlags);
  }
};

const FeatureFlagsProvider = props => {
  const { defaults, children } = props;
  const storageFlags = localStorageUser.getFirebaseFeatureStorage();
  const [flags, setFlags] = React.useState(storageFlags);

  React.useEffect(() => {
    remoteConfig.defaultConfig = defaults;
    const fetchFeatures = fetchAndActivate(remoteConfig)
      .then(() => getAll(remoteConfig))
      .then(remoteFlags => {
        const newFlags = {
          ...flags,
        };
        const keys = Object.keys(remoteFlags);
        keys.forEach(key => {
          const flagString = getString(remoteConfig, key);
          newFlags[key] =
            // eslint-disable-next-line no-constant-condition
            flagString === 'true' || flagString === 'false'
              ? Boolean(flagString === 'true')
              : flagString; //
        });
        updateFLagsLocalStorage(flags, newFlags);
        setFlags(newFlags);
      })
      .catch(error => console.error(error));
    return () => fetchFeatures;
  }, []);

  return <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>;
};

FeatureFlagsProvider.propTypes = {
  defaults: PropTypes.object,
  children: PropTypes.node,
};

FeatureFlagsProvider.defaultProps = {
  defaults: {},
};

export default FeatureFlagsProvider;
