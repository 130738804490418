import moment from 'moment';

export const dateTimeHumanize = (local, dateMoment) =>
  moment(dateMoment).calendar(null, {
    sameElse: 'LLL',
  });

export const dateTimeConvertToLocal = value =>
  moment(value)
    .local()
    .format('YYYY-MM-DD');

export const getLocalTimeOnly = value =>
  moment
    .utc(value)
    .local()
    .format('HH:mm');

export const getMinMaxDate = (
  filterPropertyName,
  fromPropertyName,
  toPropertyName,
  activeFilters,
) => {
  let maxDate = moment(new Date());
  let minDate;
  if (filterPropertyName === fromPropertyName && activeFilters[toPropertyName]) {
    maxDate = moment(new Date(activeFilters[toPropertyName]));
  }
  if (filterPropertyName === toPropertyName && activeFilters[fromPropertyName]) {
    minDate = moment(new Date(activeFilters[fromPropertyName]));
  }
  return { maxDate, minDate };
};

export const getWeekNumber = dateString => `0${moment(dateString).isoWeek()}`.slice(-2);

export function getFormattedDate(date, locale) {
  const format = locale === 'en' ? 'MMMM D, YYYY' : 'DD MMMM YYYY';
  return moment(date)
    .locale(locale)
    .format(format);
}

export function getFormattedTime(date, locale) {
  const format = locale === 'en' ? 'hh:mm:s A' : 'HH:mm:ss';
  return moment(date)
    .locale(locale)
    .format(format);
}
