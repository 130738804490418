import React from 'react';
import PropTypes from 'prop-types';

export const Parcel = props => (
  <svg
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5559 2.34509L11.555 2.34553L3.55721 6.34443L3.555 6.34553C3.38839 6.42832 3.24819 6.55594 3.15014 6.71405C3.05213 6.87211 3.00013 7.05436 3 7.24034V16.767C2.9987 16.9533 3.04949 17.1363 3.14667 17.2954C3.24369 17.4541 3.38308 17.5827 3.54916 17.6665L11.5472 21.6656C11.6861 21.7351 11.8397 21.7714 11.995 21.7714C12.1504 21.7714 12.3036 21.7352 12.4425 21.6657L20.4428 17.6656L20.445 17.6645C20.6116 17.5817 20.7518 17.4541 20.8499 17.2959C20.9479 17.1378 20.9999 16.9555 21 16.7695V7.24053C20.9999 7.05449 20.9479 6.87216 20.8499 6.71405C20.7518 6.55594 20.6116 6.42832 20.445 6.34553L20.4428 6.34443L12.445 2.34553L12.4441 2.34509C12.3061 2.27666 12.1541 2.24106 12 2.24106C11.8459 2.24106 11.6939 2.27666 11.5559 2.34509ZM12.89 1.45L13.335 0.554471C12.9202 0.348334 12.4632 0.241062 12 0.241062C11.5368 0.241062 11.0798 0.348334 10.665 0.554471L10.6628 0.555574L2.665 4.55447L2.66377 4.55508C2.16449 4.80347 1.74431 5.1861 1.45042 5.66004C1.15628 6.13437 1.0003 6.68134 1 7.23947L1 16.7569C0.996831 17.3146 1.14923 17.8623 1.44011 18.3383C1.73165 18.8153 2.15072 19.2015 2.65002 19.453L2.65279 19.4544L10.6525 23.4543C11.0693 23.6628 11.5289 23.7714 11.995 23.7714C12.461 23.7714 12.9205 23.6629 13.3372 23.4544L21.335 19.4555L21.336 19.455C21.8354 19.2067 22.2556 18.824 22.5496 18.35C22.8437 17.8756 22.9997 17.3287 23 16.7705V7.24C22.9997 6.68187 22.8437 6.13437 22.5496 5.66004C22.2557 5.18611 21.8355 4.80349 21.3363 4.55511L21.335 4.55447L13.3372 0.555574L12.89 1.45Z"
      fill="current"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.42557 5.71279C1.67256 5.21881 2.27324 5.01858 2.76721 5.26557L12 9.88197L21.2328 5.26557C21.7268 5.01858 22.3274 5.21881 22.5744 5.71279C22.8214 6.20677 22.6212 6.80744 22.1272 7.05443L12.4472 11.8944C12.1657 12.0352 11.8343 12.0352 11.5528 11.8944L1.87279 7.05443C1.37881 6.80744 1.17858 6.20677 1.42557 5.71279Z"
      fill="current"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 10C12.5523 10 13 10.4477 13 11V22.76C13 23.3123 12.5523 23.76 12 23.76C11.4477 23.76 11 23.3123 11 22.76V11C11 10.4477 11.4477 10 12 10Z"
      fill="current"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.10557 3.05279C6.35256 2.55881 6.95324 2.35858 7.44721 2.60557L17.4472 7.60557C17.9412 7.85256 18.1414 8.45324 17.8944 8.94721C17.6474 9.44119 17.0468 9.64142 16.5528 9.39443L6.55279 4.39443C6.05881 4.14744 5.85858 3.54677 6.10557 3.05279Z"
      fill="current"
    />
  </svg>
);

Parcel.propTypes = {
  size: PropTypes.number,
}
