import React, { useMemo } from 'react';

import { IconButton, styled } from '@mui/material';
import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import SuperAdminSvg from 'components/SvgComponents/icons/SuperAdmin';
import SuperAdminFill from 'components/SvgComponents/icons/SuperAdminFill';
import { useColorMode } from 'contexts/ColorModeProvider';
import { Routing } from 'routing/routing';
import { colorTheme } from 'utils/constants';
import { CustomNavLink } from 'utils/redirect-utils';

import { LOGO_TOOLBAR_HEIGHT, LOGO_TOOLBAR_WIDTH } from '../constants';

const IconButton_SuperAdminButtonStyle = styled(IconButton)(() => ({
  '& .superAdminSvgFill': {
    opacity: 0,
    position: 'absolute',
  },
  '&:hover .superAdminSvg': {
    opacity: 0,
  },
  '&:hover .superAdminSvgFill': {
    opacity: 1,
  },
}));

function SuperAdminLogo({ title = 'FINALCAD - SuperAdmin' }) {
  const location = useLocation();
  const { mode } = useColorMode();
  const theme = useTheme();
  const isDarkMode = mode === colorTheme.dark;
  const isSuperAdminRoute = location.pathname.includes('/super-admin');

  const iconColor = useMemo(
    () => (isDarkMode ? theme.palette.background.white : theme.palette.text.primary),
    [isDarkMode, theme.palette.background.white, theme.palette.text.primary],
  );

  return (
    <IconButton_SuperAdminButtonStyle
      component={CustomNavLink}
      to={Routing.superAdmin.manageOrganizations()}
      variant="text"
      aria-label={title}
    >
      <SuperAdminSvg
        title={title}
        width={LOGO_TOOLBAR_WIDTH}
        height={LOGO_TOOLBAR_HEIGHT}
        className={isDarkMode || isSuperAdminRoute ? 'superAdminSvgFill' : 'superAdminSvg'}
        color={iconColor}
      />
      <SuperAdminFill
        width={LOGO_TOOLBAR_WIDTH}
        height={LOGO_TOOLBAR_HEIGHT}
        className={isDarkMode || isSuperAdminRoute ? 'superAdminSvg' : 'superAdminSvgFill'}
        color={iconColor}
      />
    </IconButton_SuperAdminButtonStyle>
  );
}

SuperAdminLogo.propTypes = {
  title: PropTypes.string,
};

export default React.memo(SuperAdminLogo);
