import React from 'react';

const highlightText = (text, regex, highlightClass) =>
  text
    .split(regex)
    .filter(part => part !== '')
    .map((part, i) => {
      const key = `${part}-${i}`;
      return regex.test(part) ? (
        <mark key={key} className={highlightClass}>
          {part}
        </mark>
      ) : (
        part
      );
    });
export default highlightText;
