import {
  SET_LOADING_FLAG,
  CLEAR_ITEMS,
  GET_ITEMS,
  UPDATE_ITEMS,
  SET_ITEMS,
  CLEAR_ACTIVE_FILTERS,
  SET_ACTIVE_FILTERS,
  SET_SEARCH_TEXT,
  CLEAR_SEARCH_TEXT,
  SET_SORT_DATA,
  CLEAR_SORT_DATA,
  SET_CHECKED_ITEMS,
  CLEAR_CHECKED_ITEMS,
  RESET_TABLE,
  SET_TABLE_MOUNT_UNIQUE_KEY,
  SET_ITEM,
  UNCHECK_ITEM,
} from './constants';

export function setLoadingFlag(loading, namespace = 'table') {
  return {
    type: `${namespace}/${SET_LOADING_FLAG}`,
    loading,
  };
}

export function setSearchText(text, namespace = 'table') {
  return {
    type: `${namespace}/${SET_SEARCH_TEXT}`,
    text,
  };
}
export function clearSearchText(namespace = 'table') {
  return {
    type: `${namespace}/${CLEAR_SEARCH_TEXT}`,
  };
}

export function setActiveFilters(filters, namespace = 'table') {
  return {
    type: `${namespace}/${SET_ACTIVE_FILTERS}`,
    filters,
  };
}

export function clearActiveFilters(namespace = 'table') {
  return {
    type: `${namespace}/${CLEAR_ACTIVE_FILTERS}`,
  };
}

export function clearItems(namespace = 'table') {
  return {
    type: `${namespace}/${CLEAR_ITEMS}`,
  };
}

export function getItems(requestPayload, namespace = 'table', callback = undefined) {
  return {
    type: `${namespace}/${GET_ITEMS}`,
    requestPayload,
    callback,
  };
}

export function updateItems(requestPayload, namespace = 'table') {
  return {
    type: `${namespace}/${UPDATE_ITEMS}`,
    requestPayload,
  };
}

export function setItems(items, count, namespace = 'table') {
  return {
    type: `${namespace}/${SET_ITEMS}`,
    items,
    count,
  };
}

export function setSortData(sortData, namespace = 'table') {
  return {
    type: `${namespace}/${SET_SORT_DATA}`,
    sortData,
  };
}
export function clearSortData(namespace = 'table') {
  return {
    type: `${namespace}/${CLEAR_SORT_DATA}`,
  };
}

export function setCheckedItems(items, namespace = 'table') {
  return {
    type: `${namespace}/${SET_CHECKED_ITEMS}`,
    items,
  };
}
export function setUniqueKey(key, namespace = 'table') {
  return {
    type: `${namespace}/${SET_TABLE_MOUNT_UNIQUE_KEY}`,
    key,
  };
}

export function clearCheckedItems(namespace = 'table') {
  return {
    type: `${namespace}/${CLEAR_CHECKED_ITEMS}`,
  };
}

export function resetTableState(namespace = 'table') {
  return {
    type: `${namespace}/${RESET_TABLE}`,
  };
}

export function setItem(
  item,
  namespace = 'table',
  compare = (listItem, actionItem) => listItem.id === actionItem.id,
) {
  return {
    type: `${namespace}/${SET_ITEM}`,
    compare,
    item,
  };
}

export const uncheckItem = ({ id }) => ({
  type: UNCHECK_ITEM,
  id,
});
