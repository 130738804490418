import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

function Mail(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>
        {props.title || props.intl.formatMessage(globalMessages.email)}
      </title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.10657 5.55395C3.27195 5.22692 3.61203 5 3.99999 5H20C20.388 5 20.728 5.22692 20.8934 5.55395L12 11.7793L3.10657 5.55395ZM1.00004 5.98266C0.999882 5.99253 0.999865 6.00239 0.999994 6.01225V18C0.999994 19.6523 2.34771 21 3.99999 21H20C21.6523 21 23 19.6523 23 18V6.01236C23.0001 6.00242 23.0001 5.99247 22.9999 5.98251C22.9905 4.33822 21.6464 3 20 3H3.99999C2.35349 3 1.00943 4.3383 1.00004 5.98266ZM21 7.92066V18C21 18.5477 20.5477 19 20 19H3.99999C3.45228 19 2.99999 18.5477 2.99999 18V7.92066L11.4265 13.8192C11.7709 14.0603 12.2291 14.0603 12.5735 13.8192L21 7.92066Z"
      />
    </svg>
  );
}
Mail.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

Mail.defaultProps = {
  title: '',
};

export default injectIntl(Mail);
