import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import uuid from 'uuid';
import moment from 'moment';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import Dialog from 'components/dialogs/Dialog';
import Button from 'components/Button';
import { normalize } from 'utils/validators';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import globalMessages from 'translations/messages/global-messages';
import config from 'config/config';
import { selectLanguage } from '../../TradePage/selectors';

const AddNewItemDialog = ({
  classes,
  intl,
  handleCancel,
  activeDialog,
  language,
  match,
  dialogTitleNode,
  dispatchCreateItem,
  callBack,
  placeholder,
  dataKey,
  tradeId,
  moduleId,
}) => {
  const [name, setName] = useState('');

  useEffect(
    () => () => {
      setName('');
    },
    [],
  );

  const handleCreate = () => {
    const { idData, itemId } = match.params;
    const baseApiRoute = `api/projects/${idData}/${dataKey}`;

    const listsRequestOption = {
      method: 'GET',
      url: `${checkOrSetSlash(
        config.apiHostGateway,
        'apiHostGateway',
      )}${baseApiRoute}`,
      dataKey,
      namespace: 'filter1',
    };

    let item = {
      id: uuid.v4(),
      module_id: moduleId || itemId,
      client_created_at: moment(),
      names: [
        {
          localization: language,
          translation: name,
        },
      ],
    };
    if (tradeId) {
      item = {
        ...item,
        trade_id: tradeId,
      };
    }
    dispatchCreateItem(item, listsRequestOption, baseApiRoute, callBack);
  };

  const handleNameChange = e => {
    setName(normalize(e.target.value));
  };
  return (
    <>
      <Dialog
        id="dialog-add-new-item"
        open={activeDialog}
        dialogContentClasses={{ root: classes.dialogContent }}
        fullWidth
        maxWidth="sm"
        onCloseDialog={handleCancel}
        dialogTitleNode={dialogTitleNode}
        dialogActionNode={[
          <Button onClick={handleCancel} variant="plain" key="no">
            {intl.formatMessage(globalMessages.back)}
          </Button>,
          <Button onClick={handleCreate} disabled={name.length < 2} key="yes">
            {intl.formatMessage(globalMessages.create_and_continue_button)}
          </Button>,
        ]}
      >
        <>
          <TextField
            variant="standard"
            value={name}
            placeholder={placeholder}
            onChange={handleNameChange}
            fullWidth
            InputProps={{
              classes: {
                input: classes.inputUserText,
              },
              disableUnderline: true,
            }}
            InputLabelProps={{
              classes: {
                root: classes.labelHeader,
              },
            }}
          />
        </>
      </Dialog>
    </>
  );
};
AddNewItemDialog.propTypes = {
  match: PropTypes.object,
  activeDialog: PropTypes.bool.isRequired,
  classes: PropTypes.object,
  intl: intlShape.isRequired,
  handleCancel: PropTypes.func,
  language: PropTypes.string,
  callBack: PropTypes.func,
  dispatchCreateItem: PropTypes.func,
  dataKey: PropTypes.string,
  placeholder: PropTypes.string,
  dialogTitleNode: PropTypes.string,
  tradeId: PropTypes.string,
  moduleId: PropTypes.string,
};
AddNewItemDialog.defaultProps = {
  classes: {},
};

const mapStateToProps = createStructuredSelector({
  language: selectLanguage(),
});

const withConnect = connect(
  mapStateToProps,
  null,
);
export default compose(
  withConnect,
  injectIntl,
  withRouter,
)(AddNewItemDialog);
