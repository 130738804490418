import React from 'react';
import PropTypes from 'prop-types';

export const Draw = props => (
  <svg
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 3.87866C17.7026 3.87866 17.4174 3.9968 17.2071 4.20709L4.90296 16.5112L4.37437 18.6256L6.48875 18.097L18.7929 5.79288C18.897 5.68876 18.9796 5.56515 19.036 5.42909C19.0923 5.293 19.1213 5.14718 19.1213 4.99998C19.1213 4.85278 19.0923 4.70697 19.036 4.57087C18.9796 4.43482 18.897 4.31121 18.7929 4.20709C18.6888 4.10299 18.5652 4.02038 18.4291 3.96402C18.293 3.90766 18.1472 3.87866 18 3.87866ZM15.7929 2.79288C16.3783 2.20751 17.1722 1.87866 18 1.87866C18.4099 1.87866 18.8158 1.9594 19.1944 2.11626C19.5731 2.27311 19.9172 2.50301 20.2071 2.79288C20.497 3.08272 20.7269 3.42682 20.8837 3.8055C21.0406 4.18415 21.1213 4.59003 21.1213 4.99998C21.1213 5.40994 21.0406 5.81582 20.8837 6.19446C20.7269 6.57315 20.497 6.91724 20.2071 7.20709L7.7071 19.7071C7.57895 19.8352 7.41837 19.9262 7.24253 19.9701L3.24253 20.9701C2.90176 21.0553 2.54127 20.9555 2.29289 20.7071C2.04451 20.4587 1.94466 20.0982 2.02986 19.7574L3.02986 15.7574C3.07381 15.5816 3.16473 15.421 3.29289 15.2929L15.7929 2.79288ZM17.0319 19.2021C15.971 19.0404 14.6228 19.2448 13.1346 20.4637C11.7943 21.5615 10.3801 22.0226 9.02887 22.0616C8.47681 22.0776 8.01636 21.643 8.00041 21.0909C7.98447 20.5389 8.41908 20.0784 8.97113 20.0625C9.88179 20.0362 10.8703 19.7331 11.8673 18.9165C13.7823 17.3481 15.6975 16.9756 17.3333 17.2249C18.9249 17.4675 20.1813 18.2865 20.8801 19.0028C21.2657 19.3982 21.2579 20.0313 20.8625 20.4169C20.4672 20.8026 19.8341 20.7947 19.4484 20.3994C19.0108 19.9507 18.1371 19.3705 17.0319 19.2021Z"
      fill="current"
    />
  </svg>
);

Draw.propTypes = {
  size: PropTypes.number,
}
