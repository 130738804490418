import { takeLatest, put, call } from 'redux-saga/effects';
import uuid from 'uuid';

import config from 'config/config';
import { getAllOrganizations, setLoadingFlag } from 'containers/GlobalWrapper/actions';
import Analytics from 'utils/Analytics';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import request from 'utils/request';

import { API_ORGANIZATION_VERSION } from '../GlobalWrapper/saga';
import { ADD_ORGANISATION } from './constants';

function* addOrganisation({ name, callback }) {
  yield put(setLoadingFlag(true));

  const requestURL = `${checkOrSetSlash(
    config.apiHostGatewayGlobal,
    'apiHostGatewayGlobal',
  )}api/${API_ORGANIZATION_VERSION}/organizations`;

  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name,
      id: uuid.v4(),
    }),
  };

  const data = yield call(request, requestURL, options);
  if (data && data?.message) {
    Analytics.track('projects_organization_creation_failed', {
      failure_reason: 'server_error',
    });
    yield put(setLoadingFlag(false));
  } else if (data) {
    Analytics.track('projects_organization_creation_succeeded');
    yield put(setLoadingFlag(false));
    yield call(callback, data);
    yield put(getAllOrganizations());
  }
  yield put(setLoadingFlag(false));
}

export default function* saga() {
  yield takeLatest(ADD_ORGANISATION, addOrganisation);
}
