import {
  SET_STATUS_LIST,
  SET_TO_STATUS_LIST,
  GET_TO_STATUS_LIST,
  GET_ALL_STATUSES,
  SET_OBSERVATION_STATUS,
  SET_BULK_OBSERVATION_STATUS,
  SET_BULK_DUE_DATE,
  SET_BULK_COMPANY,
  SET_BULK_PRIORITY,
  SET_BULK_PHASE,
  SET_BULK_ASSIGNEE,
  SET_PRIORITIES,
  GET_PRIORITIES,
  EXPORT_OBSERVATIONS,
  EXPORT_OBSERVATIONS_LOGS,
  DELETE_OBSERVATIONS,
  SAVE_OBSERVATION,
  SET_LOADING_FLAG,
  ADD_TRADE,
  ADD_OBSERVATION,
  SET_PHASES,
  GET_PHASES,
  IMPORT_OBSERVATIONS,
  DOWNLOAD_IMPORT_TEMPLATE,
  SET_BULK_MODULE,
  SET_CONFIG,
} from './constants';

export function setLoadingFlag(loading) {
  return {
    type: SET_LOADING_FLAG,
    loading,
  };
}

export function getAllStatuses({ params }) {
  return {
    type: GET_ALL_STATUSES,
    params,
  };
}

export function setStatusList(statusList) {
  return {
    type: SET_STATUS_LIST,
    statusList,
  };
}

export function fetchToStatuses(params, statusId) {
  return {
    type: GET_TO_STATUS_LIST,
    params,
    statusId,
  };
}

export function setToStatusList(newToStatus) {
  return {
    type: SET_TO_STATUS_LIST,
    newToStatus,
  };
}

export function getPriorities({ params }) {
  return {
    type: GET_PRIORITIES,
    params,
  };
}

export function setPriorities(priorities) {
  return {
    type: SET_PRIORITIES,
    priorities,
  };
}

export function getPhases({ params }) {
  return {
    type: GET_PHASES,
    params,
  };
}

export function setPhases(phases) {
  return {
    type: SET_PHASES,
    phases,
  };
}

export function deleteObservation(id, listsRequestOption) {
  return {
    type: DELETE_OBSERVATIONS,
    id,
    listsRequestOption,
  };
}

export function setObservationStatus(observationId, statusId, listsRequestOption) {
  return {
    type: SET_OBSERVATION_STATUS,
    observationId,
    statusId,
    listsRequestOption,
  };
}

export function setBulkObservationStatus(observationList, status, listsRequestOption) {
  return {
    type: SET_BULK_OBSERVATION_STATUS,
    observationList,
    status,
    listsRequestOption,
  };
}

export function changeBulkDueDate(observationIds, dueDate, listsRequestOption) {
  return {
    type: SET_BULK_DUE_DATE,
    observationIds,
    dueDate,
    listsRequestOption,
  };
}

export function changeBulkCompany(observationIds, companyId, listsRequestOption) {
  return {
    type: SET_BULK_COMPANY,
    observationIds,
    companyId,
    listsRequestOption,
  };
}

export function changeBulkPriority(observationIds, priorityId, listsRequestOption) {
  return {
    type: SET_BULK_PRIORITY,
    observationIds,
    priorityId,
    listsRequestOption,
  };
}

export function changeBulkAssignee(observationIds, assigneeId, listsRequestOption) {
  return {
    type: SET_BULK_ASSIGNEE,
    observationIds,
    assigneeId,
    listsRequestOption,
  };
}

export function changeBulkPhase(observationIds, phaseId, listsRequestOption) {
  return {
    type: SET_BULK_PHASE,
    observationIds,
    phaseId,
    listsRequestOption,
  };
}

export function changeBulkModule(observations, module, callback) {
  return {
    type: SET_BULK_MODULE,
    observations,
    module,
    callback,
  };
}

export function exportObservations({ params, activeFilters }) {
  return {
    type: EXPORT_OBSERVATIONS,
    activeFilters,
    params,
  };
}

export function exportObservationsLogs({ project, params, activeFilters }) {
  return {
    type: EXPORT_OBSERVATIONS_LOGS,
    project,
    activeFilters,
    params,
  };
}

export function saveObservation(
  trade,
  observation,
  group,
  description,
  callback,
  projectId,
  moduleId,
  position,
  source,
  dueDate,
) {
  return {
    type: SAVE_OBSERVATION,
    trade,
    observation,
    group,
    description,
    callback,
    projectId,
    moduleId,
    position,
    source,
    dueDate,
  };
}
export function addTrade(trade, listsRequestOption, baseApiRoute, callback) {
  return {
    type: ADD_TRADE,
    trade,
    baseApiRoute,
    listsRequestOption,
    callback,
  };
}
export function addCommonObservation(observation, listsRequestOption, baseApiRoute, callback) {
  return {
    type: ADD_OBSERVATION,
    observation,
    baseApiRoute,
    listsRequestOption,
    callback,
  };
}

export function excelImportObservations(item, params, listRequestOption, callback) {
  return {
    type: IMPORT_OBSERVATIONS,
    item,
    params,
    listRequestOption,
    callback,
  };
}

export function downloadImportTemplate(params) {
  return {
    type: DOWNLOAD_IMPORT_TEMPLATE,
    params,
  };
}

export function setConfig(config) {
  return {
    type: SET_CONFIG,
    config,
  };
}
