import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import globalMessages from 'translations/messages/global-messages';

const Information = props => (
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    {!props.noTitle && (
      <title>{props.title || props.intl.formatMessage(globalMessages.information)}</title>
    )}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00032 2.00033C4.68662 2.00033 2.00033 4.68662 2.00033 8.00032C2.00033 11.314 4.68662 14.0003 8.00032 14.0003C11.314 14.0003 14.0003 11.314 14.0003 8.00032C14.0003 4.68662 11.314 2.00033 8.00032 2.00033ZM0.666992 8.00032C0.666992 3.95024 3.95024 0.666992 8.00032 0.666992C12.0504 0.666992 15.3337 3.95024 15.3337 8.00032C15.3337 12.0504 12.0504 15.3337 8.00032 15.3337C3.95024 15.3337 0.666992 12.0504 0.666992 8.00032Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99967 7.33301C8.36786 7.33301 8.66634 7.63148 8.66634 7.99967V10.6663C8.66634 11.0345 8.36786 11.333 7.99967 11.333C7.63148 11.333 7.33301 11.0345 7.33301 10.6663V7.99967C7.33301 7.63148 7.63148 7.33301 7.99967 7.33301Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.33301 5.33327C7.33301 4.96508 7.63148 4.6666 7.99967 4.6666H8.00667C8.37486 4.6666 8.67334 4.96508 8.67334 5.33327C8.67334 5.70146 8.37486 5.99994 8.00667 5.99994H7.99967C7.63148 5.99994 7.33301 5.70146 7.33301 5.33327Z"
    />
  </svg>
);

Information.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
  noTitle: PropTypes.bool,
};

Information.defaultProps = {
  noTitle: false,
};

export default compose(injectIntl)(Information);
