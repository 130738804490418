import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory, useParams, withRouter } from 'react-router-dom';

import { Routing } from 'routing/routing';
import localStorageUser from 'utils/localStorageUser';

import { getProjectInfo } from './actions';

export const ProjectContext = React.createContext(null);
export const ProjectContextUpdater = React.createContext({});

function ProjectDataValidation({ children }) {
  const [currentProject, setCurrentProject] = useState(null);
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const valueUpdater = {
    project: currentProject,
    setProject: setCurrentProject,
  };

  const { idData } = params;

  useEffect(
    () => {
      if (idData) {
        dispatch(
          getProjectInfo(
            idData,
            data => {
              if (data?.message && (data?.status === 404 || data?.status === 400)) {
                if (params.organizationId) {
                  history.push(Routing.httpRedirect.status404Project(params.organizationId));
                } else {
                  history.push(Routing.httpRedirect.status404());
                }
              } else if (data && !data.message) {
                setCurrentProject(data);
              }
            },
            false,
          ),
        );
      } else {
        setCurrentProject(null);
      }
    },
    [params, dispatch, setCurrentProject],
  );

  useEffect(
    () => {
      if (
        params?.organizationId &&
        params?.idData &&
        currentProject?.project_id === params?.idData &&
        currentProject?.business_organization_id !== params?.organizationId
      ) {
        history.push(Routing.httpRedirect.status404());
      }
    },
    [params, dispatch, currentProject],
  );

  useEffect(
    () => {
      if (currentProject) {
        localStorageUser.addRecentlyViewedProjects(currentProject);
      }
    },
    [currentProject],
  );

  return (
    <ProjectContext.Provider value={currentProject}>
      <ProjectContextUpdater.Provider value={valueUpdater}>
        {children}
      </ProjectContextUpdater.Provider>
    </ProjectContext.Provider>
  );
}

export default withRouter(ProjectDataValidation);

ProjectDataValidation.propTypes = {
  children: PropTypes.node,
};
