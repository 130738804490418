import React from 'react';

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import Button from 'components/Button';
import Error from 'components/Errors/components/Error';
import RefreshCW from 'components/SvgComponents/icons/RefreshCW';
import Undo from 'components/SvgComponents/icons/Undo';
import globalMessages from 'translations/messages/global-messages';

const styles = theme => ({
  errorPageWrapper: {
    alignSelf: 'center',
    margin: 'auto',
  },
  title: {
    maxWidth: '400px',
  },
  description: {
    marginTop: theme.spacing(3),
    maxWidth: '400px',
  },
  button: {
    display: 'flex',
    margin: 'auto',
    marginBlockStart: `${theme.spacing(10)}`,
  },
  container: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
});

const ErrorBoundaryFallback = props => {
  const {
    intl,
    classes,
    showButton,
    message,
    description,
    fullWidth,
    onReset,
    buttonText,
    backButton,
  } = props;

  return (
    <div className={classes.container}>
      <div className={classes.errorPageWrapper}>
        <Error
          title={message}
          description={description}
          customStyle={{
            width: fullWidth ? '100%' : 'auto',
          }}
        />
        <Typography
          component="h5"
          align="center"
          classes={{
            root: classes.title,
          }}
        >
          {message || intl.formatMessage(globalMessages.something_went_wrong)}
        </Typography>
        <Typography
          align="center"
          component="p"
          variant="bodyLight"
          classes={{
            root: classes.description,
          }}
        >
          {description}
        </Typography>

        {showButton && (
          <Button
            className={classes.button}
            onClick={onReset}
            startIcon={backButton ? <Undo /> : <RefreshCW />}
          >
            {buttonText || intl.formatMessage(globalMessages.retry)}
          </Button>
        )}
      </div>
    </div>
  );
};

ErrorBoundaryFallback.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  message: PropTypes.string,
  description: PropTypes.string,
  fullWidth: PropTypes.bool,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  onReset: PropTypes.func,
  backButton: PropTypes.bool,
};

ErrorBoundaryFallback.defaultProps = {
  showButton: true,
  fullWidth: false,
  message: '',
  description: '',
  buttonText: '',
};

export default compose(
  withRouter,
  injectIntl,
  withStyles(styles),
)(ErrorBoundaryFallback);
