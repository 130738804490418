import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import PdfTronViewer from './PdfTronViewer';
import PdfTronViewerFull from './PdfTronViewerFull';

const styles = theme => ({
  pdfDocument: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  imageOverlay: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  imageOverlayArea: {
    backgroundColor: 'gray',
  },
  docContainer: {
    position: 'relative',
  },
  path: {
    cursor: 'pointer',
    '&:hover': {
      stroke: theme.palette.info.main,
      strokeWidth: '4px',
      strokeLinecap: 'square',
    },
  },
});

export function PdfDocument({
  file,
  tableItems,
  changeLoader,
  zonesData,
  renderPageNumber,
  addZoomButton,
  mouseWheelZoom,
  overflow,
  onPageLoadSuccess,
  fullViewer,
  pins,
  onPlacePin,
  moduleId,
  entityType,
  statuses,
  seeAnnotations,
  isFirstPageOnly,
  updatePin,
  fileType,
  plan,
  hasOfferLimitation,
  tools,
  document,
}) {
  const shouldAddClickableZones = zonesData;
  if (!file) {
    return null;
  }
  const COMPONENT = fullViewer ? PdfTronViewerFull : PdfTronViewer;
  return (
    <COMPONENT
      renderPageNumber={renderPageNumber}
      changeLoader={changeLoader}
      onPageLoadSuccess={onPageLoadSuccess}
      docUrl={file}
      overflow={overflow}
      addZoomButton={addZoomButton}
      mouseWheelZoom={mouseWheelZoom}
      shouldAddClickableZones={shouldAddClickableZones}
      tableItems={tableItems}
      zonesData={zonesData}
      pins={pins}
      onPlacePin={onPlacePin}
      moduleId={moduleId}
      entityType={entityType}
      statuses={statuses}
      seeAnnotations={seeAnnotations}
      isFirstPageOnly={isFirstPageOnly}
      updatePin={updatePin}
      fileType={fileType}
      plan={plan}
      hasOfferLimitation={hasOfferLimitation}
      tools={tools}
      pdfDocument={document}
    />
  );
}

PdfDocument.propTypes = {
  file: PropTypes.string,
  addZoomButton: PropTypes.bool,
  mouseWheelZoom: PropTypes.bool,
  fullViewer: PropTypes.bool,
  renderPageNumber: PropTypes.number,
  zonesData: PropTypes.object,
  changeLoader: PropTypes.func,
  onPageLoadSuccess: PropTypes.func,
  overflow: PropTypes.bool,
  tableItems: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  pins: PropTypes.array,
  onPlacePin: PropTypes.func,
  moduleId: PropTypes.string,
  entityType: PropTypes.string,
  statuses: PropTypes.array,
  seeAnnotations: PropTypes.bool,
  isFirstPageOnly: PropTypes.bool,
  updatePin: PropTypes.func,
  fileType: PropTypes.string,
  plan: PropTypes.object,
  hasOfferLimitation: PropTypes.bool,
  document: PropTypes.object,
  tools: PropTypes.shape({
    annotationVisibilityOptionEnabled: PropTypes.bool,
    itemsVisibilityOptionEnabled: PropTypes.bool,
    annotationAreVisible: PropTypes.bool,
    itemsAreVisible: PropTypes.bool,
    toggleAnnotationVisibility: PropTypes.func,
    toggleItemsVisibility: PropTypes.func,
  }),
};

PdfDocument.defaultValues = {
  pins: [],
  statuses: [],
  hasOfferLimitation: false,
  tools: {
    annotationVisibilityOptionEnabled: false,
    itemsVisibilityOptionEnabled: false,
    annotationAreVisible: false,
    itemsAreVisible: false,
    toggleAnnotationVisibility: () => null,
    toggleItemsVisibility: () => null,
  },
};

export default withStyles(styles)(withRouter(PdfDocument));
