import { createSelector } from 'reselect';

import { initialState } from '../../reducer';

const globalState = state => state.global || initialState;

const selectCompany = id =>
  createSelector(globalState, dataState => dataState.get('companies').get(id));

export { selectCompany };
