import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';

import { Routing } from 'routing/routing';
import history from 'utils/history';
import localStorageUser from 'utils/localStorageUser';
import { setBusinessOrganizationInStorage } from 'utils/organisation-utils';

import { getBusinessOrganization } from './actions';

export const OrganizationContext = React.createContext(null);
export const OrganizationContextUpdater = React.createContext({});

const BusinessOrganizationDataValidation = ({ children }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { organizationId } = params;

  // Use value from cached if it's possible
  const localStorageOrganization = localStorageUser.getBusinessOrganizationLocalStorage();
  const initialValueOrganization =
    organizationId && localStorageOrganization?.id === organizationId
      ? localStorageOrganization
      : null;

  const [currentOrganization, setCurrentOrganization] = useState(initialValueOrganization);
  const [isOrganizationLoaded, setIsOrganizationLoaded] = useState(false);

  useEffect(
    () => {
      if (!params.organizationId) {
        setIsOrganizationLoaded(true);
      } else if (params.organizationId !== currentOrganization?.id) {
        setIsOrganizationLoaded(false);
      }
    },
    [params.organizationId],
  );

  useEffect(
    () => {
      if (organizationId) {
        dispatch(
          getBusinessOrganization(
            organizationId,
            data => {
              setCurrentOrganization(data);
              setBusinessOrganizationInStorage(data);
              setIsOrganizationLoaded(true);
            },
            () => {
              setIsOrganizationLoaded(true);
              history.push(Routing.httpRedirect.status404());
            },
          ),
        );
      } else {
        setCurrentOrganization(null);
      }
    },
    [params, dispatch],
  );

  const value = {
    organization: currentOrganization,
    setOrganization: setCurrentOrganization,
  };

  return (
    <OrganizationContext.Provider value={currentOrganization}>
      <OrganizationContextUpdater.Provider value={value}>
        {isOrganizationLoaded && children}
      </OrganizationContextUpdater.Provider>
    </OrganizationContext.Provider>
  );
};

export default withRouter(BusinessOrganizationDataValidation);
