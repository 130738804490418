const checkOrSetSlash = (url, key) => {
  if (!url) {
    console.log(`====CONFIGURATION LINK FOR ${key} NOT FOUND`); //eslint-disable-line
    return '';
  }
  const hasSlash = url.slice(-1) === '/';
  return hasSlash ? url : `${url}/`;
};

export default checkOrSetSlash;
