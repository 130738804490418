import React from 'react';
import PropTypes from 'prop-types';

import { Chip } from '@mui/material';
import { getTranslation } from 'utils/localization';

export function DefaultFilterItem(props) {
  const { filter, disabled, icon, activePropertyFilters, classesChip } = props;

  const name = getTranslation(filter?.names?.translations, filter?.name);

  const isActive = activePropertyFilters.find(item => item.id === filter.id);

  const handleFilterClick = item => () => props.onChange(item);
  return (
    <Chip
      onClick={handleFilterClick(filter)}
      clickable={!disabled}
      classes={classesChip}
      icon={icon}
      label={<div>{name}</div>}
      color={isActive ? 'secondary' : 'default'}
      disabled={disabled}
      component="button"
    />
  );
}

DefaultFilterItem.defaultProps = {
  disabled: false,
  activePropertyFilters: [],
};
DefaultFilterItem.propTypes = {
  filter: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  classesChip: PropTypes.object,
  icon: PropTypes.node,
  activePropertyFilters: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
};
export default DefaultFilterItem;
