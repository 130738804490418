import { fromJS } from 'immutable';

import {
  SET_LOADING_FLAG,
  SET_USER_WORKSPACES,
  SET_SELECTED_WORKSPACES,
  SET_IS_SEARCH,
} from './constants';

export const initialState = fromJS({
  loading: false,
  user_workspaces: [],
  selected_workspace: { id: 'root' },
  isSearch: false,
});

function workspaceSwitcherReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_FLAG:
      return state.set('loading', action.loading);
    case SET_USER_WORKSPACES:
      return state.set('user_workspaces', fromJS(action.items));
    case SET_SELECTED_WORKSPACES:
      return state.set('selected_workspace', fromJS(action.workspace));
    case SET_IS_SEARCH:
      return state.set('isSearch', action.isSearch);
    default:
      return state;
  }
}
export default workspaceSwitcherReducer;
