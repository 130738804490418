import React from 'react';

import { takeEvery, takeLatest, call, select, put } from 'redux-saga/effects';
import uuid from 'uuid';

import {
  getItems,
  updateItems,
  setItems,
  setLoadingFlag as setTableLoadingFlag,
} from 'components/InfiniteTableSelfStandingV3/actions';
import { selectItems } from 'components/InfiniteTableSelfStandingV3/selectors';
import config from 'config/config';
import { setGlobalError } from 'containers/ErrorHandler/actions';
import { selectRouteParams } from 'containers/GlobalWrapper/selectors';
import { setGlobalMessage } from 'containers/MessageHandler/actions';
import globalMessages from 'translations/messages/global-messages';
import Analytics from 'utils/Analytics';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { uploadMedia } from 'utils/chunkUploader';
import { documentType, apiFetchItemsCount, locationType } from 'utils/constants';
import { checkParams } from 'utils/errors/check-params';
import request from 'utils/request';

import SuccessMessage from '../components/SuccessMessage';
import { setLoadingFlag, setSearchInAllDocuments } from './actions';
import { filterFailedUpload, filterSuccessfulUpload } from './components/utils/document';
import {
  ADD_DOCUMENTS,
  ADD_FOLDER,
  DELETE_DOCUMENTS,
  GET_FOLDERS_CONTENT,
  GET_PLAN_FOLDERS_CONTENT,
  MOVE_ITEMS_TO,
  CREATE_AS_PLAN,
  GET_SEARCH_IN_ALL_DOCUMENTS,
  UPDATE_DOCUMENT,
  RENAME_DOCUMENT,
  UPLOAD_DOCUMENT,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILED,
} from './constants';

const createMediaResourceFromDocument = document => {
  const { id, mime_type, name, md5, size, width, height, data, url } = document.media_resource;

  return {
    media_resource_id: id,
    media_resource_mime_type: mime_type,
    media_resource_display_name: name,
    media_resource_md5: md5,
    media_resource_size: size,
    media_resource_width: width,
    media_resource_height: height,
    media_resource_data: data,
    url,
  };
};

export function* addDocuments({ documents, listRequestOption, folderId }) {
  yield put(setLoadingFlag(true));
  const { namespace } = listRequestOption;
  const tableList = yield select(selectItems(namespace));
  const prevList = JSON.parse(JSON.stringify(tableList));
  const smallDocuments = documents.map(({ resultedBlob, ...smallDocument }) => smallDocument);

  yield put(setItems([...prevList, ...smallDocuments], namespace));
  for (let i = 0; i < documents.length; i += 1) {
    const item = documents[i];
    yield call(uploadDocument, {
      item,
      folderId,
      *callbackSuccess(doc) {
        const { resultedBlob, ...smallDoc } = doc;
        yield call(uploadDocumentSuccess, {
          item: {
            ...smallDoc,
            loading: false,
            failed: false,
          },
        });
      },
      *callbackError(doc) {
        const { resultedBlob, ...smallDoc } = doc;
        yield call(uploadDocumentFailed, {
          item: {
            ...smallDoc,
            loading: false,
            failed: true,
          },
        });
      },
    });
  }
}

export function* addFolder({ folder, listRequestOption, callback }) {
  yield put(setLoadingFlag(true));

  const { namespace, ...requestOption } = listRequestOption;

  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.1/projects/${route.idData}/documents`;

  const body = {
    id: uuid.v4(),
    type: documentType.folder,
    name: folder.name,
    ...(route.itemId && { parent_id: route.itemId }),
  };

  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    yield put(
      getItems(
        {
          ...requestOption,
          urlParams: {
            ...requestOption?.urlParams,
            offset: 0,
            limit: apiFetchItemsCount,
          },
        },
        namespace,
      ),
    );
    Analytics.track('documents_folder_created');
    if (callback) {
      yield call(callback, data);
    }
  }
  yield put(setLoadingFlag(false));
}

export function* deleteDocuments({ ids, listRequestOption, callback }) {
  yield put(setLoadingFlag(true));
  const route = yield select(selectRouteParams());

  const { namespace, ...requestOption } = listRequestOption;

  yield ids.map(doc_id => {
    const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
      route.idData
    }/documents/${doc_id}`;

    const options = {
      method: 'DELETE',
      headers: {
        'Cache-Control': 'No-Store',
        'Content-Type': 'application/json',
      },
    };
    return call(request, requestURL, options);
  });

  yield put(
    getItems(
      {
        ...requestOption,
        urlParams: {
          ...requestOption?.urlParams,
          offset: 0,
          limit: apiFetchItemsCount,
        },
      },
      namespace,
    ),
  );
  if (callback) {
    yield call(callback);
  }
  yield put(setLoadingFlag(false));
}

export function* fetchFoldersContent({ params, id = '', callback }) {
  yield put(setLoadingFlag(true));
  const folderQuery = id ? `document_id=${id}&` : '';
  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    params.idData
  }/documents/children-folders?${folderQuery}offset=0&limit=500`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
    },
  };

  const data = yield call(request, requestURL, options);

  if (data && !data.message) {
    const response = id
      ? data.documents
      : data.documents.filter(element => element.parent_id === null);

    yield call(callback, { folders: response });
  }
  yield put(setLoadingFlag(false));
}

export function* moveItemsTo({ parentId, items, callback }) {
  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    route.idData
  }/documents/change-parent-folder`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      parent_id: parentId !== 'root' ? parentId : null,
      moved_document_ids: items.map(i => i.id),
    }),
  };
  const data = yield call(request, requestURL, options);
  if (data[0].parent_id) {
    Analytics.track('documents_folder_moved');
  } else {
    Analytics.track('documents_file_moved');
  }
  if (data && !data.message) {
    yield call(callback, data);
  }
}

export function* getPlanFoldersContent({ id = '', callback, params }) {
  yield put(setLoadingFlag(true));

  checkParams({ params, keys: ['idData'] });
  const folderQuery = id ? `/${id}/children-folders?` : '?';

  const requestURL = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/projects/${
    params.idData
  }/folders${folderQuery}offset=0&limit=500`;

  const options = {
    method: 'GET',
    headers: {
      'Cache-Control': 'No-Store',
    },
  };

  const data = yield call(request, requestURL, options);

  if (data && !data.message) {
    const response = id
      ? data.folders
      : data.folders.filter(element => element.parent_folder_id === null);

    yield call(callback, { folders: response });
  }
  yield put(setLoadingFlag(false));
}

export function* createAsPlan({ document, folder_id, onRedirectClick }) {
  yield put(setLoadingFlag(true));

  const route = yield select(selectRouteParams());
  const { idData } = route;

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.3/projects/${idData}/plans`;

  const payload = {
    plan_id: uuid.v4(),
    folder_id: folder_id !== 'root' ? folder_id : '',
    plan_type: locationType.plan,
    name: document.name,
    document_id: document.id,
    media_resource_from_document: createMediaResourceFromDocument(document),
    media_resource: {
      root_media_resource_id: document.media_resource.id,
      media_resource_id: document.media_resource.id,
      media_resource_label: document.name,
    },
  };

  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  const data = yield call(request, requestURL, options, {
    errorCustom: true,
  });

  if (data?.response?.code === 'ERROR_MEDIA_ALREADY_ASSOCIATED') {
    yield put(
      setGlobalError({
        data,
        customErrorMessageIntl: globalMessages.cant_create_document_as_plan_twice,
      }),
    );
  }
  if (data && !data.message) {
    Analytics.track('documents_file_to_plan_converted', {
      file_format: document.media_resource.mime_type,
    });
    yield put(
      setGlobalMessage({
        type: 'success',
        intlMessage: {
          id: 'creation_successful',
        },
        active: true,
        message: <SuccessMessage data={data} onClick={onRedirectClick} />,
      }),
    );
  }
  yield put(setLoadingFlag(false));
}

export function* getSearchInAllDocuments({ search_term, params }) {
  yield put(setLoadingFlag(true));
  checkParams({ params, keys: ['idData'] });

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/search/projects/${params.idData}/documents`;

  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ search_term }),
  };

  const data = yield call(request, requestURL, options);

  if (data && !data.message) {
    yield put(setSearchInAllDocuments(data.search_results));
  }
  yield put(setLoadingFlag(false));
}

export function* updateDocument({ document, listRequestOption, callback }) {
  yield put(setTableLoadingFlag(true));

  const route = yield select(selectRouteParams());

  const requestUploadMediaUrl = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.2/projects/${route.idData}/`;
  const dataUpload = yield call(uploadMedia, document.media_resource, {
    baseUrl: requestUploadMediaUrl,
    parallel: false,
    requestHeaders: {
      'Content-Type': 'application/json',
    },
  });
  const payload = {
    name: document.name,
    media_resource: {
      root_media_resource_id: dataUpload.id,
      media_resource_id: dataUpload.id,
      media_resource_label: dataUpload.file_name,
    },
  };

  if (route?.itemId) payload.parent_id = route.itemId;
  if (document.parent_id) payload.parent_id = document.parent_id;
  if (document?.client_created_at) payload.client_created_at = document.client_created_at;
  if (document?.client_updated_at) payload.client_updated_at = document.client_updated_at;

  if (payload.parent_id === 'root') {
    payload.parent_id = null;
  }

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.1/projects/${route.idData}/documents/${document.id}`;

  const optionsUpload = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  const data = yield call(request, requestURL, optionsUpload);
  if (data && data?.message) {
    yield put(setTableLoadingFlag(false));
  } else if (listRequestOption) {
    const { namespace, ...requestOption } = listRequestOption;
    yield put(updateItems(requestOption, namespace));
  }
  if (callback) {
    yield call(callback);
  }
}

export function* renameDocument({ document, listRequestOption }) {
  yield put(setTableLoadingFlag(true));

  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.1/projects/${route.idData}/documents/${document.id}`;

  const payload = { name: document.name };
  if (route?.itemId) payload.parent_id = route.itemId;

  const optionsUpload = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };

  const data = yield call(request, requestURL, optionsUpload);

  if (data && data?.message) {
    yield put(setGlobalError(data));
    yield put(setTableLoadingFlag(false));
  } else {
    const { namespace, ...requestOption } = listRequestOption;
    yield put(updateItems(requestOption, namespace));
    yield put(setTableLoadingFlag(false));
  }
}

export function* uploadDocument({ item, folderId, callbackSuccess, callbackError }) {
  yield put(setLoadingFlag(true));

  const route = yield select(selectRouteParams());

  const requestUploadMediaUrl = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.2/projects/${route.idData}/`;
  const dataUpload = yield call(uploadMedia, item, {
    baseUrl: requestUploadMediaUrl,
    parallel: false,
    requestHeaders: {
      'Content-Type': 'application/json',
    },
  });

  if (dataUpload && !dataUpload.message) {
    const payload = {
      id: item.id,
      type: documentType.document,
      name: item.name,
      media_resource: {
        root_media_resource_id: dataUpload.id,
        media_resource_id: dataUpload.id,
        media_resource_label: dataUpload.file_name,
      },
    };

    if (folderId) {
      payload.parent_id = folderId;
    }

    const requestURL = `${checkOrSetSlash(
      config.apiHostGateway,
      'apiHostGateway',
    )}api/v1.1/projects/${route.idData}/documents`;

    const optionsUpload = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };

    const data = yield call(request, requestURL, optionsUpload);
    if (data && (data.message || data.status === 500)) {
      yield call(callbackError, item);
    } else {
      Analytics.track('documents_file_created', {
        file_format: data.media_resource.media_resource_mime_type,
        size: data.media_resource.media_resource_size,
        source: 'documents',
      });

      yield call(callbackSuccess, {
        ...item,
        ...data,
        version: data.revision,
        type: documentType.document,
      });
    }
  }
  yield put(setLoadingFlag(false));
}

export function* uploadDocumentSuccess({ item, namespace = 'table' }) {
  yield put(setLoadingFlag(true));
  const tableList = yield select(selectItems(namespace));
  yield put(setItems(filterSuccessfulUpload(tableList, item), namespace));
}
export function* uploadDocumentFailed({ item, namespace = 'table' }) {
  yield put(setLoadingFlag(true));
  const tableList = yield select(selectItems(item));
  yield put(setItems(filterFailedUpload(tableList, item), namespace));
}
export default function* documentsPageSaga() {
  yield takeEvery(ADD_DOCUMENTS, addDocuments);
  yield takeEvery(ADD_FOLDER, addFolder);
  yield takeEvery(DELETE_DOCUMENTS, deleteDocuments);
  yield takeLatest(MOVE_ITEMS_TO, moveItemsTo);
  yield takeLatest(GET_FOLDERS_CONTENT, fetchFoldersContent);
  yield takeLatest(GET_PLAN_FOLDERS_CONTENT, getPlanFoldersContent);
  yield takeLatest(CREATE_AS_PLAN, createAsPlan);
  yield takeLatest(GET_SEARCH_IN_ALL_DOCUMENTS, getSearchInAllDocuments);
  yield takeLatest(UPDATE_DOCUMENT, updateDocument);
  yield takeLatest(RENAME_DOCUMENT, renameDocument);
  yield takeEvery(UPLOAD_DOCUMENT, uploadDocument);
  yield takeEvery(UPLOAD_DOCUMENT_SUCCESS, uploadDocumentSuccess);
  yield takeEvery(UPLOAD_DOCUMENT_FAILED, uploadDocumentFailed);
}
