import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

function Finalcad(props) {
  return (
    <svg
      width="31"
      height="32"
      viewBox="0 0 31 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>
        {props.title || props.intl.formatMessage(globalMessages.finalcad)}
      </title>
      <path d="M16.0306 15.6034V0.644165H21.0028V10.6345H30.9939V15.6063L16.0306 15.6034ZM0.368164 16.3053V21.2771H10.3593V31.2674H15.3315V16.3053H0.368164Z" />
    </svg>
  );
}
Finalcad.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

Finalcad.defaultProps = {
  title: '',
};

export default injectIntl(Finalcad);
