import { fromJS } from 'immutable';

import {
  SET_LOADING_FLAG,
  SET_LIMIT_OFFER_OBSERVATION,
  SET_LIMIT_OFFER_FORMS,
} from './constants';

export const initialState = fromJS({
  loading: false,
  limitsObservation: {},
  limitsForms: {},
});

function limitationOfferReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_FLAG:
      return state.set('loading', action.loading);
    case SET_LIMIT_OFFER_OBSERVATION:
      return state.set('limitsObservation', fromJS(action.limitsObservation));
    case SET_LIMIT_OFFER_FORMS:
      return state.set('limitsForms', fromJS(action.limitsForms));
    default:
      return state;
  }
}

export default limitationOfferReducer;
