import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

function CheckSquare(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.33325" y="1.3335" width="13.3333" height="13.3333" rx="2.66667" fill="#FF8029" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.44458 7.99962C4.44458 7.75416 4.64356 7.55518 4.88902 7.55518H11.1112C11.3567 7.55518 11.5557 7.75416 11.5557 7.99962C11.5557 8.24508 11.3567 8.44406 11.1112 8.44406H4.88902C4.64356 8.44406 4.44458 8.24508 4.44458 7.99962Z"
        fill="white"
      />
    </svg>
  );
}
CheckSquare.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

CheckSquare.defaultProps = {
  title: '',
};

export default injectIntl(CheckSquare);
