import React from 'react';
import PropTypes from 'prop-types';

const Paragraph = props => {
  const { title, ...otherProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <title>{title ? <title>{title}</title> : null}</title>
      <rect x="4" y="19" width="11" height="2" />
      <rect x="13" y="7" width="4" height="2" />
      <rect x="7" y="7" width="4" height="2" />
      <rect x="7" y="11" width="4" height="2" />
      <rect x="7" y="15" width="4" height="2" />
      <rect x="13" y="11" width="4" height="2" />
      <rect x="13" y="15" width="2" height="2" />
      <path d="M5,14.55V12.44H5V5.11L19,5c0,.12,0,1.38,0,1.58V15h2V6c0-2.58,0-3-1.45-3H4.45A1.49,1.49,0,0,0,3,4.53V19.88c0,1.52,1.27,1.05,2,1.05Z" />
      <polygon points="21 19 21 17 19 17 19 19 17 19 17 21 19 21 19 23 21 23 21 21 23 21 23 19 21 19" />
    </svg>
  );
};

Paragraph.propTypes = {
  title: PropTypes.string,
};

Paragraph.defaultProps = {
  title: '',
};

export default Paragraph;
