import React from 'react';
import PropTypes from 'prop-types';

function AlertCircle(props) {
  const { title, ...otherProps } = props;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3.99992C9.37262 3.99992 4.00004 9.3725 4.00004 15.9999C4.00004 22.6273 9.37262 27.9999 16 27.9999C22.6275 27.9999 28 22.6273 28 15.9999C28 9.3725 22.6275 3.99992 16 3.99992ZM1.33337 15.9999C1.33337 7.89974 7.89986 1.33325 16 1.33325C24.1002 1.33325 30.6667 7.89974 30.6667 15.9999C30.6667 24.1001 24.1002 30.6666 16 30.6666C7.89986 30.6666 1.33337 24.1001 1.33337 15.9999Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 9.33325C16.7363 9.33325 17.3333 9.93021 17.3333 10.6666V15.9999C17.3333 16.7363 16.7363 17.3333 16 17.3333C15.2636 17.3333 14.6666 16.7363 14.6666 15.9999V10.6666C14.6666 9.93021 15.2636 9.33325 16 9.33325Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6666 21.3333C14.6666 20.597 15.2636 20 16 20H16.0133C16.7497 20 17.3466 20.597 17.3466 21.3333C17.3466 22.0697 16.7497 22.6667 16.0133 22.6667H16C15.2636 22.6667 14.6666 22.0697 14.6666 21.3333Z"
      />
    </svg>
  );
}
AlertCircle.propTypes = {
  title: PropTypes.string,
};

AlertCircle.defaultProps = {
  title: null,
};

export default AlertCircle;
