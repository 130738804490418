import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';
import globalMessages from 'translations/messages/global-messages';

const Grid = props => (
  <svg
    id="grid"
    data-name="Grid"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <title>
      {props.title || props.intl.formatMessage(globalMessages.grid)}
    </title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.3335 1.99998C1.3335 1.63179 1.63197 1.33331 2.00016 1.33331H6.66683C7.03502 1.33331 7.3335 1.63179 7.3335 1.99998V6.66665C7.3335 7.03484 7.03502 7.33331 6.66683 7.33331H2.00016C1.63197 7.33331 1.3335 7.03484 1.3335 6.66665V1.99998ZM2.66683 2.66665V5.99998H6.00016V2.66665H2.66683Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.6665 1.99998C8.6665 1.63179 8.96498 1.33331 9.33317 1.33331H13.9998C14.368 1.33331 14.6665 1.63179 14.6665 1.99998V6.66665C14.6665 7.03484 14.368 7.33331 13.9998 7.33331H9.33317C8.96498 7.33331 8.6665 7.03484 8.6665 6.66665V1.99998ZM9.99984 2.66665V5.99998H13.3332V2.66665H9.99984Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.6665 9.33335C8.6665 8.96516 8.96498 8.66669 9.33317 8.66669H13.9998C14.368 8.66669 14.6665 8.96516 14.6665 9.33335V14C14.6665 14.3682 14.368 14.6667 13.9998 14.6667H9.33317C8.96498 14.6667 8.6665 14.3682 8.6665 14V9.33335ZM9.99984 10V13.3334H13.3332V10H9.99984Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.3335 9.33335C1.3335 8.96516 1.63197 8.66669 2.00016 8.66669H6.66683C7.03502 8.66669 7.3335 8.96516 7.3335 9.33335V14C7.3335 14.3682 7.03502 14.6667 6.66683 14.6667H2.00016C1.63197 14.6667 1.3335 14.3682 1.3335 14V9.33335ZM2.66683 10V13.3334H6.00016V10H2.66683Z"
    />
  </svg>
);

Grid.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};
export default compose(injectIntl)(Grid);
