import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import globalMessages from 'translations/messages/global-messages';

function MessageMulti(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{props.title || props.intl.formatMessage(globalMessages.message)}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.22222 4C3.16329 4 3.10676 4.02341 3.06509 4.06509C3.02341 4.10676 3 4.16329 3 4.22222V11.5858L3.73734 10.8484C3.92487 10.6609 4.17923 10.5556 4.44444 10.5556H5.5C6.05228 10.5556 6.5 11.0033 6.5 11.5556C6.5 12.1078 6.05228 12.5556 5.5 12.5556H4.85866L2.70711 14.7071C2.42111 14.9931 1.99099 15.0787 1.61732 14.9239C1.24364 14.7691 1 14.4045 1 14V4.22222C1 3.63285 1.23413 3.06762 1.65087 2.65087C2.06762 2.23413 2.63285 2 3.22222 2H12.7778C13.3671 2 13.9324 2.23413 14.3491 2.65087C14.7659 3.06762 15 3.63285 15 4.22222V5.5C15 6.05228 14.5523 6.5 14 6.5C13.4477 6.5 13 6.05228 13 5.5V4.22222C13 4.16329 12.9766 4.10676 12.9349 4.06509C12.8932 4.02341 12.8367 4 12.7778 4H3.22222Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5556 10C20.6734 10 20.7865 10.0468 20.8698 10.1302C20.9532 10.2135 21 10.3266 21 10.4444V19.5858L19.8182 18.404C19.6307 18.2165 19.3763 18.1111 19.1111 18.1111H10.4444C10.3266 18.1111 10.2135 18.0643 10.1302 17.9809C10.0468 17.8976 10 17.7845 10 17.6667V10.4444C10 10.3266 10.0468 10.2135 10.1302 10.1302C10.2135 10.0468 10.3266 10 10.4444 10H20.5556ZM22.284 8.71596C21.8256 8.25754 21.2039 8 20.5556 8H10.4444C9.79614 8 9.17438 8.25754 8.71596 8.71596C8.25754 9.17438 8 9.79614 8 10.4444V17.6667C8 18.315 8.25754 18.9367 8.71596 19.3951C9.17438 19.8536 9.79614 20.1111 10.4444 20.1111H18.6969L21.2929 22.7071C21.5789 22.9931 22.009 23.0787 22.3827 22.9239C22.7564 22.7691 23 22.4045 23 22V10.4444C23 9.79614 22.7425 9.17438 22.284 8.71596Z"
      />
    </svg>
  );
}
MessageMulti.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
  size: PropTypes.number,
};

MessageMulti.defaultProps = {
  title: '',
  size: 24,
};

export default injectIntl(MessageMulti);
