import React from 'react';

import { List, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ListLoader } from 'components/feedback/loaders';

import { EndMessage, LoadMoreHackBox } from './components';

export function ListInfinite({
  children,
  dataLength,
  dense,
  disablePadding,
  endMessage,
  hasMore,
  height = 300,
  isLoading,
  limit = 1,
  moreLoader,
  loadMoreLoaderRows = 3,
  next,
  scrollThreshold,
  sx,
  onScroll,
}) {
  const theme = useTheme();

  const getEndMessage = () => {
    if (isLoading || !endMessage || dataLength < limit) {
      return null;
    }
    if (typeof endMessage === 'string') {
      return <EndMessage message={endMessage} />;
    }
    if (typeof endMessage === 'object') {
      return endMessage;
    }
    return <EndMessage />;
  };

  const getLoadMoreComp = () => {
    if (!moreLoader) return null;
    if (typeof moreLoader === 'object') {
      return moreLoader;
    }
    return <ListLoader rows={loadMoreLoaderRows} />;
  };

  const endMsgComp = getEndMessage();
  const loadMoreComp = getLoadMoreComp();

  return (
    <List
      as={InfiniteScroll}
      dataLength={dataLength}
      dense={dense}
      disablePadding={disablePadding}
      endMessage={endMsgComp}
      hasMore={hasMore}
      height={height}
      loader={loadMoreComp}
      next={next}
      onScroll={onScroll}
      scrollThreshold={scrollThreshold}
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        ...theme.scrollbar.small,
        ...sx,
      }}
    >
      {children}
      {hasMore && <LoadMoreHackBox />}
    </List>
  );
}

ListInfinite.propTypes = {
  children: PropTypes.node.isRequired, // The data is passed as children to the InfiniteScroll component and the data should contain previous items too
  dataLength: PropTypes.number.isRequired, // set the length of the data.This will unlock the subsequent calls to next.
  dense: PropTypes.bool, // MUI property,
  disablePadding: PropTypes.bool, // MUI property,
  next: PropTypes.func, // 	a function which must be called after reaching the bottom. It must trigger some sort of action which fetches the next data
  endMessage: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]), // message to show at the end of the list (set to true for default)
  hasMore: PropTypes.bool, // it tells the InfiniteScroll component on whether to call next function on reaching the bottom and shows an endMessage to the user
  height: PropTypes.number,
  isLoading: PropTypes.bool,
  limit: PropTypes.number, // the end message is hidden when list.length < limit
  moreLoader: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]), // Loader component when loading more (set to true for default)
  loadMoreLoaderRows: PropTypes.number, // number of default skeleton rows when loading more
  scrollThreshold: PropTypes.string,
  sx: PropTypes.object, // MUI styles prop
  onScroll: PropTypes.func,
};
