const scope = 'FormPage/';
export const SET_FORM = `${scope}SET_FORM`;
export const GET_FORM = `${scope}GET_FORM`;
export const CLEAR_FORM = `${scope}CLEAR_FORM`;
export const SET_FORM_TREE = `${scope}SET_FORM_TREE`;
export const GET_FORM_TREE = `${scope}GET_FORM_TREE`;
export const SET_LOADING_FLAG = `${scope}SET_LOADING_FLAG`;
export const SET_SELECTED_TREE_NODE = `${scope}SET_SELECTED_TREE_NODE`;
export const SET_FORM_LANGUAGE = `${scope}SET_FORM_LANGUAGE`;
export const SAVE_FORM_TREE_ORDER = `${scope}SAVE_FORM_TREE_ORDER`;
