import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import globalMessages from 'translations/messages/global-messages';

function Diamond(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>
        {props.title || props.intl.formatMessage(globalMessages.warning)}
      </title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6771 7.22038L8.78037 0.323654C8.36364 -0.0930734 7.63637 -0.0930734 7.22037 0.323654L0.323642 7.22038C-0.106904 7.65093 -0.106904 8.35056 0.323642 8.78038L7.22037 15.6771C7.42837 15.8851 7.70546 16 8.00001 16C8.29455 16 8.57164 15.8851 8.78037 15.6771L15.6771 8.78038C16.1076 8.34984 16.1076 7.6502 15.6771 7.22038ZM8.36364 11.6364H7.63637C7.43564 11.6364 7.27273 11.4735 7.27273 11.2727V10.5455C7.27273 10.3447 7.43564 10.1818 7.63637 10.1818H8.36364C8.56437 10.1818 8.72728 10.3447 8.72728 10.5455V11.2727C8.72728 11.4735 8.56437 11.6364 8.36364 11.6364ZM8.36364 8.72729H7.63637C7.43564 8.72729 7.27273 8.56438 7.27273 8.36365V4.72729C7.27273 4.52656 7.43564 4.36365 7.63637 4.36365H8.36364C8.56437 4.36365 8.72728 4.52656 8.72728 4.72729V8.36365C8.72728 8.56438 8.56437 8.72729 8.36364 8.72729Z"
      />
    </svg>
  );
}
Diamond.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
};

Diamond.defaultProps = {
  title: '',
};

export default injectIntl(Diamond);
