export const styles = theme => ({
  searchTextField: {
    display: 'flex',
    flex: 1,
    maxWidth: 488,
    color: theme.palette.text.primary,
    background: theme.palette.background.primary,
    borderRadius: 12,
    maxHeight: 42,
    flexShrink: 0,
  },

  searchTextFieldWithTag: {
    display: 'flex',
    flex: 1,
    color: theme.palette.text.primary,
    background: theme.palette.background.primary,
    borderRadius: 12,
    flexShrink: 0,
    position: 'relative',
  },

  searchIcon: {
    color: theme.palette.text.primary,
  },
  clearIcon: {
    cursor: 'pointer',
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.text.dark,
    },
  },
  adornedStart: {
    paddingLeft: 10,
  },
  adornedEnd: {
    paddingRight: 12,
  },
  cssInput: {
    backgroundColor: theme.palette.background.primary, 
    border: 0,
    borderRadius: 12,
    '&:not(hover):not($cssFocused) $notchedOutline': {
      border: 0,
    },
    '&:hover:not($cssFocused) $notchedOutline': {
      border: 0,
      borderRadius: 12,
    },
    '&$cssFocused $notchedOutline': {
      border: `1px solid ${theme.palette.text.primary}`,
      borderRadius: 12,
    },
    '& input': {
      paddingTop: 8,
      paddingBottom: 10,
    },
  },
  notchedOutline: {},
  cssFocused: {},
});

export default styles;
