import { fromJS } from 'immutable';
import uuid from 'uuid';

import {
  SET_LOADING_FLAG,
  SET_ACTIVE_FILTERS,
  CLEAR_ACTIVE_FILTERS,
  SET_SEARCH_TEXT,
  CLEAR_ITEMS,
  SET_ITEMS,
  SET_ITEM,
  SET_SORT_DATA,
  CLEAR_SORT_DATA,
  CLEAR_SEARCH_TEXT,
  SET_CHECKED_ITEMS,
  CLEAR_CHECKED_ITEMS,
  RESET_TABLE,
  SET_TABLE_MOUNT_UNIQUE_KEY,
  UNCHECK_ITEM,
} from './constants';

const tableState = {
  activeFilters: {},
  checkedItems: [],
  sortData: {},
  searchText: '',
  loading: false,
  key: uuid.v4(),
  count: 0,
  list: [],
};

export const initialState = fromJS(tableState);

const infiniteTableReducer = (namespace = 'table') => (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case `${namespace}/${SET_TABLE_MOUNT_UNIQUE_KEY}`:
      return state.set('key', action.key);
    case `${namespace}/${SET_SEARCH_TEXT}`:
      return state.set('loading', true).set('searchText', action.text);
    case `${namespace}/${CLEAR_SEARCH_TEXT}`:
      return state
        .set('loading', true)
        .set('searchText', '')
        .set('list', fromJS(tableState.list));
    case `${namespace}/${CLEAR_ACTIVE_FILTERS}`:
      return state.set('loading', true).set('activeFilters', fromJS({}));
    case `${namespace}/${SET_ACTIVE_FILTERS}`:
      return state
        .set('loading', false) // change to false after update to react 18
        .set('activeFilters', fromJS(action.filters));
    case `${namespace}/${CLEAR_ITEMS}`:
      return state.set('list', fromJS(tableState.list)).set('count', 0);
    case `${namespace}/${SET_ITEMS}`:
      return state.set('list', fromJS(action.items)).set('count', action.count);
    case `${namespace}/${SET_SORT_DATA}`:
      return state.set('sortData', fromJS(action.sortData));
    case `${namespace}/${CLEAR_SORT_DATA}`:
      return state.set('sortData', fromJS({}));
    case `${namespace}/${SET_LOADING_FLAG}`:
      return state.set('loading', action.loading);
    case `${namespace}/${SET_CHECKED_ITEMS}`:
      return state.set('checkedItems', fromJS(action.items));
    case `${namespace}/${CLEAR_CHECKED_ITEMS}`:
      return state.set('checkedItems', fromJS([]));
    case `${namespace}/${RESET_TABLE}`:
      return initialState;
    case `${namespace}/${SET_ITEM}`: {
      const parsedList = JSON.parse(JSON.stringify(state.get('list'), null, 2));
      const updatedList = parsedList.map(item => {
        if (action.compare(item, action.item)) {
          return { ...action.item };
        }
        return item;
      });
      return state.set('list', fromJS(updatedList));
    }

    case UNCHECK_ITEM: {
      return state.set(
        'checkedItems',
        state
          .get('checkedItems')
          .delete(
            state.get('checkedItems').findIndex(x => x.get('id') === action.id),
          ),
      );
    }

    default:
      return state;
  }
};

export default infiniteTableReducer;
